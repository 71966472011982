import { InteractionStatus } from '@azure/msal-browser';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelectChange } from '@angular/material/select';
import { ValueSelect } from 'src/modules/types';

@Component({
  selector: 'wag-back-alloction-selection',
  template: `
  <form [formGroup]="allocationFiltersForm" class="well-filters">
  <mat-form-field appearance="outline" floatLabel="always">
    <mat-label>Asset</mat-label>
    <mat-select formControlName="Asset"  multiple   (selectionChange)="onSelectValue($event)" [placeholder]="'Select Asset'"
      required disableOptionCentering panelClass="my-panel-class-cl" [compareWith]="compareWithFn">
      <mat-option *ngFor="let asset of assets" [value]="asset">{{ asset }}</mat-option>
    </mat-select>
    <mat-error *ngIf="allocationFiltersForm.controls.Asset.errors?.required"> Please select an asset </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" floatLabel="always">
    <mat-label>Field</mat-label>
    <mat-select formControlName="Field" multiple (selectionChange)="onSelectValue($event)" placeholder="Select Field"
      required disableOptionCentering panelClass="my-panel-class-cl" [compareWith]="compareWithFn">
      <mat-option *ngFor="let field of fields" [value]="field">{{ field }}</mat-option>
    </mat-select>
    <mat-error *ngIf="allocationFiltersForm.controls.Field.errors?.required"> Please select a field </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" floatLabel="always">
  <mat-label>Well</mat-label>
  <mat-select formControlName="Well" multiple (selectionChange)="onSelectValue($event)" placeholder="Select Well"
    required disableOptionCentering panelClass="my-panel-class-cl" [compareWith]="compareWithFn2">
    <mat-option *ngFor="let well of wells" [value]="well">{{ well }}</mat-option>
  </mat-select>
  <mat-error *ngIf="allocationFiltersForm.controls.Well.errors?.required"> Please select a well </mat-error>
</mat-form-field>

<div class="checkbox-bloc">
      <fieldset class="out-line">
      <legend class="legend-title"></legend>
      <div *ngFor="let target of TARGET_LIST; let i = index" formArrayName="targetTypeList" class="mr-1">
      <mat-checkbox color="warn"  [formControlName]="i"(change)="onClickCheckbox($event,target)" [value]="target" >{{target}} </mat-checkbox>
      </div>
    </fieldset>

 </div>

  <button class="primary-btn" color="primary" [disabled]="allocationFiltersForm.invalid"
    (click)="onClickGetWell()">Get Wells</button>
</form>
  `,
  styleUrls: ['./back-alloction-selection.component.scss']
})
export class BackAlloctionSelectionComponent implements OnChanges ,OnInit {
  TARGET_LIST: string[] = ['Oil','Qgas','Water']
  @Input() assets!: string[] | null;
  @Input() fields!: string[] | null;
  @Input() wells!: string[] | null;
  @Input() passedData:any;

  @Output() valueSelected = new EventEmitter<ValueSelect>();
  @Output() allocationFetch = new EventEmitter();
  @Output() targetType = new EventEmitter();

  allocationFiltersForm: FormGroup = this.formBuilder.group({
    'Asset': [this.assets],
    'Field': [{ value: '', disabled: true }],
    'Well': [{ value: '', disabled: true }],
    'targetTypeList': this.formBuilder.array(this.TARGET_LIST.map((x, i) => {
      if (i == 0) return true
      else { return false }
    })),
  });
  constructor(private formBuilder:FormBuilder) { }
  ngOnInit(): void {

    if(this.passedData!=undefined){
      this.allocationFiltersForm.controls['Asset'].setValue([this.passedData.assets]);
      this.allocationFiltersForm.controls['Field'].enable();

      this.allocationFiltersForm.controls['Field'].setValue([this.passedData.fields]);

      this.allocationFiltersForm.controls['Well'].enable();
      this.valueSelected.emit({ control: 'Field', value: this.passedData.fields });

      this.allocationFiltersForm.controls['Well'].patchValue(this.passedData.wells);
      // this.valueSelected.emit({ control: 'Well', value: this.passedData.wells });
      let indexArray: boolean[] = [];

      this.passedData.tgt.forEach((t:string) => {
        if(t==='Qoil'){
          indexArray.push(true);
        }else if(t==='Qgas'){
          indexArray.push(true);
        }else if(t==='Qwat'){
          indexArray.push(true);
        }
        else{
          indexArray.push(false);
        }
      });

      const formElement = this.allocationFiltersForm.controls['targetTypeList'] as FormArray
      formElement.controls.forEach(x => x.setValue(false));
      formElement.controls.forEach((ctrl, i) => {
        indexArray.forEach((t,j) => {
          if (i === j) {
            ctrl.setValue(t);
          }
        })
      })
     setTimeout(() => {
      this.allocationFetch.emit({'formValue':this.allocationFiltersForm.value,"isCatalouge":true});
     }, 100);
    }
    // this.allocationFiltersForm.controls['Asset'].setValue( Object.assign([], this.passedData.assets));
  }


  onSelectValue($event: MatSelectChange): void {
    if ($event.source.ngControl.name) {

      this.valueSelected.emit({ control: $event.source.ngControl.name.toString(), value: $event.value.toString() });
      if ($event.source.ngControl.name === 'Asset') {
        this.allocationFiltersForm.controls['Field'].enable();
      }
      if ($event.source.ngControl.name === 'Field') {
        this.allocationFiltersForm.controls['Well'].enable();
      }
    } else if ($event.source.ngControl.name === 'Field') {
      this.allocationFiltersForm.controls['Well'].enable();

    }
  }

  ngOnChanges(changes: SimpleChanges): void {
     console.log(changes)
    // if (changes.assets.currentValue!=undefined) {
    //   this.allocationFiltersForm.controls['Field'].disable();
    //   this.allocationFiltersForm.controls['Field'].setValue('');
    //   this.allocationFiltersForm.setErrors({ 'invalid': true });
    // }
  }
  onClickCheckbox(event: MatCheckboxChange, wellType: string) {
    // do nothing
  }

  onClickGetWell(){
  this.allocationFetch.emit({'formValue':this.allocationFiltersForm.value,"isCatalouge":false});
  }

  compareWithFn(optionOne: any, optionTwo: any): boolean {
    if (typeof optionOne === "string" && typeof optionTwo === "string") {
      return optionOne === optionTwo;
    }
    else{
      return false;
    }
  }
  compareWithFn2(optionOne: any, optionTwo: any): boolean {
    if (typeof optionOne === "string" && typeof optionTwo === "string") {
      return optionOne === optionTwo;
    }
    else{
      return false;
    }
  }

}
