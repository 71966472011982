import {Action, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {ProductionAllocated} from 'src/modules/types';
import {GetProductionAllocated} from './actions';

@State<ProductionAllocated>({
  name: 'productionAllocated',
  defaults: {
    date: [],
    Qliq_d: [],
    Qoil_c_d: [],
    GOR_d: [],
    WCT_d: [],
    Qgas_c_d: []
  }
})
@Injectable()
export class ProductionAllocatedState {
  @Action(GetProductionAllocated)
  getProductionAllocated(context: StateContext<ProductionAllocated>, {payload}: GetProductionAllocated): void {
    context.setState(payload);
  }
}
