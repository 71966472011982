import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelectChange } from '@angular/material/select';
import { SaveModelTableData, ValueSelect } from 'src/modules/types';

@Component({
  selector: 'wag-selection',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./selection.component.scss'],
  template: `
    <h2>Well selection</h2>
    <form [formGroup]="wellFiltersForm" class="well-filters">
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Asset</mat-label>
        <mat-select formControlName="Asset" (selectionChange)="onSelectValue($event)" [placeholder]="'Select Asset'"
          required disableOptionCentering panelClass="my-panel-class">
          <mat-option *ngFor="let asset of assets" [value]="asset">{{ asset }}</mat-option>
        </mat-select>
        <mat-error *ngIf="wellFiltersForm.controls.Asset.errors?.required"> Please select an asset </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Field</mat-label>
        <mat-select formControlName="Field" (selectionChange)="onSelectValue($event)" placeholder="Select Field"
          required disableOptionCentering panelClass="my-panel-class">
          <mat-option *ngFor="let field of fields" [value]="field">{{ field }}</mat-option>
        </mat-select>
        <mat-error *ngIf="wellFiltersForm.controls.Field.errors?.required"> Please select a field </mat-error>
      </mat-form-field>
      <div class="checkbox-bloc">
      <fieldset class="out-line">
      <legend class="legend-title">Well Type:</legend>
      <div *ngFor="let wellType of WELL_TYPE_LIST; let i = index" formArrayName="wellTypeList">
      <mat-checkbox color="warn" [formControlName]="i"(change)="onClickCheckbox($event,wellType)">{{wellType}} </mat-checkbox>
    </div>
    </fieldset>
      
      </div>
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Well</mat-label>
        <mat-select formControlName="Well" (selectionChange)="onSelectValue($event)" placeholder="Select Well"
          required disableOptionCentering panelClass="my-panel-class">
          <mat-option *ngFor="let well of wells" [value]="well">{{ well }}</mat-option>
        </mat-select>
        <mat-error *ngIf="wellFiltersForm.controls.Well.errors?.required"> Please select a well </mat-error>
      </mat-form-field>
      <button class="primary-btn" color="primary" [disabled]="wellFiltersForm.invalid"
        (click)="wellFetch.emit()">Visualize Well</button>
    </form>
  `
})
export class SelectionComponent implements OnInit, OnChanges {
  WELL_TYPE_LIST: string[] = ['Producer', 'Injector']
  @Input() assets!: string[] | null;
  @Input() fields!: string[] | null;
  @Input() wells!: string[] | null;
  @Output() valueSelected = new EventEmitter<ValueSelect>();
  @Output() wellFetch = new EventEmitter();
  @Output() wellFetchCatalog = new EventEmitter();
  @Output() wellType = new EventEmitter();
  @Input() passedData: any;
  @Input() saveModelTableData!: SaveModelTableData[] | null;
  @Output() ClickSavedModelItemCatalog = new EventEmitter();

  wellFiltersForm: FormGroup = this.formBuilder.group({
    'Asset': [this.assets],
    'Field': [{ value: '', disabled: true }],
    'wellTypeList': this.formBuilder.array(this.WELL_TYPE_LIST.map((x, i) => {
      if (i == 0) return true
      else { return false }
    })),
    'Well': [{ value: '', disabled: true }]
  });


  constructor(private formBuilder: FormBuilder) { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.assets && !this.passedData) {
      this.wellFiltersForm.controls['Field'].disable();
      this.wellFiltersForm.controls['Field'].setValue('');
      this.wellFiltersForm.controls['Well'].disable();
      this.wellFiltersForm.controls['Well'].setValue('');
      this.wellFiltersForm.setErrors({ 'invalid': true });
    }
  }
  ngOnInit(): void {
    if(this.passedData!==undefined){
    if (this.passedData.asset_id !== null && this.passedData.asset_id !== undefined) {
      this.wellFiltersForm.controls['Asset'].patchValue(this.passedData.asset_id);
      if (this.passedData.asset_id !== null) {
        this.wellFiltersForm.controls['Field'].enable();
        this.wellFiltersForm.controls["Field"].setValue(this.passedData.field_id);

        if (this.wells != null && this.wells != undefined && this.wells.length == 0) {
          this.valueSelected.emit({ control: "Asset", value: this.passedData.asset_id });
          this.valueSelected.emit({ control: "Field", value: this.passedData.field_id });
          this.valueSelected.emit({ control: "Well", value: this.passedData.well_id });
        }

        if (this.wells != null && this.wells != undefined && this.wells.length > 0) {
          this.valueSelected.emit({ control: "Asset", value: this.passedData.asset_id });
          this.valueSelected.emit({ control: "Field", value: this.passedData.field_id });
          this.valueSelected.emit({ control: "Well", value: this.passedData.well_id });
        }

      }

      let indexArray: number[] = [];

      this.WELL_TYPE_LIST.forEach((data, i) => {
        if (this.passedData.well_type != undefined) {
          if (data.toLowerCase() === this.passedData.well_type) {
            indexArray.push(i);
          }

        }
      })

      const formElement = this.wellFiltersForm.controls['wellTypeList'] as FormArray
      formElement.controls.forEach(x => x.setValue(false));
      formElement.controls.forEach((ctrl, i) => {
        indexArray.forEach(j => {
          if (i === j) {
            ctrl.setValue(true);
          }
        })
      })

      if (this.passedData.field_id !== null) {

        this.wellFiltersForm.controls['Well'].enable();
        this.wellFiltersForm.controls['Well'].setValue(this.passedData.well_id);
        this.wellFetchCatalog.emit();
      }

    }
  }
  }


  onSelectValue($event: MatSelectChange): void {
    if ($event.source.ngControl.name) {
      this.valueSelected.emit({ control: $event.source.ngControl.name.toString(), value: $event.value });
      if ($event.source.ngControl.name === 'Asset') {
        this.wellFiltersForm.controls['Field'].enable();
      } else if ($event.source.ngControl.name === 'Field') {
        this.wellFiltersForm.controls['Well'].enable();
      }
    }
  }

  onClickCheckbox(event: MatCheckboxChange, wellType: string) {
    this.wellType.emit({ checked: event.checked, value: wellType });
  }
}