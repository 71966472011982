import {Component, OnInit} from '@angular/core';
import {environment} from '@environments/environment';
import {AuthService} from '../../services';

@Component({
  selector: 'wag-unauthorized',
  template: `
    <p>Access Unauthorized</p>

    <div *ngIf="debug">{{ error }}</div>
  `
})
export class UnauthorizedContainer implements OnInit {
  error!: string;
  debug: boolean = environment.debug.msal;

  constructor(private authService: AuthService) {
  }

  ngOnInit(): void {
    if (this.authService.error) this.error = this.authService.error.message;
  }
}
