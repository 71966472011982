import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FieldRangeDates, PlotData, WellQcTableData } from 'src/modules/types';
@Component({
  selector: 'wag-input-data-qc',
  template: `
    <div class="section-container">
      <h2>Input Data Quality Check</h2>

      <wag-date-filters [dateRange]="qcDateRange"
                        [minMaxRange]="minMaxRange"
                        (dateRangeChange)="qcDateRangeChange.emit($event)"></wag-date-filters>
    <ng-container *ngIf="wellTypeInjector===true">
    <h4 class="chart-title">PDMS - Injection Data
        <img src="./../../../../assets/info.svg" class="info-icon" [mdePopoverTriggerFor]="pdmsDataPopOver"
        mdePopoverTriggerOn="hover" mdePopoverPositionY="above" mdePopoverPositionX="after">
    </h4>
    </ng-container>

    <ng-container *ngIf="wellTypeInjector===false">
    <h4 class="chart-title">Production Data
        <img src="./../../../../assets/info.svg" class="info-icon" [mdePopoverTriggerFor]="pdmsDataPopOver"
        mdePopoverTriggerOn="hover" mdePopoverPositionY="above" mdePopoverPositionX="after">
    </h4>
    
  
    </ng-container>
    <ng-container *ngIf="wellTypeInjector===false">
    <wag-well-graph  [data]="productionDataGraph" [layout]="productionDataLayout" [graphTitle]="'ProductionData'" [selectedWell]="selectedWell"></wag-well-graph>
    </ng-container>
    
    <ng-container *ngIf="wellTypeInjector===true">
    <wag-well-graph  [data]="productionDataGraph" [layout]="productionDataLayout"></wag-well-graph>
    </ng-container>

    <ng-container *ngIf="wellTypeInjector===true">
    <h4 class="chart-title">PI - Injection Monitoring 
        <img src="./../../../../assets/info.svg" class="info-icon" [mdePopoverTriggerFor]="piDataPopOver"
         mdePopoverTriggerOn="hover">
    </h4>
    </ng-container>

    <ng-container *ngIf="wellTypeInjector===false"> 
    <h4 class="chart-title">Production Monitoring - PI Data
        <img src="./../../../../assets/info.svg" class="info-icon" [mdePopoverTriggerFor]="piDataPopOver"
         mdePopoverTriggerOn="hover">
    </h4>
    </ng-container>

    <ng-container *ngIf="wellTypeInjector===false"> 
     <wag-well-graph  [data]="productionMonitoringGraph" [layout]="productionMonitoringLayout" [graphTitle]="'ProductionMonitoring'" [selectedWell]="selectedWell"></wag-well-graph>
     </ng-container>

     <ng-container *ngIf="wellTypeInjector===true">
     <wag-well-graph  [data]="productionMonitoringGraph" [layout]="productionMonitoringLayout"></wag-well-graph>
     </ng-container>
     
     <wag-well-qc-table [wellQcTableData]="wellQcTableData" [wellTypeInjector]="wellTypeInjector"></wag-well-qc-table>
    </div>

    <mde-popover #piDataPopOver="mdePopover" [mdePopoverOverlapTrigger]="false">
    <mat-card class="card-width" >
      <mat-card-content>
      Daily Average from relevant PI sensor (as available in Tmore database)
      </mat-card-content>
    </mat-card>
  </mde-popover>

    <mde-popover #pdmsDataPopOver="mdePopover" [mdePopoverOverlapTrigger]="false">
    <mat-card class="card-width" >
      <mat-card-content>
      NPDMS: daily allocated data from NPDMS or PDMS database
      </mat-card-content>
    </mat-card>
  </mde-popover>

  `,
  styleUrls: ['./input-data-qc.component.scss']
})
export class InputDataQcComponent {
  @Input() wellQcTableData!: WellQcTableData[] | null;
  @Input() productionDataGraph!: PlotData[];
  @Input() productionMonitoringGraph!: PlotData[];
  @Input() productionMonitoringLayout!: any;
  @Input() productionDataLayout!: any;
  @Input() minMaxRange!: FieldRangeDates | null;
  @Input() qcDateRange!: FieldRangeDates | null;
  @Output() qcDateRangeChange: EventEmitter<FieldRangeDates> = new EventEmitter<FieldRangeDates>();
  @Input() wellTypeInjector!: boolean;
  @Input() selectedWell!: any;
  @Input() graphTitle?: any;

}
