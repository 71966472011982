import {Action, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {GetFieldRangeDates} from './actions';
import {FieldRangeDates} from 'src/modules/types';

@State<FieldRangeDates>({
  name: 'fieldRangeDates',
  defaults: undefined
})
@Injectable()
export class FieldRangeDatesState {
  @Action(GetFieldRangeDates)
  getFieldRangeDates(context: StateContext<FieldRangeDates>, {payload}: GetFieldRangeDates): void {
    context.setState(payload);
  }
}
