import {Action, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {GetWells} from './actions';

@State<string[]>({
  name: 'wells',
  defaults: []
})
@Injectable()
export class WellsState {
  @Action(GetWells)
  getAssets(context: StateContext<string[]>, {payload}: GetWells): void {
    context.setState([...payload]);
  }
}
