import {Component, Input} from '@angular/core';

@Component({
  selector: 'wag-field-daily-well-data-header',
  template: `
    <div class="container" [class.injectors]="headerName === 'injectors'">
      <div *ngFor="let data of headersList" class="data-container">
        <div class="data-type">{{data}}</div>
        <div class="data-label">
          <span>Potential</span>
          <span>Estimated</span>
          <span>Allocated</span>
          <span>Modeled</span>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./field-daily-well-data-header.component.scss']
})
export class FieldDailyWellDataHeaderComponent {
  @Input() headersList!: string[];
  @Input() headerName!: string;
}
