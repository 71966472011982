import {Action, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {MachineLearningData} from 'src/modules/types';
import {GetMachineLearningData} from './actions';


@State<MachineLearningData>({
  name: 'machineLearningData',
  defaults: {} as MachineLearningData
})
@Injectable()
export class MachineLearningDataState {
  @Action(GetMachineLearningData)
  getMachineLearningData(context: StateContext<MachineLearningData>, {payload}: GetMachineLearningData): void {
    context.setState(payload);
  }
}
