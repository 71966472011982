import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppSandbox } from 'src/modules/app/app.sandbox';
import { LoaderService } from 'src/modules/app/services';



@Component({
  selector: 'wag-model-stage',
  template: `

  <wag-loader></wag-loader>

  <mat-dialog-content class="mat-typography">
  <div>
      <h1 mat-dialog-title>
          <mat-icon class="delete-icon"> error </mat-icon>
          Model Status
          <img src="./../../../../assets/close.svg" class="close-icon" mat-dialog-close>
      </h1>
  </div>

      <div *ngIf="data.data.model_status === 'Archived'"class="field-container">
          <label class="sub-caption">
          Are you sure you want to change to <b>PRODUCTION</b> the following model
          </label>
          <label class="caption">
          {{data.data.model_name}}
          </label>
          <br>
          <label class="sub-caption">
        <ng-container *ngIf="data.stageResponse.production_model_name!==null">
        Model currently in production is <b>{{data.stageResponse.production_model_name}}</b> This model will be replaced and pushed to <b>ARCHIVE</b>
        </ng-container>
        <ng-container *ngIf="data.stageResponse.production_model_name===null">
        No model currently in Production for this well
        </ng-container>
          </label>

      </div>
      <div *ngIf="data.data.model_status !== 'Archived'"class="field-container">
          <label class="sub-caption">
          Are you sure want to <b>ARCHIVE</b> the following Model?
          </label>
          <label class="caption">
          {{data.data.model_name}}
          </label>
      </div>

  </mat-dialog-content>
<mat-dialog-actions align="end">
<div class="action-container">
<label class="hint" *ngIf="data.data.model_status === 'Archived'">Note that this change will be seen in Monitoring page within 30 minutes (inference function update frequency)</label>
  <button class="secondary-btn" mat-dialog-close style="margin-right: 16px">Cancel</button>
  <button class="primary-btn"   (click)="onClickAction()">Confirm</button>
</div>
</mat-dialog-actions>

  `,
  styleUrls: ['./model-stage.component.scss']
})
export class ModelStageComponent {
  affiliate!: string;
  isError: boolean = false;

  constructor(
    private sb: AppSandbox,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ModelStageComponent>, private loader: LoaderService) {
    this.sb.selectedAffiliate$.subscribe(affiliate => { this.affiliate = affiliate; });

  }

  onClickAction() {
    if (this.data.data.model_status === 'Archived') {
      this.callStageProduction()
    } else {
      this.callStageArchive()
    }
  }
  callStageArchive() {
    this.loader.isLoading.next(true);
    this.sb.getModelStageToArchive(this.affiliate, this.data.data.asset_id, this.data.data.well_id, this.data.data.target_value, this.data.data.run_id, this.data.data.model_name).subscribe(prodRes => {
      this.loader.isLoading.next(false);
      this.sb.loadModelCatalogueTableData(this.affiliate, this.data.data.asset_id, this.data.data.field_id);
      this.dialogRef.close({ "flag": true });
    })
  }
  callStageProduction() {
    this.loader.isLoading.next(true);
    if(this.data.stageResponse.production_model_name!==null){
      this.sb.getModelStageToArchive(this.affiliate, this.data.data.asset_id, this.data.data.well_id, this.data.data.target_value, this.data.stageResponse.production_run_id, this.data.stageResponse.production_model_name).subscribe(arhiveRes => {
        if (arhiveRes != undefined) {
          this.sb.getModelStageToProduction(this.affiliate, this.data.data.asset_id, this.data.data.well_id, this.data.data.target_value, this.data.data.run_id, this.data.data.model_name).subscribe(prodRes => {
            this.loader.isLoading.next(false);
            this.dialogRef.close({ "flag": true });
            this.sb.loadModelCatalogueTableData(this.affiliate, this.data.data.asset_id, this.data.data.field_id);
          })
        }
      })
    }
    else{
      this.sb.getModelStageToProduction(this.affiliate, this.data.data.asset_id, this.data.data.well_id, this.data.data.target_value, this.data.data.run_id, this.data.data.model_name).subscribe(prodRes => {
        this.loader.isLoading.next(false);
        this.dialogRef.close({ "flag": true });
        this.sb.loadModelCatalogueTableData(this.affiliate, this.data.data.asset_id, this.data.data.field_id);
      })
    }

  }

}
