import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {AuthService} from '../../services';

@Component({
  selector: 'wag-login',
  styleUrls: ['./login.container.scss'],
  template: `
    <div *ngIf="!authenticationPending" class="login-background">
      <div class="login-modal">
       <div>
       <div class="total-logo">
       <img src="./../../../../assets/total_energies_icon.png" alt="Total energies icon">
       
       </div>
       <div class="center-logo">
       <img src="./../../../../assets/t-dash-logo.png" class="t-dash-logo" alt="t-dash icon">
       </div>
       </div>
        <div class="modal-title">Tmore Data Analysis Synthesis Hub</div>
        <button class="primary-btn" (click)="login()">Login</button>
      </div>
    </div>
  `
})
export class LoginContainer implements OnInit, OnDestroy {
  authenticationPending = true;
  private readonly _destroying$ = new Subject<void>();

  constructor(private authService: AuthService) {
    
  }

  ngOnInit(): void {
    // Redirect authenticated user to home
    this.authService.redirectAuhenticatedUserToHome();

    // Update authentication process status
    this.authService.authenticationPending
      .pipe(
        filter((state: boolean) => !state),
        takeUntil(this._destroying$)
      )
      .subscribe({
        next: (state) => {
          this.authenticationPending = state;
        }
      });
  }

  login(): void {
    this.authService.login();
  }

  ngOnDestroy(): void {
    this._destroying$.complete();
  }
}
