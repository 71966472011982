
import {PlotData} from "src/modules/types";

export const PRODUCTION_MONITORING_PLOT_DATA_INJECTOR: PlotData[] = [
  {name: "optime-(hr)", type: "scatter", mode: "lines", marker: {color: "#e91e63"}, yaxis: 'y1'},
  {name: "whp-(bar)", type: "scatter", mode: "lines", marker: {color: "#2196f3"}, yaxis: "y2"},
  {name: "wht-(°C)", type: "scatter", mode: "lines", marker: {color: "#ff9800"}, yaxis: "y3"},
  {name: "bhp-(bar)", type: "scatter", mode: "lines", marker: {color: "#0d47a1"}, yaxis: "y4"},
  {name: "bht-(°C)", type: "scatter", mode: "lines", marker: {color: "#ff5722"}, yaxis: "y5"},
  {name: "choke_opening-(%)", type: "scatter", mode: "lines", marker: {color: "#6C7678"}, yaxis: "y6"},
  {name: "Qginj-(kSm3/d)", type: "scatter", mode: "lines", marker: {color: "#FF0000"}, yaxis: "y7"},
  {name: "Qwinj-(bbl/d)", type: "scatter", mode: "lines", marker: {color: "#0d2bd6"}, yaxis: "y8"},
];

export const PRODUCTION_PLOT_DATA_INJECTOR: PlotData[] = [
  {name: "optime-(hr)", type: "scatter", mode: "lines", marker: {color: "#e91e63"}, yaxis: "y1"},
  {name: "whp-(bar)", type: "scatter", mode: "lines", marker: {color: "#2196f3"}, yaxis: "y2"},
  {name: "wht-(°C)", type: "scatter", mode: "lines", marker: {color: "#ff9800"}, yaxis: "y3"},
  {name: "bhp-(bar)", type: "scatter", mode: "lines", marker: {color: "#0d47a1"}, yaxis: "y4"},
  {name: "bht-(°C)", type: "scatter", mode: "lines", marker: {color: "#ff5722"}, yaxis: "y5"},
  {name: "choke_opening-(%)", type: "scatter", mode: "lines", marker: {color: "#6C7678"}, yaxis: "y6"},
  {name: "Qginj-(kSm3/d)", type: "scatter", mode: "lines", marker: {color: "#FF0000"}, yaxis: "y7"},
  {name: "Qwinj-(bbl/d)", type: "scatter", mode: "lines", marker: {color: "#0d2bd6"}, yaxis: "y8"},

];

export const MOPRODUCTION_MONITORING_LAYOUT_INJECTOR = {
  title: {
    text: '',
    font: {
      family: `Roboto, "Helvetica Neue", sans- serif`,
      size: 16
    },
    x: 0
  },
  xaxis: {
    domain: [0.20, 0.75]
  },
  yaxis: {
    //title: "Hr",
    side: "left",
    position: 0.00,
    titlefont: {
      color: "#e91e63"
    },
    tickfont: {
      color: "#e91e63"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: true,
  },
  yaxis2: {
    //title: "WH Pressure, Bar",
    side: "left",
    overlaying: "y",
    position: 0.05,
    titlefont: {
      color: "#2196f3"
    },
    tickfont: {
      color: "#2196f3"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: true,
  },
  yaxis3: {
    //title: "WH Temperature, Degree C",
    side: "left",
    overlaying: "y",
    position: 0.08,
    titlefont: {
      color: "#ff9800"
    },
    tickfont: {
      color: "#ff9800"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: true,
  },
  yaxis4: {
    //title: "BH Pressure, Bar",
    side: "left",
    overlaying: "y",
    position: 0.11,
    titlefont: {
      color: "#0d47a1"
    },
    tickfont: {
      color: "#0d47a1"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: true,
  },
  yaxis5: {
    //title: "BH Temperature, Degree C",
    side: "left",
    overlaying: "y",
    position: 0.14,
    titlefont: {
      color: "#ff5722"
    },
    tickfont: {
      color: "#ff5722"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: true,
  },
  yaxis6: {
   // title: "-",
    side: "right",
    overlaying: "y",
    position: 0.78,
    titlefont: {
      color: "#6C7678"
    },
    tickfont: {
      color: "#6C7678"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: true,
  },
 yaxis7: {
   // title: "kSm3/d",
    side: "right",
    overlaying: "y",
    position: 0.83,
    titlefont: {
      color: "#FF0000"
    },
    tickfont: {
      color: "#FF0000"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: true,
  },
  yaxis8: {
    //title: "bbl/d",
    side: "right",
    overlaying: "y",
    titlefont: {
      color: "#0d2bd6"
    },
    tickfont: {
      color: "#0d2bd6"
    },
    position: 0.87,
    zeroline: true,
    showline: false,
    visible: true,
  },
  yaxis10: {
   // title: "kSm3/d",
    side: "right",
    overlaying: "y",
    titlefont: {
      color: "red"
    },
    tickfont: {
      color: "red"
    },
    position: 0.92,
    zeroline: true,
    showline: false,
    visible: true,
  },
  legend: {
    orientation: "h",
    side: "top",
    xanchor: "center",
    x: 0.5
  },
  showlegend: true
  
};

export const PRODUCTION_DATA_LAYOUT_INJECTOR= { 
  title: {
    text: '',
    font: {
      family: `Roboto, "Helvetica Neue", sans- serif`,
      size: 16
    },
    x: 0
  },
  legend: {
    orientation: "h",
    side: "top",
    xanchor: "center",
    x: 0.5
  },
  showlegend: true,
  xaxis: {
    domain: [0.20, 0.75]
  },
  yaxis: {
   // title: "Hr",
    side: "left",
    position: 0.00,
    titlefont: {
      color: "#e91e63"
    },
    tickfont: {
      color: "#e91e63"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: true,
  },
  yaxis2: {
    //title: "WH Pressure, Bar",
    side: "left",
    overlaying: "y",
    position: 0.04,
    titlefont: {
      color: "#2196f3"
    },
    tickfont: {
      color: "#2196f3"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: true,
  },
  yaxis3: {
    //title: "WH Temperature, Degree C",
    side: "left",
    overlaying: "y",
    position: 0.08,
    titlefont: {
      color: "#ff9800"
    },
    tickfont: {
      color: "#ff9800"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: true,
  },
  yaxis4: {
    side: "left",
    overlaying: "y",
    position: 0.12,
    titlefont: {
      color: "#0d47a1"
    },
    tickfont: {
      color: "#0d47a1"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: true,
  },
  yaxis5: {
    side: "left",
    overlaying: "y",
    position: 0.16,
    titlefont: {
      color: "#ff5722"
    },
    tickfont: {
      color: "#ff5722"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: true,
  },
  yaxis6: {
    side: "right",
    overlaying: "y",
    position: 0.80,
    titlefont: {
      color: "#6C7678"
    },
    tickfont: {
      color: "#6C7678"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: true,
  },
 yaxis7: {
    side: "right",
    overlaying: "y",
    position: 0.84,
    titlefont: {
      color: "#FF0000"
    },
    tickfont: {
      color: "#FF0000"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: true,
  },
  yaxis8: {
    side: "right",
    overlaying: "y",
    titlefont: {
      color: "#0d2bd6"
    },
    tickfont: {
      color: "#0d2bd6"
    },
    position: 0.88,
    zeroline: true,
    showline: false,
    visible: true,
  },
  yaxis9: {
    side: "right",
    overlaying: "y",
    titlefont: {
      color: "blue"
    },
    tickfont: {
      color: "blue"
    },
    position: 0.93,
    zeroline: true,
    showline: false,
    visible: true,
  },
  yaxis10: {
    side: "right",
    overlaying: "y",
    titlefont: {
      color: "red"
    },
    tickfont: {
      color: "red"
    },
    position: 0.95,
    zeroline: true,
    showline: false,
    visible: true,
  },

};