// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.dev.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  debug: {
    msal: true,
    router: true
  },
  appInsightInstrumentationKey: '861ce233-cfc0-4fcf-bde1-c0fe31273ea0',
  aad: {
    clientId: '893036f1-f6e7-4059-8aa5-0ab506f57d06',
    tenantId: '329e91b0-e21f-48fb-a071-456717ecc28e',
    redirectUri: '/callback',
    backends: [
      {
        uri: 'https://wag-backend-d.azurefd.net',
        scopes: ['api://eb61cee6-fa01-49e3-933a-eb348f3349ec/Access']
      }
    ]
  },
  apiUrl: 'https://wag-backend-d.azurefd.net/api',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
