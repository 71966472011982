import {Action, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import { SaveModelTableData } from 'src/modules/types';
import { GetSaveModelTableData } from './actions';



@State<SaveModelTableData[]>({
  name: 'saveModelTableData',
  defaults: {} as SaveModelTableData[]
})
@Injectable()
export class SaveModelTableDataState {
  @Action(GetSaveModelTableData)
  getSaveModelParams(context: StateContext<SaveModelTableData[]>, {payload}: GetSaveModelTableData): void {
    context.setState(payload);
  }
}
