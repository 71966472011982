import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppSandbox } from 'src/modules/app/app.sandbox';

@Component({
  selector: 'wag-delete-model',
  template: `
  <mat-dialog-content class="mat-typography">
  <div>
      <h1 mat-dialog-title>
          <mat-icon class="delete-icon">delete </mat-icon>
          Delete Model
          <img src="./../../../../assets/close.svg" class="close-icon" mat-dialog-close>
      </h1>
  </div>

      <div *ngIf="data.data.model_status === 'Archived'"class="field-container">
          <label class="sub-caption">
          The following models will be deleted permanently ?
          </label>
          <label class="caption">
          {{data.data.model_name}}
          </label>
      </div>
      <div *ngIf="data.data.model_status !== 'Archived'"class="field-container">
          <label class="sub-caption">
          The following Model is staged as Production. Do you really want to continue?
          </label>
          <label class="caption">
          {{data.data.model_name}}
          </label>
      </div>

  </mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="secondary-btn" mat-dialog-close style="margin-right: 16px">Cancel</button>
  <button class="primary-btn"   (click)="onClickAction()">Delete</button>
</mat-dialog-actions>

  `,
  styleUrls: ['./delete-model.component.scss']
})
export class DeleteModelComponent {
  affiliate!: string;
  isError: boolean = false;
  constructor(
    private sb: AppSandbox,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DeleteModelComponent>) {
    this.sb.selectedAffiliate$.subscribe(affiliate => { this.affiliate = affiliate; });

  }


  onClickAction() {
    if (this.data.data.model_status !== 'Archive') {
      this.sb.deleteSaveModel(this.affiliate, this.data.data.run_id,this.data.data.asset_id,this.data.data.well_id,this.data.data.model_name,this.data.data.target_value).subscribe(response => {
        setTimeout(() => {
          if (response.response === "Model name should be unique" || response.response === "Run id should be unique") {
            this.isError = true;
          } else {

            this.isError = false;
            this.sb.loadModelCatalogueTableData(this.affiliate, this.data.data.asset_id, this.data.data.field_id,);
            this.dialogRef.close({ "flag": true });
          }


        }, 200);
      })
    }
  }
}
