import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {Observable} from 'rxjs';
import {DataFilters, ProductionData, ProductionDataInjector, ProductionMonitoring, ProductionMonitoringInjector} from 'src/modules/types';
import {setHttpParams} from '../../tools';

@Injectable({
  providedIn: 'root'
})
export class WellProductionService {

  constructor(private http: HttpClient) { }

  getProductionData(aff:string,filterData: DataFilters): Observable<ProductionData> {
    return this.http.get<ProductionData>(
      `${environment.apiUrl}/${aff}/production-data`,
      {params: setHttpParams(filterData)}
    );
  }

  getProductionMonitoring(aff:string,filterData: DataFilters): Observable<ProductionMonitoring> {
    return this.http.get<ProductionMonitoring>(
      `${environment.apiUrl}/${aff}/production-monitoring`,
      {params: setHttpParams(filterData)}
    );
  }

  getProductionDataInjector(aff:string,filterData: DataFilters): Observable<ProductionDataInjector> {
    return this.http.get<ProductionDataInjector>(
      `${environment.apiUrl}/${aff}/injection-data`,
      {params: setHttpParams(filterData)}
    );
  }

  getProductionMonitoringInjector(aff:string,filterData: DataFilters): Observable<ProductionMonitoringInjector> {
    return this.http.get<ProductionMonitoringInjector>(
      `${environment.apiUrl}/${aff}/injection-monitoring`,
      {params: setHttpParams(filterData)}
    );
  }


}
