import {Component} from '@angular/core';

@Component({
  selector: 'wag-root',
  template: `
    <router-outlet></router-outlet>
  `
})
export class AppContainer {
}
