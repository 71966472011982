import {Component} from '@angular/core';

@Component({
  selector: 'wag-public-layout',
  template: `
    <div>
      <router-outlet></router-outlet>
    </div>
  `
})
export class PublicLayoutContainer {
}
