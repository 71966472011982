import {Action, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {WellDailyData} from 'src/modules/types';
import {GetInjectorWellsDailyData} from './actions';

@State<WellDailyData[]>({
  name: 'injectorWellsDailyData',
  defaults: []
})
@Injectable()
export class InjectorWellsDailyDataState {
  @Action(GetInjectorWellsDailyData)
  getInjectorWellsDailyData(context: StateContext<WellDailyData[]>, {payload}: GetInjectorWellsDailyData): void {
    context.setState(payload);
  }
}
