
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {Observable} from 'rxjs';
import {
  DailyDataFilters,
  DataFilters,
  DataType,
  FieldGraphData,
  FieldRangeDates,
  ProductionAllocated,
  ResponseI,
  WellDailyData,
  WellOptimesData
} from 'src/modules/types';
import {DailyData} from '../../containers';
import {setHttpParams} from '../../tools';

@Injectable({
  providedIn: 'root'
})
export class WellMonitoringService {

  constructor(private http: HttpClient) {
  }

  getDailyData(aff:string,filters: DailyDataFilters, well:string, typeofWell:string): Observable<ResponseI<DailyData>> {
    
    let params = setHttpParams(filters);
    params= params.append('well_type', typeofWell);
    params= params.append('well_name', well);
    return this.http.get<ResponseI<DailyData>>(`${environment.apiUrl}/${aff}/daily-data`, {params});
  }

  getProductionAllocated(aff:string,filterData: DataFilters, well:string, typeofWell:string): Observable<ResponseI<ProductionAllocated>> {
    let params = setHttpParams(filterData);
    params= params.append('well_type', typeofWell);
    params= params.append('well_name', well);
    return this.http.get<ResponseI<ProductionAllocated>>(
      `${environment.apiUrl}/${aff}/official-production-profile`,
      {params}
    );
  }

  getDataOnTimelapseWell(aff:string,dataType: DataType, filterData: DataFilters, well?:string): Observable<ResponseI<FieldGraphData>> {
   
    let params = setHttpParams(filterData);
    if(well!=undefined){
     params= params.append('well_name', well);
    }

     
    params = params.append('data_type', dataType);
    return this.http.get<ResponseI<FieldGraphData>>(`${environment.apiUrl}/${aff}/field-data-graph`, {params});
  }

  getWellstypeDailyData(aff:string,wellType: string, filterData: DataFilters, well:string): Observable<ResponseI<WellDailyData[]>> {
    let params = setHttpParams(filterData);
     params= params.append('well_name', well);
    const endpoint = wellType === 'producer' ? 'producers' : 'injectors';
    return this.http.get<ResponseI<WellDailyData[]>>(`${environment.apiUrl}/${aff}/daily-well-data/${endpoint}`, {params});
  }

  getWellOptimesforWell(aff:string, wellType: string, filterData: DataFilters, well:string): Observable<ResponseI<WellOptimesData[]>> {
   
    let params = setHttpParams(filterData);
    params= params.append('well_name', well);
    params = params.append('well_type', wellType === 'producer' ? 'producers' : 'injectors');
    return this.http.get<ResponseI<WellOptimesData[]>>(`${environment.apiUrl}/${aff}/well-optimes`, {params});
  }

  getwellRangeDates(aff:string,filterData: DataFilters,well:string, typeofWell:string): Observable<ResponseI<FieldRangeDates>> {
    
    let params = setHttpParams(filterData);
    params= params.append('well_name', well);
    params = params.append('well_type', typeofWell === 'producer' ? 'producers' : 'injectors');
    return this.http.get<ResponseI<FieldRangeDates>>(`${environment.apiUrl}/${aff}/dates-range`, {params});
  }
  getWellType(aff:string,well?:string):  Observable<ResponseI<any>> {
    
    let params = new HttpParams();
    if(well!=undefined){
    params = params.append('well', well);
    }
    return this.http.get<ResponseI<any>>(`${environment.apiUrl}/${aff}/well-type`, {params});
  }

}
