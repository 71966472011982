import {Component, Input} from '@angular/core';
import {InjectorType, WellDailyData, WellStatus} from 'src/modules/types';

@Component({
  selector: 'wag-well-data-line',
  template: `
    <div class="container" [ngClass]="{'open': wellData?.well_status === WellStatus.OPEN,
      'close': wellData?.well_status === WellStatus.CLOSE}">
      <div class="well-infos">
        <mat-icon>
          {{wellData?.well_status === WellStatus.OPEN ? 'check_circle' : 'close'}}
        </mat-icon>
        <div class="name-state">
          <span class="name">{{wellData?.wellname}}</span>
          <span class="state">{{wellData?.well_status}}</span>
        </div>
      </div>

      <div *ngIf="wellData?.oil_production" class="data-container producers">
      <div class="data liquid-data">
          <span class="data-item">{{wellData?.liquid_production?.potential | number | displayNA}}</span>
          <span class="data-item">
            {{wellData?.liquid_production?.estimated | number | displayNA}}
            <span *ngIf="wellData?.oil_production?.estimated_pourcentage"
                  class="percentage">{{wellData?.liquid_production?.estimated_pourcentage}}%</span>
          </span>
          <span class="data-item">
            {{wellData?.liquid_production?.allocated | number | displayNA}}
            <span *ngIf="wellData?.oil_production?.allocated_pourcentage"
                  class="percentage">{{wellData?.liquid_production?.allocated_pourcentage}}%</span>
          </span>
          <span class="data-item">
            {{wellData?.liquid_production?.modeled | number | displayNA}}
            <span *ngIf="wellData?.oil_production?.modeled_pourcentage"
                  class="percentage">{{wellData?.liquid_production?.modeled_pourcentage}}%</span>
          </span>
        </div>
        <span class="separator"></span>
        <div class="data oil-data">
          <span class="data-item">{{wellData?.oil_production?.potential | number | displayNA}}</span>
          <span class="data-item">
            {{wellData?.oil_production?.estimated | number | displayNA}}
            <span *ngIf="wellData?.oil_production?.estimated_pourcentage"
                  class="percentage">{{wellData?.oil_production?.estimated_pourcentage}}%</span>
          </span>
          <span class="data-item">
            {{wellData?.oil_production?.allocated | number | displayNA}}
            <span *ngIf="wellData?.oil_production?.allocated_pourcentage"
                  class="percentage">{{wellData?.oil_production?.allocated_pourcentage}}%</span>
          </span>
          <span class="data-item">
            {{wellData?.oil_production?.modeled | number | displayNA}}
            <span *ngIf="wellData?.oil_production?.modeled_pourcentage"
                  class="percentage">{{wellData?.oil_production?.modeled_pourcentage}}%</span>
          </span>
        </div>

        <div class="data gas-data">
          <span class="data-item">{{wellData?.gas_production?.potential | number:'1.0-0' | displayNA}}</span>
          <span class="data-item">
            {{wellData?.gas_production?.estimated | number:'1.0-0' | displayNA}}
            <span *ngIf="wellData?.gas_production?.estimated_pourcentage"
                  class="percentage">{{wellData?.gas_production?.estimated_pourcentage}}%</span>
          </span>
          <span class="data-item">
            {{wellData?.gas_production?.allocated | number:'1.0-0' | displayNA}}
            <span *ngIf="wellData?.gas_production?.allocated_pourcentage"
                  class="percentage">{{wellData?.gas_production?.allocated_pourcentage}}%</span>
          </span>
          <span class="data-item">
            {{wellData?.gas_production?.modeled | number:'1.0-0' | displayNA}}
            <span *ngIf="wellData?.gas_production?.modeled_pourcentage"
                  class="percentage">{{wellData?.gas_production?.modeled_pourcentage}}%</span>
          </span>
        </div>

        <span class="separator"></span>

        <div class="data gor-data">
          <span class="data-item">{{wellData?.GOR?.potential | number | displayNA}}</span>
          <span class="data-item">
            {{wellData?.GOR?.estimated | number | displayNA}}
            <span *ngIf="wellData?.GOR?.estimated_pourcentage"
                  class="percentage">{{wellData?.GOR?.estimated_pourcentage}}%</span>
          </span>
          <span class="data-item">
            {{wellData?.GOR?.allocated | number | displayNA}}
            <span *ngIf="wellData?.GOR?.allocated_pourcentage"
                  class="percentage">{{wellData?.GOR?.allocated_pourcentage}}%</span>
          </span>
          <span class="data-item">
            {{wellData?.GOR?.modeled | number | displayNA}}
            <span *ngIf="wellData?.GOR?.modeled_pourcentage"
                  class="percentage">{{wellData?.GOR?.modeled_pourcentage}}%</span>
          </span>
        </div>

        <span class="separator"></span>

        <div class="data wcut-data">
          <span class="data-item">{{wellData?.WCT?.potential | number | displayNA}}</span>
          <span class="data-item">
            {{wellData?.WCT?.estimated | number | displayNA}}
            <span *ngIf="wellData?.WCT?.estimated_pourcentage"
                  class="percentage">{{wellData?.WCT?.estimated_pourcentage}}%</span>
          </span>
          <span class="data-item">
            {{wellData?.WCT?.allocated | number | displayNA}}
            <span *ngIf="wellData?.WCT?.allocated_pourcentage"
                  class="percentage">{{wellData?.WCT?.allocated_pourcentage}}%</span>
          </span>
          <span class="data-item">
            {{wellData?.WCT?.modeled | number | displayNA}}
            <span *ngIf="wellData?.WCT?.modeled_pourcentage"
                  class="percentage">{{wellData?.WCT?.modeled_pourcentage}}%</span>
          </span>
        </div>
      </div>

      <div *ngIf="wellData?.water_injection" class="data-container injectors"
           [ngClass]="{'water-injector': wellData?.injector_type === InjectorType.WATER,
        'gas-injector': wellData?.injector_type === InjectorType.GAS}">
        <div class="data water-injected">
          <span class="data-item">{{wellData?.water_injection?.potential | number | displayNA}}</span>
          <span class="data-item">
            {{wellData?.water_injection?.estimated | number | displayNA}}
            <span *ngIf="wellData?.water_injection?.estimated_pourcentage"
                  class="percentage">{{wellData?.water_injection?.estimated_pourcentage}}%</span>
          </span>
          <span class="data-item">
            {{wellData?.water_injection?.allocated | number | displayNA}}
            <span *ngIf="wellData?.water_injection?.allocated_pourcentage"
                  class="percentage">{{wellData?.water_injection?.allocated_pourcentage}}%</span>
          </span>
          <span class="data-item">
            {{wellData?.water_injection?.modeled | number | displayNA}}
            <span *ngIf="wellData?.water_injection?.modeled_pourcentage"
                  class="percentage">{{wellData?.water_injection?.modeled_pourcentage}}%</span>
          </span>
        </div>

        <span class="separator"></span>

        <div class="data gas-injected">
          <span class="data-item">{{wellData?.gas_injection?.potential | number | displayNA}}</span>
          <span class="data-item">
            {{wellData?.gas_injection?.estimated | number | displayNA}}
            <span *ngIf="wellData?.gas_injection?.estimated_pourcentage"
                  class="percentage">{{wellData?.gas_injection?.estimated_pourcentage}}%</span>
          </span>
          <span class="data-item">
            {{wellData?.gas_injection?.allocated | number | displayNA}}
            <span *ngIf="wellData?.gas_injection?.allocated_pourcentage"
                  class="percentage">{{wellData?.gas_injection?.allocated_pourcentage}}%</span>
          </span>
          <span class="data-item">
            {{wellData?.gas_injection?.modeled | number | displayNA}}
            <span *ngIf="wellData?.gas_injection?.modeled_pourcentage"
                  class="percentage">{{wellData?.gas_injection?.modeled_pourcentage}}%</span>
          </span>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./well-data-line.component.scss']
})
export class WellDataLineComponent {
  @Input() wellData!: WellDailyData;
  WellStatus = WellStatus;
  InjectorType = InjectorType;
}
