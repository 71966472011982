import { Component, EventEmitter, Input, Output, ViewChild, OnInit, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SaveAllocationData, ValueSelect } from 'src/modules/types';
import { Router } from '@angular/router';

@Component({
  selector: 'wag-allocation-table',
  template: `
   <div>
   <h3><mat-checkbox color="warn" [formControl]="chkMyAllocation" >Show only my allocation</mat-checkbox></h3>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons ></mat-paginator>
  <table *ngIf="saveAllocationTableData" mat-table [dataSource]="dataSourceTable">
    <ng-container matColumnDef="model_name">
      <th mat-header-cell *matHeaderCellDef> Model Name </th>
      <td mat-cell *matCellDef="let element" (click)="gotoAllocation(element)"> {{element.model_name}} </td>
    </ng-container>

    <ng-container matColumnDef="model_description">
      <th mat-header-cell *matHeaderCellDef> Description </th>
      <td mat-cell *matCellDef="let element"> {{element.model_description}} </td>
    </ng-container>

    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef> User </th>
      <td mat-cell *matCellDef="let element"> {{element.username}} </td>
    </ng-container>

    <ng-container matColumnDef="date_of_creation">
      <th mat-header-cell *matHeaderCellDef> Date time </th>
      <td mat-cell *matCellDef="let element"> {{element.date_of_creation}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
   </div>
  `,
  styleUrls: ['./allocation-table.component.scss']
})
export class AllocationTableComponent implements OnInit,OnChanges {
  @Input() saveAllocationTableData!:SaveAllocationData[]|null;

  @Output() clickOnMyAllocationOnly: EventEmitter<ValueSelect> = new EventEmitter<ValueSelect>();

  dataSourceTable!: MatTableDataSource<SaveAllocationData>
  private paginator!: MatPaginator;
  chkMyAllocation!: FormControl;
  displayedColumns: string[] = ['model_name', 'model_description', 'username', 'date_of_creation'];
  constructor(private router:Router) { }

  ngOnChanges() {

    this.dataSourceTable = new MatTableDataSource(this.saveAllocationTableData!);
    this.setDataSourceAttributes();
  }

  ngOnInit(): void {

    this.dataSourceTable = new MatTableDataSource(this.saveAllocationTableData!);
    this.chkMyAllocation = new FormControl(false);
    this.chkMyAllocation.valueChanges.subscribe(values => {
      this.clickOnMyAllocationOnly.emit({ control: 'chkMyAllocation', value: values })
    })

  }

  @ViewChild(MatPaginator, { static: false }) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSourceTable.paginator = this.paginator;
  }

  gotoAllocation(element:any) {
    let tableList= JSON.stringify(element)
    this.router.navigate(
      ['/allocation'],
      { queryParams: { tableList } }
  );
    }

}
