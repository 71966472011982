import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FieldRangeDates, PlotDataBar, WellStatus} from 'src/modules/types';

@Component({
  selector: 'wag-field-wells-optimes',
  styleUrls: ['./field-wells-optimes.component.scss'],
  template: `
    <h3> {{title}} </h3>

    <section *ngFor="let wellOptimesPlotData of wellsOptimesPlotData">
      <div class="well-infos">
        <span class="optimes-well-name">{{wellOptimesPlotData.name}}</span>
        <span class="optimes-well-status"> Current Status: </span>
        <span [ngClass]="{'open': wellOptimesPlotData?.status === WellStatus.OPEN,
          'close': wellOptimesPlotData?.status === WellStatus.CLOSE}">{{wellOptimesPlotData.status}}
        </span>
      </div>
      
      <wag-well-graph [data]="[wellOptimesPlotData]"
                      [style]="style" [layout]="layout"
                      [config]="config"></wag-well-graph>
    </section>
  `
})
export class FieldWellsOptimesComponent implements OnChanges {
  @Input() title!: string;
  @Input() wellsOptimesPlotData!: PlotDataBar[];
  @Input() dateRange!: FieldRangeDates | null;
  WellStatus = WellStatus;
  style = {
    position: 'relative', width: '100%', height: '32px'
  };
  config = {
    displayModeBar: false
  };

  layout = {
    paper_bgcolor: 'white',
    plot_bgcolor: '#F7F9FA',
    bargap: 0,
    margin: {
      l: 0,
      r: 0,
      b: 0,
      t: 0,
    },
    xaxis: {
      domain: [0.085, 0.885],
      visible: false,
      showgrid: false,
      showline: false,
      zeroline: false,
      fixedrange: true,
      range: [0, Date.now()],
      type: 'date'
    },
    yaxis: {
      showgrid: false,
      showline: false,
      visible: false,
      zeroline: false,
      fixedrange: true,
    },
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dateRange?.currentValue) {
      this.layout.xaxis.range = [changes.dateRange.currentValue.startDate, changes.dateRange.currentValue.endDate];
      this.layout = JSON.parse(JSON.stringify(this.layout));
    }
  }
}
