import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {Observable} from 'rxjs';
import {
  DailyDataFilters,
  DataFilters,
  DataType,
  FieldGraphData,
  FieldRangeDates,
  ProductionAllocated,
  ResponseI,
  WellDailyData,
  WellOptimesData,
  WellType
} from 'src/modules/types';
import {DailyData} from '../../containers';
import {setHttpParams} from '../../tools';

@Injectable({
  providedIn: 'root'
})
export class FieldMonitoringService {

  constructor(private http: HttpClient) {
  }

  getDailyData(aff:string,filters: DailyDataFilters): Observable<ResponseI<DailyData>> {
    
    const params = setHttpParams(filters);
    return this.http.get<ResponseI<DailyData>>(`${environment.apiUrl}/${aff}/daily-data`, {params});
  }

  getProductionAllocated(aff:string,filterData: DataFilters): Observable<ResponseI<ProductionAllocated>> {
    const params = setHttpParams(filterData);
    return this.http.get<ResponseI<ProductionAllocated>>(
      `${environment.apiUrl}/${aff}/official-production-profile`,
      {params}
    );
  }

  getDataOnTimelapse(aff:string,dataType: DataType, filterData: DataFilters): Observable<ResponseI<FieldGraphData>> {
    let params = setHttpParams(filterData);
    params = params.append('data_type', dataType);
    return this.http.get<ResponseI<FieldGraphData>>(`${environment.apiUrl}/${aff}/field-data-graph`, {params});
  }

  getWellsDailyData(aff:string,wellType: WellType, filterData: DataFilters): Observable<ResponseI<WellDailyData[]>> {
    let params = setHttpParams(filterData);
    const endpoint = wellType === WellType.PRODUCER ? 'producers' : 'injectors';
    return this.http.get<ResponseI<WellDailyData[]>>(`${environment.apiUrl}/${aff}/daily-well-data/${endpoint}`, {params});
  }

  getWellsOptimes(aff:string,wellType: WellType, filterData: DataFilters): Observable<ResponseI<WellOptimesData[]>> {
    let params = setHttpParams(filterData);
    params = params.append('well_type', wellType === WellType.PRODUCER ? 'producers' : 'injectors');
    return this.http.get<ResponseI<WellOptimesData[]>>(`${environment.apiUrl}/${aff}/well-optimes`, {params});
  }

  getFieldRangeDates(aff:string,filterData: DataFilters): Observable<ResponseI<FieldRangeDates>> {
    
    let params = setHttpParams(filterData);
    return this.http.get<ResponseI<FieldRangeDates>>(`${environment.apiUrl}/${aff}/dates-range`, {params});
  }
}
