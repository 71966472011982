import {Action, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {GetFieldWaterInjection} from './actions';
import {FieldGraphData} from 'src/modules/types';

@State<FieldGraphData>({
  name: 'fieldWaterInjection',
  defaults: {} as FieldGraphData
})
@Injectable()
export class FieldWaterInjectionState {
  @Action(GetFieldWaterInjection)
  getFieldWaterInjection(context: StateContext<FieldGraphData>, {payload}: GetFieldWaterInjection): void {
    context.setState(payload);
  }
}
