import {Component} from '@angular/core';

@Component({
  selector: 'wag-callback',
  template: `
    <div>
      <mat-spinner mode="indeterminate" strokeWidth="3" color="warn"></mat-spinner>
      <p>Authentication in progress</p>
    </div>
  `
})
export class CallbackContainer {

}
