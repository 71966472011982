import {Action, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {WellDailyData} from 'src/modules/types';
import {GetProducerWellsDailyData} from './actions';

@State<WellDailyData[]>({
  name: 'producerWellsDailyData',
  defaults: []
})
@Injectable()
export class ProducerWellsDailyDataState {
  @Action(GetProducerWellsDailyData)
  getProducerWellsDailyData(context: StateContext<WellDailyData[]>, {payload}: GetProducerWellsDailyData): void {
    context.setState(payload);
  }
}
