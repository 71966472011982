import {Component} from '@angular/core';
import {Subject} from 'rxjs';
import {LoaderService} from '../../services';

@Component({
  selector: 'wag-loader',
  template: `
    <div *ngIf="isLoading | async" class="overlay">
      <mat-spinner strokeWidth="3" color="warn" class="spinner"></mat-spinner>
    </div>
  `,
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  isLoading: Subject<boolean> = this.loaderService.isLoading;

  constructor(private loaderService: LoaderService) { }
}
