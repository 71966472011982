import {HttpParams} from '@angular/common/http';
import {DataFilters} from 'src/modules/types';
import * as moment from 'moment';

export const setHttpParams = (filters: DataFilters): HttpParams => {
  const {asset, field, well, date_min, date_max} = filters;
  const format = "YYYY-MM-DD"
  let params = new HttpParams().set('asset', asset);

  if (field) params = params.append('field', field);
  if (well) params = params.append('well', well);
  if (date_min) params = params.append('min_date', moment(date_min).format(format));
  if (date_max) params = params.append('max_date', moment(date_max).format(format));

  return params;
};
