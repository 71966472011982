import { Layout } from "plotly.js";
import { PlotData } from "src/modules/types";

export const ALLOCATION_VS_ESTIMATION_QOIL: PlotData[] = [
  {name: 'total_estimation_qoil', type: 'scatter', mode: 'lines'},
  {name: 'total_allocation_qoil', type: 'scatter', mode: 'markers'},
];

export const ALLOCATION_VS_ESTIMATION_QGAS: PlotData[] = [
  {name: 'total_estimation_qgas', type: 'scatter', mode: 'lines'},
  {name: 'total_allocation_qgas', type: 'scatter', mode: 'markers'},
];

export const ALLOCATION_VS_ESTIMATION_QWAT: PlotData[] = [
  {name: 'total_estimation_qwat', type: 'scatter', mode: 'lines'},
  {name: 'total_allocation_qwat', type: 'scatter', mode: 'markers'},
];


export const COEFFICENT_PLOT_DATA_QOIL: PlotData[] = [
  {name: 'Correction Coefficient: Allocation_Qoil/Estimation_Qoil', type: 'scatter', mode: 'lines'},
  {name: 'Ratio=1', type: 'scatter', mode: 'lines',line: {dash: 'dash'}, marker: {color: "#000000"}}
];
export const COEFFICENT_PLOT_DATA_QGAS: PlotData[] = [
  {name: 'Correction Coefficient: Allocation_Qgas/Estimation_Qgas', type: 'scatter', mode: 'lines'},
  {name: 'Ratio=1', type: 'scatter', mode: 'lines',line: {dash: 'dash'}, marker: {color: "#000000"}}
];
export const COEFFICENT_PLOT_DATA_QWAT: PlotData[] = [
  {name: 'Correction Coefficient: Allocation_Qwat/Estimation_Qwat', type: 'scatter', mode: 'lines'},
  {name: 'Ratio=1', type: 'scatter', mode: 'lines',line: {dash: 'dash'}, marker: {color: "#000000"}}
];


export const TOTAL_ALLOCATION_DATA_QOIL: PlotData[] = [
  {name: 'Total official allocation', type: "bar", marker: { color: "#285AFF" }},
  {name: 'Total new allocation', type: "bar", marker: { color: "#ff6347" }}
];
export const TOTAL_ALLOCATION_DATA_QGAS: PlotData[] = [
  {name: 'Total official allocation', type: "bar", marker: { color: "#285AFF" }},
  {name: 'Total new allocation', type: "bar", marker: { color: "#ff6347" }}
];
export const TOTAL_ALLOCATION_DATA_QWAT: PlotData[] = [
  {name: 'Total official allocation', type: "bar", marker: { color: "#285AFF" }},
  {name: 'Total new allocation', type: "bar", marker: { color: "#ff6347" }}
];

export const COEFFICENT_LAYOUT:  Partial<Layout> = {
  title: {
    text: '',
    font: {
      family: `Roboto, "Helvetica Neue", sans- serif`,
      size: 15
    },
    x: 0.5
  },
  xaxis: {
    title: {
      text: '',
      font: {
        family: 'Roboto, "Helvetica Neue", sans- serif',
        size: 14
      }
    },
  },
  legend: {
    orientation: 'h',
    xanchor: 'center',
    x: 0.5
  },

  hovermode: 'x',
  yaxis: {
    title: "",
    side: "left",
    position: 0.00,
    titlefont: {
      color: "#f20089"
    },
    tickfont: {
      color: "#f20089"
    },
    showgrid: true,
    zeroline: false,
    showline: false,
    visible: true,
  }
};
export const ALLOCATION_VS_ESTIMATION_LAYOUT:  Partial<Layout> = {
  title: {
    text: '',
    font: {
      family: `Roboto, "Helvetica Neue", sans- serif`,
      size: 15
    },
    x: 0.5
  },
  xaxis: {
    title: {
      text: '',
      font: {
        family: 'Roboto, "Helvetica Neue", sans- serif',
        size: 14
      }
    },
  },
  legend: {
    orientation: 'h',
    xanchor: 'center',
    x: 0.5
  },

  hovermode: 'x',
  yaxis: {
    title: "",
    side: "left",
    position: 0.00,
    titlefont: {
      color: "#f20089"
    },
    tickfont: {
      color: "#f20089"
    },
    showgrid: true,
    zeroline: false,
    showline: false,
    visible: true,
  }
};

export const TOTAL_ALLOCATION_LAYOUT: Partial<Layout> = {
  title: {
    text: '',
    font: {
      family: `Roboto, "Helvetica Neue", sans- serif`,
      size: 15
    },
    x: 0.5
  },
  legend: {
    orientation: 'h',
    xanchor: 'center',
    x: 0.5
  },
  hovermode: 'x',
  yaxis: {
    title: "%",
    side: "left",
    position: 0.00,
    titlefont: {
      color: "#f20089"
    },
    tickfont: {
      color: "#f20089"
    },
    showgrid: true,
    zeroline: false,
    showline: false,
    visible: true,
  }
};



