import {Action, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {GetUserName} from './actions';

@State<string>({
  name: 'userName',
  defaults: ''
})
@Injectable()
export class UserNameState {
  @Action(GetUserName)
  getUserName(context: StateContext<string>, {payload}: GetUserName): void {
    context.setState(payload);
  }
}
