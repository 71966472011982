import {Action, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {GetQcRangeDates} from './actions';
import {FieldRangeDates} from 'src/modules/types';

@State<FieldRangeDates>({
  name: 'qcRangeDates',
  defaults: undefined
})
@Injectable()
export class QcRangeDatesState {
  @Action(GetQcRangeDates)
  getQcRangeDates(context: StateContext<FieldRangeDates>, {payload}: GetQcRangeDates): void {
    context.setState(payload);
  }
}
