import {Component, EventEmitter, Input, Output} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {Layout} from 'plotly.js';
import {AttributeTypeInjector, PlotData} from 'src/modules/types';
import {INJ_DATA_ATTR, POTENTIAL_INJ_ATTR, PRESSURE_STATIC_ATTR, INJ_MONITORING_ATTR,INJ_ALLOCATION_ATTR} from './ml-visualize-injector.const'

@Component({
  selector: 'wag-ml-visualize-injector',
  template: `
   <div>
      <wag-well-graph [data]="machineLearningGraph" [layout]="machineLearningLayout"></wag-well-graph>
      <form [formGroup]="checkboxFormInjector" class="well-filters">
      <div class="attributes-container">
        <div class="attribute-bloc">
          <p class="bloc-title">Injection Data Attributes</p>
          <div class="checkbox-list">
            <mat-checkbox *ngFor="let attr of INJ_DATA_ATTR"
                          [value]="attr" formControlName="Checkbox"
                          (change)="onClickInjectorCheckbox($event,AttributeTypeInjector.INJECTION_DATA)"
                          color="warn">{{attr.split("-", 1)}}</mat-checkbox>
          </div>
        </div>
        
        <div class="attribute-bloc">
          <p class="bloc-title">Injection Monitoring Attributes</p>
          <div class="checkbox-list">
            <mat-checkbox *ngFor="let attr of INJ_MONITORING_ATTR"
                          [value]="attr" formControlName="Checkbox2"
                          (change)="onClickInjectorCheckbox($event, AttributeTypeInjector.INJECTION_MONITORING_ATTR)"
                          color="warn">{{attr.split("-", 1)}}</mat-checkbox>
          </div>
        </div>

        <div class="attribute-bloc">
          <p class="bloc-title">Potential Attributes</p>
          <div class="checkbox-list">
            <mat-checkbox *ngFor="let attr of POTENTIAL_INJ_ATTR"
                          [value]="attr" formControlName="Checkbox3"
                          (change)="onClickInjectorCheckbox($event, AttributeTypeInjector.POTENTIAL)"
                          color="warn">{{attr.split("-", 1)}}</mat-checkbox>
          </div>
        </div>
        
      

        <div class="attribute-bloc">
          <p class="bloc-title">Daily Injection Allocation Attributes</p>
          <div class="checkbox-list">
            <mat-checkbox *ngFor="let attr of INJ_ALLOCATION_ATTR"
                          [value]="attr" formControlName="Checkbox4"
                          (change)="onClickInjectorCheckbox($event, AttributeTypeInjector.INJECTION_ALLOCATION)"
                          color="warn">{{attr.split("-", 1)}}</mat-checkbox>
          </div>
        </div>
        <div class="attribute-bloc">
          <p class="bloc-title">Pressure Static Data Attributes</p>
          <div class="checkbox-list">
            <mat-checkbox *ngFor="let attr of PRESSURE_STATIC_ATTR"
                          [value]="attr" formControlName="Checkbox5"
                          (change)="onClickInjectorCheckbox($event, AttributeTypeInjector.PRESSURE_STATIC_ATTR)"
                          color="warn">{{attr.split("-", 1)}}</mat-checkbox>
          </div>
        </div>
      </div>
      </form>
    </div>
  `,
  styleUrls: ['./ml-visualize-injector.component.scss']
})
export class MlVisualizeInjectorComponent{
  INJ_DATA_ATTR = INJ_DATA_ATTR;
  POTENTIAL_INJ_ATTR = POTENTIAL_INJ_ATTR;
  PRESSURE_STATIC_ATTR = PRESSURE_STATIC_ATTR;
  INJ_MONITORING_ATTR = INJ_MONITORING_ATTR;
  INJ_ALLOCATION_ATTR = INJ_ALLOCATION_ATTR;
  AttributeTypeInjector =AttributeTypeInjector;
  @Input() machineLearningGraph!: PlotData[];
  @Input() machineLearningLayout!: Partial<Layout>;
  @Output() clickInjectorCheckbox: EventEmitter<any> = new EventEmitter<any>();
  constructor(private formBuilder: FormBuilder) { }
  checkboxFormInjector: FormGroup = this.formBuilder.group({
    'Checkbox': [''],
    'Checkbox2': [''],
    'Checkbox3': [''],
    'Checkbox4': [''],
    'Checkbox5': [''],
    
  });
  clearAllAttr(){
   this.checkboxFormInjector.reset();
  }
  onClickInjectorCheckbox(event: MatCheckboxChange, attributeTypeInjector: AttributeTypeInjector): void {
    this.clickInjectorCheckbox.emit({
      attribute: event.source.value,
      checked: event.checked,
      attributeTypeInjector
    });
  }
}
