import { PlotData } from 'src/modules/types';
import { Layout } from 'plotly.js';

export const ML_FEATURES_IMPORTANCE_PLOT_DATA: PlotData[] = [
  { name: "Features Importance", type: "bar", marker: { color: "#285AFF" } }
];
export const ML_FEATURE_VS_TARGET: PlotData[] = [
  { name: "Model_Prediction", type: "scatter",mode: "markers", marker: { color: "#000000" } },
  { name: "Testing_Data_set", type: "scatter",mode: "markers", marker: { color: "#FF0000" } },
  { name: "Training_Data_set", type: "scatter",mode: "markers", marker: { color: "#0000FF" } },
  { name: "target_predict", type: "scatter",mode: "markers", marker: { color: "#ff9800" } },
  { name: "target_test", type: "scatter",mode: "markers", marker: { color: "#ffcdd2" } },
  { name: "target_train", type: "scatter",mode: "markers", marker: { color: "#ff5722" } },

];

export const ML_FEATURES_IMPORTANCE_LAYOUT: Partial<Layout> = {
  legend: {
    orientation: 'h',
    xanchor: 'center',
    x: 0.5
  },
  hovermode: 'x',
};
export const ML_FEATURE_VS_TARGET_LAYOUT: any = {
  title: {
    text: '',
    font: {
      family: `Roboto, "Helvetica Neue", sans- serif`,
      size: 15
    },
    x: 0.5
  },
  xaxis: {
    title: {
      text: '',
      font: {
        family: 'Roboto, "Helvetica Neue", sans- serif',
        size: 14
      }
    },
  },
  legend: {
    orientation: 'h',
    xanchor: 'center',
    x: 0.5
  },
  
  hovermode: 'x',
  yaxis: {
    title: "",
    side: "left",
    position: 0.00,
    titlefont: {
      color: "#f20089"
    },
    tickfont: {
      color: "#f20089"
    },
    showgrid: true,
    zeroline: false,
    showline: false,
    visible: true,
  }
};
export const TEMPETATURE:string[]=['WHT','BHT','Topside T']
export const PRESSURE:string[]=['WHP','BHP','Topside P']
export const PERCENTAGE:string[]=['Choke_Opening','Optime']
export const KSMUNIT:string[]=['Qgl_c_e']
export const CHUNIT:string[]=['Choke_Size']