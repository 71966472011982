import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FieldRangeDates, InjectorType, PlotData, PlotDataBar} from 'src/modules/types';
import {WellOptimesData} from 'src/modules/types/well-optimes.interface';
import {FIELD_PRODUCTION_LAYOUT} from './field-official-production.const';

@Component({
  selector: 'wag-field-official-production',
  styleUrls: ['./field-official-production.component.scss'],
  template: `
    <div class="section-container">
      <h2>Official Production Profile</h2>
      <wag-well-graph [data]="productionAllocatedGraph" [layout]="graphLayout" [isFieldProd]="isFieldProd"></wag-well-graph>

      <section class="optimes-container">
        <wag-field-wells-optimes *ngIf="isSelected && this.forField==='isField'" [wellsOptimesPlotData]="producerWellOptimesPlots"
                                 title="Producers" [dateRange]="dateRange"></wag-field-wells-optimes>
        <wag-field-wells-optimes *ngIf="isSelected && this.forField==='isField'" [wellsOptimesPlotData]="injectorWellOptimesPlots"
                                 title="Injectors" [dateRange]="dateRange"></wag-field-wells-optimes>

        <wag-field-wells-optimes *ngIf="isSelected && this.forAsset==='isAsset'" [wellsOptimesPlotData]="producerWellOptimesPlots"
                                 title="Producers" [dateRange]="dateRange"></wag-field-wells-optimes>
        <wag-field-wells-optimes *ngIf="isSelected && this.forAsset==='isAsset'" [wellsOptimesPlotData]="injectorWellOptimesPlots"
                                 title="Injectors" [dateRange]="dateRange"></wag-field-wells-optimes>

       <wag-field-wells-optimes *ngIf="isSelected && this.typeofWell==='producer'" [wellsOptimesPlotData]="producerWellOptimesPlots"
                                 title="Producers" [dateRange]="dateRange"></wag-field-wells-optimes>
        <wag-field-wells-optimes *ngIf="isSelected && this.typeofWell==='injector'" [wellsOptimesPlotData]="injectorWellOptimesPlots"
                                 title="Injectors" [dateRange]="dateRange"></wag-field-wells-optimes>
      </section>

      <section class="optime-toggle">
        <button mat-icon-button (click)="toggleOptimeCharts()">
          {{isSelected ? 'Close Optime' : 'See Optime'}}
          <mat-icon>{{isSelected ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
        </button>
      </section>
    </div>
  `
})
export class FieldOfficialProductionComponent implements OnChanges {
  @Input() injectorWellOptimes!: WellOptimesData[] | null;
  @Input() producerWellOptimes!: WellOptimesData[] | null;
  @Input() productionAllocatedGraph!: PlotData[];
  @Input() dateRange!: FieldRangeDates | null;
  @Input() typeofWell !:string;
  @Input() forField! :string;
  @Input() forAsset! :string;
  isFieldProd:boolean=true;
  graphLayout = FIELD_PRODUCTION_LAYOUT;
  producerWellOptimesPlots: PlotDataBar[] = [];
  injectorWellOptimesPlots: PlotDataBar[] = [];
  isSelected: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dateRange?.currentValue) {
      this.graphLayout.xaxis.range = [changes.dateRange.currentValue.startDate, changes.dateRange.currentValue.endDate];
      this.graphLayout = JSON.parse(JSON.stringify(this.graphLayout));
    }

    this.producerWellOptimesPlots = [];
    if (this.producerWellOptimes)
      this.producerWellOptimes.forEach((wellOptimes: WellOptimesData) => {
        this.producerWellOptimesPlots.push({
          name: wellOptimes.wellname,
          status: wellOptimes.status,
          x: wellOptimes.dates,
          y: wellOptimes.optimes,
          type: 'bar',
          hovertemplate: 'Date:%{x|%Y/%m/%d} Optime:%{y} %',
          marker: {color: "#00884A"}
        });
      });

    this.injectorWellOptimesPlots = [];
    if (this.injectorWellOptimes)
      this.injectorWellOptimes.forEach((wellOptimes: WellOptimesData) => {
        this.injectorWellOptimesPlots.push({
          name: wellOptimes.wellname,
          status: wellOptimes.status,
          x: wellOptimes.dates,
          y: wellOptimes.optimes,
          type: 'bar',
          hovertemplate: 'Date:%{x|%Y/%m/%d} Optime:%{y} %',
          marker: {
            color: wellOptimes.injectionType?.map(
              injectionType => {
                if (injectionType == InjectorType.WATER)
                  return '#4632FF'
                else if (injectionType == InjectorType.GAS)
                  return '#EE0000'
                else return 'gray'
              }
            )
          }
        });
      });
  }

  toggleOptimeCharts(): void {
    this.isSelected = !this.isSelected;
  }
}
