import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import {
  AttributeData,
  AttributeType,
  AttributeTypeInjector,
  DataFilters, DataFiltersInference, FieldRangeDates, MachineLearningData,
  MlInputSourceLabel,
  MLRunParams,
  MlTargetSourceLabel,
  MlTargetSourceLabelInjector,
  ResponseI,
  SaveMlRequestParam,
  SaveModelParams,
  SaveModelTableData,
  SelectedWellTypeInterface,
  TMoreColumnsInfo,
  TrainingDataVisualizationInterface,
  FeatureDataVisualizationInterface,
  WellQcTableData,
  StageResponse
} from 'src/modules/types';
import { ModelCatalogueData, ModelStatus } from 'src/modules/types/model-catalogue-data';
import { setHttpParams } from '../../tools';


@Injectable({
  providedIn: 'root'
})
export class BuildModelService {
  format = "YYYY-MM-DD"
  constructor(private http: HttpClient) {

  }

  getMLResult(aff: string, selectedWell: SelectedWellTypeInterface,
    mlRunParams: MLRunParams, flagData?: string): Observable<ResponseI<MachineLearningData>> {
      let params=new HttpParams().set('input_features', mlRunParams.inputFeatures.toString());
       if(mlRunParams.column_source!=='' && mlRunParams.column_source!==undefined){
       params = params.append('column_source', mlRunParams.column_source);
      }

      if(mlRunParams.target_source=='PS Production Test Data'){
        params = params.append('column_source', mlRunParams.target);
      }
      if(mlRunParams.added_features!==undefined){
        params = params.append('added_features', mlRunParams.added_features.toString());
       }
      params = params.append('target', mlRunParams.target);
      let max_date_range:string[]=[];
      let min_date_range:string[]=[];
      if (mlRunParams.max_date != undefined)
      max_date_range.push(moment(mlRunParams.max_date).format(this.format))
      if (mlRunParams.max_date1 != undefined)
      max_date_range.push(moment(mlRunParams.max_date1).format(this.format))

      if (mlRunParams.max_date2 != undefined)
        max_date_range.push(moment(mlRunParams.max_date2).format(this.format))

      if (mlRunParams.max_date3 != undefined)
        max_date_range.push(moment(mlRunParams.max_date3).format(this.format))

      if (mlRunParams.min_date != undefined)
      min_date_range.push(moment(mlRunParams.min_date).format(this.format))

      if (mlRunParams.min_date1 != undefined)
      min_date_range.push(moment(mlRunParams.min_date1).format(this.format))

      if (mlRunParams.min_date2 != undefined)
        min_date_range.push(moment(mlRunParams.min_date2).format(this.format))

      if (mlRunParams.min_date3 != undefined)
        min_date_range.push(moment(mlRunParams.min_date3).format(this.format))

      params = params.append('min_date', min_date_range.toString());
      params = params.append('max_date', max_date_range.toString());

    params = params.append('data_input_source', MlInputSourceLabel[mlRunParams.inputSource as keyof typeof MlInputSourceLabel]);
    params = params.append('ml_algorithm', mlRunParams.ml_algorithm);
    if(mlRunParams.optimize!=undefined && mlRunParams.optimize!=null)
    {
      params = params.append('optimize', mlRunParams.optimize);
    }
    params = params.append('ml_training_set_size', mlRunParams.ml_training_set_size);
    params = params.append('inference_min_date', moment(mlRunParams.inference_min_date).format(this.format));
    params = params.append('inference_max_date', moment(mlRunParams.inference_max_date).format(this.format));
    if (mlRunParams.wellTestStatus?.length)
      params = params.append('well_test_status', mlRunParams.wellTestStatus.toString());

    if (selectedWell.well && selectedWell.asset && selectedWell.field) {
      params = params.append('wellname', selectedWell.well);
      params = params.append('assetname', selectedWell.asset);
      params = params.append('fieldname', selectedWell.field);
    }

    if (mlRunParams.validFlagcheck == true) {
      let validFlagTrue = "true";
      params = params.append('valid_flag', validFlagTrue);
    }

    if (mlRunParams.computeLearning != undefined && mlRunParams.computeLearning != null) {
      params = params.append('learning_curve', mlRunParams.computeLearning);

    }
    // if (mlRunParams.threshold != undefined && mlRunParams.threshold != null) {
    // params = params.append('threshold', mlRunParams.threshold);
    // }

    if (mlRunParams.threshold != undefined && mlRunParams.threshold != null && mlRunParams.threshold==true) {
      let thresholdValue=false;
      params = params.append('threshold', thresholdValue);

    }
    else if(mlRunParams.threshold != undefined && mlRunParams.threshold != null && mlRunParams.threshold==false) {
      let thresholdValue=true;
      params = params.append('threshold', thresholdValue);

    }

    if (mlRunParams.outliers != undefined && mlRunParams.outliers != null) {
      params = params.append('removing_outliers', mlRunParams.outliers);
    }

    if (mlRunParams.forceOptime != undefined && mlRunParams.forceOptime != null) {
      params = params.append('force_optime', mlRunParams.forceOptime);
     }
    if(mlRunParams.well_type!=undefined)
    {
      params = params.append('well_type', mlRunParams.well_type);
     }
     else{
       params = params.append('well_type', 'producer');
      }

    if (mlRunParams.well_type === "injector") {
      params = params.append('target_source', MlTargetSourceLabelInjector[mlRunParams.target_source as keyof typeof MlTargetSourceLabelInjector]);
    } else {
      params = params.append('target_source', MlTargetSourceLabel[mlRunParams.target_source as keyof typeof MlTargetSourceLabel]);
    }

    params = params.append('log_in_mlflow', mlRunParams.log_in_mlflow);

// if(mlRunParams.featureVisualizationKey != undefined){
//     params = params.append('features_visualization', mlRunParams.featureVisualizationKey)
//   }

//   if(mlRunParams.wellTestVisualizationKey != undefined){
//     params = params.append('well_test_visualization_cache_key', mlRunParams.wellTestVisualizationKey);
//   }


    return this.http.get<ResponseI<MachineLearningData>>(`${environment.apiUrl}/${aff}/run-ml`, { params });
  }

  getAttributeData(aff: string, attribute: string,
    filterData: DataFiltersInference,
    attributeType: AttributeType): Observable<ResponseI<AttributeData>> {

    let params = setHttpParams(filterData);
    params = params.append('attribute', attribute);
    params = params.append('attribute_type', attributeType);

    return this.http.get<ResponseI<AttributeData>>(
      `${environment.apiUrl}/${aff}/visualise-data-attribute`,
      {
        params: params,
      });
  }

  getAttributeDataInjector(aff: string, attribute: string,
    filterData: DataFiltersInference,
    attributeTypeInjector: AttributeTypeInjector): Observable<ResponseI<AttributeData>> {

    let params = setHttpParams(filterData);
    params = params.append('attribute', attribute);
    params = params.append('attribute_type', attributeTypeInjector);

    return this.http.get<ResponseI<AttributeData>>(
      `${environment.apiUrl}/${aff}/visualise-data-attribute`,
      {
        params: params,
      });
  }
  getWellTestStatus(aff: string, filterData: DataFilters, flagData?: string): Observable<ResponseI<string[]>> {
    let params = setHttpParams(filterData);

    if (flagData == 'true') {
      params = params.append('valid_flag', flagData);
    }
    return this.http.get<ResponseI<string[]>>(
      `${environment.apiUrl}/${aff}/well-test-status`,
      { params }
    );
  }

  getWellQcTableData(aff: string, filterData: DataFilters): Observable<ResponseI<WellQcTableData[]>> {

    return this.http.get<ResponseI<WellQcTableData[]>>(
      `${environment.apiUrl}/${aff}/input-qc-data-percentage`,
      { params: setHttpParams(filterData) }
    );
  }

  getWellQcTableDataInjector(aff: string, filterData: DataFilters, well_type: string): Observable<ResponseI<WellQcTableData[]>> {

    let params = setHttpParams(filterData);
    params = params.append('well_type', well_type);
    return this.http.get<ResponseI<WellQcTableData[]>>(
      `${environment.apiUrl}/${aff}/input-qc-data-percentage`,
      { params }
    );
  }

  getSaveModel(aff: string, saveModelData: SaveModelParams, flagData?: boolean): Observable<ResponseI<string>> {
    const headers = { 'content-type': 'application/json' }
    let requestObject!: SaveMlRequestParam;
    let well_test: string = "";

    let well_type: string;
   if (saveModelData.well_type != undefined)
   {
    well_type = saveModelData.well_type
   }else {
    well_type = 'producer'
  }
  let max_date_range:string[]=[];
  let min_date_range:string[]=[];
  let coloumnValues:string='';

  if (saveModelData.max_date != undefined)
  max_date_range.push(moment(saveModelData.max_date).format(this.format))
  if (saveModelData.max_date1 != undefined)
  max_date_range.push(moment(saveModelData.max_date1).format(this.format))

  if (saveModelData.max_date2 != undefined)
    max_date_range.push(moment(saveModelData.max_date2).format(this.format))

  if (saveModelData.max_date3 != undefined)
    max_date_range.push(moment(saveModelData.max_date3).format(this.format))

  if (saveModelData.min_date != undefined)
  min_date_range.push(moment(saveModelData.min_date).format(this.format))

  if (saveModelData.min_date1 != undefined)
  min_date_range.push(moment(saveModelData.min_date1).format(this.format))

  if (saveModelData.min_date2 != undefined)
    min_date_range.push(moment(saveModelData.min_date2).format(this.format))

  if (saveModelData.min_date3 != undefined)
    min_date_range.push(moment(saveModelData.min_date3).format(this.format))

    if (saveModelData.well_test_status != undefined || saveModelData.well_test_status != null) {
      well_test = saveModelData.well_test_status.toString()
    } else {
      well_test = "";

    }
    let targetSourceList;
    if (saveModelData.well_type === "injector") {
      targetSourceList = MlTargetSourceLabelInjector[saveModelData.target_source as keyof typeof MlTargetSourceLabelInjector];
    }
    else {
      targetSourceList = MlTargetSourceLabel[saveModelData.target_source as keyof typeof MlTargetSourceLabel];
    }

    if(saveModelData.ColumnValue!=undefined){
      coloumnValues= saveModelData.ColumnValue.toString();
    }
    let reqObject: any = {
      asset: saveModelData.asset,
      field: saveModelData.field,
      well: saveModelData.well,
      target_value: saveModelData.target_value,
      model_name: saveModelData.model_name,
      model_description: saveModelData.model_description,
      user: saveModelData.user,
      save_model_time: saveModelData.save_model_time,
      run_id: saveModelData.run_id,
      features: saveModelData.features.toString(),
      algorithm: saveModelData.algorithm,
      min_date: min_date_range.toString(),
      max_date: max_date_range.toString(),
      inference_min_date: moment(saveModelData.inference_min_date).format(this.format),
      inference_max_date: moment(saveModelData.inference_max_date).format(this.format),
      ml_training_set_size: saveModelData.ml_training_set_size,
      data_input_source: MlInputSourceLabel[saveModelData.data_input_source as keyof typeof MlInputSourceLabel],
      well_test_status: well_test,
      target_source: targetSourceList,
      well_type: well_type,
      optimize:saveModelData.optimize,
      column_source:saveModelData.column_source,
      added_features:coloumnValues,
      learning_curve: saveModelData.computeLearning,
      threshold: saveModelData.threshold,
      force_optime: saveModelData.forceOptime,
      removing_outliers:saveModelData.outliers
    }

    if (flagData == true) {
      let flagDataTrue = "true";
      reqObject.valid_flag = flagDataTrue;

      requestObject = reqObject;
    }
    else {
      requestObject = reqObject;
    }
    return this.http.post<ResponseI<string>>(
      `${environment.apiUrl}/${aff}/save-ml`, JSON.stringify(requestObject), { headers });


  }
  getSaveModelTableData(aff: string, field: string, asset: string, well?: string, user?: string, flagData?: boolean): Observable<ResponseI<SaveModelTableData[]>> {
    let params = new HttpParams().set('field', field);

    if (user != undefined) {
      params = params.append('user', user);
    }
    params = params.append('asset', asset);


    if (well != undefined) {
      params = params.append('wellname', well);
    }

    if (flagData == true) {
      let ifTrue = "true";
      params = params.append('valid_flag', ifTrue);

    }
    return this.http.get<ResponseI<SaveModelTableData[]>>(
      `${environment.apiUrl}/${aff}/save-ml`, { params });
  }
  getModelCatalogueTableData(aff: string, asset: string, field: string, well?: string, user?: string): Observable<ResponseI<ModelCatalogueData[]>> {
    let params = new HttpParams().set('asset', asset);
    if (user != undefined) {
      params = params.append('user', user);
    }
    params = params.append('field', field);

    if (well != undefined) { params = params.append('wellname', well); }

    return this.http.get<ResponseI<ModelCatalogueData[]>>(
      `${environment.apiUrl}/${aff}/save-ml`, { params});
  }

  getModelCatalogueTableData1(aff: string, filterData: DataFilters, well?: string, user?: string): Observable<ResponseI<ModelCatalogueData[]>> {
    return this.http.get<ResponseI<ModelCatalogueData[]>>(
          `${environment.apiUrl}/${aff}/save-ml`, { params: setHttpParams(filterData) });
      }
  getSavedModelStatus(aff: string, runIdArray: string[]): Observable<ResponseI<ModelStatus[]>> {
    const headers = { 'content-type': 'application/json' }
    let requestObject: any = {
      run_id: runIdArray
    }
    return this.http.post<ResponseI<ModelStatus[]>>(
      `${environment.apiUrl}/${aff}/staging-status`, JSON.stringify(requestObject), { headers });
  }

  DownloadExcelDataCataglog(aff: string, mdoelData:any): Observable<ResponseI<any>> {
    const headers = { 'content-type': 'application/json' }
    return this.http.post<ResponseI<any>>(
      `${environment.apiUrl}/${aff}/model-predictions`, mdoelData, { headers });
  }

  getTrainingVisualizeData(aff: string, asset: string, field: string, well: string, target: string, well_type: string, target_source: string, wellStatus: string, colunmSourceValue:string, max_date?: Date, min_date?: Date,
    max_date1?:Date,min_date1?:Date, max_date2?:Date,min_date2?:Date, max_date3?:Date,min_date3?:Date, 
    inference_min_date?: Date, inference_max_date?: Date, flagData?: string) {
    let min_date_range:string[]=[];
     let max_date_range:string[]=[];
    let params = new HttpParams().set('asset', asset);
    params = params.append('field', field);
    params = params.append('well', well);

    if (max_date != undefined){
      max_date_range.push(moment(max_date).format(this.format))
    }

    if (max_date1 != undefined){
    max_date_range.push(moment(max_date1).format(this.format))
    }

    if (max_date2 != undefined){
      max_date_range.push(moment(max_date2).format(this.format))
      }

    if (max_date3 != undefined){
        max_date_range.push(moment(max_date3).format(this.format))
      }

    if (min_date != undefined){
    min_date_range.push(moment(min_date).format(this.format))
    }
    if (min_date1 != undefined){
    min_date_range.push(moment(min_date1).format(this.format))
    }

    if (min_date2 != undefined){
      min_date_range.push(moment(min_date2).format(this.format))
      }

      if (min_date3 != undefined){
        min_date_range.push(moment(min_date3).format(this.format))
        }

    params = params.append('min_date', min_date_range.toString());
    params = params.append('max_date', max_date_range.toString());

      if(colunmSourceValue!==undefined && colunmSourceValue!=="")
      {
        params = params.append('target', colunmSourceValue);
      }
      else
      {
        params = params.append('target', target);
      }
    if (well_type === 'producer') {
      params = params.append('target_source', MlTargetSourceLabel[target_source as keyof typeof MlTargetSourceLabel]);
    } else {
      params = params.append('target_source', MlTargetSourceLabelInjector[target_source as keyof typeof MlTargetSourceLabelInjector]);
    }
    if (wellStatus != '') {
      params = params.append('well_test_status', wellStatus);
    }
    if (inference_min_date != undefined)
      params = params.append('inference_min_date', moment(inference_min_date).format(this.format));
    if (inference_max_date != undefined)
      params = params.append('inference_max_date', moment(inference_max_date).format(this.format));
    if (flagData == 'true') {
      params = params.append('valid_flag', flagData);
    }



    return this.http.get<ResponseI<TrainingDataVisualizationInterface>>(
      `${environment.apiUrl}/${aff}/well-test-visualization`, { params });
  }


  getFeaturesVisualizeData(aff: string, asset: string, field: string, well: string, well_type: string, selectedInputSource:string, selectedFeatureSource:string, selectedUserAdded?:any[], tomreTableName?:string,max_date?: Date, 
    min_date?: Date,max_date1?:Date,min_date1?:Date, max_date2?:Date,min_date2?:Date,max_date3?:Date,min_date3?:Date,
    inference_min_date?: Date, inference_max_date?: Date,) {
    const headers = { 'content-type': 'application/json' }
    let min_date_range:string[]=[];
    let max_date_range:string[]=[];
    let userAddedFeatures:any=[]=[];

    let inferenceMinDate:any;
    let inferenceMaxDate:any;
    let wellType:string;

    let requestObject:any={}

   if (max_date != undefined)
    max_date_range.push(moment(max_date).format(this.format))
  
    if (max_date1 != undefined)
    max_date_range.push(moment(max_date1).format(this.format))

    if (max_date2 != undefined)
      max_date_range.push(moment(max_date2).format(this.format))

    if (max_date3 != undefined)
      max_date_range.push(moment(max_date3).format(this.format))

    if (min_date != undefined)
    min_date_range.push(moment(min_date).format(this.format))

    if (min_date1 != undefined)
    min_date_range.push(moment(min_date1).format(this.format))

    if (min_date2 != undefined)
      min_date_range.push(moment(min_date2).format(this.format))

    if (min_date3 != undefined)
      min_date_range.push(moment(min_date3).format(this.format))

    if((selectedUserAdded!=undefined) && (tomreTableName!=undefined) ){
      userAddedFeatures=selectedUserAdded;
    }

    if (inference_min_date != undefined)
    inferenceMinDate=moment(inference_min_date).format(this.format);

    if (inference_max_date != undefined)
     inferenceMaxDate=moment(inference_max_date).format(this.format);

    if(well_type!=undefined)
    {
      wellType=well_type;
     }
     else{
      wellType='producer';
      }

    requestObject={
      data_input_source:selectedInputSource.toLocaleLowerCase(),
      input_features:selectedFeatureSource,
      max_date:max_date_range.toString(),
      min_date:min_date_range.toString(),
      well_type:wellType,
      inference_min_date:inferenceMinDate,
      inference_max_date:inferenceMaxDate,
      added_features:userAddedFeatures,
      well:well,
      field:field,
      asset:asset
    }
    return this.http.post<ResponseI<FeatureDataVisualizationInterface>>(
      `${environment.apiUrl}/${aff}/features-visualization`, JSON.stringify(requestObject), { headers });
  }


  getTMoreColumnInfo(aff: string, target: string, target_source: string,well_type:string, colunmSourceValue:string) {

    let params = new HttpParams();
    if(colunmSourceValue!==undefined && colunmSourceValue!=="")
    {
      params = params.append('target', colunmSourceValue);
    }
    else
    {
      params = params.append('target', target);
    }
    if(well_type==='producer'){
      params = params.append('target_source', MlTargetSourceLabel[target_source as keyof typeof MlTargetSourceLabel]);
    }else{
      params = params.append('target_source', MlTargetSourceLabelInjector[target_source as keyof typeof MlTargetSourceLabelInjector]);
    }

    return this.http.get<ResponseI<TMoreColumnsInfo>>(
      `${environment.apiUrl}/${aff}/tmore-table-column`, { params });
  }

  loadColunmSource(aff: string, target: string, target_source: string, well_type:string) {

    let params = new HttpParams();
    if(target!==undefined){
      params = params.append('target', target);
    }
    if (well_type === 'producer') {
      if(target_source!==undefined){
      params = params.append('target_source', MlTargetSourceLabel[target_source as keyof typeof MlTargetSourceLabel]);
     }
   } else {
    if(target_source!==undefined){
      params = params.append('target_source', MlTargetSourceLabelInjector[target_source as keyof typeof MlTargetSourceLabelInjector]);
    }
  }

    return this.http.get<ResponseI<any>>(
      `${environment.apiUrl}/${aff}/column-source`, { params });
  }

  loadTmoreColunm(aff: string) {

    let params = new HttpParams();


    return this.http.get<ResponseI<any>>(
      `${environment.apiUrl}/${aff}/user-added-feature`, { params });
  }

  loadFeatureList(aff: string,tableName:string, field: string, asset:string, max_date: string, min_date: string, well: string) {

        let params = new HttpParams();
        params = params.append('table_name',tableName);
        params = params.append('field', field);
        params = params.append('asset', asset);
        params = params.append('max_date', max_date);
        params = params.append('min_date', min_date);
        params = params.append('well', well);
        return this.http.get<ResponseI<any>>(
          `${environment.apiUrl}/${aff}/col-name?`, { params });
      }


  updateSaveModel(aff: string, model_name: string, model_description: string, run_id: string): Observable<ResponseI<any>> {

    const headers = { 'content-type': 'application/json' }
    let requestObject!: any;
    let reqObject: any = {
      model_name: model_name,
      model_description: model_description,
      last_updated_time: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
      run_id: run_id
    }
    requestObject = reqObject;

    return this.http.put<ResponseI<string>>(
      `${environment.apiUrl}/${aff}/save-ml`, JSON.stringify(requestObject), { headers });
  }

  deleteSaveModel(aff: string, run_id: string,asset:string,well_name:string,model_name:string,target:string): Observable<ResponseI<string>> {
    let params = new HttpParams().set('run_id', run_id);
    params = params.append('asset', asset);
    params = params.append('well_name', well_name);
    params = params.append('model_name',model_name );
    params = params.append('target',target );
    return this.http.delete<ResponseI<any>>(
      `${environment.apiUrl}/${aff}/save-ml`, { params });
  }
  getFieldRangeDates(aff: string, filterData: DataFilters, well_type: string): Observable<ResponseI<FieldRangeDates>> {

    let params = setHttpParams(filterData).set('well_type', well_type);
    return this.http.get<ResponseI<FieldRangeDates>>(`${environment.apiUrl}/${aff}/dates-range`, { params });
  }

  getModelStatus(aff:string,asset: string, well_name: string, target: string,run_id:string):Observable<StageResponse> {
    let params = new HttpParams().set('asset', asset);
    params = params.append('well_name', well_name);
    params = params.append('target', target);
    params = params.append('run_id', run_id);
    return this.http.get<StageResponse>(`${environment.apiUrl}/${aff}/model-status`, { params });
  }

  stageToProduction(aff:string,asset: string, well_name: string, target: string,run_id:string,model_name:string) {
    let params = new HttpParams().set('asset', asset);
    params = params.append('well_name', well_name);
    params = params.append('target', target);
    params = params.append('run_id', run_id);
    params = params.append('model_name', model_name);
    return this.http.get<ResponseI<any>>(`${environment.apiUrl}/${aff}/stage-to-production`, { params });
  }
  stageToArchive(aff:string,asset: string, well_name: string, target: string,run_id:string,model_name:string) {
    let params = new HttpParams().set('asset', asset);
    params = params.append('well_name', well_name);
    params = params.append('target', target);
    params = params.append('run_id', run_id);
    params = params.append('model_name', model_name);
    return this.http.get<ResponseI<any>>(`${environment.apiUrl}/${aff}/stage-to-archive`, { params });
  }

}
