import {Action, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {FieldRangeDates} from 'src/modules/types';
import { GetAllocationRangeDates } from './actions';

@State<FieldRangeDates>({
  name: 'AllocationRangeDates',
  defaults: undefined
})
@Injectable()
export class AllocationRangeDatesState {

  @Action(GetAllocationRangeDates)
  GetAllocationRangeDates(context: StateContext<FieldRangeDates>, {payload}: GetAllocationRangeDates): void {

    context.setState(payload);
  }
}
