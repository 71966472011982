import { Component, Input } from '@angular/core';
import { AdditionalLinks } from 'src/modules/types';

@Component({
  selector: 'wag-additional-link',
  template: `
  
   <h2 >Additional Link for {{affiliate}}</h2>
   
   <ng-container *ngIf="addtionalLinks.length ;else noLinks">
   <table *ngIf="addtionalLinks.length" mat-table [dataSource]="addtionalLinks">
     <ng-container matColumnDef="link_name">
      <th mat-header-cell *matHeaderCellDef> Link Name </th>
      <td mat-cell *matCellDef="let element"> {{element.link_name}} </td>
    </ng-container>
    
    <ng-container matColumnDef="link">
      <th mat-header-cell *matHeaderCellDef> Link </th>
      <td mat-cell *matCellDef="let element"> <button class="badge badge-danger" (click)="addtionalLinksItemClick(element.link)"> > &nbsp; Go to Page</button> </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  
   </ng-container>
   <ng-template #noLinks>
   <div class="na">
   <h2 >N/A</h2>
   </div>
   </ng-template>
  `,
  styleUrls: ['./additional-link.component.scss']
})
export class AdditionalLinkComponent  {
  @Input() addtionalLinks: AdditionalLinks[]=[];
  @Input() affiliate!: string;
  
  displayedColumns: string[] = ['link_name', 'link'];
  constructor() { 
    // do nothing.
  }

  addtionalLinksItemClick(link:string){
    window.open(link, "_blank");
  }

}
