import {Action, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {GetWellQcTableData} from './actions';
import {WellQcTableData} from 'src/modules/types';


@State<WellQcTableData[]>({
  name: 'wellQcTableData',
  defaults: []
})
@Injectable()
export class WellQcTableDataState {
  @Action(GetWellQcTableData)
  getWellQcTableData(context: StateContext<WellQcTableData[]>, {payload}: GetWellQcTableData): void {
    context.setState(payload);
  }
}
