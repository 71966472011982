
import {PlotData1} from "src/modules/types";

export const FEATURE_DATA_PLOT_DATA: PlotData1[] = [

  {name: "wht-(°C)", type: "scatter", mode: "markers", line: { dash: 'dot', width: 2 }, marker: {color: "#ff9800",  size: 3}, yaxis: "y1"},
  {name: "bht-(°C)", type: "scatter", mode: "markers",line: { dash: 'dot', width: 2 }, marker: {color: "#ff5722", size: 3 }, yaxis: "y2"},
  {name: "whp-(bar)", type: "scatter", mode: "markers", line: { dash: 'dot', width: 2 }, marker: {color: "#2196f3", size: 3}, yaxis: "y3"},
  {name: "bhp-(bar)", type: "scatter", mode: "markers", line: { dash: 'dot', width: 2 },  marker: {color: "#0d47a1", size: 3}, yaxis: "y4"},
  {name: "choke_opening-(%)", type: "scatter", mode: "markers", line: { dash: 'dot', width: 2 }, marker: {color: "#6C7678", size: 3},yaxis: "y5"},
  {name: "topside_t-(°C)", type: "scatter", mode: "markers", line: { dash: 'dot', width: 2 },marker: {color: "#FF906D", size: 3}, yaxis: "y6"},
  {name: "topside_p-(bar)", type: "scatter", mode: "markers", line: { dash: 'dot', width: 2 }, marker: {color: "#4806A8", size: 3},  yaxis: "y7"},
  {name: "qgl_c_e-(bbl/d)", type: "scatter", mode: "markers", line: { dash: 'dot', width: 2 }, marker: {color: "#E2BC00", size: 3}, yaxis: "y8"},
  {name: "choke_size-(inch)", type: "scatter", mode: "markers", line: { dash: 'dot', width: 2 }, marker: {color: "#505758", size: 3}, yaxis: "y9"}

];

export const FEATURE_DATA_LAYOUT = {
 
  title: {
    text: '',
    font: {
      family: `Roboto, "Helvetica Neue", sans- serif`,
      size: 12
    },
    x: 0
  },
  legend: {
    orientation: "h",
    side: "top",
    xanchor: "center",
    x: 0.5
  },
  showlegend: true,
  xaxis: {
    domain: [0.20, 0.80]
  },
  yaxis: {
  //  title: "Temperature, Degree C",
    side: "left",
    position: 0.00,
    titlefont: {
      color: "#ff9800",
      size: 12
    },
    tickfont: {
      color: "#ff9800"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: false,
   
  },
  yaxis2: {
   // title: "Temperature, Degree C",
    side: "right",
    overlaying: "y",
    position: 0.90,
    titlefont: {
      color: "#ff5722",
      size: 12
    },
    tickfont: {
      color: "#ff5722"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: false,
   
  },
  yaxis3: {
    //title: "Pressure Bar",
    side: "left",
    overlaying: "y",
    position: 0.05,
    titlefont: {
      color: "#2196f3"
    },
    tickfont: {
      color: "#2196f3",
      size: 12
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: false,
    
  },
  yaxis4: {
   // title: "Pressure, Bar",
    side: "right",
    overlaying: "y",
    position: 0.85,
    titlefont: {
      color: "#0d47a1",
      size: 12
    },
    tickfont: {
      color: "#0d47a1"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: false,
   
  },
  yaxis5: {
    //title: "-",
    side: "left",
    overlaying: "y",
    position: 0.10,
    titlefont: {
      color: "#6C7678",
      size: 12
    },
    tickfont: {
      color: "#6C7678"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: false,
  
  },
  yaxis6: {
    //title: "Temprature, Degree C",
    side: "right",
    overlaying: "y",
    position: 0.95,
    titlefont: {
      color: "#FF906D",
      size: 12
    },
    tickfont: {
      color: "#FF906D"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: false,
  
  },
 yaxis7: {
    //title: "Pressure Bar",
    side: "left",
    overlaying: "y",
    position: 0.15,
    titlefont: {
      color: "#4806A8",
      size: 12
    },
    tickfont: {
      color: "#4806A8"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: false,
  
  },
  yaxis8: {
   // title: "bbl/d",
    side: "left",
    overlaying: "y",
    position: 0.18,
    titlefont: {
      color: "#E2BC00",
      size: 12
    },
    tickfont: {
      color: "#E2BC00"
    },
    zeroline: false,
    showline: false,
    visible: false,
  
  },
  yaxis9: {
    // title: "bbl/d",
     side: "left",
     overlaying: "y",
     position: 0.21,
     titlefont: {
       color: "#505758",
       size: 12
     },
     tickfont: {
       color: "#505758"
     },
     zeroline: false,
     showline: false,
     visible: false,
   
   },

   yaxis10: {
    // title: "bbl/d",
     side: "left",
     overlaying: "y",
     position: 0.21,
     titlefont: {
       color: "#00b3ff",
       size: 12
     },
     tickfont: {
       color: "#00b3ff"
     },
     zeroline: false,
     showline: false,
     visible: false,
   
   }

};

