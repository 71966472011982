import { VFMModel } from './../../../types/VFMModel.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { DataFilters, ResponseI, FieldRangeDates, SaveAllocationData } from 'src/modules/types';
@Injectable({
  providedIn: 'root'
})
export class BackAllocationService {

  format = "YYYY-MM-DD"
  constructor(private http: HttpClient) {

  }
  callBackAllocation(aff:string,reqObj:any) {
    const headers = { 'content-type': 'application/json' }
    return this.http.post<any>(
      `${environment.apiUrl}/${aff}/back-allocation`, JSON.stringify(reqObj), { headers });
  }

  getFieldRangeDatesForAllocation(aff:string,filterData: DataFilters): Observable<ResponseI<FieldRangeDates>> {
    const headers = { 'content-type': 'application/json' }
    let reqObj={
      field:filterData.field,
      asset:filterData.asset,
      well:filterData.well,
      well_type:'producer'
    }
    return this.http.post<any>(
      `${environment.apiUrl}/${aff}/dates-range`, JSON.stringify(reqObj), { headers });
  }

  getVFMModelsList(aff:string,filterData:DataFilters): Observable<ResponseI<VFMModel[]>> {
    const headers = { 'content-type': 'application/json' }
    let reqObj= {
    assets:filterData.asset,
    fields:filterData.field,
    well_names:filterData.well
  }
    return this.http.post<ResponseI<VFMModel[]>>(`${environment.apiUrl}/${aff}/vfm-model`,  JSON.stringify(reqObj), { headers });
  }

  saveBackAllocation(aff:string,reqObj:any): Observable<any> {
    const headers = { 'content-type': 'application/json' }

    return this.http.post<any>(
      `${environment.apiUrl}/${aff}/save-back-allocation`, JSON.stringify(reqObj), { headers });
  }

  getSavedBackAllocation(aff:string,user?:string): Observable<ResponseI<SaveAllocationData[]>> {
    let params:any
    if(user!=undefined && user!=''){
      debugger
      params=new HttpParams().set('username',user);
    }
    return this.http.get<ResponseI<SaveAllocationData[]>>(
      `${environment.apiUrl}/${aff}/save-back-allocation`,{params});
  }

}
