import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {DataFilters, FieldRangeDates, WellDailyData} from 'src/modules/types';
import { outputAst } from '@angular/compiler';

@Component({
  selector: 'wag-field-daily-well-data',
  styleUrls: ['./field-daily-well-data.component.scss'],
  template: `
    <div *ngIf="true"
         class="section-container">
      <h2>Daily Well Data ({{dateRange?.endDate | date:'dd/MM/YYYY'}})</h2>
      <mat-accordion multi="true">
      <mat-expansion-panel (click)="onPanelClick('producer')" >
          <mat-expansion-panel-header>Producer</mat-expansion-panel-header>
          <div *ngIf="producerWellsDailyData && this.typeofWell==='producer'" class="producers-wells">
              <wag-field-daily-well-data-header headerName="producers" [headersList]="producerWellsHeaders">
              </wag-field-daily-well-data-header>
  
              <div class="wells-list">
                  <wag-well-data-line *ngFor="let wellData of producerWellsDailyData" [wellData]="wellData">
                  </wag-well-data-line>
              </div>
          </div>

          <div *ngIf="producerWellsDailyData && this.forField==='isField'" class="producers-wells">
              <wag-field-daily-well-data-header headerName="producers" [headersList]="producerWellsHeaders">
              </wag-field-daily-well-data-header>
  
              <div class="wells-list">
                  <wag-well-data-line *ngFor="let wellData of producerWellsDailyData" [wellData]="wellData">
                  </wag-well-data-line>
              </div>
          </div>

          <div *ngIf="producerWellsDailyData && this.forAsset==='isAsset'" class="producers-wells">
              <wag-field-daily-well-data-header headerName="producers" [headersList]="producerWellsHeaders">
              </wag-field-daily-well-data-header>
  
              <div class="wells-list">
                  <wag-well-data-line *ngFor="let wellData of producerWellsDailyData" [wellData]="wellData">
                  </wag-well-data-line>
              </div>
          </div>
      </mat-expansion-panel>
  
      <mat-expansion-panel (click)="onPanelClick('injector')" >
          <mat-expansion-panel-header>Injector</mat-expansion-panel-header>
          <div *ngIf="injectorWellsDailyData && this.typeofWell==='injector'" class="injectors-wells">
              <wag-field-daily-well-data-header headerName="injectors" [headersList]="injectorWellsHeaders">
              </wag-field-daily-well-data-header>
  
              <div class="wells-list">
                  <wag-well-data-line *ngFor="let wellData of injectorWellsDailyData" [wellData]="wellData">
                  </wag-well-data-line>
              </div>
          </div>

          <div *ngIf="injectorWellsDailyData && this.forField==='isField'" class="injectors-wells">
              <wag-field-daily-well-data-header headerName="injectors" [headersList]="injectorWellsHeaders">
              </wag-field-daily-well-data-header>
  
              <div class="wells-list">
                  <wag-well-data-line *ngFor="let wellData of injectorWellsDailyData" [wellData]="wellData">
                  </wag-well-data-line>
              </div>
          </div>

          <div *ngIf="injectorWellsDailyData && this.forAsset==='isAsset'" class="injectors-wells">
              <wag-field-daily-well-data-header headerName="injectors" [headersList]="injectorWellsHeaders">
              </wag-field-daily-well-data-header>
  
              <div class="wells-list">
                  <wag-well-data-line *ngFor="let wellData of injectorWellsDailyData" [wellData]="wellData">
                  </wag-well-data-line>
              </div>
          </div>
      </mat-expansion-panel>
  
  </mat-accordion>
      
    </div>
  `
})
export class FieldDailyWellDataComponent {
  @Input() producerWellsDailyData!: WellDailyData[] | null;
  @Input() injectorWellsDailyData!: WellDailyData[] | null;
  @Input() dateRange!: FieldRangeDates | null;
  @Input() typeofWell!:string;
  @Input() forField!:string;
  @Input() forAsset!:string;
  @Output() expandClick = new EventEmitter<any>();
  dataFilters!: DataFilters;
  producerWellsHeaders: string[] = ['Liquid Production (BBL/d)', 'Oil Production (BBL/d)', 'Gas Production (kSm3/d)', 'GOR (v/v)', 'Water Cut (%)'];
  injectorWellsHeaders: string[] = ['Water injected', 'Gas injected'];
  well: string ="";
  affiliate!: string;
  firstClick = true;
  injectorFirstClick=true;

  onPanelClick(panelName:any)
  {
    if (panelName=='producer')
    {
        if(this.firstClick){
            this.expandClick.emit(panelName);
            this.firstClick = false;
            } 
    }
    else 
        {
            if (this.injectorFirstClick)
            {
                this.expandClick.emit(panelName);
                this.injectorFirstClick=false;
            }
        }
    }

}
