import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SaveAllocationData, ValueSelect } from 'src/modules/types';

@Component({
  selector: 'wag-allocation-catalogue',
  template: `
     <mat-accordion >
      <mat-expansion-panel>
        <mat-expansion-panel-header>
        <h2>Allocation Catalogue</h2>
        </mat-expansion-panel-header>
        <wag-allocation-table
        *ngIf="saveAllocationTableData"
         [saveAllocationTableData]="saveAllocationTableData"
         (clickOnMyAllocationOnly)="clickOnMyAllocationOnly.emit($event)">
        </wag-allocation-table>

      </mat-expansion-panel>
  </mat-accordion>
  `,
  styleUrls: ['./allocation-catalogue.component.scss']
})
export class AllocationCatalogueComponent  {

  @Input()saveAllocationTableData!:SaveAllocationData[] |null;
  @Output()clickOnMyAllocationOnly= new EventEmitter<ValueSelect>();
  constructor() { }


}
