import {WellStatus} from '.';
export interface ProductionMonitoring {
  date: Date[];
  optime: number[];
  whp: number[];
  wht: number[];
  bhp: number[];
  bht: number[];
  qgl: number[];
  choke_opening: number[];
  // service_line_p: number[];
  // csg_head_p: number[];
  date_test: Date[];
  qoil_test: number[];
  qgas_test: number[];
  qwat_test: number[];
}

export interface ProductionData {
  date: Date[];
  optime: number[];
  whp: number[];
  wht: number[];
  bhp: number[];
  bht: number[];
  choke_opening: number[];
  // choke_size: number[];
  qgl_c_e: number[];
  date_test: Date[];
  qoil_test: number[];
  qgas_test: number[];
  qwat_test: number[];
  // topside_t: number[];
  // topside_p: number[];
}
export interface ProductionMonitoringInjector {
  date: Date[];
  optime: number[];
  whp: number[];
  wht: number[];
  bhp: number[];
  bht: number[];
  Qginj:number[];
  Qwinj:number[]
  choke_opening: number[];
}
export interface ProductionDataInjector {
  date: Date[];
  optime: number[];
  whp: number[];
  wht: number[];
  bhp: number[];
  bht: number[];
  choke_opening: number[];
  Qginj:number[];
  Qwinj:number[];
  // choke_size: number[];
  // qgl_c_e: number[];
  // date_test: Date[];
  // qoil_test: number[];
  // qgas_test: number[];
  // qwat_test: number[];
  // topside_t: number[];
  // topside_p: number[];
}

export interface PlotData {
  id?: string;
  name: string;
  x?: number[] | Date[] | string[];
  y?: number[] | Date[] | string;
  z?: number[] | Date[] | string;
  type: string;
  mode?: string;
  marker?: object;
  yaxis?: string;
  line?: object;
  boxpoints?: string,
  jitter?: number,
  pointpos?: number,
  legendgroup?:string;
  legendgrouptitle?:object;
  disable?:boolean;
  title?:object;
  colorscale?:object;

}

export interface PlotDataCore {
  id?: string;
  name: string;
  x?: number[] | Date[] | string[];
  y?: number[] | Date[] | string[];
  z?: number[] | Date[] | string[];
  type: string;
  mode?: string;
  marker?: object;
  yaxis?: string;
  line?: object;
  boxpoints?: string,
  jitter?: number,
  pointpos?: number,
  legendgroup?:string;
  legendgrouptitle?:object;
  disable?:boolean;
  title?:object;
  colorscale?:object;

}


export interface PlotData1 {
  id?: string;
  name: string;
  x?: number[] | Date[] | string[];
  y?: number[] | Date[]| string;
  type: string;
  mode?: string;
  marker?: object;
  yaxis?: string;
  line?: object;
  boxpoints?: string,
  jitter?: number,
  pointpos?: number,
  connectgaps?:boolean,
  legendgroup?:string;
  legendgrouptitle?:object;

}
export interface PlotDataBar {
  id?: string;
  name: string;
  status?: WellStatus;
  x?: number[] | Date[];
  y?: number[] | Date[];
  type: string;
  hovertemplate?: string;
  marker?: object;
}
export enum TestAttributes {
  qoil_test,
  qgas_test,
  qwat_test
}








