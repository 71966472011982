import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { ModelCatalogueData } from 'src/modules/types/model-catalogue-data';
import { GetModelCatalogueTableData } from './actions';



@State<ModelCatalogueData[]>({
  name: 'modelCatalogueTableData',
  defaults: {} as ModelCatalogueData[]
})
@Injectable()
export class ModelCatalogueTableDataState {
  @Action(GetModelCatalogueTableData)
  getSaveModelParams(context: StateContext<ModelCatalogueData[]>, {payload}: GetModelCatalogueTableData): void {
    context.setState(payload);
  }
}
