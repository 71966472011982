import { LoaderService } from './../../../../services/loader/loader.service';
import { BackAllocationService } from './../../../../services/back-allocation/back-allocation.service';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppSandbox } from 'src/modules/app/app.sandbox';

@Component({
  selector: 'wag-save-my-allocation',
  template: `
    <wag-loader></wag-loader>
    <form [formGroup]="saveAllocationForm">
      <mat-dialog-content class="mat-typography">
        <div>
          <h1 mat-dialog-title>
            <img src="./../../../../assets/save.svg" class="save-icon" />
            Save Allocation
            <img
              src="./../../../../assets/close.svg"
              class="close-icon"
              mat-dialog-close/>
          </h1>
        </div>

        <div class="field-container">
          <mat-form-field appearance="outline">
            <mat-label>Allocation name</mat-label>
            <input
              matInput
              placeholder="Back Allocation Name"
              formControlName="model_name"/>
            <mat-error *ngIf="duplicateError">Allocation name should be unique</mat-error>
          </mat-form-field>
        </div>
        <div class="field-container">
          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <textarea
              matInput
              placeholder="Description"
              formControlName="model_description"></textarea>
          </mat-form-field>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <button
          class="secondary-btn"
          mat-dialog-close
          style="margin-right: 16px"
          (click)="OnCancelClick()">
          Cancel
        </button>
        <button
          class="primary-btn"
          [disabled]="saveAllocationForm.invalid"
          (click)="onClickSaveMyAllocartion()" >
          Save Allocation
        </button>
      </mat-dialog-actions>
    </form>
  `,
  styleUrls: ['./save-my-allocation.component.scss'],
})
export class SaveMyAllocationComponent implements OnInit {
  saveAllocationForm: FormGroup = new FormGroup({});
  duplicateError!: boolean;
  affiliate!: string;
  action!: string;
  constructor(
    private formBuilder: FormBuilder,
    private sb: AppSandbox,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SaveMyAllocationComponent>,
    private loader: LoaderService
  ) {
    this.sb.selectedAffiliate$.subscribe((affiliate) => {
      this.affiliate = affiliate;
    });

  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.saveAllocationForm = this.formBuilder.group({
      model_name: [this.data.model_name, [Validators.required]],
      model_description: [this.data.model_description, [Validators.required]],
    });
  }

  OnCancelClick() {}

  onCall() {
    this.loader.isLoading.next(true);
    this.data.data.model_name = this.saveAllocationForm.get('model_name')?.value;
    this.data.data.model_description =this.saveAllocationForm.get('model_description')?.value;

  }

  onClickSaveMyAllocartion() {
    this.loader.isLoading.next(true);
    this.data.data.model_name = this.saveAllocationForm.get('model_name')?.value;
    this.data.data.model_description =this.saveAllocationForm.get('model_description')?.value;

    this.sb.
      saveBackAllocation(this.affiliate, this.data.data)
      .subscribe(res => {
        let response:any;
            response=res.response;
          if(response.response ==="Success") {
            this.loader.isLoading.next(false);
            this.dialogRef.close();
            this.duplicateError = false;
          }
          else if(response.response === "Model name should be unique") {
            this.saveAllocationForm.controls['model_name'].setErrors({
              nameExsist: true,
            });
            this.duplicateError = true;
            this.loader.isLoading.next(false);

          }

      });
  }
}
