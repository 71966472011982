import {Action, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {FieldRangeDates} from 'src/modules/types';
import { GetInferenceRangeDates } from './actions';

@State<FieldRangeDates>({
  name: 'inferenceRangeDates',
  defaults: undefined
})
@Injectable()
export class InferenceRangeDatesState {
  @Action(GetInferenceRangeDates)
  getQcRangeDates(context: StateContext<FieldRangeDates>, {payload}: GetInferenceRangeDates): void {
    context.setState(payload);
  }
}
