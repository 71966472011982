
import {MonitoringService} from '../../services';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {AppSandbox} from '../../app.sandbox';
import {ActivatedRoute} from '@angular/router';
import {
  DataFilters,
  FieldRangeDates,
  PlotData,
  ProductionAllocated,
  WellDailyData,
  WellOptimesData
} from 'src/modules/types';
import {first, skip} from 'rxjs/operators';
import { DailyData } from '../field-overview/field-overview.types';
import { PRODUCTION_ALLOCATED_PLOT_DATA } from '../field-overview/components';

@Component({
  selector: 'wag-well-overview',
  template: `
    <h1> {{well}} Well Monitoring</h1>
    <wag-date-filters [dateRange]="dateRange$ | async"
                      [minMaxRange]="minMaxRange$ | async"
                      (dateRangeChange)="onDateChange($event)"
                      title="Select period to monitor"></wag-date-filters>

    <wag-field-official-production [injectorWellOptimes]="injectorWellOptimes$ | async"
                                   [producerWellOptimes]="producerWellOptimes$ | async"
                                   [dateRange]="dateRange$ | async" [typeofWell]="typeofWell"
                                   [productionAllocatedGraph]="productionAllocatedGraph">
    </wag-field-official-production>

    <wag-field-daily-data [dailyData]="dailyData$ | async"
                          [dataFilters]="dataFilters" [well]="well" [typeofWell]="typeofWell"
                          [dateRange]="dateRange$ | async"></wag-field-daily-data>

    <wag-field-daily-well-data [injectorWellsDailyData]="injectorWellsDailyData$ | async"
                               [producerWellsDailyData]="producerWellsDailyData$ | async"
                               [dateRange]="dateRange$ | async" [typeofWell]="typeofWell">
    </wag-field-daily-well-data>
  `
})
export class WellOverviewContainer implements OnInit, OnDestroy{
  producerWellOptimes$: Observable<WellOptimesData[]> = this.sb.producerWellsOptimesData$;
  injectorWellOptimes$: Observable<WellOptimesData[]> = this.sb.injectorWellsOptimesData$;
  dailyData$: Observable<DailyData> = this.sb.dailyData$;
  producerWellsDailyData$: Observable<WellDailyData[]> = this.sb.producerWellsDailyData$.pipe(skip(1));
  injectorWellsDailyData$: Observable<WellDailyData[]> = this.sb.injectorWellsDailyData$.pipe(skip(1));
  productionAllocatedGraph: PlotData[] = JSON.parse(JSON.stringify(PRODUCTION_ALLOCATED_PLOT_DATA));
  productionAllocated$: Observable<ProductionAllocated> = this.sb.productionAllocated$.pipe(skip(1));
  dateRange$: Observable<FieldRangeDates> = this.sb.fieldRangeDates$.pipe(skip(1));
  minMaxRange$: Observable<FieldRangeDates> = this.sb.fieldRangeDates$.pipe(skip(1), first());
  dataFilters!: DataFilters;
  field!: string;
  well!:string;
  affiliate!:string;
  typeofWell!:string;

  dateRangeSubscription: Subscription = this.dateRange$.subscribe(dateRange => {
    this.dataFilters = {
      ...this.dataFilters,
      date_min: new Date(dateRange.startDate),
      date_max: new Date(dateRange.endDate)
    };

    this.loadWellOverviewData();
  });

  constructor(private monitoringService: MonitoringService, private sb: AppSandbox,
    private activatedRoute: ActivatedRoute,) {
    this.monitoringService.logPageView('wellOverview');
    this.sb.loadUserName();
    this.sb.selectedAffiliate$.subscribe(affiliate=>{this.affiliate=affiliate;});
  }

  async loadWelltypedata(){
    this.sb.loadWellType(this.affiliate,this.well).subscribe((res: any) => {
      this.typeofWell = res.well_type;
        this.sb.loadWellRangeDates(this.affiliate,this.dataFilters,this.well, this.typeofWell);
    });
  }

  
  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.field = params['field_name'];
      this.well = params['well_name'];
      this.loadWelltypedata();
      if (params['asset_name'] && this.field) {
        this.dataFilters = {
          ...this.dataFilters,
          asset: params['asset_name'],
          field: this.field
        };
        this.sb.fieldSelectChange.next();
      }
    });

    this.productionAllocated$.subscribe(
      (data: ProductionAllocated) => this.productionAllocatedGraph.map(
        (plot: PlotData) => {
          plot.x = data.date;
          plot.y = data[plot.id as keyof ProductionAllocated];
        }
      )
    );
  }
  onDateChange(dateRange: FieldRangeDates): void {
    
    this.sb.setFieldRangeDates(dateRange);
  }

  loadWellOverviewData(): void {
    
    this.sb.loadwellDailyData(this.affiliate, this.dataFilters, this.well, this.typeofWell);
    this.sb.loadwellProductionAllocated(this.affiliate,this.dataFilters, this.well, this.typeofWell);
    this.sb.loadWellsDailyData(this.affiliate,this.typeofWell, this.dataFilters, this.well);
    this.sb.loadWellsOptimesData(this.affiliate,this.typeofWell, this.dataFilters, this.well);
  }

  ngOnDestroy() {
    this.dateRangeSubscription.unsubscribe();
  }
}
