import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MsalGuard} from '@azure/msal-angular';
import {
  AssetOverviewContainer,
  FieldOverviewContainer,
  LoginContainer,
  ModelContainer,
  WellOverviewContainer,
  CallbackContainer,
  UnauthorizedContainer,
  PublicLayoutContainer,
  PrivateLayoutContainer,
  HomeContainer,
  CatalogueContainerComponent,
  BackAllocationContainerComponent
} from './containers';


const routes: Routes = [
  {
    path: '',
    component: PublicLayoutContainer,
    children: [
      {path: '', redirectTo: 'login', pathMatch: 'full'},
      {path: 'login', component: LoginContainer},
      {path: 'unauthorized', component: UnauthorizedContainer},
      {path: 'callback', component: CallbackContainer},
    ]
  },
  {
    path: '',
    component: PrivateLayoutContainer,
    children: [
      {path: 'home', component: HomeContainer},
      {path: 'model', component: ModelContainer},
      {path: 'modelcatalogue', component: CatalogueContainerComponent},
      {path: 'allocation', component: BackAllocationContainerComponent},
      {
        path: 'asset/:asset_name',
        children: [
          {
            path: 'field/:field_name',
            children: [
              {
                path: 'well/:well_name',
                component: WellOverviewContainer,
                pathMatch: 'full',
              },
              {
                path: '',
                pathMatch: 'full',
                component: FieldOverviewContainer
              }
            ]
          },
          {
            path: '',
            component: AssetOverviewContainer,
            pathMatch: 'full'
          }
        ],
      },
      {path: '**', redirectTo: 'home'}
    ],
    canActivate: [MsalGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
