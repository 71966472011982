import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ValueSelect } from 'src/modules/types';

@Component({
  selector: 'wag-catalogue-selction',
  template: `
    <form [formGroup]="catalogueFiltersForm" class="well-filters">
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Asset</mat-label>
        <mat-select formControlName="Asset" (selectionChange)="onSelectValue($event)" [placeholder]="'Select Asset'"
          required disableOptionCentering panelClass="my-panel-class">
          <mat-option *ngFor="let asset of assets" [value]="asset">{{ asset }}</mat-option>
        </mat-select>
        <mat-error *ngIf="catalogueFiltersForm.controls.Asset.errors?.required"> Please select an asset </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Field</mat-label>
        <mat-select formControlName="Field" (selectionChange)="onSelectValue($event)" placeholder="Select Field"
          required disableOptionCentering panelClass="my-panel-class">
          <mat-option *ngFor="let field of fields" [value]="field">{{ field }}</mat-option>
        </mat-select>
        <mat-error *ngIf="catalogueFiltersForm.controls.Field.errors?.required"> Please select a field </mat-error>
      </mat-form-field>
     

      <button class="primary-btn" color="primary" [disabled]="catalogueFiltersForm.invalid"
        (click)="catalogueFetch.emit()">Visualize Catalogue</button>
    </form>
  `,
  styleUrls: ['./catalogue-selction.component.scss']
})
export class CatalogueSelctionComponent implements OnChanges {
  @Input() assets!: string[] | null;
  @Input() fields!: string[] | null;
  @Input() wells!: string[] | null;
  @Output() valueSelected = new EventEmitter<ValueSelect>();
  @Output() catalogueFetch = new EventEmitter();


  catalogueFiltersForm: FormGroup = this.formBuilder.group({
    'Asset': [this.assets],
    'Field': [{ value: '', disabled: true }]
  });
  constructor(private formBuilder: FormBuilder) { }

  onSelectValue($event: MatSelectChange): void {
    if ($event.source.ngControl.name) {
      this.valueSelected.emit({ control: $event.source.ngControl.name.toString(), value: $event.value });
      if ($event.source.ngControl.name === 'Asset') {
        this.catalogueFiltersForm.controls['Field'].enable();
      }
    } else if ($event.source.ngControl.name === 'Field') {
      this.catalogueFiltersForm.controls['Well'].enable();

    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.assets) {
      this.catalogueFiltersForm.controls['Field'].disable();
      this.catalogueFiltersForm.controls['Field'].setValue('');
      this.catalogueFiltersForm.setErrors({ 'invalid': true });
    }
  }
}
