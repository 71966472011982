import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { PlotData } from 'src/modules/types';

@Component({
  selector: 'wag-allocation-chart',
  template: `



    <div class="toggle-button-container">
    <mat-button-toggle-group  [value]="selectedButton" (change)="changeToggle($event)">
      <mat-button-toggle  *ngFor="let option of toogleButtonList ; index  as i" [value]="option">
        {{option}}
      </mat-button-toggle>
    </mat-button-toggle-group>
    </div>


      <div class="graphs-container">
        <wag-well-graph
          [data]="allocationVSEstimationGraphData"
          [layout]="allocationVSEstimationLayout" >
        </wag-well-graph>
      </div>
      <div class="graphs-container">
        <wag-well-graph
          [data]="coefficientGraphData"
          [layout]="coefficientLayout">
        </wag-well-graph>
      </div>
      <div class="graphs-container">
        <wag-well-graph
          [data]="totalNewAllocationsGraphData"
          [layout]="totalNewAllocationsLayout">
        </wag-well-graph>
      </div>


  `,
  styleUrls: ['./allocation-chart.component.scss'],
})
export class AllocationChartComponent implements  OnChanges {
  @Input() allocationVSEstimationGraphData!: PlotData[];
  @Input() allocationVSEstimationLayout!: any;

  @Input() coefficientGraphData!: PlotData[];
  @Input() coefficientLayout!: any;

  @Input() totalNewAllocationsGraphData!: PlotData[];
  @Input() totalNewAllocationsLayout!: any;

  @Input()toogleButtonList!:string[]



  @Output() clickOnToggle:EventEmitter<any> = new EventEmitter<any>();

  selectedButton!:string;

  constructor() {
    //do somthing
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.selectedButton=this.toogleButtonList[0]
  }

  // ngOnInit() {
  //   // <div class="graphs-container">
  //   //     <wag-well-graph
  //   //       [data]="totalNewAllocationsGraphData"
  //   //       [layout]="coefficientLayout">
  //   //     </wag-well-graph>
  //   //   </div>
  // }

  changeToggle(event:any){
   this.selectedButton=event.value;
   let index=this.toogleButtonList.indexOf(event.value);
   let obj:any={
    value:this.selectedButton,
    index:index
   }
   this.clickOnToggle.emit(obj);
  }
}
