import { ColumnDefinitions } from "src/modules/types";

export const MODEL_CATALOGUE_COLUMN: ColumnDefinitions[] = [
    { colDef: 'checkbox', label: "", isEnabled: true, showHide: false },
    { colDef: 'select', label: "", isEnabled: true, showHide: false },
    { colDef: 'id', label: "ID", isEnabled: true, showHide: true },
    { colDef: 'model_name', label: "Model Name", isEnabled: true, showHide: true },
    { colDef: 'well_id', label: "Well Name", isEnabled: true, showHide: true },
    { colDef: 'target_value', label: "Target", isEnabled: true, showHide: true },
    { colDef: 'model_description', label: "Description", isEnabled: false, showHide: true },
    { colDef: 'algorithm', label: "Model Type", isEnabled: false, showHide: true },
    { colDef: 'data_input_source', label: "Input Source", isEnabled: false, showHide: true },
    { colDef: 'features', label: "Features", isEnabled: false, showHide: true },
    { colDef: 'target_source', label: "Target Source", isEnabled: false, showHide: true },
    { colDef: 'save_model_time', label: "Model Save Date", isEnabled: false, showHide: true },

    { colDef: 'user_id', label: "User", isEnabled: false, showHide: true },
    { colDef: 'model_status', label: "Model Status", isEnabled: false, showHide: true },
    { colDef: 'min_date', label: "Start Training Date", isEnabled: false, showHide: false },
    { colDef: 'max_date', label: "End Training Date", isEnabled: false, showHide: false },
    { colDef: 'ml_training_set_size', label: "ML Training Size", isEnabled: false, showHide: false },
    { colDef: 'valid_flag', label: "Valid Flag", isEnabled: false, showHide: false },
    { colDef: 'well_test_status', label: "Well Test Status", isEnabled: false, showHide: false },
    { colDef: 'well_type', label: "Well Type", isEnabled: false, showHide: false },
]
