import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Affiliate } from 'src/modules/types';
import { AppSandbox } from '../../app.sandbox';
import { AFFILIATELIST } from '../../containers/home/home-constant';
import { AuthService } from '../../services';


@Component({
  selector: 'wag-header',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./header.component.scss'],
  template: `
    <mat-toolbar>
      <button mat-icon-button color="accent" (click)="toggleMenu.emit()">
        <mat-icon>menu</mat-icon>
      </button>
      <img src="./../../../../assets/total_energies_icon.png" alt="Total energies icon">

      <span class="logo-container"><img src="./../../../../assets/t-dash-logo.png" class="app-logo" alt="Total energies icon">Tmore Data Analysis Synthesis Hub</span>
      <span class="aff-slection">
          <form [formGroup]="affiliateForm" class="aff-form">
          <label class="aff-lable">Affiliate Selection</label>
              <mat-form-field appearance="outline" floatLabel="always">

                <mat-select formControlName="affiliateList" (selectionChange)="changeAffilate($event.value)"
                  required disableOptionCentering panelClass="my-panel-class-maxheight">
                  <mat-select-trigger>
                  <ng-container *ngIf="selected!==undefined">

                  <img src="{{selected.path}}" style="width: 24px;height: 24px;padding: 4px; margin-bottom: -12px; margin-top: -12px;">
                    {{selected.displayName}}
                  </ng-container>
                  </mat-select-trigger>
                  <mat-option class="mat-option" *ngFor="let affiliate of AFFILIATELIST" [value]='affiliate.value' ><img [src]="affiliate.path" style=" width: 1.7em; height: 1.3em; padding: 4px; margin-bottom: -12px; padding-right: 28px;
    margin-left: -4px;">
                  {{ affiliate.displayName }}
                  </mat-option>
                </mat-select>

              </mat-form-field>

            </form>
       </span>

      <span class="user-name">{{userName$ | async}}</span>

      <button mat-icon-button (click)="logout()" title="Sign out" class="logout-btn">
        <mat-icon>logout</mat-icon>
      </button>


       <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Help Icon">
  <mat-icon class="red-icon">help</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item [matMenuTriggerFor]="vertebrates">
  <mat-icon><img src="./../../../../assets/microsoft-teams.svg"></mat-icon>
    <span class="mat-menu-items">Teams Channel</span>
  </button>
</mat-menu>

<mat-menu #vertebrates="matMenu">

<button class="mat-menu-items" mat-menu-item>
    <mat-icon>groups</mat-icon>
    <span><a target="_blank" style="text-decoration:none !important; color:inherit" href="https://teams.microsoft.com/l/channel/19%3aaCXOL6RmS_4S8HpNYYhPQ_s_C_QHTLyHY_TVEg6wTvQ1%40thread.tacv2/General?groupId=22d8f617-aebd-43e1-afb6-b7e865db0b4c&tenantId=329e91b0-e21f-48fb-a071-456717ecc28e"> General</a> </span>
  </button>

<button class="mat-menu-items" mat-menu-item>
    <mat-icon>bug_report</mat-icon>
    <span><a target="_blank" style="text-decoration:none !important; color:inherit" href="https://teams.microsoft.com/l/channel/19%3a4d765f5d918540858f855431258260c4%40thread.tacv2/Bugs?groupId=22d8f617-aebd-43e1-afb6-b7e865db0b4c&tenantId=329e91b0-e21f-48fb-a071-456717ecc28e"> Bug Reporting</a> </span>
  </button>

  <button class="mat-menu-items" mat-menu-item>
    <mat-icon>menu_book</mat-icon>
    <span><a target="_blank" style="text-decoration:none !important; color:inherit" href="https://totalworkplace.sharepoint.com/:f:/r/sites/TDASHWAG-UsersChannel/Documents%20partages/General/User%20Manual?csf=1&web=1&e=dAV7Ru"> User Manual</a> </span>
  </button>

   <button class="mat-menu-items" mat-menu-item>
    <mat-icon>videocam</mat-icon>
    <span><a target="_blank" style="text-decoration:none !important; color:inherit" href="https://totalworkplace.sharepoint.com/:f:/r/sites/TDASHWAG-UsersChannel/Documents%20partages/General/TDASH%20Tutorials?csf=1&web=1&e=122rbi"> Tutorial Videos</a> </span>
  </button>

</mat-menu>
    </mat-toolbar>
  `
})
export class HeaderComponent implements OnInit {
  AFFILIATELIST: Affiliate[] = [];
  selected!:Affiliate;

  @Output() toggleMenu: EventEmitter<void> = new EventEmitter<void>();
  userName$: Observable<string> = this.sb.userName$;
  affiliateForm: FormGroup = this.formBuilder.group({
    'affiliateList': []
  });
  constructor(
    private authService: AuthService,
    private sb: AppSandbox,
    private formBuilder: FormBuilder,
    private router:Router) {
    let data: any = localStorage.getItem('rolls');
    let currentAffiliate: any = localStorage.getItem('Selected_Aff_List');
    let myAffJson= JSON.parse(currentAffiliate);

    if (data !== null) {

      const roll: any[] = JSON.parse(data);
      if (roll.includes('Admin') && roll.length <2) {

        if(myAffJson!==null && myAffJson!==undefined){
          this.AFFILIATELIST = AFFILIATELIST.filter(aff=> aff.value!='als');
          this.affiliateForm.controls['affiliateList'].setValue(myAffJson.value);
          this.selected = myAffJson;
          this.sb.setselectedAffiliate(this.affiliateForm.controls['affiliateList'].value);
        }
        else{
          this.AFFILIATELIST = AFFILIATELIST.filter(aff=> aff.value!='als');
          this.affiliateForm.controls['affiliateList'].setValue(this.AFFILIATELIST[0].value);
          this.selected = this.AFFILIATELIST[0];
          this.sb.setselectedAffiliate(this.affiliateForm.controls['affiliateList'].value);
        }

      }
      else if(roll.includes('Admin')&& roll.includes('Access.ALS')){
          if(myAffJson!==null && myAffJson!==undefined){
            this.AFFILIATELIST = AFFILIATELIST
            this.affiliateForm.controls['affiliateList'].setValue(myAffJson.value);
            this.selected = myAffJson;
            this.sb.setselectedAffiliate(this.affiliateForm.controls['affiliateList'].value);
          }
          else{
            this.AFFILIATELIST = AFFILIATELIST
            this.affiliateForm.controls['affiliateList'].setValue(this.AFFILIATELIST[0].value);
            this.selected = this.AFFILIATELIST[0];
            this.sb.setselectedAffiliate(this.affiliateForm.controls['affiliateList'].value);
          }
      }
      else {
        if(myAffJson!==null && myAffJson!==undefined){
          let temp: any[] = [];

          temp = roll.map(r => {
            let a = r.split('.');
            let b: string = a[1];
            return b.toLowerCase();
          })

          this.AFFILIATELIST = AFFILIATELIST.filter(item => temp.includes(item.value));
          this.affiliateForm.controls['affiliateList'].setValue(myAffJson.value);
          this.selected = myAffJson;
          this.sb.setselectedAffiliate(this.affiliateForm.controls['affiliateList'].value);
        }
        else{
          let temp: any[] = [];

          temp = roll.map(r => {
            let a = r.split('.');
            let b: string = a[1];
            return b.toLowerCase();
          })

          this.AFFILIATELIST = AFFILIATELIST.filter(item => temp.includes(item.value));
          this.selected = this.AFFILIATELIST[0];
          this.affiliateForm.controls['affiliateList'].setValue(this.AFFILIATELIST[0].value);
          this.sb.setselectedAffiliate(this.AFFILIATELIST[0].value);
        }


      }


    }
  }

  ngOnInit(): void {
    this.sb.loadUserName();

  }

  logout(): void {
    this.authService.logout();
  }

  changeAffilate(event: any) {
    let tempArray: Affiliate[] = this.AFFILIATELIST.filter(affilate => affilate.value === event);
    this.selected = tempArray[0];
    this.sb.setselectedAffiliate(event);
    this.router.navigate(['home']);
    localStorage.setItem("Selected_Aff_List", JSON.stringify(this.selected));
  }
}
