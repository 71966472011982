import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { skip, map } from 'rxjs/operators';
import { AppSandbox } from 'src/modules/app/app.sandbox';
import { PS_PRODUCTION_ATTRIBUTE, PS_PRODUCTION_ATTRIBUTE_GOR, PS_PRODUCTION_ATTRIBUTE_QGAS, PS_PRODUCTION_ATTRIBUTE_QLIQ, PS_PRODUCTION_ATTRIBUTE_QWAT, PS_PRODUCTION_ATTRIBUTE_WCT, PS_PRODUCTION_MONITORING_ATTRIBUTE, PS_PRODUCTION_MONITORING_ATTRIBUTE_GOR, PS_PRODUCTION_MONITORING_ATTRIBUTE_QGAS, PS_PRODUCTION_MONITORING_ATTRIBUTE_QLIQ, PS_PRODUCTION_MONITORING_ATTRIBUTE_QWAT, PS_PRODUCTION_MONITORING_ATTRIBUTE_WCT, PS_PRODUCTION_SETTINGS, PS_PRODUCTION_SETTINGS_QGAS, PS_PRODUCTION_SETTINGS_QWAT } from 'src/modules/app/containers/back-allocation.container/components/allocation-settinggs/allocation-settings-const';
import { TARGET_LIST_PRRODUCER } from 'src/modules/app/containers/model/components/machine-learning/machine-learning.const';
import { WellSelectionService } from 'src/modules/app/services';
import { DataFilters, FieldRangeDates, VFMModel } from 'src/modules/types';
import { SaveMergeModel, TargetSource } from 'src/modules/types/merge-model.interface';

@Component({
  selector: 'wag-merge-model',
  template: `
    <wag-loader></wag-loader>
    <form [formGroup]="mergeModelForm">
      <mat-dialog-content class="">
 <div>
      <h1 mat-dialog-title>
          
          Create Merge Model
          <img src="./../../../../assets/close.svg" class="close-icon" mat-dialog-close>
      </h1>
  </div>
  <div>
  <form [formGroup]="mergeModelFieldAndTargetForm">
       <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Well</mat-label>
        <mat-select formControlName="Well" (selectionChange)="onSelectWellValue($event)" placeholder="Select Well"
          required disableOptionCentering panelClass="my-panel-class">
          <mat-option *ngFor="let well of wells" [value]="well">{{ well }}</mat-option>
        </mat-select>
        <mat-error *ngIf="mergeModelFieldAndTargetForm.controls.Well.errors?.required"> Please select a well </mat-error>
      </mat-form-field>

       <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Target</mat-label>
        <mat-select formControlName="Target" (selectionChange)="onSelectTargetValue($event)" placeholder="Select Target"
          required disableOptionCentering panelClass="my-panel-class">
          <mat-option *ngFor="let target of targets" [value]="target">{{ target }}</mat-option>
        </mat-select>
        <mat-error *ngIf="mergeModelFieldAndTargetForm.controls.Target.errors?.required"> Please select a target </mat-error>
      </mat-form-field>

      <button class="primary-btn" color="primary"   [disabled]="mergeModelFieldAndTargetForm.invalid"
        (click)="onClickVisualizeWell()">Load Well</button>
  </form>
</div>

 <div class="field-container">
          <mat-form-field appearance="outline">
              <mat-label>Model name</mat-label>
              <input matInput required placeholder="Model name ( e.g.<field_name>_<wel_name>_<target>)" formControlName="model_name">
              <mat-error *ngIf="mergeModelForm.controls.model_name.errors?.required"> Please select a model name </mat-error>
              </mat-form-field>
      </div>
        <div class="field-container">
          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <textarea
              matInput required
              placeholder="Description"
              formControlName="Description"></textarea>
               <mat-error *ngIf="mergeModelForm.controls.Description.errors?.required"> Please select a discription </mat-error>
          </mat-form-field>
        </div>

        <div class="title">
        <h3 mat-dialog-title>
        Data Source Selection
        </h3>
         <h3 mat-dialog-title>
        Time Seperators
        </h3>
        </div>

        <div>
         <h1>1</h1>
       <mat-form-field appearance="outline" floatLabel="always">       
        <mat-select formControlName="VFMModel" (selectionChange)="onSelectVFMModelValue($event)" placeholder="Select Value"
          required disableOptionCentering panelClass="my-panel-class">
          <mat-option *ngFor="let vfmModel of vfmModels" [value]="vfmModel">{{ vfmModel }}</mat-option>
        </mat-select>
        <mat-error *ngIf="mergeModelForm.controls.VFMModel.errors?.required"> Please select a value </mat-error>
      </mat-form-field>

       <mat-form-field appearance="outline" floatLabel="always">
       
        <mat-select formControlName="VFMTarget" (selectionChange)="onSelectVFMTargetValue($event)" placeholder="Select Value"
          required disableOptionCentering panelClass="my-panel-class">
          <mat-option *ngFor="let vfmTarget of vfmTargets" [value]="vfmTarget" [matTooltip] = "vfmTarget.tooltip"  [matTooltipPosition]="'right'" >{{ VFMModelValue === 'VFM_Models' ? vfmTarget.model : vfmTarget }}</mat-option>
        </mat-select>
        <mat-error *ngIf="mergeModelForm.controls.VFMTarget.errors?.required"> Please select a value </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline"  class="date-picker-position superRequired" floatLabel="always">
       
          <mat-label>Start date (dd/mm/YYYY)</mat-label>
          <input matInput
                 [matDatepicker]="startDatePickerElement"
                 placeholder="dd/mm/yyyy"
              required
              formControlName="startDatePickerElement"

                 [max]="datePickerEnd"
                 (dateChange)="onDateChange('startDate', $event.target.value)">
          <mat-datepicker-toggle matSuffix [for]="startDatePickerElement">
            <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #startDatePickerElement></mat-datepicker>
           <mat-error *ngIf="mergeModelForm.controls.startDatePickerElement.errors?.required"> Please select a value </mat-error>
        </mat-form-field>

</div>


 <div>
         <h1>2</h1>
       <mat-form-field appearance="outline" floatLabel="always">       
        <mat-select formControlName="VFMModel2" (selectionChange)="onSelectVFMModel2Value($event)" placeholder="Select Value"
          required disableOptionCentering panelClass="my-panel-class">
          <mat-option *ngFor="let vfmModel2 of vfmModels2" [value]="vfmModel2">{{ vfmModel2 }}</mat-option>
        </mat-select>
        <mat-error *ngIf="mergeModelForm.controls.VFMModel2.errors?.required"> Please select a value </mat-error>
      </mat-form-field>

       <mat-form-field appearance="outline" floatLabel="always">
       
        <mat-select formControlName="VFMTarget2" (selectionChange)="onSelectVFMTarget2Value($event)" placeholder="Select Value"
          required disableOptionCentering panelClass="my-panel-class">
          <mat-option *ngFor="let vfmTarget2 of vfmTargets2" [value]="vfmTarget2" [matTooltip] = "vfmTarget2.tooltip"  [matTooltipPosition]="'right'" >{{ VFMModel2Value === 'VFM_Models' ? vfmTarget2.model : vfmTarget2 }}</mat-option>
        </mat-select>
        <mat-error *ngIf="mergeModelForm.controls.VFMTarget2.errors?.required"> Please select a value </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline"  class="date-picker-position superOptional" floatLabel="always">
          <mat-label>End date (dd/mm/YYYY)</mat-label>
          <input matInput
                 [matDatepicker]="startDatePickerElement2"
                 placeholder="dd/mm/yyyy"
                
                 [max]="datePickerEnd"
                 (dateChange)="onDateChange('endDate', $event.target.value)">
          <mat-datepicker-toggle matSuffix [for]="startDatePickerElement2">
            <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #startDatePickerElement2></mat-datepicker>
        </mat-form-field>

</div>

 <div>
         <h1>3</h1>
       <mat-form-field appearance="outline" floatLabel="always">       
        <mat-select formControlName="VFMModel3" (selectionChange)="onSelectVFMModel3Value($event)" placeholder="Select Value"
           disableOptionCentering panelClass="my-panel-class">
          <mat-option *ngFor="let vfmModel3 of vfmModels3" [value]="vfmModel3">{{ vfmModel3 }}</mat-option>
        </mat-select>
       
      </mat-form-field>

       <mat-form-field appearance="outline" floatLabel="always">
       
        <mat-select formControlName="VFMTarget3" (selectionChange)="onSelectVFMTarget3Value($event)" placeholder="Select Value"
           disableOptionCentering panelClass="my-panel-class">
          <mat-option *ngFor="let vfmTarget3 of vfmTargets3" [value]="vfmTarget3" [matTooltip] = "vfmTarget3.tooltip"  [matTooltipPosition]="'right'" >{{ VFMModel3Value === 'VFM_Models' ? vfmTarget3.model : vfmTarget3 }}</mat-option>
        </mat-select>
       
      </mat-form-field>
</div>

      </mat-dialog-content>

      <mat-dialog-actions align="end">
        <button
          class="secondary-btn"
          mat-dialog-close
          style="margin-right: 16px"
          (click)="OnCancelClick()">
          Cancel
        </button>
        <button
          class="primary-btn"
          [disabled]="mergeModelForm.invalid"
          (click)="onClickSaveModelButton()" >
         Save Model
        </button>
      </mat-dialog-actions>
      </form>
  `,
  styleUrls: ['./merge-model.component.scss']
})
export class MergeModelComponent implements OnInit {
  mergeModelForm: FormGroup = new FormGroup({});
  mergeModelFieldAndTargetForm: FormGroup = new FormGroup({});
  affiliate!: string;
  action!: string;
  filterData: DataFilters = { asset: '', field: '', well: '' };
  VFMModelsList: any;
  saveVfmDetails: any;
  datePickerEnd!: Date;
  asset: string = '';
  field: string = '';
  wells: string[] = [];
  targets: string[] = TARGET_LIST_PRRODUCER;
  vfmModels: string[] = [];
  vfmTargets: any;
  vfmModels2: string[] = [];
  vfmTargets2: any;
  vfmModels3: string[] = [];
  vfmTargets3: any;
  selectedWell: any;
  selectedTarget: any;
  VFMModelValue: string = "";
  VFMModel2Value: string = "";
  VFMModel3Value: string = "";
  startDate: any;
  endDate: any;
  userName$: Observable<string> = this.sb.userName$;
  userName: string = '';
  dateRangeSubscription: Subscription;
  dateRange$: Observable<FieldRangeDates> = this.sb.allocationRangeDates$.pipe(skip(1));
  modelNameFromVfm: string[] = [];
  descriptionFromVfm: string = '';
  modelData: any;
  vfmModelsSelectedTargets: any;
  vfmModelsSelectedTargets2: any;
  vfmModelsSelectedTargets3: any;
  vfmModelList: VFMModel[] = [];


  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly sb: AppSandbox,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly dialogRef: MatDialogRef<MergeModelComponent>,
    private readonly wellSelectionService: WellSelectionService,
  ) {
    this.sb.selectedAffiliate$.subscribe((affiliate) => {
      this.affiliate = affiliate;
    });
    if (this.data) {
      this.action = this.data.action;
    }

    this.dateRangeSubscription = this.dateRange$.subscribe(dateRange => {

      this.filterData = {
        ...this.filterData,
        date_min: new Date(dateRange.startDate),
        date_max: new Date(dateRange.endDate),
      };
    });

  }


  ngOnInit(): void {
    this.asset = this.wellSelectionService.getAssetValue();
    this.field = this.wellSelectionService.getFieldValue();

    this.filterData.asset = this.asset;
    this.filterData.field = this.field;

    this.sb.loadWells(this.affiliate, this.asset, this.field).subscribe(response => {
      this.wells = response;
    });

    this.mergeModelFieldAndTargetForm = this.formBuilder.group({
      'Well': [''],
      'Target': [''],
    })

    this.initilaizeMergeModelForm();

  }


  initilaizeMergeModelForm() {
    this.mergeModelForm = this.formBuilder.group({
      'Well': [''],
      'Target': [''],
      'model_name': [''],
      'Description': [''],
      'VFMModel': [''],
      'VFMTarget': [''],
      'VFMModel2': [''],
      'VFMTarget2': [''],
      'VFMModel3': [''],
      'VFMTarget3': [''],
      'startDatePickerElement': ['']
    })
  }

  onSelectWellValue(event: any) {
    this.selectedWell = event.value
  }

  onSelectTargetValue(event: any) {
    this.selectedTarget = event.value;
    switch (this.selectedTarget) {
      case 'Qoil':
        this.vfmModels = PS_PRODUCTION_SETTINGS;
        this.vfmModels2 = PS_PRODUCTION_SETTINGS;
        this.vfmModels3 = PS_PRODUCTION_SETTINGS;
        break;
      case 'Qgas':
        this.vfmModels = PS_PRODUCTION_SETTINGS_QGAS;
        this.vfmModels2 = PS_PRODUCTION_SETTINGS_QGAS;
        this.vfmModels3 = PS_PRODUCTION_SETTINGS_QGAS;
        break;
      case 'Qwat':
        this.vfmModels = PS_PRODUCTION_SETTINGS_QWAT;
        this.vfmModels2 = PS_PRODUCTION_SETTINGS_QWAT;
        this.vfmModels3 = PS_PRODUCTION_SETTINGS_QWAT;
        break;
      case 'Qliq':
        this.vfmModels = PS_PRODUCTION_ATTRIBUTE_QLIQ;
        this.vfmModels2 = PS_PRODUCTION_ATTRIBUTE_QLIQ;
        this.vfmModels3 = PS_PRODUCTION_ATTRIBUTE_QLIQ;
        break;
      case 'GOR':
        this.vfmModels = PS_PRODUCTION_ATTRIBUTE_GOR;
        this.vfmModels2 = PS_PRODUCTION_ATTRIBUTE_GOR;
        this.vfmModels3 = PS_PRODUCTION_ATTRIBUTE_GOR;
        break;
      // case 'GLR':
      //   // this.vfmModels =  ML_TARGET_SOURCE_PRODUCER;
      //   break;
      case 'WCT':
        this.vfmModels = PS_PRODUCTION_ATTRIBUTE_WCT;
        this.vfmModels2 = PS_PRODUCTION_ATTRIBUTE_WCT;
        this.vfmModels3 = PS_PRODUCTION_ATTRIBUTE_WCT;
        break;
      default:
        break;
    }

  }

  onClickVisualizeWell() {
    this.filterData.well = this.selectedWell;
    this.filterData.target = this.selectedTarget;
    this.sb.loadFieldRangeDatesForAllocation(this.affiliate, this.filterData);
    this.sb.callVFMMOdelsList(this.affiliate, this.filterData).subscribe((response: any) => {
      setTimeout(() => {

        this.VFMModelsList = response;
        this.saveVfmDetails = this.getVFMModelList(this.selectedWell, this.selectedTarget);
        this.VFMModelsList.map((data: any) => {
          this.modelNameFromVfm.push(data.model_name);
        })
      })
      this.mergeModelForm.controls['model_name'].setValue(this.asset + '_' + this.selectedWell + '_' + this.selectedTarget + '_' + moment().format('YYYY-MM-DD') + '_',);
      this.mergeModelForm.controls['VFMModel'].setValue('');
      this.mergeModelForm.controls['VFMTarget'].setValue('');
      this.mergeModelForm.controls['VFMModel2'].setValue('');
      this.mergeModelForm.controls['VFMTarget2'].setValue('');
      this.mergeModelForm.controls['VFMModel3'].setValue('');
      this.mergeModelForm.controls['VFMTarget3'].setValue('');

    })
  }

  getVFMModelList(well: string, target: string): any[] {
    let filterData: any = this.VFMModelsList
      .filter((data: any) => {
        return (data.well_id === well && data.target_value === target) && !data.is_merge_model
      })
      .map((data: any) => {
        return {
          model_name: data.model_name,
          run_id: data.run_id,
          username: data.username,
          save_model_time: data.save_model_time,
          description: data.description
        };
      });
    return filterData;
  }




  onSelectVFMModelValue(event: any) {
    this.VFMModelValue = event.value;
    this.mergeModelForm.controls['VFMTarget'].setValue('');
    this.vfmTargets = [];
    this.vfmTargets = this.manipulateModelDropdown(event.value);
  }

  manipulateModelDropdown(value: string) {
    switch (value) {
      case 'PS_Production_data':
        if (this.selectedTarget == 'Qoil') {
          return PS_PRODUCTION_ATTRIBUTE;
        } else if (this.selectedTarget == 'Qgas') {
          return PS_PRODUCTION_ATTRIBUTE_QGAS;
        } else if (this.selectedTarget == 'Qwat') {
          return PS_PRODUCTION_ATTRIBUTE_QWAT;

        } else if (this.selectedTarget == 'Qliq') {
          return PS_PRODUCTION_ATTRIBUTE_QLIQ;

        } else if (this.selectedTarget == 'GOR') {
          return PS_PRODUCTION_ATTRIBUTE_GOR;

        } else if (this.selectedTarget == 'GLR') {
          //  return [];

        } else if (this.selectedTarget == 'WCT') {
          return PS_PRODUCTION_ATTRIBUTE_WCT

        } else {
          return ['No Value'];
        }
        break;

      case 'VFM_Models':
        return this.saveVfmDetails.map((name: any) => {
          return { model: name.model_name, tooltip: name.model_name + ', ' + name.save_model_time + ', ' + name.description + ', ' + name.username }
        });
        break;

      case 'PS_Production_Monitoring':
        if (this.selectedTarget == 'Qoil') {
          return PS_PRODUCTION_MONITORING_ATTRIBUTE;
        } else if (this.selectedTarget == 'Qgas') {
          return PS_PRODUCTION_MONITORING_ATTRIBUTE_QGAS;
        } else if (this.selectedTarget == 'Qwat') {
          return PS_PRODUCTION_MONITORING_ATTRIBUTE_QWAT;

        } else if (this.selectedTarget == 'Qliq') {
          return PS_PRODUCTION_ATTRIBUTE_QLIQ;

        } else if (this.selectedTarget == 'GOR') {
          return PS_PRODUCTION_MONITORING_ATTRIBUTE_GOR;

        } else if (this.selectedTarget == 'GLR') {
          //  return [];

        } else if (this.selectedTarget == 'WCT') {
          return PS_PRODUCTION_MONITORING_ATTRIBUTE_WCT;
        } else {
          return ['No Value'];
        }
        break;

      default:
        break;



    }

  }
  onSelectVFMTargetValue(event: any) {
    this.vfmModelsSelectedTargets = this.manipulateTargetDropdowns(event.value);
  }

  onSelectVFMModel2Value(event: any) {
    this.VFMModel2Value = event.value
    this.mergeModelForm.controls['VFMTarget2'].setValue('');
    this.vfmTargets2 = this.manipulateModelDropdown(event.value);
  }

  onSelectVFMTarget2Value(event: any) {
    this.vfmModelsSelectedTargets2 = this.manipulateTargetDropdowns(event.value);
  }

  onSelectVFMModel3Value(event: any) {
    this.VFMModel3Value = event.value;
    this.mergeModelForm.controls['VFMTarget3'].setValue('');
    this.vfmTargets3 = this.manipulateModelDropdown(event.value);
  }

  onSelectVFMTarget3Value(event: any) {
    this.vfmModelsSelectedTargets3 = this.manipulateTargetDropdowns(event.value);
  }

  manipulateTargetDropdowns(value: any) {
    this.modelData = [];
    this.VFMModelsList.map((modelData: any) => {
      if (value.model == modelData.model_name) {
        this.modelData = modelData;
      }
    })

    if (this.modelData.length != 0) {
      return {
        model_name: this.modelData.model_name,
        run_id: this.modelData.run_id,
        username: this.modelData.username,
        save_model_time: this.modelData.save_model_time,
        description: this.modelData.description
      }
    } else {
      return value;
    }
  }

  onDateChange(dateType: string, date: Date): void {
    if (dateType === 'startDate') {
      this.startDate = this.formattedDate(date);
    }
    if (dateType === 'endDate') {
      this.endDate = this.formattedDate(date);
    }

  }

  formattedDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  OnCancelClick() {
    this.dialogRef.close({ "flag": false });
  }

  onClickSaveModelButton() {
    this.userName$.subscribe(data => {
      this.userName = data;
    })


    let targetSource: TargetSource = {
      target_source_1: {
        tbl_s: this.mergeModelForm.controls['VFMModel'].value,
        col_s: this.vfmModelsSelectedTargets,
        end_date: this.startDate
      },

      target_source_2: {
        tbl_s: this.mergeModelForm.controls['VFMModel2'].value,
        col_s: this.vfmModelsSelectedTargets2,
        end_date: this.endDate
      },

      target_source_3: {
        tbl_s: this.mergeModelForm.controls['VFMModel3'].value,
        col_s: this.vfmModelsSelectedTargets3 ? this.vfmModelsSelectedTargets3 : ""
      }
    }


    let mergeModelParams: SaveMergeModel = {
      asset: this.asset,
      field: this.field,
      well: this.selectedWell,
      model_name: this.mergeModelForm.controls['model_name'].value,
      description: this.mergeModelForm.controls['Description'].value,
      target: this.selectedTarget,
      username: this.userName,
      target_source: targetSource,
    };

    console.log(mergeModelParams);

    this.sb.saveMergeModel(this.affiliate, mergeModelParams).pipe(map((resp: any) => {
      this.sb.loadSavedModelTableData(this.affiliate, this.field, this.asset);
      this.sb.loadModelCatalogueTableData(this.affiliate, this.filterData.asset, this.field);
      if (resp.response.status_code == 200 || resp.response.status_code == 201) {
        this.dialogRef.close({ "flag": false });
      } else {
        console.log('Faild API');
        this.dialogRef.close({ "flag": true });
      }  
    })

    ).subscribe((response: any) => {
      console.log(response);
    },
      (error) => {
        console.log('Faild API');
        this.dialogRef.close({ "flag": true });
      });



  }


}
