import {Action, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {GetFields} from './actions';

@State<string[]>({
  name: 'fields',
  defaults: []
})
@Injectable()
export class FieldsState {
  @Action(GetFields)
  getAssets(context: StateContext<string[]>, {payload}: GetFields): void {
    context.setState([...payload]);
  }
}
