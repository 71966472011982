import {Component, ViewChild} from '@angular/core';
import {MatDrawer} from '@angular/material/sidenav';
import { AppSandbox } from 'src/modules/app/app.sandbox';

@Component({
  selector: 'wag-private-layout',
  template: `
    <wag-header (toggleMenu)="toggleSideMenu()"></wag-header>
    <mat-drawer-container>
      <mat-drawer #drawer mode="push" opened="true">
       <ng-container *ngIf="showSideNav">
       <wag-sidenav></wag-sidenav>
       </ng-container>
      </mat-drawer>
      <mat-drawer-content>
        <main>
          <wag-loader></wag-loader>
          <router-outlet></router-outlet>
        </main>
      </mat-drawer-content>
    </mat-drawer-container>
  `,
  styleUrls: ['./private-layout.container.scss']
})
export class PrivateLayoutContainer {
  @ViewChild('drawer') drawer!: MatDrawer;
  showSideNav:boolean=false;
  toggleSideMenu(): void {
    this.drawer.toggle().then(() => window.dispatchEvent(new Event('resize')));
  }

  constructor(private sb:AppSandbox){
    this.sb.selectedAffiliate$.subscribe(aff=>{
      
       if(aff!==''){
        this.showSideNav=true;
       }
    })
  }
}
