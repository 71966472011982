import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SaveModelTableData, ValueSelect } from 'src/modules/types';

@Component({
  selector: 'wag-saved-model-list',
  template: `
  <div class="section-container">
     
          
          <h2 class="mr-b-24">Models</h2>
          
          <wag-save-model-tables *ngIf="saveModelTableData" [saveModelTableData]="saveModelTableData"
          (savedModelItemClick)="onClickItem($event)" (clickOnMyModelsOnly)="clickOnMyModelsOnly.emit($event)" >
          </wag-save-model-tables>
            </div>
  `,
  styleUrls: ['./saved-model-list.component.scss']
})
export class SavedModelListComponent {
  @Input() saveModelTableData!: SaveModelTableData[] | null;
  @Output() savedModelItemClick: EventEmitter<SaveModelTableData> = new EventEmitter<SaveModelTableData>();
  @Output() clickOnMyModelsOnly: EventEmitter<ValueSelect> = new EventEmitter<ValueSelect>();

  constructor() {
    //pass 
  }

  onClickItem(element: SaveModelTableData) {
   
    this.savedModelItemClick.emit(element);
  }
}
