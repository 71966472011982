import { SaveAllocationData } from './../../../types/save-allocation-data.interface';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { skip } from 'rxjs/operators';
import { SelectedWellTypeInterface, StageResponse, ValueSelect } from 'src/modules/types';
import { ModelCatalogueData } from 'src/modules/types/model-catalogue-data';
import { AppSandbox } from '../../app.sandbox';
import { SaveMyModelComponent } from '../model';
import { DeleteModelComponent, ModelStageComponent } from './components';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Component({
  selector: 'wag-model-catalogue-container',
  template: `
  <h1>Model Catalogue</h1>
  <wag-model-catalogue
         [assets]="assets$ | async"
          [fields]="fields$ | async"
          [catalogueExpand]="catalogueExpand"
          (valueSelected)="onValueSelect($event)"
          (catalogueFetch)="onCatalogueFetch()"
          (clickEdit)="onClickEdit($event)"
          (clickProduction)="onClickProduction($event)"
          (clickArchive)="onClickArchive($event)"
          (clickDelete)="onClickDelete($event)"
          (clickDownload)="onclickDownload($event)"
          [enableItem]="enableActionItem"
          [catalogueTableData]="catalogueTableData$ | async "
          [filterData]="filterData">

  </wag-model-catalogue>

 <ng-container>
 <wag-allocation-catalogue
  [saveAllocationTableData]="saveAllocationTableData | async"
  (clickOnMyAllocationOnly)="clickOnMyAllocationOnly($event)">
 </wag-allocation-catalogue>
 </ng-container>
  `,
  styleUrls: ['./model-catalogue-container.component.scss']
})
export class CatalogueContainerComponent {
  assets$ = this.sb.assets$;
  fields$ = this.sb.fields$;
  wells$ = this.sb.wells$;
  filterData: SelectedWellTypeInterface = { asset: '', field: '', well: '' };
  affiliate!: string;
  userName!: string;
  catalogueExpand: boolean = false;
  enableActionItem: boolean = true;
  catalogueTableData$: Observable<ModelCatalogueData[]> = this.sb.modelCatalogueTableData$.pipe(skip(1));
  constructor(private sb: AppSandbox, private dialog: MatDialog, private snackBar: MatSnackBar) {
    this.sb.selectedAffiliate$.subscribe(affiliate => { this.affiliate = affiliate; });
    this.sb.loadUserName();
    this.sb.userName$.subscribe(user=>
      { this.userName=user
      })

  }

  saveAllocationTableData: Observable<SaveAllocationData[]> = this.sb.saveAllocationTableData$.pipe(skip(1));
  isShowMyAllocationsOnly:boolean= false;

  onValueSelect(event: ValueSelect): void {

    if (event.value) {
      switch (event.control) {
        case 'Asset':
          this.filterData.asset = event.value;
          this.sb.loadFields(this.affiliate, event.value).subscribe();
          break;
        case 'Field':
          this.filterData.field = event.value;
          break;
        default:
          break;
      }
    }
  }
  clickOnMyAllocationOnly(event: ValueSelect) {
    this.isShowMyAllocationsOnly = JSON.parse(event.value);
    if (this.isShowMyAllocationsOnly)
     {
      this.sb.getSavedAllocationsData(this.affiliate,this.userName);
     }
    else
      {
        this.sb.getSavedAllocationsData(this.affiliate)
      }
  }
  onCatalogueFetch(): void {

    this.catalogueExpand = true;
    this.sb.loadModelCatalogueTableData(this.affiliate, this.filterData.asset, this.filterData.field);
    this.sb.loadFieldRangeDates(this.affiliate, this.filterData);
    if(this.isShowMyAllocationsOnly){
      this.sb.getSavedAllocationsData(this.affiliate,this.userName);
    }
    else{
      this.sb.getSavedAllocationsData(this.affiliate);
    }

  }


  onClickEdit(event: any) {
    const editDialogRef = this.dialog.open(SaveMyModelComponent, {
      panelClass: 'popup-class-apnnel',
      data: { 'data': event, 'showMyOnly': false, 'action': 'Edit' },
      disableClose: false
    })

    editDialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.enableActionItem = res.flag;
      }
    })
  }
  onClickDelete(event: any) {

    const deleteDialogRef = this.dialog.open(DeleteModelComponent, {
      panelClass: 'delete-popup-class-apnnel',
      data: { 'data': event },
      disableClose: false
    })

    deleteDialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.enableActionItem = res.flag;

      }
    })
  }

  onclickDownload(event: any) {
    this.sb.GetDownloadExcelDataCataglog(this.affiliate, event).subscribe(response => {
      let ExceldataFormat = response;
      this.exportAsExcelFile(ExceldataFormat);
    });

  }

  public exportAsExcelFile(ExceldataFormat: any): void {
    let SrNo = 0;
    let csvJsonData: any[] = [];
    csvJsonData = ExceldataFormat.data;

    csvJsonData.map(data=>{
      data.dates=moment(data.dates).format('DD/MM/YYYY');
    })
    csvJsonData = JSON.parse(JSON.stringify(csvJsonData).split('"dates":').join('"Model Name":'));
    let target:any[]=ExceldataFormat.target;
    let units:any[]=ExceldataFormat.unit;
    target.unshift("Target")
    units.unshift("Unit")


    let HeadingUnit = [units];
    let HeadingTarget = [target];
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(csvJsonData);

    XLSX.utils.sheet_add_aoa(worksheet, HeadingTarget, { origin: "A2" });
    XLSX.utils.sheet_add_aoa(worksheet, HeadingUnit, { origin: "A3" });
    XLSX.utils.sheet_add_json(worksheet, csvJsonData, { origin: "A4", skipHeader: true });
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    this.saveAsExcelFile(excelBuffer);
  }
  private saveAsExcelFile(buffer: any): void {

    let fileName = this.filterData.asset + '_' + this.filterData.field + '_' + moment().format('YYYY-MM-DD')

    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  onClickProduction(event: ModelCatalogueData) {
    this.sb.getModelStatus(this.affiliate, event.asset_id, event.well_id, event.target_value, event.run_id).subscribe(response => {
      let stageResponse: StageResponse = response;
      if (stageResponse.production_run_id === event.run_id) {
        this.snackBar.open('Model is already staged to Production' + '!', '', {
          duration: 10000,
          panelClass: 'success-snackbar',
        });
      } else {
        const deleteDialogRef = this.dialog.open(ModelStageComponent, {
          panelClass: 'stage-popup-class-apnnel',
          data: { 'data': event, 'stageResponse': stageResponse },
          disableClose: false
        })

        deleteDialogRef.afterClosed().subscribe(res => {
          if (res) {
            this.enableActionItem = res.flag;

          }
        })
      }
    })
  }
  onClickArchive(event: ModelCatalogueData) {

    this.sb.getModelStatus(this.affiliate, event.asset_id, event.well_id, event.target_value, event.run_id).subscribe(response => {
      const stageResponse: StageResponse = response;
      if (stageResponse.production_run_id !== event.run_id) {
        this.snackBar.open('Model is already in Archive' + '!', '', {
          duration: 10000,
          panelClass: 'success-snackbar',
        });
      } else {
        const deleteDialogRef = this.dialog.open(ModelStageComponent, {
          panelClass: 'stage-popup-class-apnnel',
          data: { 'data': event, 'stageResponse': stageResponse },
          disableClose: false
        })

        deleteDialogRef.afterClosed().subscribe(res => {
          if (res) {
            this.enableActionItem = res.flag;

          }
        })
      }
    })

  }
}
