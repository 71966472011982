import {PlotData} from "src/modules/types";

export const FIELD_OIL_PRODUCTION_PLOT_DATA: PlotData[] = [
  {name: "allocated", type: "scatter", mode: "lines", line: {dash: 'solid'}, marker: {color: "green"}},
  {name: "estimated", type: "scatter", mode: "lines", line: {dash: 'dash'}, marker: {color: "green"}},
  {name: "potential", type: "scatter", mode: "lines", line: {dash: 'dashdot'}, marker: {color: "green"}},
  {name: "modeled", type: "scatter", mode: "lines", line: {dash: 'dot'}, marker: {color: "green"}},
];

export const FIELD_LIQUID_PRODUCTION_PLOT_DATA: PlotData[] = [
  {name: "allocated", type: "scatter", mode: "lines", line: {dash: 'solid'}, marker: {color: "purple"}},
  {name: "estimated", type: "scatter", mode: "lines", line: {dash: 'dash'}, marker: {color: "purple"}},
  {name: "potential", type: "scatter", mode: "lines", line: {dash: 'dashdot'}, marker: {color: "purple"}},
  {name: "modeled", type: "scatter", mode: "lines", line: {dash: 'dot'}, marker: {color: "purple"}},
];

export const FIELD_GAS_PRODUCTION_PLOT_DATA: PlotData[] = [
  {name: "allocated", type: "scatter", mode: "lines", line: {dash: 'solid'}, marker: {color: "red"}},
  {name: "estimated", type: "scatter", mode: "lines", line: {dash: 'dash'}, marker: {color: "red"}},
  {name: "potential", type: "scatter", mode: "lines", line: {dash: 'dashdot'}, marker: {color: "red"}},
  {name: "modeled", type: "scatter", mode: "lines", line: {dash: 'dot'}, marker: {color: "red"}},
];

export const FIELD_WATER_INJECTION_PLOT_DATA: PlotData[] = [
  {name: "allocated", type: "scatter", mode: "lines", line: {dash: 'solid'}, marker: {color: "blue"}},
  {name: "estimated", type: "scatter", mode: "lines", line: {dash: 'dash'}, marker: {color: "blue"}},
  {name: "potential", type: "scatter", mode: "lines", line: {dash: 'dashdot'}, marker: {color: "blue"}},
  {name: "modeled", type: "scatter", mode: "lines", line: {dash: 'dot'}, marker: {color: "blue"}},
];

export const FIELD_GAS_INJECTION_PLOT_DATA: PlotData[] = [
  {name: "allocated", type: "scatter", mode: "lines", line: {dash: 'solid'}, marker: {color: "red"}},
  {name: "estimated", type: "scatter", mode: "lines", line: {dash: 'dash'}, marker: {color: "red"}},
  {name: "potential", type: "scatter", mode: "lines", line: {dash: 'dashdot'}, marker: {color: "red"}},
  {name: "modeled", type: "scatter", mode: "lines", line: {dash: 'dot'}, marker: {color: "red"}},
];

export const FIELD_DATA_LAYOUT = {
  title: {
    text: '',
    font: {
      family: `Roboto, "Helvetica Neue", sans- serif`,
      size: 16
    },
    x: 0,
  },
  legend: {
    orientation: "h",
    side: "top",
    xanchor: "center",
    x: 0.5
  },
  showlegend: true,
  hovermode: "x",
  yaxis: {
    visible: true
  },
  xaxis: {
    range: [0, Date.now()],
    type: "date"
  },
};
