import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {skip} from 'rxjs/operators';
import {AppSandbox} from 'src/modules/app/app.sandbox';
import {DataFilters, FieldGraphData, FieldRangeDates, PlotData} from 'src/modules/types';
import {DailyData} from '../../field-overview.types';
import {
  FIELD_DATA_LAYOUT,
  FIELD_GAS_INJECTION_PLOT_DATA,
  FIELD_OIL_PRODUCTION_PLOT_DATA,
  FIELD_WATER_INJECTION_PLOT_DATA,
  FIELD_GAS_PRODUCTION_PLOT_DATA,
  FIELD_LIQUID_PRODUCTION_PLOT_DATA
} from './field-daily-data.const';

@Component({
  selector: 'wag-field-daily-data',
  styleUrls: ['./field-daily-data.component.scss'],
  template: `
    <div class="section-container">
      <h2>Last Daily Data Available ({{dateRange?.endDate | date:'dd/MM/YYYY'}})</h2>

      <div class="cards-container">
      <wag-card name="liquid_production"
                  [data]="dailyData?.liquid_production"
                  [isSelected]="liquidProductionDisplayed"
                  (toggleGraph)="onToggleGraph($event)"></wag-card>
        <wag-card name="oil_production"
                  [data]="dailyData?.oil_production"
                  [isSelected]="oilProductionDisplayed"
                  (toggleGraph)="onToggleGraph($event)"></wag-card>
       
        <wag-card name="gas_production"
                  [data]="dailyData?.gas_production"
                  [isSelected]="gasProductionDisplayed"
                  (toggleGraph)="onToggleGraph($event)"></wag-card>
        <wag-card name="gas_injection"
                  [data]="dailyData?.gas_injection"
                  [isSelected]="gasInjectionDisplayed"
                  (toggleGraph)="onToggleGraph($event)"></wag-card>
        <wag-card name="water_injection"
                  [data]="dailyData?.water_injection"
                  [isSelected]="waterInjectionDisplayed"
                  (toggleGraph)="onToggleGraph($event)"></wag-card>
        <wag-card name="flaring" [data]="dailyData?.gas_flaring"></wag-card>
      </div>

      <div class="graphs-container">
        <wag-well-graph *ngIf="oilProductionDisplayed"
                        title="Oil Production (BBL/d)"
                        [data]="oilProductionGraph"
                        [layout]="graphLayout"></wag-well-graph>

        <wag-well-graph *ngIf="liquidProductionDisplayed"
                        title="Liquid Production (BBL/d)"
                        [data]="liquidProductionGraph"
                        [layout]="graphLayout"></wag-well-graph>

        <wag-well-graph *ngIf="gasProductionDisplayed"
                        title="Gas Production (kSm3/d)"
                        [data]="gasProductionGraph"
                        [layout]="graphLayout"></wag-well-graph>

        <wag-well-graph *ngIf="gasInjectionDisplayed"
                        title="Gas injection Rate (v/v)"
                        [data]="gasInjectionGraph"
                        [layout]="graphLayout"></wag-well-graph>

        <wag-well-graph *ngIf="waterInjectionDisplayed"
                        title="Water injection (%)"
                        [data]="waterInjectionGraph"
                        [layout]="graphLayout"></wag-well-graph>
      </div>
    </div>
  `
})
export class FieldDailyDataComponent implements OnInit, OnDestroy, OnChanges {
  
  @Input() dailyData!: DailyData | null;
  @Input() dataFilters!: DataFilters;
  @Input() well!:string;
  @Input() typeofWell!:string;
  oilProductionDisplayed = false;
  gasInjectionDisplayed = false;
  waterInjectionDisplayed = false;
  liquidProductionDisplayed = false;
  gasProductionDisplayed = false;
  
  oilProductionGraph: PlotData[] = JSON.parse(JSON.stringify(FIELD_OIL_PRODUCTION_PLOT_DATA));
  gasInjectionGraph: PlotData[] = JSON.parse(JSON.stringify(FIELD_GAS_INJECTION_PLOT_DATA));
  waterInjectionGraph: PlotData[] = JSON.parse(JSON.stringify(FIELD_WATER_INJECTION_PLOT_DATA));
  liquidProductionGraph: PlotData[] = JSON.parse(JSON.stringify(FIELD_LIQUID_PRODUCTION_PLOT_DATA));
  gasProductionGraph: PlotData[] = JSON.parse(JSON.stringify(FIELD_GAS_PRODUCTION_PLOT_DATA));

  graphLayout = FIELD_DATA_LAYOUT;

  oilProductionData$: Observable<FieldGraphData> = this.sb.fieldOilProduction$;
  gasInjectionData$: Observable<FieldGraphData> = this.sb.fieldGasInjection$;
  waterInjectionData$: Observable<FieldGraphData> = this.sb.fieldWaterInjection$;
  gasProductionData$: Observable<FieldGraphData> = this.sb.fieldGasProdInjection$;
  liquidProductionData$: Observable<FieldGraphData> = this.sb.fieldLiquidInjection$;


  fieldSelectChangeSubscription!: Subscription;
  affiliate!:string;
  welltype!:string;

 

  @Input() dateRange!: FieldRangeDates | null;

  constructor(private sb: AppSandbox) {
    this.sb.selectedAffiliate$.subscribe(affiliate=>{this.affiliate=affiliate;})
  }

  ngOnInit(): void {
    this.oilProductionData$.pipe(skip(1)).subscribe((data: FieldGraphData) => {
      this.plotData(this.oilProductionGraph, data);
      this.oilProductionDisplayed = true;
    });

    this.gasInjectionData$.pipe(skip(1)).subscribe((data: FieldGraphData) => {
      this.plotData(this.gasInjectionGraph, data);
      this.gasInjectionDisplayed = true;
    });

    this.waterInjectionData$.pipe(skip(1)).subscribe((data: FieldGraphData) => {
      this.plotData(this.waterInjectionGraph, data);
    
      this.waterInjectionDisplayed = true;
      
    });

    this.gasProductionData$.pipe(skip(1)).subscribe((data: FieldGraphData) => {
      this.plotData(this.gasProductionGraph, data);
      this.gasProductionDisplayed = true;
    });

    this.liquidProductionData$.pipe(skip(1)).subscribe((data: FieldGraphData) => {
      this.plotData(this.liquidProductionGraph, data);
      this.liquidProductionDisplayed = true;
    });

    this.fieldSelectChangeSubscription = this.sb.fieldSelectChange.subscribe(() => {
      this.oilProductionDisplayed = false;
      this.gasInjectionDisplayed = false;
      this.waterInjectionDisplayed = false;
      this.gasProductionDisplayed = false;
      this.liquidProductionDisplayed = false;
    });
  }

  onToggleGraph(name: string) {
    
    switch (name) {
      
      case 'oil_production':
        if (this.oilProductionDisplayed) this.oilProductionDisplayed = false;
       
        else if (this.typeofWell) this.sb.loadWellOilProduction(this.affiliate,this.dataFilters, this.well);

        else  this.sb.loadFieldOilProduction(this.affiliate,this.dataFilters, this.well);
        break;

      case 'gas_injection':
        if (this.gasInjectionDisplayed) this.gasInjectionDisplayed = false;

        else if (this.typeofWell) this.sb.loadWellGasInjection(this.affiliate,this.dataFilters, this.well);

        else this.sb.loadFieldGasInjection(this.affiliate,this.dataFilters, this.well);
        break;

      case 'liquid_production':
        if (this.liquidProductionDisplayed) this.liquidProductionDisplayed = false;

        else if (this.typeofWell) this.sb.loadWellLiquidProduction(this.affiliate,this.dataFilters, this.well);

        else this.sb.loadFieldLiquidProduction(this.affiliate,this.dataFilters, this.well);
        break;

      case 'gas_production':
        if (this.gasProductionDisplayed) this.gasProductionDisplayed = false;
        else if (this.typeofWell) this.sb.loadWellGasProduction(this.affiliate,this.dataFilters, this.well);
        else this.sb.loadFieldGasProduction(this.affiliate,this.dataFilters, this.well);
        break;

      case 'water_injection':
        if (this.waterInjectionDisplayed) this.waterInjectionDisplayed = false;

        else if (this.typeofWell) this.sb.loadWellWaterInjection(this.affiliate,this.dataFilters, this.well);

        else this.sb.loadFieldWaterInjection(this.affiliate,this.dataFilters, this.well);
        break;

      default:
        break;
    }
  }

  ngOnDestroy(): void {
    this.fieldSelectChangeSubscription.unsubscribe();
  }

  private plotData(graph: PlotData[], data: FieldGraphData): void {
    graph.forEach((plot: PlotData) => {
      plot.x = data[plot.name as keyof FieldGraphData].dates;
      plot.y = data[plot.name as keyof FieldGraphData].values;
    });
  }

  private reloadDisplayedCardGraphs(): void {
    if (this.waterInjectionDisplayed) this.sb.loadFieldWaterInjection(this.affiliate,this.dataFilters);
    if (this.gasInjectionDisplayed) this.sb.loadFieldGasInjection(this.affiliate,this.dataFilters);
    if (this.oilProductionDisplayed) this.sb.loadFieldOilProduction(this.affiliate,this.dataFilters);
    if (this.gasProductionDisplayed) this.sb.loadFieldGasProduction(this.affiliate,this.dataFilters);
    if (this.liquidProductionDisplayed) this.sb.loadFieldLiquidProduction(this.affiliate,this.dataFilters);
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dateRange?.currentValue && changes.dateRange?.previousValue) this.reloadDisplayedCardGraphs();

    if (changes.dateRange?.currentValue) {
      this.graphLayout.xaxis.range = [changes.dateRange.currentValue.startDate, changes.dateRange.currentValue.endDate];
      this.graphLayout = JSON.parse(JSON.stringify(this.graphLayout));
    }
  }
}
