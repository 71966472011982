import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { CardData, DailyData } from 'src/modules/app/containers';
import { GetDailyData } from './actions';

const EMPTY_DATA: CardData = {
  estimated: 0,
  potential: 0,
  modeled: 0,
  allocated: 0
};

@State<DailyData>({
  name: 'dailyData',
  defaults: {
    oil_production: EMPTY_DATA,
    water_injection: EMPTY_DATA,
    gas_injection: EMPTY_DATA,
    gas_flaring: EMPTY_DATA,
    liquid_production: EMPTY_DATA,
    gas_production: EMPTY_DATA
  }
})
@Injectable()
export class DailyDataState {
  @Action(GetDailyData)
  getDailyData(context: StateContext<DailyData>, { payload }: GetDailyData): void {
    context.setState(payload);
  }
}
