import {PlotData} from 'src/modules/types';
import {Layout} from 'plotly.js';

export const ML_METRICS_PLOT_DATA: PlotData[] = [
  {name: "y_pred", type: "scatter", mode: "markers", marker: {color: "green"}},
  {name: "identity_func", type: "scatter", mode: "lines", marker: {color: "black"}},
];

export const ML_METRICS_LAYOUT: Partial<Layout> = {
  legend: {
    orientation: 'h',
    xanchor: 'center',
    x: 0.5,
    y: -0.3
  },
  hovermode: 'x',
  xaxis: {
    title: 'Well test value',
  },
  yaxis: {
    title: 'Prediction value (bbl/d)',
  },
};

export const ML_METRICS_BOXPLOT_DATA: PlotData[] = [
  {name: "y_delta", type: "box", boxpoints: 'all', jitter: 0.3, pointpos: -1.8},
];

export const ML_METRICS_BOXPLOT_LAYOUT: Partial<Layout> = {
  hovermode: 'x',
  yaxis: {
    title: 'y_pred - y_reel',
  },
};
export const M3_TO_BBL=6.29;