import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { TrainingDataVisualizationInterface } from 'src/modules/types';
import { GetTrainingDataVisualization } from './actions';




@State<TrainingDataVisualizationInterface>({
  name: 'Training_data_visualization',
  defaults: {} as TrainingDataVisualizationInterface
})
@Injectable()
export class TrainingDataVisualizationState {
  @Action(GetTrainingDataVisualization)
  getSaveModelParams(context: StateContext<TrainingDataVisualizationInterface>, {payload}: GetTrainingDataVisualization): void {
    context.setState(payload);
  }
}
