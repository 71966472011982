
import {PlotData} from "src/modules/types";

export const PRODUCTION_MONITORING_PLOT_DATA: PlotData[] = [

  {name: "optime-(hr)", type: "scatter", mode: "lines", marker: {color: "#e91e63"}, yaxis: 'y1'},
  {name: "whp-(bar)", type: "scatter", mode: "lines", marker: {color: "#2196f3"}, yaxis: "y2"},
  {name: "wht-(°C)", type: "scatter", mode: "lines", marker: {color: "#ff9800"}, yaxis: "y3"},
  {name: "bhp-(bar)", type: "scatter", mode: "lines", marker: {color: "#0d47a1"}, yaxis: "y4"},
  {name: "bht-(°C)", type: "scatter", mode: "lines", marker: {color: "#ff5722"}, yaxis: "y5"},
  {name: "qgl-(kSm3/d)", type: "scatter", mode: "lines", marker: {color: "#FFD915"}, yaxis: "y6"},
  {name: "choke_opening-(%)", type: "scatter", mode: "lines", marker: {color: "#6C7678"}, yaxis: "y7"},
  // {name: "service_line_p", type: "scatter", mode: "lines", marker: {color: "#f9844a"}, yaxis: "y8"},
  // {name: "csg_head_p", type: "scatter", mode: "lines", marker: {color: "#f8961e"}, yaxis: "y9"},
  {name: "qoil_test-(bbl/d)", type: "scatter", mode: "markers", marker: {color: "#1d5f03"}, yaxis: "y8"},
  {name: "qwat_test-(bbl/d)", type: "scatter", mode: "markers", marker: {color: "#0000ff"}, yaxis: "y9"},
  {name: "qgas_test-(kSm3/d)", type: "scatter", mode: "markers", marker: {color: "#FF0000"}, yaxis: "y10"}

];


export const PRODUCTION_PLOT_DATA: PlotData[] = [

  {name: "optime-(hr)", type: "scatter", mode: "lines", marker: {color: "#e91e63"}, yaxis: "y1"},
  {name: "whp-(bar)", type: "scatter", mode: "lines", marker: {color: "#2196f3"}, yaxis: "y2"},
  {name: "wht-(°C)", type: "scatter", mode: "lines", marker: {color: "#ff9800"}, yaxis: "y3"},
  // {name: "topside_t", type: "scatter", mode: "lines", line: {dash: 'dash'}, marker: {color: "#2b9348"}, yaxis: "y4"},
  {name: "bhp-(bar)", type: "scatter", mode: "lines", marker: {color: "#0d47a1"}, yaxis: "y4"},
  // {name: "topside_p", type: "scatter", mode: "lines", line: {dash: 'dash'}, marker: {color: "#f94144"}, yaxis: "y6"},
  {name: "bht-(°C)", type: "scatter", mode: "lines", marker: {color: "#ff5722"}, yaxis: "y5"},
  {name: "choke_opening-(%)", type: "scatter", mode: "lines", marker: {color: "#6C7678"}, yaxis: "y6"},
  // {name: "choke_size", type: "scatter", mode: "lines", marker: {color: "#00b2ca"}, yaxis: "y9"},
  {name: "qgl_c_e-(kSm3/d)", type: "scatter", mode: "lines", marker: {color: "#E2BC00"}, yaxis: "y7"},
  {name: "qoil_test-(bbl/d)", type: "scatter", mode: "markers", marker: {color: "#1d5f03"}, yaxis: "y8"},
  {name: "qwat_test-(bbl/d)", type: "scatter", mode: "markers", marker: {color: "#0000ff"}, yaxis: "y9"},
  {name: "qgas_test-(kSm3/d)", type: "scatter", mode: "markers", marker: {color: "#FF0000"}, yaxis: "y10"}

];

export const PRODUCTION_MONITORING_LAYOUT = {
  title: {
    text: '',
    font: {
      family: `Roboto, "Helvetica Neue", sans- serif`,
      size: 16
    },
    x: 0
  },
  xaxis: {
    domain: [0.20, 0.75]
  },
  yaxis: {
   // title: "Hr",
    side: "left",
    position: 0.00,
    titlefont: {
      color: "#e91e63"
    },
    tickfont: {
      color: "#e91e63"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: true,
   // legendgroup:group
  },
  yaxis2: {
    //title: "Pressure, Bar",
    side: "left",
    overlaying: "y",
    position: 0.03,
    titlefont: {
      color: "#2196f3"
    },
    tickfont: {
      color: "#2196f3"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: true,
    //legendgroup:'group2'
  },
  yaxis3: {
   // title: "Temperature, Degree C",
    side: "left",
    overlaying: "y",
    position: 0.06,
    titlefont: {
      color: "#ff9800"
    },
    tickfont: {
      color: "#ff9800"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: true,
   // legendgroup:'group3'
  },
  yaxis4: {
   // title: "Pressure, Bar",
    side: "left",
    overlaying: "y",
    position: 0.09,
    titlefont: {
      color: "#0d47a1"
    },
    tickfont: {
      color: "#0d47a1"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: true,
  },
  yaxis5: {
   // title: "Temperature, Degree C",
    side: "left",
    overlaying: "y",
    position: 0.12,
    titlefont: {
      color: "#ff5722"
    },
    tickfont: {
      color: "#ff5722"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: true,
  },
  yaxis6: {
   // title: "kSm3/d",
    side: "right",
    overlaying: "y",
    position: 0.78,
    titlefont: {
      color: "#FFD915"
    },
    tickfont: {
      color: "#FFD915"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: true,
  },
  yaxis7: {
  //  title: "-",
    side: "right",
    overlaying: "y",
    titlefont: {
      color: "#E2BC00"
    },
    tickfont: {
      color: "#E2BC00"
    },
    position: 0.81,
    zeroline: true,
    showline: false,
    visible: true,
  },
  yaxis8: {
    //title: "bbl/d",
    side: "right",
    overlaying: "y",
    titlefont: {
      color: "#1d5f03"
    },
    tickfont: {
      color: "#1d5f03"
    },
    position: 0.84,
    zeroline: true,
    showline: false,
    visible: true,
  },
  yaxis9: {
   // title: "bbl/d",
    side: "right",
    overlaying: "y",
    titlefont: {
      color: "#0000ff"
    },
    tickfont: {
      color: "#0000ff"
    },
    position: 0.88,
    zeroline: true,
    showline: false,
    visible: true,
  },
  yaxis10: {
   // title: "kSm3/d",
    side: "right",
    overlaying: "y",
    titlefont: {
      color: "#FF0000"
    },
    tickfont: {
      color: "#FF0000"
    },
    position: 0.92,
    zeroline: true,
    showline: false,
    visible: true,
  },

  legend: {
    orientation: "h",
    side: "top",
    xanchor: "center",
    x: 0.5
  },
  showlegend: true
  
};
export const PRODUCTION_DATA_LAYOUT = { 
  title: {
    text: '',
    font: {
      family: `Roboto, "Helvetica Neue", sans- serif`,
      size: 16
    },
    x: 0
  },
  legend: {
    orientation: "h",
    side: "top",
    xanchor: "center",
    x: 0.5
  },
  showlegend: true,
  xaxis: {
    domain: [0.20, 0.75]
  },
  yaxis: {
   // title: "Hr",
    side: "left",
    position: 0.00,
    titlefont: {
      color: "#e91e63"
    },
    tickfont: {
      color: "#e91e63"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: true,
  },
  yaxis2: {
    //title: "Pressure, Bar",
    side: "right",
    overlaying: "y",
    position: 0.94,
    titlefont: {
      color: "#2196f3"
    },
    tickfont: {
      color: "#2196f3"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: true,
  },
  yaxis3: {
    //title: "Temperature, Degree C",
    side: "left",
    overlaying: "y",
    position: 0.04,
    titlefont: {
      color: "#ff9800"
    },
    tickfont: {
      color: "#ff9800"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: true,
  },
  yaxis4: {
    //title: "Pressure, Bar",
    side: "left",
    overlaying: "y",
    position: 0.08,
    titlefont: {
      color: "#0d47a1"
    },
    tickfont: {
      color: "#0d47a1"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: true,
  },
  yaxis5: {
    //title: "Temperature, Degree C",
    side: "left",
    overlaying: "y",
    position: 0.12,
    titlefont: {
      color: "#ff5722"
    },
    tickfont: {
      color: "#ff5722"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: true,
  },
  yaxis6: {
   // title: "-",
    side: "right",
    overlaying: "y",
    position: 0.76,
    titlefont: {
      color: "#6C7678"
    },
    tickfont: {
      color: "#6C7678"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: true,
  },
 yaxis7: {
   // title: "kSm3/d",
    side: "right",
    overlaying: "y",
    position: 0.79,
    titlefont: {
      color: "#ef9a9a"
    },
    tickfont: {
      color: "#ef9a9a"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: true,
  },
  yaxis8: {
   // title: "bbl/d",
    side: "right",
    overlaying: "y",
    titlefont: {
      color: "#1d5f03"
    },
    tickfont: {
      color: "#1d5f03"
    },
    position: 0.82,
    zeroline: true,
    showline: false,
    visible: true,
  },
  yaxis9: {
   // title: "bbl/d",
    side: "right",
    overlaying: "y",
    titlefont: {
      color: "#0000ff"
    },
    tickfont: {
      color: "#0000ff"
    },
    position: 0.87,
    zeroline: true,
    showline: false,
    visible: true,
  },
  yaxis10: {
   // title: "kSm3/d",
    side: "right",
    overlaying: "y",
    titlefont: {
      color: "#FF0000"
    },
    tickfont: {
      color: "#FF0000"
    },
    position: 0.92,
    zeroline: true,
    showline: false,
    visible: true,
  },

};

