import {Action, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {GetWellTestStatus} from './actions';


@State<string[]>({
  name: 'wellTestStatus',
  defaults: []
})
@Injectable()
export class WellTestStatusState {
  @Action(GetWellTestStatus)
  getWellTestStatus(context: StateContext<string[]>, {payload}: GetWellTestStatus): void {
    context.setState(payload);
  }
}
