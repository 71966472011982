import { AllocationCatalogueTableDataState } from './allocation-table-data/state';
import { AllocationRangeDatesState } from './allocation-range-dates/state';
import { FieldRangeDatesState1 } from './field-range-dates1/state';
import { FieldRangeDatesState2 } from './field-range-dates2/state';
import { FieldRangeDatesState3 } from './field-range-dates3/state';
import {AssetsState} from './assets';
import {FieldsState} from './field';
import {WellsState} from './well';
import {DailyDataState} from './daily-data';
import {ProductionAllocatedState} from './production-allocated';
import {FieldOilProductionState} from './field-oil-production';
import {FieldGasInjectionState} from './field-gas-injection';
import {FieldWaterInjectionState} from './field-water-injection';
import {UserNameState} from './user-name';
import {ProducerWellsDailyDataState} from './producer-wells-daily-data';
import {ProducerWellsOptimesDataState} from './producer-wells-optimes';
import {InjectorWellsOptimesDataState} from './injector-wells-optimes';
import {InjectorWellsDailyDataState} from './injector-wells-daily-data';
import {FieldRangeDatesState} from './field-range-dates';
import {MachineLearningDataState} from './machine-learning-data';
import {SelectedWellState} from './selected-well';
import {AttributeDataState} from './ml-attribute-data';
import {MLRunParamsState} from './ml-run-params';
import {WellTestStatusState} from './well-test-status';
import {WellQcTableDataState} from './well-qc-table-data';
import {QcRangeDatesState} from './qc-range-dates';
import {SaveModelDataState} from './save-model-data';
import {SaveModelTableDataState } from './save-model-table-data';
import {InferenceRangeDatesState } from './inference-range-dates';
import {TrainingDataVisualizationState } from './TrainingDataVisualization';
import { ModelCatalogueTableDataState } from './model-catalogue-table-data';
import { FieldLiquidProductionState } from './field-liquid-production';
import { FieldGasProductionState } from './field-gas-production';
import {FeatureDataVisualizationState} from './FeatureDataVisualization';
import{CatalogRangeDatesState} from './catalog-range-dates';
import { MergeModelDataState } from './merge-model-data';
export const globalState = [
  AssetsState,
  FieldsState,
  WellsState,
  DailyDataState,
  ProductionAllocatedState,
  FieldOilProductionState,
  FieldGasInjectionState,
  FieldWaterInjectionState,
  UserNameState,
  ProducerWellsDailyDataState,
  ProducerWellsOptimesDataState,
  InjectorWellsOptimesDataState,
  InjectorWellsDailyDataState,
  FieldRangeDatesState,
  MachineLearningDataState,
  SelectedWellState,
  AttributeDataState,
  MLRunParamsState,
  WellTestStatusState,
  WellQcTableDataState,
  QcRangeDatesState,
  SaveModelDataState,
  SaveModelTableDataState,
  InferenceRangeDatesState,
  TrainingDataVisualizationState,
  ModelCatalogueTableDataState,
  FieldLiquidProductionState,
  FieldGasProductionState,
  FeatureDataVisualizationState,
  CatalogRangeDatesState,
  FieldRangeDatesState1,
  FieldRangeDatesState2,
  FieldRangeDatesState3,
  AllocationRangeDatesState,
  AllocationCatalogueTableDataState,
  MergeModelDataState
];
