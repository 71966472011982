import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {DailyData} from './field-overview.types';
import {AppSandbox} from '../../app.sandbox';
import {ActivatedRoute} from '@angular/router';
import {
  DataFilters,
  FieldRangeDates,
  PlotData,
  ProductionAllocated,
  WellDailyData,
  WellOptimesData,
  WellType
} from 'src/modules/types';
import {MonitoringService} from '../../services';
import {first, skip} from 'rxjs/operators';
import {PRODUCTION_ALLOCATED_PLOT_DATA} from './components';

@Component({
  selector: 'wag-field-overview',
  template: `
    <h1>{{field}} Field Monitoring</h1>

    <wag-date-filters [dateRange]="dateRange$ | async"
                      [minMaxRange]="minMaxRange$ | async"
                      (dateRangeChange)="onDateChange($event)"
                      title="Select period to monitor"></wag-date-filters>

    <wag-field-official-production [injectorWellOptimes]="injectorWellOptimes$ | async"
                                   [producerWellOptimes]="producerWellOptimes$ | async"
                                   [dateRange]="dateRange$ | async" [forField]="forField"
                                   [productionAllocatedGraph]="productionAllocatedGraph">
    </wag-field-official-production>

    <wag-field-daily-data [dailyData]="dailyData$ | async"
                          [dataFilters]="dataFilters"
                          [dateRange]="dateRange$ | async"></wag-field-daily-data>

    <wag-field-daily-well-data [injectorWellsDailyData]="injectorWellsDailyData$ | async"
                               [producerWellsDailyData]="producerWellsDailyData$ | async"
                               [dateRange]="dateRange$ | async" [forField]="forField">
    </wag-field-daily-well-data>
  `
})
export class FieldOverviewContainer implements OnInit, OnDestroy {
  producerWellOptimes$: Observable<WellOptimesData[]> = this.sb.producerWellsOptimesData$;
  injectorWellOptimes$: Observable<WellOptimesData[]> = this.sb.injectorWellsOptimesData$;
  dailyData$: Observable<DailyData> = this.sb.dailyData$;
  producerWellsDailyData$: Observable<WellDailyData[]> = this.sb.producerWellsDailyData$.pipe(skip(1));
  injectorWellsDailyData$: Observable<WellDailyData[]> = this.sb.injectorWellsDailyData$.pipe(skip(1));
  productionAllocatedGraph: PlotData[] = JSON.parse(JSON.stringify(PRODUCTION_ALLOCATED_PLOT_DATA));
  productionAllocated$: Observable<ProductionAllocated> = this.sb.productionAllocated$.pipe(skip(1));
  dateRange$: Observable<FieldRangeDates> = this.sb.fieldRangeDates$.pipe(skip(1));
  minMaxRange$: Observable<FieldRangeDates> = this.sb.fieldRangeDates$.pipe(skip(1), first());

  dataFilters!: DataFilters;
  field!: string;
  affiliate!:string;
  forField:string="isField";
  dateRangeSubscription: Subscription = this.dateRange$.subscribe(dateRange => {
    
    this.dataFilters = {
      ...this.dataFilters,
      date_min: new Date(dateRange.startDate),
      date_max: new Date(dateRange.endDate)
    };

    this.loadFieldOverviewData();
  });

  constructor(private sb: AppSandbox,
    private activatedRoute: ActivatedRoute,
    private monitoringService: MonitoringService) {
    this.monitoringService.logPageView('fieldOverview');
    this.sb.loadUserName();
    this.sb.selectedAffiliate$.subscribe(affiliate=>{this.affiliate=affiliate;});
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      
      this.field = params['field_name'];
      if (params['asset_name'] && this.field) {
        this.dataFilters = {
          ...this.dataFilters,
          asset: params['asset_name'],
          field: this.field
        };

        this.sb.loadFieldRangeDates(this.affiliate,this.dataFilters);
        this.sb.fieldSelectChange.next();
      }
    });

    this.productionAllocated$.subscribe(
      (data: ProductionAllocated) => this.productionAllocatedGraph.map(
        (plot: PlotData) => {
          plot.x = data.date;
          plot.y = data[plot.id as keyof ProductionAllocated];
        }
      )
    );
  }

  onDateChange(dateRange: FieldRangeDates): void {
    
    this.sb.setFieldRangeDates(dateRange);
  }

  loadFieldOverviewData(): void {
    
    this.sb.loadDailyData(this.affiliate,this.dataFilters);
    this.sb.loadProductionAllocated(this.affiliate,this.dataFilters);
    this.sb.loadFieldWellsDailyData(this.affiliate,WellType.PRODUCER, this.dataFilters);
    this.sb.loadFieldWellsDailyData(this.affiliate,WellType.INJECTOR, this.dataFilters);
    this.sb.loadFieldWellsOptimesData(this.affiliate,WellType.PRODUCER, this.dataFilters);
    this.sb.loadFieldWellsOptimesData(this.affiliate,WellType.INJECTOR, this.dataFilters);
  }

  ngOnDestroy() {
    this.dateRangeSubscription.unsubscribe();
  }
}
