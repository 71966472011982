import {PlotData} from "src/modules/types";

export const TRAINING_DATA_PLOT_DATA: PlotData[] = [
  {name: "Target data", type: "dot", mode: "markers", marker: {color: "black"}},
  {name: "Training Selection", type: "scatter", mode: "markers" ,marker: {color: "#ED0000"}}
];


export const TRAINING_DATA_LAYOUT = {
    title: {
      text: '',
      font: {
        family: `Roboto, "Helvetica Neue", sans- serif`,
        size: 16
      },
      x: 0.5
    },
    xaxis: {
      domain: [0.20, 0.80]
    },
    yaxis: {
      title: "",
      side: "left",
      position: 0.00,
      titlefont: {
        color: "black"
      },
      tickfont: {
        color: "black"
      },
      showgrid: true,
      zeroline: false,
      showline: false,
      visible: true,
    },
    legend: {
      orientation: "h",
      side: "top",
      xanchor: "center",
      x: 0.5
    },
    showlegend: true
    
  };