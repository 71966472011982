import {Action, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {GetSelectedWell} from './actions';
import {SelectedWellTypeInterface} from 'src/modules/types';

@State<SelectedWellTypeInterface>({
  name: 'selectedWell',
  defaults: {} as SelectedWellTypeInterface
})
@Injectable()
export class SelectedWellState {
  @Action(GetSelectedWell)
  getSelectedWell(context: StateContext<SelectedWellTypeInterface>, {payload}: GetSelectedWell): void {
    context.setState({...payload});
  }
}
