import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Layout } from 'plotly.js';
import { MLConfidenceData, PlotData } from 'src/modules/types';

@Component({
  selector: 'wag-ml-confidence',
  template: `
    <mat-accordion multi="true">
      <mat-expansion-panel>
        <mat-expansion-panel-header>Model performance</mat-expansion-panel-header>
        <wag-model-performance  [mlConfidenceData]="mlConfidenceData"
                                [mlMetricsGraph]="mlMetricsGraph"
                                [mlMetricsLayout]="mlMetricsLayout"
                                [displayedMetricsGraph]="displayedMetricsGraph"
                                (switchMetricsGraph)="switchMetricsGraph.emit($event)"
                                [mlLearningCurvesGraph]="mlLearningCurvesGraph"
                                [mlLearningCurvesLayout]="mlLearningCurvesLayout"
                                [target]="target">
                                </wag-model-performance>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>Model Interpretability </mat-expansion-panel-header>
        <wag-model-interpretability 
                                [mlFeaturesImportanceGraph]="mlFeaturesImportanceGraph"
                                [mlFeaturesImportanceLayout]="mlFeaturesImportanceLayout"
                                [mlCorelationGraph]="mlCorelationGraph"
                                [mlCorelationLayout]="mlCorelationLayout"
                                [mlFeaturesGraph]="mlFeaturesGraph"
                                [mlFeaturesLayout]="mlFeaturesLayout"
                                [mlFeatureGraphTitle]="mlFeatureGraphTitle"
                                [mlFeatureXtitle]="mlFeatureXtitle">
        </wag-model-interpretability>
      </mat-expansion-panel>
    </mat-accordion>
  `,
  styleUrls: ['./ml-confidence.component.scss']
})
export class MlConfidenceComponent {
  @Input() mlConfidenceData!: MLConfidenceData;
  @Input() mlMetricsGraph!: PlotData[];
  @Input() mlMetricsLayout!: Partial<Layout>;
  @Input() displayedMetricsGraph!: string;
  @Output() switchMetricsGraph: EventEmitter<string> = new EventEmitter<string>();
  @Input() mlLearningCurvesGraph!: PlotData[];
  @Input() mlLearningCurvesLayout!: Partial<Layout>;
  @Input() mlFeaturesImportanceGraph!: PlotData[];
  @Input() mlFeaturesImportanceLayout!: Partial<Layout>;

  @Input() mlCorelationGraph!: PlotData[];
  @Input() mlCorelationLayout!: Partial<Layout>;
  
  @Input() target!: string;

  @Input() mlFeaturesGraph!: PlotData[][];
  @Input() mlFeaturesLayout!: any[];
  @Input() mlFeatureGraphTitle!:string[];
  @Input() mlFeatureXtitle!:string[];
}
