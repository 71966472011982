export const DEFAULT_LAYOUT = {
  title: {
    text: '',
    font: {
      family: `Roboto, "Helvetica Neue", sans- serif`,
      size: 16
    },
    x: 0
  },
  xaxis: {
    domain: [0.1, 0.85]
  },
  yaxis: {
    title: "Pressure, Bar",
    side: "left",
    position: 0.00,
    titlefont: {
      color: "#f94144"
    },
    tickfont: {
      color: "#f94144"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: true,
  },
  yaxis2: {
    title: "Temperature, Degree °C",
    side: "left",
    overlaying: "y",
    position: 0.05,
    titlefont: {
      color: "#007f5f"
    },
    tickfont: {
      color: "#007f5f"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: true,
  },
  yaxis3: {
    title: "Fraction / Ratio",
    side: "left",
    overlaying: "y",
    position: 0.10,
    titlefont: {
      color: "#22223b"
    },
    tickfont: {
      color: "#22223b"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: true,
  },
  yaxis4: {
    title: "Choke",
    side: "right",
    overlaying: "y",
    position: 0.85,
    titlefont: {
      color: "#00b2ca"
    },
    tickfont: {
      color: "#00b2ca"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: true,
  },
  yaxis5: {
    title: "Medium duration, Hour",
    side: "right",
    overlaying: "y",
    position: 0.90,
    titlefont: {
      color: "#f20089"
    },
    tickfont: {
      color: "#f20089"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: true,
  },
  yaxis6: {
    title: "Daily Rate (Gas), M^2 Day",
    side: "right",
    overlaying: "y",
    position: 0.95,
    titlefont: {
      color: "#ffd500"
    },
    tickfont: {
      color: "#ffd500"
    },
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: true,
  },
  yaxis7: {
    title: "Well test, bbl Day",
    side: "right",
    overlaying: "y",
    position: 1.00,
    zeroline: true,
    showline: false,
    visible: true,
  },
  yaxis8: {
    title: "Well test, bbl Day",
    side: "right",
    overlaying: "y",
    position: 1.00,
    zeroline: true,
    showline: false,
    visible: true,
  },
  yaxis9: {
    title: "Well test, bbl Day",
    side: "right",
    overlaying: "y",
    position: 1.00,
    zeroline: true,
    showline: false,
    visible: true,
  },
  legend: {
    orientation: "h",
    side: "top",
    xanchor: "center",
    x: 0.5
  },
  showlegend: true
};
