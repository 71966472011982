import {Component, EventEmitter, Input, Output} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {Layout} from 'plotly.js';
import {AttributeType, PlotData} from 'src/modules/types';
import {
  MONITORING_ATTR,
  POTENTIAL_ATTR,
  PROD_ALLOCATION_ATTR,
  PROD_DATA_ATTR,
  PROD_TEST_ATTR,
} from './ml-visualize.const';

@Component({
  selector: 'wag-ml-visualize',
  styleUrls: ['./ml-visualize.component.scss'],
  template: `
    <div>
      <wag-well-graph [data]="machineLearningGraph" [layout]="machineLearningLayout"></wag-well-graph>
      <form [formGroup]="checkboxForm" class="well-filters">
      <div class="attributes-container">
        <div class="attribute-bloc">
          <p class="bloc-title">Production Data Attributes</p>
          <div class="checkbox-list">
            <mat-checkbox *ngFor="let attr of PROD_DATA_ATTR"
                          [value]="attr" formControlName="Checkbox"
                          (change)="onClickCheckbox($event, AttributeType.PRODUCTION_DATA)"
                          color="warn">{{attr.split("-", 1)}}</mat-checkbox>
          </div>
        </div>
        
        <div class="attribute-bloc">
          <p class="bloc-title">Production Monitoring Attributes</p>
          <div class="checkbox-list">
            <mat-checkbox *ngFor="let attr of MONITORING_ATTR"
                          [value]="attr" formControlName="Checkbox2"
                          (change)="onClickCheckbox($event, AttributeType.MONITORING_ATTR)"
                          color="warn">{{attr.split("-", 1)}}</mat-checkbox>
          </div>
        </div>

        <div class="attribute-bloc">
          <p class="bloc-title">Potential Attributes</p>
          <div class="checkbox-list">
            <mat-checkbox *ngFor="let attr of POTENTIAL_ATTR"
                          [value]="attr" formControlName="Checkbox3"
                          (change)="onClickCheckbox($event, AttributeType.POTENTIAL)"
                          color="warn">{{attr.split("-", 1)}}</mat-checkbox>
          </div>
        </div>
        
        <div class="attribute-bloc">
          <p class="bloc-title">Daily Production Allocation Attributes</p>
          <div class="checkbox-list">
            <mat-checkbox *ngFor="let attr of PROD_ALLOCATION_ATTR"
                          [value]="attr" formControlName="Checkbox4"
                          (change)="onClickCheckbox($event, AttributeType.PROD_ALLOCATION)"
                          color="warn">{{attr.split("-", 1)}}</mat-checkbox>
          </div>
        </div>

        <div class="attribute-bloc">
          <p class="bloc-title">Production Test Data Attributes</p>
          <div class="checkbox-list">
            <mat-checkbox *ngFor="let attr of PROD_TEST_ATTR"
                          [value]="attr" formControlName="Checkbox5"
                          (change)="onClickCheckbox($event, AttributeType.PROD_TEST_ATTR)"
                          color="warn">{{attr.split("-", 1)}}</mat-checkbox>
          </div>
        </div>

        <!-- <div class="attribute-bloc" *ngIf="selectedUserAdded">
          <p class="bloc-title">User Added Attributes</p>
          <div class="checkbox-list">
            <mat-checkbox *ngFor="let attr of selectedUserAdded"
                          [value]="attr" formControlName="Checkbox6"
                          (change)="onClickCheckbox($event, AttributeType.PROD_TEST_ATTR)"
                          color="warn">{{attr.split("-", 1)}}</mat-checkbox>
          </div>
        </div> -->

      </div>
      </form>
    </div>
   
  `,
})
export class MlVisualizeComponent {
  @Input() machineLearningGraph!: PlotData[];
  @Input() machineLearningLayout!: Partial<Layout>;
//  @Input() selectedUserAdded!:any;
  @Output() clickCheckbox: EventEmitter<any> = new EventEmitter<any>();

  PROD_DATA_ATTR = PROD_DATA_ATTR;
  POTENTIAL_ATTR = POTENTIAL_ATTR;
  PROD_TEST_ATTR = PROD_TEST_ATTR;
  MONITORING_ATTR = MONITORING_ATTR;
  PROD_ALLOCATION_ATTR = PROD_ALLOCATION_ATTR;
  AttributeType = AttributeType;
  Eventstest:string[]=[];

  constructor(private formBuilder: FormBuilder) {
   // this.selectedUserAdded;
  }
  checkboxForm: FormGroup = this.formBuilder.group({
    'Checkbox': [''],
    'Checkbox2': [''],
    'Checkbox3': [''],
    'Checkbox4': [''],
    'Checkbox5': [''],
    
  });
  clearAll(){
   this.checkboxForm.reset();
  }
  onClickCheckbox(event: MatCheckboxChange, attributeType: AttributeType): void {
    this.Eventstest.push(event.checked.toString());
    this.clickCheckbox.emit({
      attribute: event.source.value,
      checked: event.checked,
      attributeType
    });
  }
}
