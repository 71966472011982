
import { ViewportScroller } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { Layout } from 'plotly.js';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { first, skip } from 'rxjs/operators';
import {
  DataFilters, DataFiltersInference, FieldRangeDates,
  MachineLearningData, MLConfidenceData,
  MLConfidenceMetrics,
  MLDispersionError, MlInputSourceLabel, MLLearningCurves, MLRunParams, MlTargetSourceLabel, MlTargetSourceLabelInjector, PlotData,
  ProductionData,
  ProductionDataInjector,
  ProductionMonitoring, ProductionMonitoringInjector, SaveModelTableData, SelectedWellTypeInterface,
  TestAttributes,
  ValueSelect, WellQcTableData
} from 'src/modules/types';
import { SaveModelParams } from 'src/modules/types/save-model.interface';
import * as XLSX from 'xlsx';
import { AppSandbox } from '../../app.sandbox';
import { MonitoringService } from '../../services';
import { MachineLearningComponent, SaveMyModelComponent } from './components';
import { FEATURE_DATA_LAYOUT, FEATURE_DATA_PLOT_DATA } from './components/machine-learning/ml-settings/ml-settings-feature-graph.const';
import { TRAINING_DATA_LAYOUT, TRAINING_DATA_PLOT_DATA } from './components/machine-learning/ml-settings/ml-settings-graph.const';
import { ML_FEATURES_IMPORTANCE_LAYOUT, ML_FEATURES_IMPORTANCE_PLOT_DATA, ML_FEATURE_VS_TARGET, ML_FEATURE_VS_TARGET_LAYOUT, PERCENTAGE, PRESSURE, TEMPETATURE, KSMUNIT, CHUNIT } from './components/ml-features-importance-layout.const';
import { ML_CORELATION_PLOT_DATA, ML_CORELATION_LAYOUT } from './components/ml-corelation-coefficient-layout.const'
import { BBL_UNIT, MACHINE_LEARNING_LAYOUT, MACHINE_LEARNING_PLOT_DATA, mlPlotColorMap } from './components/ml-graph-layout.const';
import { ML_LEARNING_CURVES_LAYOUT, ML_LEARNING_CURVES_PLOT_DATA } from './components/ml-learning-curves-layout.const';
import { M3_TO_BBL, ML_METRICS_BOXPLOT_DATA, ML_METRICS_BOXPLOT_LAYOUT, ML_METRICS_LAYOUT, ML_METRICS_PLOT_DATA } from './components/ml-metrics-layout.const';
import { DEFAULT_LAYOUT } from './components/well-graph/default-layout.const';
import { PRODUCTION_DATA_LAYOUT, PRODUCTION_MONITORING_LAYOUT, PRODUCTION_MONITORING_PLOT_DATA, PRODUCTION_PLOT_DATA } from './model.container.const';
import { MOPRODUCTION_MONITORING_LAYOUT_INJECTOR, PRODUCTION_DATA_LAYOUT_INJECTOR, PRODUCTION_MONITORING_PLOT_DATA_INJECTOR, PRODUCTION_PLOT_DATA_INJECTOR } from './model.container.injector_const';
import { ML_TARGET_SOURCE_INJECTOR } from './components/machine-learning/machine-learning.const';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Component({
  selector: 'wag-model',
  styleUrls: ['./model.container.scss'],
  template: `
   <div *ngIf="isModelName" class="headerClass">
  <h2 class="nameClass">{{this.LatestName}}</h2>
  <button mat-icon-button class="btnclass" (click)="ModalCatalog('goToModel')">Go to Catalog</button>
</div>
    <h1>Build Model</h1>

    <wag-selection [assets]="assets$ | async"
                   [fields]="fields$ | async"
                   [wells]="wells$ | async"
                   (valueSelected)="onValueSelect($event)"
                   (wellType)="onClickCheckboxOfWellType($event)"
                   (wellFetch)="onWellFetch()"
                   (wellFetchCatalog)="onWellFetchCatalog()"
                   [passedData]="passedData"
                   (ClickSavedModelItemCatalog)="onClickSavedModelItemCatalog($event)">
    </wag-selection>

    <wag-input-data-qc  *ngIf="isWellSelected"
                        [wellQcTableData]="wellQcTableData$ | async"
                        [productionDataGraph]="productionDataGraph"
                        [productionDataLayout]="productionDataLayout"
                        [productionMonitoringGraph]="productionMonitoringGraph"
                        [productionMonitoringLayout]="productionMonitoringLayout"
                        [minMaxRange]="minMaxRange$ | async"
                        [qcDateRange]="qcDateRange$ | async"
                        [wellTypeInjector]="onlyInjector"
                      [graphTitle]='graphTitle'
                      [selectedWell]='selectedWell'
                        (qcDateRangeChange)="onQcDateChange($event)"></wag-input-data-qc>

    <wag-saved-model-list id="goToModel" *ngIf="isWellSelected" [saveModelTableData]="saveModelTableData$ | async"
                        (savedModelItemClick)="onClickSavedModelItem($event)"
                        (clickOnMyModelsOnly)="onClickOnMyModelsOnly($event)">
    </wag-saved-model-list>

    <wag-machine-learning *ngIf="isWellSelected"
                          [dateRange]="dateRange$ | async"
                          [minMaxRange]="minMaxRange$ | async"
                          [dateRange1]="dateRange1$ | async"
                          [minMaxRange1]="minMaxRange1$ | async"
                          [dateRange2]="dateRange2$ | async"
                          [minMaxRange2]="minMaxRange2$ | async"
                          [dateRange3]="dateRange3$ | async"
                          [minMaxRange3]="minMaxRange3$ | async"
                          (dateRangeChange)="onDateChange($event)"
                          (dateRangeChange1)="onDateChange1($event)"
                          (dateRangeChange2)="onDateChange2($event)"
                          (dateRangeChange3)="onDateChange3($event)"
                          (Number_TrainingPeriodDropdown)="setNumber_TrainingPeriodDropdown($event)"
                          (clickValidFlag)="onClickValidFlag($event)"
                          (clicklearnigFlag)="onClickLearning($event)"
                          (clickZeroValue)="onClickZeroValue($event)"
                          (clickAddRemovePicker)="onClicAddkRemovePicker($event)"
                          [inferenceDateRange]="inferenceDateRange$ |async"
                          [inferenceMinMaxRange]="inferenceMinMaxRange$ | async"
                          (inferenceDateRangeChange)="onInferenceDateChange($event)"
                          (clickRunML)="onClickRunML($event)"
                          [machineLearningGraph]="machineLearningGraph"
                          [machineLearningLayout]="machineLearningLayout"
                          (clickCheckbox)="onClickCheckbox($event)"
                          (clickInjectorCheckbox)="clickInjectorCheckbox($event)"
                          (downloadCSV)="onClickExportExcel()"
                          (clickSaveModel)="onClickSaveModel()"
                          [isRunMlClicked]="isRunMlClicked"
                          [mlConfidenceData]="mlConfidenceData"
                          [mlMetricsGraph]="mlMetricsGraph"
                          [mlMetricsLayout]="mlMetricsLayout"
                          [displayedMetricsGraph]="displayedMetricsGraph"
                          (switchMetricsGraph)="onSwitchMetricsGraph($event)"
                          [mlLearningCurvesGraph]="mlLearningCurvesGraph"
                          [mlLearningCurvesLayout]="mlLearningCurvesLayout"
                          [mlFeaturesImportanceGraph]="mlFeaturesImportanceGraph"
                          [mlFeaturesImportanceLayout]="mlFeaturesImportanceLayout"
                          [selectedUserAdded]="selectedUserAdded"
                          [mlCorelationGraph]="mlCorelationGraph"
                          [mlCorelationLayout]="mlCorelationLayout"
                          [wellTestStatus]="wellTestStatus$ | async"
                          [target]="mlRunParams.target"
                          [trainingDataGraph]="trainingDataGraph"
                          [trainingDataLayout]="trainingDataLayout"
                          [featureDataLayout]="featureDataLayout"
                          [featureDataGraph]="featureDataGraph"
                          (targetValueSelected)="targetValueChange($event)"
                          (valueSelectedSource)="valueSelectedSourceChange($event)"
                          (inputSourceValueSelected)="inputSourceValueChange($event)"
                          (targetSourceValueSelected)="targetSourceValueChange($event)"
                          (wellStatusSelected)="wellStatusValueChange($event)"
                          (ColunmSourceSelected)="ColunmSourceSelectedChange($event)"
                          (ClickInputFeature)="onClickInputFeaturebox($event)"
                          (ClickUserAddedFeature)="onClickUserAddedbox($event)"
                          (TableValueSelected)="onTableValueChange($event)"
                          [tMoreCloumnInfo]="tMoreCloumnInfo"
                          [columSourceList]="columSourceList"
                          [featuresList]="featuresList"
                          [percentageList]= "percentageList"
                          [TmoreTableList]="TmoreTableList"
                          [wellTypeInjector]="isInjector"
                          [wellTypeProducer]="isProducer"
                          [wellType]="wellType"
                          [mlFeaturesGraph]="mlFeaturesGraph"
                          [mlFeaturesLayout]="mlFeaturesLayout"
                          [mlFeatureGraphTitle]="mlFeatureGraphTitle"
                          [mlFeatureXtitle]="mlFeatureXtitle"
                          >
    </wag-machine-learning>
  `
})
export class ModelContainer implements OnInit, OnDestroy {
  @ViewChild(MachineLearningComponent) child!: MachineLearningComponent;
  @ViewChild(MachineLearningComponent) childInjector!: MachineLearningComponent;

  @Input() layout?= JSON.parse(JSON.stringify(DEFAULT_LAYOUT));
  @Input() passedData: any;
  assets$ = this.sb.assets$;
  fields$ = this.sb.fields$;
  wells$ = this.sb.wells$;
  affiliate!: string;
  selectedWell!: SelectedWellTypeInterface;
  dateRange$: Observable<FieldRangeDates> = this.sb.fieldRangeDates$.pipe(skip(1));
  minMaxRange$: Observable<FieldRangeDates> = this.sb.fieldRangeDates$.pipe(skip(1), first());

  dateRange1$: Observable<FieldRangeDates> = this.sb.fieldRangeDates1$.pipe(skip(1));
  minMaxRange1$: Observable<FieldRangeDates> = this.sb.fieldRangeDates1$.pipe(skip(1), first());

  dateRange2$: Observable<FieldRangeDates> =   this.sb.fieldRangeDates2$.pipe(skip(1));
  minMaxRange2$: Observable<FieldRangeDates> = this.sb.fieldRangeDates2$.pipe(skip(1), first());
  dateRange3$: Observable<FieldRangeDates> =   this.sb.fieldRangeDates3$.pipe(skip(1));
  minMaxRange3$: Observable<FieldRangeDates> = this.sb.fieldRangeDates3$.pipe(skip(1), first());
  countddl:number=0;


  inferenceDateRange$: Observable<FieldRangeDates> = this.sb.inferenceRangeDates$.pipe(skip(1));
  inferenceMinMaxRange$: Observable<FieldRangeDates> = this.sb.inferenceRangeDates$.pipe(skip(1), first());
  filterData: DataFilters = { asset: '', field: '', well: '' };
  isWellSelected = false;
  productionMonitoringGraph!: PlotData[];
  productionMonitoringLayout!: any;
  productionDataLayout!: any;
  productionDataGraph!: PlotData[];
  ML_TARGET_SOURCE = ML_TARGET_SOURCE_INJECTOR;
  trainingDataLayout: any = TRAINING_DATA_LAYOUT;
  featureDataLayout: any = FEATURE_DATA_LAYOUT;
  trainingDataGraph: PlotData[] = JSON.parse(JSON.stringify(TRAINING_DATA_PLOT_DATA));
  featureDataGraph: PlotData[] = JSON.parse(JSON.stringify(FEATURE_DATA_PLOT_DATA));
  machineLearningGraph: PlotData[] = JSON.parse(JSON.stringify(MACHINE_LEARNING_PLOT_DATA));
  machineLearningLayout: any = MACHINE_LEARNING_LAYOUT;
  machineLearningData$: Observable<MachineLearningData> = this.sb.machineLearningData$.pipe(skip(1));
  mlRunParams!: MLRunParams;
  mlConfidenceData!: MLConfidenceData;
  mlConfidenceMetrics!: MLConfidenceMetrics;
  mlMetricsDispersionGraph: PlotData[] = JSON.parse(JSON.stringify(ML_METRICS_PLOT_DATA));
  mlMetricsDispersionLayout: Partial<Layout> = ML_METRICS_LAYOUT;
  mlMetricsBoxGraph: PlotData[] = JSON.parse(JSON.stringify(ML_METRICS_BOXPLOT_DATA));
  mlMetricsBoxLayout: Partial<Layout> = ML_METRICS_BOXPLOT_LAYOUT;
  mlMetricsGraph: PlotData[] = JSON.parse(JSON.stringify(ML_METRICS_PLOT_DATA));
  mlMetricsLayout: Partial<Layout> = ML_METRICS_LAYOUT;
  mlLearningCurvesGraph: PlotData[] = JSON.parse(JSON.stringify(ML_LEARNING_CURVES_PLOT_DATA));
  mlLearningCurvesLayout: Partial<Layout> = ML_LEARNING_CURVES_LAYOUT;
  displayedMetricsGraph: string = 'dispersion';
  mlFeaturesImportanceGraph: PlotData[] = JSON.parse(JSON.stringify(ML_FEATURES_IMPORTANCE_PLOT_DATA));
  mlFeaturesImportanceLayout: Partial<Layout> = ML_FEATURES_IMPORTANCE_LAYOUT;
  mlCorelationGraph: PlotData[] = JSON.parse(JSON.stringify(ML_CORELATION_PLOT_DATA));
  mlCorelationLayout: Partial<Layout> = ML_CORELATION_LAYOUT;
  mlFeaturesGraph: PlotData[][] = [];
  mlFeaturesLayout: any[] = [];
  mlFeatureGraphTitle: string[] = [];
  mlFeatureXtitle: string[] = [];
  wellTestStatus$: Observable<string[]> = this.sb.wellTestStatus$.pipe(skip(1));
  wellQcTableData$: Observable<WellQcTableData[]> = this.sb.wellQcTableData$.pipe(skip(1));
  qcDateRange$: Observable<FieldRangeDates> = this.sb.qcRangeDates$.pipe(skip(1));
  qcFilterData: DataFilters = { asset: '', field: '', well: '' };
  saveModelTableData$: Observable<SaveModelTableData[]> = this.sb.saveModelTableData$.pipe(skip(1));
  userName$: Observable<string> = this.sb.userName$;
  userName!: string;
  isRunMlClicked?: boolean = true;
  run_id!: string;
  ExcelData: any;
  isSavedItemclick?: boolean = false;
  isProducer: boolean = true;
  isInjector: boolean = false;
  selectedTarget: string = '';
  selectedTargetSource: string = '';
  selectedInputSource: string = '';
  selectedFeatureSource: string = '';
  selectedUserAdded!:any[];
  wellStatus: string = '';
  isShowMyModelsOnly: boolean = true;
  tMoreCloumnInfo: string = '';
  columSourceList: any;
  TmoreTableList:any;
  featuresList:any=[];
  percentageList: any = [];
  LatestName!: string;
  isModelName: boolean = false;
  flagStatus: string | undefined;
  onlyInjector: boolean = false;
  wellType!: string;
  graphTitle?: any;
  finalArrayData: any = [];
  routeSubscription!: Subscription;
  WellTestStatus: string = '';
  loadColunmSource!: Subscription;
  colunmSourceValue: string = '';
  addRemovePicker: boolean = false;
  dataof: any;
  ValuesOfJSON: string = '';
  ValuesOfJSONArray: any = [];
  textColor: string = '';
  Samplearray: any = [];
  arrayofx: any = [];
  featuredata:any=[];
  featuresVisualizationRedisKey: string = "";
  wellTestVisualizationKey: string = "";
  k: string = '';
  corelationCoefficient:any=[];
  AtrributeArray: any = [];
  tmoreTableName:any=undefined;
  userAddedColorCode:string[]=['#301934','#CBC3E3','#AA336A','#FDB0C0'];
  format = "YYYY-MM-DD"
  dateRangeSubscription: Subscription = this.dateRange$.subscribe(dateRange => {

    this.filterData = {
      ...this.filterData,
      date_min: new Date(dateRange.startDate),
      date_max: new Date(dateRange.endDate),
    };
    this.mlRunParams = {
      ...this.mlRunParams,
      min_date: new Date(dateRange.startDate),
      max_date: new Date(dateRange.endDate),
    };
    // this.sb.loadWellTestStatus(this.affiliate, this.filterData);
  });
  dateRangeSubscription1: Subscription = this.dateRange1$.subscribe(dateRange => {

    this.filterData = {
      ...this.filterData,
      date_min1: new Date(dateRange.startDate),
      date_max1: new Date(dateRange.endDate),
    };

    this.mlRunParams = {
      ...this.mlRunParams,
      min_date1: new Date(dateRange.startDate),
      max_date1: new Date(dateRange.endDate),
    };
  });

  dateRangeSubscription2: Subscription = this.dateRange2$.subscribe(dateRange => {

    this.filterData = {
      ...this.filterData,
      date_min2: new Date(dateRange.startDate),
      date_max2: new Date(dateRange.endDate),
    };

    this.mlRunParams = {
      ...this.mlRunParams,
      min_date2: new Date(dateRange.startDate),
      max_date2: new Date(dateRange.endDate),
    };
  });

  dateRangeSubscription3: Subscription = this.dateRange3$.subscribe(dateRange => {

    this.filterData = {
      ...this.filterData,
      date_min3: new Date(dateRange.startDate),
      date_max3: new Date(dateRange.endDate),
    };

    this.mlRunParams = {
      ...this.mlRunParams,
      min_date3: new Date(dateRange.startDate),
      max_date3: new Date(dateRange.endDate),
    };
  });


  qcDateRangeSubscription: Subscription = this.qcDateRange$.subscribe(dateRange => {

    this.qcFilterData = {
      ...this.qcFilterData,
      date_min: new Date(dateRange.startDate),
      date_max: new Date(dateRange.endDate),
    };
    this.sb.loadWellTestStatus(this.affiliate, this.qcFilterData);

    if (this.isInjector === true && this.isProducer === false) {
      this.sb.loadWellQcTableDataInjector(this.affiliate, this.qcFilterData, 'injector');
    }
    else {
      this.sb.loadWellQcTableData(this.affiliate, this.qcFilterData);
    }



    if (this.onlyInjector === true) {
      this.productionMonitoringGraph = JSON.parse(JSON.stringify(PRODUCTION_MONITORING_PLOT_DATA_INJECTOR));
      this.productionMonitoringLayout = MOPRODUCTION_MONITORING_LAYOUT_INJECTOR;
      this.productionDataLayout = PRODUCTION_DATA_LAYOUT_INJECTOR;
      this.productionDataGraph = JSON.parse(JSON.stringify(PRODUCTION_PLOT_DATA_INJECTOR));

      const productionMonitoringInjector$ = this.sb.loadProductionMonitoringInjector(this.affiliate, this.qcFilterData);
      const productionDataInjector$ = this.sb.loadProductionDataInjector(this.affiliate, this.qcFilterData);
      forkJoin([productionMonitoringInjector$, productionDataInjector$]).subscribe(data => {

        this.productionMonitoringGraph.forEach((plot: PlotData) => ModelContainer.handlePlotInjectors(plot, data[0], this.isInjector));
        this.productionDataGraph.forEach((plot: PlotData) => ModelContainer.handlePlotInjectors(plot, data[1], this.isInjector));
      });
    } else {

      this.productionMonitoringGraph = JSON.parse(JSON.stringify(PRODUCTION_MONITORING_PLOT_DATA));
      this.productionMonitoringLayout = PRODUCTION_MONITORING_LAYOUT;
      this.productionDataLayout = PRODUCTION_DATA_LAYOUT;
      this.productionDataGraph = JSON.parse(JSON.stringify(PRODUCTION_PLOT_DATA));

      const productionMonitoring$ = this.sb.loadProductionMonitoring(this.affiliate, this.qcFilterData);
      const productionData$ = this.sb.loadProductionData(this.affiliate, this.qcFilterData);

      forkJoin([productionData$, productionMonitoring$]).subscribe(data => {
        this.productionMonitoringGraph.forEach((plot: PlotData) => ModelContainer.handlePlot(plot, data[1], this.isInjector));
        this.productionDataGraph.forEach((plot: PlotData) => ModelContainer.handlePlot(plot, data[0], this.isInjector));
      });
    }

  });

  inferenceDateRangeSubscription: Subscription = this.inferenceDateRange$.subscribe(dateRange => {

    this.mlRunParams = {
      ...this.mlRunParams,
      inference_min_date: new Date(dateRange.startDate),
      inference_max_date: new Date(dateRange.endDate),
    };

  })

  UpdatedNameSubscription: Subscription = this.saveModelTableData$.subscribe(data => {

    if (data.length > 0) {
      this.LatestName = data[0].model_name;
    }

  });

  selectedWellSubscription: Subscription = this.sb.selectedWell$.subscribe((selectedWell: SelectedWellTypeInterface) => this.selectedWell = selectedWell);

  mlRunParamsSubscription: Subscription = this.sb.mlRunParams$.subscribe((mlRunParams: MLRunParams) => this.mlRunParams = mlRunParams);

  machineLearningDataSubscription: Subscription = this.machineLearningData$.subscribe((data: MachineLearningData) => {

    this.isRunMlClicked = false;
    this.run_id = data.base.run_id;

    if (this.machineLearningGraph.length > 0) this.resetMLGraphData();

    this.machineLearningGraph.forEach((plot: PlotData) => {

      plot.x = plot.name === 'Model Prediction' ? data.base.inferred_dates : data.base.well_test_dates;
      if (this.mlRunParams.target === 'Qliq' || this.mlRunParams.target === 'Qwat' || this.mlRunParams.target === 'Qoil' || this.mlRunParams.target === 'Qwinj') {
        if (plot.name === "Model Prediction") {
          const tempValue: any = data.base.inference_predictions;
          const tempYValue = tempValue.map((d: any) => {
            return d * M3_TO_BBL;
          })
          plot.y = tempYValue;
        } else {
          const tempValue: any = data.base.well_test;
          const tempYValue = tempValue.map((d: any) => {
            return d * M3_TO_BBL;
          })
          plot.y = tempYValue;
        }


      } else {
        if (plot.name === "Model Prediction")
          plot.y = data.base.inference_predictions;
        else
          plot.y = data.base.well_test;
      }

      this.setTargetPlotLayout(plot);
      if (this.machineLearningLayout) this.machineLearningLayout.title.text = `Target-(${this.mlRunParams.target})`;
    });

    this.mlConfidenceData = { ...data.confidence };

    this.mlMetricsDispersionGraph.forEach((plot: PlotData) => {

      if (plot.name !== 'identity_func') {
        if (plot.name === 'y_pred') {
          if (this.mlRunParams.target === "Qoil" || this.mlRunParams.target === "Qliq" || this.mlRunParams.target === "Qwinj" || this.mlRunParams.target === "Qwat") {
            let tempYarray: any[] = data.confidence.dispersion_error[plot.name as keyof MLDispersionError];
            tempYarray = tempYarray.map(item => item * M3_TO_BBL);
            let tempXarray: any[] = data.confidence.dispersion_error.y_reel;
            tempXarray = tempXarray.map(item => item * M3_TO_BBL);
            plot.y = tempYarray;
            plot.x = tempXarray;
          }
          else {
            plot.x = data.confidence.dispersion_error.y_reel;
            plot.y = data.confidence.dispersion_error[plot.name as keyof MLDispersionError];
          }

          this.setMetricsDispersionGraph();
        }
      }
      else {

        if (this.mlRunParams.target === "Qoil" || this.mlRunParams.target === "Qliq" || this.mlRunParams.target === "Qwat" || this.mlRunParams.target === "Qwinj") {
          const max_value = Math.max(...data.confidence.dispersion_error.y_pred, ...data.confidence.dispersion_error.y_reel);
          const min_value = Math.min(...data.confidence.dispersion_error.y_pred, ...data.confidence.dispersion_error.y_reel);
          plot.x = [min_value * M3_TO_BBL, max_value * M3_TO_BBL];
          plot.y = [min_value * M3_TO_BBL, max_value * M3_TO_BBL];
        } else {
          const max_value = Math.max(...data.confidence.dispersion_error.y_pred, ...data.confidence.dispersion_error.y_reel);
          const min_value = Math.min(...data.confidence.dispersion_error.y_pred, ...data.confidence.dispersion_error.y_reel);
          plot.x = [min_value, max_value];
          plot.y = [min_value, max_value];
        }


      }
    });

    this.mlMetricsBoxGraph[0].y = data.confidence.dispersion_error.y_delta;

    if (this.displayedMetricsGraph === 'dispersion') {
      this.mlMetricsGraph = JSON.parse(JSON.stringify(this.mlMetricsDispersionGraph));
      this.setMetricsDispersionGraph();
    }

    else
      this.mlMetricsGraph = JSON.parse(JSON.stringify(this.mlMetricsBoxGraph));

    this.mlLearningCurvesGraph.forEach((plot: PlotData) => {
      if (this.mlRunParams.target === "Qoil" || this.mlRunParams.target === "Qliq" || this.mlRunParams.target === "Qwat" || this.mlRunParams.target === "Qwinj") {
        const tempValue: any = data.confidence.learning_curves[plot.name as keyof MLLearningCurves];
        if (tempValue != undefined) {
          const tempYValue = tempValue.map((d: any) => {
            return d * M3_TO_BBL;
          })
          plot.y = tempYValue;
          plot.x = data.confidence.learning_curves.x;
        }
      } else {
        plot.y = data.confidence.learning_curves[plot.name as keyof MLLearningCurves];
        plot.x = data.confidence.learning_curves.x;
      }
      this.setMLLearningCurve();
    });

    this.mlFeaturesImportanceGraph[0].x = data.confidence.features_importance.features;
    this.mlFeaturesImportanceGraph[0].y = data.confidence.features_importance.values;


    const replaceKeys = (object: any) => {
      Object.keys(object).forEach((key) => {
        const newKey = key.replace(/\s+/g, '');

        const newKey2 = newKey.replace('-', '_');
        let finaltext = '';
        finaltext = newKey2.replace("PS_Production_Data_", "").replace("PS_Production_Monitoring_", "").replace("PS_Injection_Data_", "").replace("PS_Injection_Monitoring_", "");
        if (object[key] && typeof object[key] === 'object') {
          replaceKeys(object[key]);
        }

        if (key !== finaltext) {

          object[finaltext] = object[key];
          delete object[key];
        }
        if (object[finaltext] && typeof object[finaltext] === 'object' && (finaltext == "Qoil" || finaltext == "Qgas" || finaltext == "Qwat"
          || finaltext == "Qliq" || finaltext == "GOR" || finaltext == "GLR" || finaltext == "WCT" || finaltext == "Qwinj" || finaltext == "Qginj")) {
          delete object[finaltext];
        }
        else if (object[finaltext] && typeof object[finaltext] === 'number' && (finaltext == "Qoil" || finaltext == "Qgas" || finaltext == "Qwat"
          || finaltext == "Qliq" || finaltext == "GOR" || finaltext == "GLR" || finaltext == "WCT" || finaltext == "Qwinj" || finaltext == "Qginj")) {
          delete object[finaltext];
        }
      });
    }



    this.corelationCoefficient = JSON.parse(JSON.stringify(data.confidence.correlation_profile));
    replaceKeys(this.corelationCoefficient);
    let LatestCoeffiData: any;
    LatestCoeffiData = Object.values(this.corelationCoefficient)
      .map(item => [item]);
    this.arrayofx = Object.keys(LatestCoeffiData[0][0]);
    let k1: any;
    this.ValuesOfJSONArray = [];
    let t = 0;
    for (let m = 0; m < LatestCoeffiData.length; m++, t++) {
      this.ValuesOfJSONArray[m] = [];
      k1 = Object.entries(LatestCoeffiData[t][0]);

      for (let o = 0; o < k1.length; o++) {
        let h = k1[o][1];
        this.ValuesOfJSONArray[m][o] = parseFloat(h.toFixed(3));
      }

    }

    this.mlCorelationGraph[0].x = this.arrayofx;
    this.mlCorelationGraph[0].y = this.arrayofx;
    this.mlCorelationGraph[0].z = this.ValuesOfJSONArray;
    this.mlCorelationLayout.annotations = [];
    for (let i = 0; i < this.arrayofx.length; i++) {
      for (let j = 0; j < this.arrayofx.length; j++) {
        const result: any = {
          xref: 'x1',
          yref: 'y1',
          x: this.arrayofx[j],
          y: this.arrayofx[i],
          text: this.ValuesOfJSONArray[i][j],
          font: {
            family: 'Arial',
            size: 10,
            color: '#ffffff'
          },
          showarrow: false,

        };
        this.mlCorelationLayout.annotations?.push(result);
      }
    }

    this.mlFeaturesGraph = [];
    this.mlFeaturesLayout = [];
    this.mlFeatureGraphTitle = [];
    this.mlFeatureXtitle = [];

    let reqObj: any[] = [];
    let keys: any[] = [];
    for (const [key, value] of Object.entries(data.confidence.model_interpretability.xdata)) {
      reqObj.push({ [key]: value })
      keys.push(key);

      this.mlFeaturesGraph.push(JSON.parse(JSON.stringify(ML_FEATURE_VS_TARGET)))
      this.mlFeaturesLayout.push(ML_FEATURE_VS_TARGET_LAYOUT);
      this.mlFeatureGraphTitle.push(`${this.mlRunParams.target} vs ${key}`)
      let keyAtrr = key.split("- ", 2);
      let attrKey = keyAtrr[1];
      if (TEMPETATURE.includes(attrKey)) {
        this.mlFeatureXtitle.push(`${attrKey} Temperature, Degree °C`);
      }
      else if (PRESSURE.includes(attrKey)) {
        this.mlFeatureXtitle.push(`${attrKey} Pressure, Bar`);
      }
      else if (PERCENTAGE.includes(attrKey)) {
        this.mlFeatureXtitle.push(`${attrKey} %`);
      }
      else if (KSMUNIT.includes(attrKey)) {
        this.mlFeatureXtitle.push(`${attrKey} kSm3/d`);
      }
      else if (CHUNIT.includes(attrKey)) {
        this.mlFeatureXtitle.push(`${attrKey} inch`);
      }
      else {
        this.mlFeatureXtitle.push(`${attrKey} bbl/d`);
      }
    }

    reqObj.forEach((res: any, i) => {

      if (this.mlRunParams.target === "Qoil" || this.mlRunParams.target === "Qwat" || this.mlRunParams.target === "Qliq" || this.mlRunParams.target === "Qwinj") {
        this.mlFeaturesLayout[i].yaxis.title = `${this.mlRunParams.target} bbl/d`
      } else if (this.mlRunParams.target === "Qgas") {
        this.mlFeaturesLayout[i].yaxis.title = `${this.mlRunParams.target}(kSm3/d)`
      }
      else if (this.mlRunParams.target === "GOR") {
        this.mlFeaturesLayout[i].yaxis.title = `${this.mlRunParams.target} (sm3/sm3)`
      }
      this.mlFeaturesGraph[i].forEach((plot: PlotData) => {


        if (plot.name === 'Model_Prediction') {
          plot.x = res[keys[i]][plot.name];
          if (this.mlRunParams.target === "Qoil" || this.mlRunParams.target === "Qliq" || this.mlRunParams.target === "Qwat" || this.mlRunParams.target === "Qwinj") {
            const tempValue: any = data.confidence.model_interpretability.ydata['target_predict'];
            const tempYValue = tempValue.map((d: any) => {
              return d * M3_TO_BBL;
            })
            plot.y = tempYValue;

          } else {
            plot.y = data.confidence.model_interpretability.ydata['target_predict'];
          }


        }
        if (plot.name === 'Testing_Data_set') {
          plot.x = res[keys[i]][plot.name];

          if (this.mlRunParams.target === "Qoil" || this.mlRunParams.target === "Qliq" || this.mlRunParams.target === "Qwat" || this.mlRunParams.target === "Qwinj") {
            const tempValue: any = data.confidence.model_interpretability.ydata['target_test'];
            const tempYValue = tempValue.map((d: any) => {
              return d * M3_TO_BBL;
            })
            plot.y = tempYValue;

          } else {
            plot.y = data.confidence.model_interpretability.ydata['target_test'];
          }
        }
        if (plot.name === 'Training_Data_set') {
          plot.x = res[keys[i]][plot.name];

          if (this.mlRunParams.target === "Qoil" || this.mlRunParams.target === "Qliq" || this.mlRunParams.target === "Qwat" || this.mlRunParams.target === "Qwinj") {
            const tempValue: any = data.confidence.model_interpretability.ydata['target_train'];
            const tempYValue = tempValue.map((d: any) => {
              return d * M3_TO_BBL;
            })
            plot.y = tempYValue;

          } else {
            plot.y = data.confidence.model_interpretability.ydata['target_train'];
          }

        }

      })
    })

  });



  constructor(
    private sb: AppSandbox,
    private monitoringService: MonitoringService,
    private dialog: MatDialog,
    private viewportScroller: ViewportScroller,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.monitoringService.logPageView('buildContainer');
    this.sb.selectedAffiliate$.subscribe(affiliate => { this.affiliate = affiliate; });
    this.sb.loadUserName();

    this.routeSubscription = this.route.queryParams.subscribe((value:Params) => {
    if(Object.keys(value).length>0){
     let decoded = decodeURI(value.tableList);
      this.passedData = JSON.parse(decoded);
      if (this.passedData.well_type !== undefined) {
        if (this.passedData.well_type == 'producer') {
          this.wellType = 'producer';
          this.isInjector = false;
          this.isProducer = true;
        }
        else {
          this.wellType = 'injector';
          this.isInjector = true;
          this.isProducer = false;
        }
      }
    }
  })


  }
  trainingDataVisualizationSubscription: Subscription = this.sb.trainingDataVisualization$.subscribe(data => {
    this.trainingDataLayout = TRAINING_DATA_LAYOUT;
    this.wellTestVisualizationKey = data.redis_cache_key;

    this.trainingDataGraph.forEach((plotData, i) => {
      if (plotData.name === 'Target data') {
        plotData.x = data.inference_dates;
        if (this.selectedTarget === "Qoil" || this.selectedTarget === "Qwat" || this.selectedTarget === "Qliq" || this.selectedTarget === "Qwinj") {
          const tempValue: any = data.inference_values;
          const tempYValue = tempValue.map((value: any) => {
            if (value != null) {
              return value * M3_TO_BBL;
            } else {
              return null;
            }

          })
          plotData.y = tempYValue;

        } else {
          plotData.y = data.inference_values;
        }

        this.setTrainingDataTarget(data.target);
      }
      else {
        plotData.x = data.training_dates;
        if (this.selectedTarget === "Qoil" || this.selectedTarget === "Qwat" || this.selectedTarget === "Qliq" || this.selectedTarget === "Qwinj") {
          const tempValue: any = data.training_values;
          const tempYValue = tempValue.map((value: any) => {
            if (value != null) {
              return value * M3_TO_BBL;
            }
            else {
              return null;
            }

          })
          plotData.y = tempYValue;

        } else {
          plotData.y = data.training_values;
        }



        this.setTrainingDataTarget(this.selectedTarget);
      }
      if (this.trainingDataLayout) this.trainingDataLayout.title.text = `Target-(${data.target})`;

    })
  })


  featureDataVisualizationSubscription: Subscription = this.sb.featureDataVisualization$.pipe(skip(1)).subscribe(data => {

    this.featureDataLayout = FEATURE_DATA_LAYOUT;
    this.featureDataGraph = JSON.parse(JSON.stringify(FEATURE_DATA_PLOT_DATA));

    this.featuredata= Object.keys(data.values);
    this.featuresVisualizationRedisKey = data.redis_cache_key;

    let featuredataArray:any;
    let userAddedFeturesValue:string[]=[];
    if(this.selectedUserAdded!=undefined){
      userAddedFeturesValue= this.selectedUserAdded.map((feature:any)=>{
        return feature.column_name;
      });
    }

    if(this.featuredata!=undefined)
    {
      for(let o=0; o < this.featuredata.length; o++)
    {

      if(userAddedFeturesValue.includes(this.featuredata[o]))
      {
        featuredataArray={

          name: this.featuredata[o], type: "scatter", mode: "markers", line: { dash: 'dot', width: 2 }, marker: {color: this.userAddedColorCode[o],  size: 3}, yaxis: "y10"
        }
        this.featureDataGraph.push(featuredataArray);
      }
    }
    }

    const yaxisList = Object.keys(this.featureDataLayout).filter(element => element.startsWith('yaxis'));

    let selectedPlot: any;

    yaxisList.forEach((i) => {

      selectedPlot = i;
      this.featureDataLayout[selectedPlot].visible = false;
    })



    for (let key in data.values) {

      if (key == "wht") {
        selectedPlot = yaxisList[0];
        this.featureDataLayout[selectedPlot].visible = true;
      }

      if (key == "bht") {
        selectedPlot = yaxisList[1];
        this.featureDataLayout[selectedPlot].visible = true;
      }

      if (key == "whp") {
        selectedPlot = yaxisList[2];
        this.featureDataLayout[selectedPlot].visible = true;
      }

      if (key == "bhp") {
        selectedPlot = yaxisList[3];
        this.featureDataLayout[selectedPlot].visible = true;
      }

      if (key == "choke_opening") {
        selectedPlot = yaxisList[4];
        this.featureDataLayout[selectedPlot].visible = true;
      }

      if (key == "topside_t") {
        selectedPlot = yaxisList[5];
        this.featureDataLayout[selectedPlot].visible = true;
      }

      if (key == "topside_p") {
        selectedPlot = yaxisList[6];
        this.featureDataLayout[selectedPlot].visible = true;
      }

      if (key == "qgl_c_e") {
        selectedPlot = yaxisList[7];
        this.featureDataLayout[selectedPlot].visible = true;
      }

      if (key == "choke_size") {
        selectedPlot = yaxisList[8];
        this.featureDataLayout[selectedPlot].visible = true;
      }

      this.featureDataGraph.forEach((plot, i) => {
        if (plot.name.replace("-(°C)", '').replace("-(bar)", '').replace("-(%)", '').replace("-(bbl/d)", '').replace("-(inch)", '') === key) {
          plot.id = i + "";
          let val: any = data.values;
          let values: any = val[key];
          plot.x = data.dates;
          plot.y = values;

        }
      })
    }

  })


  public ModalCatalog(elementId: string): void {

    this.viewportScroller.scrollToAnchor(elementId);
  }
  private static handlePlot(plot: PlotData, data: ProductionMonitoring | ProductionData, isInjector: boolean): void {

    if (isInjector === true) {
      if (plot.name.replace("-(%)", '').replace("-(hr)", '').replace("-(bar)", '').replace("-(°C)", '').replace("-(bar)", '').replace("-(°C)", '').replace("-(%)", '').replace("-(%)", '').replace("-(%)", '').replace("-(bbl/d)", '').replace("-(bbl/d)", '').replace("-(kSm3/d)", '') in TestAttributes) {

        plot.x = data.date;

      } else {

        plot.x = data.date;

      }
    } else {

      if (plot.name.replace("-(%)", '').replace("-(hr)", '').replace("-(bar)", '').replace("-(°C)", '').replace("-(bar)", '').replace("-(°C)", '').replace("-(%)", '').replace("-(%)", '').replace("-(%)", '').replace("-(bbl/d)", '').replace("-(bbl/d)", '').replace("-(kSm3/d)", '') in TestAttributes) {

        plot.x = data.date_test;

      } else {

        plot.x = data.date;

      }
    }

    if (plot.name.replace("-(bbl/d)", '') == "qoil_test" || plot.name.replace("-(bbl/d)", '') == "qwat_test" || plot.name === "q_liq") {

      const tempValue = (data[plot.name.replace("-(bbl/d)", '').replace("-(bbl/d)", '') as keyof (ProductionMonitoring | ProductionData)]);
      if (tempValue != undefined || tempValue != null) {
        const tempYValue = tempValue.map((d: any) => {
          return d * M3_TO_BBL;
        })
        plot.y = tempYValue;
      } else {

        plot.y = [0];
      }
    }
    else {

      if (data[plot.name.replace("-(%)", '').replace("-(hr)", '').replace("-(bar)", '').replace("-(°C)", '').replace("-(bar)", '').replace("-(°C)", '').replace("-(%)", '').replace("-(%)", '').replace("-(%)", '').replace("-(bbl/d)", '').replace("-(bbl/d)", '').replace("-(kSm3/d)", '') as keyof (ProductionMonitoring | ProductionData)] != undefined) {
        plot.y = (data[plot.name.replace("-(%)", '').replace("-(hr)", '').replace("-(bar)", '').replace("-(°C)", '').replace("-(bar)", '').replace("-(°C)", '').replace("-(%)", '').replace("-(%)", '').replace("-(%)", '').replace("-(bbl/d)", '').replace("-(bbl/d)", '').replace("-(kSm3/d)", '') as keyof (ProductionMonitoring | ProductionData)]);
      }

      else {

        plot.y = [0]
      }
    }

  }

  private static handlePlotInjectors(plot: PlotData, data: ProductionMonitoringInjector | ProductionDataInjector, isInjector: boolean): void {
    plot.x = data.date;
    if (plot.name.replace("-(kSm3/d)", '') === "Qwinj") {
      const tempValue = (data[plot.name.replace("-(kSm3/d)", '') as keyof (ProductionMonitoringInjector | ProductionDataInjector)]);
      if (tempValue != undefined || tempValue != null) {
        const tempYValue = tempValue.map((d: any) => {
          return Math.round(d * M3_TO_BBL);
        })
        plot.y = tempYValue;
      } else {
        plot.y = [0];
      }
    } else {
      if (data[plot.name.replace("-(%)", '').replace("-(hr)", '').replace("-(bar)", '').replace("-(°C)", '').replace("-(bar)", '').replace("-(°C)", '').replace("-(%)", '').replace("-(%)", '').replace("-(%)", '').replace("-(bbl/d)", '').replace("-(bbl/d)", '').replace("-(kSm3/d)", '') as keyof (ProductionMonitoringInjector | ProductionDataInjector)] != undefined) {
        plot.y = (data[plot.name.replace("-(%)", '').replace("-(hr)", '').replace("-(bar)", '').replace("-(°C)", '').replace("-(bar)", '').replace("-(°C)", '').replace("-(%)", '').replace("-(%)", '').replace("-(%)", '').replace("-(bbl/d)", '').replace("-(bbl/d)", '').replace("-(kSm3/d)", '') as keyof (ProductionMonitoringInjector | ProductionDataInjector)]);
      }
      else {
        plot.y = [0]
      }
    }

  }

  ngOnInit(): void {


    this.sb.loadAssets(this.affiliate).subscribe();
    window.history.replaceState({}, '', '/model');

    this.sb.loadTmoreColunm(this.affiliate).subscribe((res: any) => {

      this.TmoreTableList = res.table_name_list;
    });

  }

  onValueSelect(event: ValueSelect): void {

    this.monitoringService.logEvent('fieldWellAssetSelection', event);
    if (event.value) {
      switch (event.control) {
        case 'Asset':
          this.filterData.asset = event.value;
          this.qcFilterData.asset = event.value;
          this.sb.loadFields(this.affiliate, event.value).subscribe();
          break;
        case 'Field':
          this.filterData.field = event.value;
          this.qcFilterData.field = event.value;
          this.callWellSelection(this.filterData.asset,this.filterData.field);
          break;
        case 'Well':
          this.filterData.well = event.value;
          this.qcFilterData.well = event.value;
          if (this.filterData.field && this.filterData.well)
            this.sb.setSelectedWell({
              asset: this.filterData.asset,
              field: this.filterData.field,
              well: this.filterData.well
            });
          break;
        default:
          break;
      }
    }
  }

  callWellSelection(asset: string, value: any) {
    if (this.isProducer && !this.isInjector) {
      this.sb.loadWells(this.affiliate, asset, value, 'producer').subscribe();
    } else if (this.isInjector && !this.isProducer) {
      this.sb.loadWells(this.affiliate, asset, value, 'injector').subscribe();
    } else if (this.isProducer && this.isInjector) {
      this.sb.loadWells(this.affiliate, asset, value, 'both').subscribe();
    } else {
      this.sb.loadWells(this.affiliate, asset, value).subscribe();
    }

  }

  onClickUserAddedbox(event:any):void{
    this.selectedUserAdded=event.ColumnValue;
    if (event.ColumnValue != null) {
      if (this.mlRunParams.min_date != undefined && this.mlRunParams.max_date != undefined) {

        if (this.selectedUserAdded.length>0) {
         if(this.addRemovePicker)
          this.sb.loadFeaturesVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.wellType, this.selectedInputSource, this.selectedFeatureSource, this.selectedUserAdded,this.tmoreTableName, 
        this.mlRunParams.max_date, this.mlRunParams.min_date, 
        (this.countddl>=2 ? this.mlRunParams.max_date1:undefined), (this.countddl>=2 ? this.mlRunParams.min_date1:undefined),
        (this.countddl>=3 ? this.mlRunParams.max_date2:undefined), (this.countddl>=3 ? this.mlRunParams.min_date2:undefined),
        (this.countddl>3 ? this.mlRunParams.max_date3:undefined), (this.countddl >3 ? this.mlRunParams.min_date2:undefined), 
        this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
          else
          this.sb.loadFeaturesVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.wellType, this.selectedInputSource, this.selectedFeatureSource, this.selectedUserAdded,this.tmoreTableName, this.mlRunParams.max_date, this.mlRunParams.min_date,
            (this.countddl>=2 ? this.mlRunParams.max_date1:undefined), (this.countddl>=2 ? this.mlRunParams.min_date1:undefined),
            (this.countddl>=3 ? this.mlRunParams.max_date2:undefined), (this.countddl>=3 ? this.mlRunParams.min_date2:undefined),
            (this.countddl>3 ? this.mlRunParams.max_date3:undefined), (this.countddl >3 ? this.mlRunParams.min_date2:undefined), 
        this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
        }
      }
      else {
        this.selectedUserAdded = event.ColumnValue;
        if (this.selectedUserAdded.length>0) {
          if(this.addRemovePicker)
          this.sb.loadFeaturesVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.wellType, this.selectedInputSource, this.selectedFeatureSource, this.selectedUserAdded,this.tmoreTableName, this.filterData.date_max, this.filterData.date_min, 
        (this.countddl>=2 ? this.filterData.date_max1:undefined), (this.countddl>=2 ? this.filterData.date_min1:undefined),
        (this.countddl>=3 ? this.filterData.date_max2:undefined), (this.countddl>=3 ? this.filterData.date_min2:undefined),
        (this.countddl>3 ? this.filterData.date_max3:undefined), (this.countddl >3 ? this.filterData.date_min3:undefined), 
        this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
          else
          this.sb.loadFeaturesVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.wellType, this.selectedInputSource, this.selectedFeatureSource, this.selectedUserAdded,this.tmoreTableName, this.filterData.date_max, this.filterData.date_min, 
            (this.countddl>=2 ? this.filterData.date_max1:undefined), (this.countddl>=2 ? this.filterData.date_min1:undefined),
            (this.countddl>=3 ? this.filterData.date_max2:undefined), (this.countddl>=3 ? this.filterData.date_min2:undefined),
            (this.countddl>3 ? this.filterData.date_max3:undefined), (this.countddl >3 ? this.filterData.date_min3:undefined), 
        this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);

        }

      }
    } else {
      this.featureDataGraph.forEach(plotData => {
        plotData.x = undefined;
        plotData.y = undefined;
        plotData.yaxis = "";


      })
    }
  }

  onClickInputFeaturebox(event: any): void {
    if (event.inputFeatures != null) {
      if (this.mlRunParams.min_date != undefined && this.mlRunParams.max_date != undefined) {
        this.selectedFeatureSource = event.inputFeatures.toString();
        if (this.selectedInputSource != '' && this.selectedFeatureSource != '') {
          if(this.addRemovePicker)
          this.sb.loadFeaturesVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.wellType, this.selectedInputSource, this.selectedFeatureSource, this.selectedUserAdded,this.tmoreTableName, 
        this.mlRunParams.max_date, this.mlRunParams.min_date, 
        (this.countddl>=2 ? this.mlRunParams.max_date1:undefined), (this.countddl>=2 ? this.mlRunParams.min_date1:undefined),
        (this.countddl>=3 ? this.mlRunParams.max_date2:undefined), (this.countddl>=3 ? this.mlRunParams.min_date2:undefined),
        (this.countddl>3 ? this.mlRunParams.max_date3:undefined), (this.countddl >3 ? this.mlRunParams.min_date3:undefined), 
        this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
         else
         this.sb.loadFeaturesVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.wellType, this.selectedInputSource, this.selectedFeatureSource, this.selectedUserAdded,this.tmoreTableName, 
        this.mlRunParams.max_date, this.mlRunParams.min_date, 
        (this.countddl>=2 ? this.mlRunParams.max_date1:undefined), (this.countddl>=2 ? this.mlRunParams.min_date1:undefined),
            (this.countddl>=3 ? this.mlRunParams.max_date2:undefined), (this.countddl>=3 ? this.mlRunParams.min_date2:undefined),
            (this.countddl>3 ? this.mlRunParams.max_date3:undefined), (this.countddl >3 ? this.mlRunParams.min_date3:undefined), 
        this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
        }
      }
      else {
        this.selectedFeatureSource = event.inputFeatures.toString();
        if (this.selectedInputSource != '' && this.selectedFeatureSource != '') {
          if(this.addRemovePicker)
          this.sb.loadFeaturesVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.wellType, this.selectedInputSource, this.selectedFeatureSource, this.selectedUserAdded,this.tmoreTableName, this.filterData.date_max, this.filterData.date_min, 
            (this.countddl>=2 ? this.filterData.date_max1:undefined), (this.countddl>=2 ? this.filterData.date_min1:undefined),
            (this.countddl>=3 ? this.filterData.date_max2:undefined), (this.countddl>=3 ? this.filterData.date_min2:undefined),
            (this.countddl>3 ? this.filterData.date_max3:undefined), (this.countddl >3 ? this.filterData.date_min3:undefined),
        this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
        else
          this.sb.loadFeaturesVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.wellType, this.selectedInputSource, this.selectedFeatureSource, this.selectedUserAdded,this.tmoreTableName, 
        this.filterData.date_max, this.filterData.date_min, 
        (this.countddl>=2 ? this.filterData.date_max1:undefined), (this.countddl>=2 ? this.filterData.date_min1:undefined),
        (this.countddl>=3 ? this.filterData.date_max2:undefined), (this.countddl>=3 ? this.filterData.date_min2:undefined),
        (this.countddl>3 ? this.filterData.date_max3:undefined), (this.countddl >3 ? this.filterData.date_min3:undefined),
        this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);

        }

      }
    } else {
      this.featureDataGraph.forEach(plotData => {
        plotData.x = undefined;
        plotData.y = undefined;
        plotData.yaxis = "";


      })
    }
  }

  inputSourceValueChange(event: ValueSelect) {

    if (event.value != null) {
      if (this.mlRunParams.min_date != undefined && this.mlRunParams.max_date != undefined) {
        this.selectedInputSource = event.value;
        if (this.selectedInputSource != '' && this.selectedFeatureSource != '') {

          this.sb.loadFeaturesVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.wellType, this.selectedInputSource, this.selectedFeatureSource, this.selectedUserAdded,this.tmoreTableName, this.mlRunParams.max_date, this.mlRunParams.min_date, 
            (this.countddl>=2 ? this.mlRunParams.max_date1:undefined), (this.countddl>=2 ? this.mlRunParams.min_date1:undefined),
            (this.countddl>=3 ? this.mlRunParams.max_date2:undefined), (this.countddl>=3 ? this.mlRunParams.min_date2:undefined),
            (this.countddl>3 ? this.mlRunParams.max_date3:undefined), (this.countddl >3 ? this.mlRunParams.min_date3:undefined),
            this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
        }
      }
      else {
        this.selectedInputSource = event.value;
        if (this.selectedInputSource != '' && this.selectedFeatureSource != '') {
          this.sb.loadFeaturesVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.wellType, this.selectedInputSource, this.selectedFeatureSource, this.selectedUserAdded,this.tmoreTableName, this.filterData.date_max, this.filterData.date_min, 
            (this.countddl>=2 ? this.filterData.date_max1:undefined), (this.countddl>=2 ? this.filterData.date_min1:undefined),
            (this.countddl>=3 ? this.filterData.date_max2:undefined), (this.countddl>=3 ? this.filterData.date_min2:undefined),
            (this.countddl>3 ? this.filterData.date_max3:undefined), (this.countddl >3 ? this.filterData.date_min3:undefined),
            this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);

        }

      }
    } else {
      this.featureDataGraph.forEach(plotData => {
        plotData.x = undefined;
        plotData.y = undefined;
        plotData.yaxis = "";
      })
    }
  }

  targetValueChange(event: ValueSelect) {

    this.tMoreCloumnInfo = '';
    this.colunmSourceValue = '';
    if (event.value != null) {
      if (this.mlRunParams.min_date != undefined && this.mlRunParams.max_date != undefined) {
        this.selectedTarget = event.value;
        if (this.selectedTarget != '' && this.selectedTargetSource != '' && this.selectedTargetSource == 'PS Production Test Data') {
          if (this.addRemovePicker) {
            this.sb.loadTrainingVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.selectedTarget, this.wellType, this.selectedTargetSource, this.wellStatus, this.colunmSourceValue, 
              this.mlRunParams.max_date, this.mlRunParams.min_date, 
              (this.countddl>=2 ? this.mlRunParams.max_date1:undefined), (this.countddl>=2 ? this.mlRunParams.min_date1:undefined),
              (this.countddl>=3 ? this.mlRunParams.max_date2:undefined), (this.countddl>=3 ? this.mlRunParams.min_date2:undefined),
              (this.countddl>3 ? this.mlRunParams.max_date3:undefined), (this.countddl >3 ? this.mlRunParams.min_date3:undefined), 
              this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
          }
          else {
            this.sb.loadTrainingVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.selectedTarget, this.wellType, this.selectedTargetSource, this.wellStatus, this.colunmSourceValue, 
              this.mlRunParams.max_date, this.mlRunParams.min_date, 
              (this.countddl>=2 ? this.mlRunParams.max_date1:undefined), (this.countddl>=2 ? this.mlRunParams.min_date1:undefined),
            (this.countddl>=3 ? this.mlRunParams.max_date2:undefined), (this.countddl>=3 ? this.mlRunParams.min_date2:undefined),
            (this.countddl>3 ? this.mlRunParams.max_date3:undefined), (this.countddl >3 ? this.mlRunParams.min_date3:undefined), 
              this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
          }
          this.sb.getTMoreColumnInfo(this.affiliate, this.selectedTarget, this.selectedTargetSource, this.wellType, this.colunmSourceValue).subscribe(res => {
            if (res.response.column_name != '' && res.response.tmore_table_name != '')
              this.tMoreCloumnInfo = res.response.tmore_table_name + "; " + res.response.column_name;
          });

        }
        else if (this.selectedTarget != '' && this.selectedTargetSource != '' && this.selectedTargetSource !== 'PS Production Test Data') {
          this.sb.loadColunmSource(this.affiliate, this.selectedTarget, this.selectedTargetSource, this.wellType).subscribe((res: any) => {

            this.columSourceList = res;
          });
        }

      } else {
        this.selectedTarget = event.value;
        if (this.colunmSourceValue != '' && this.selectedTargetSource != '' && this.selectedTargetSource !== 'PS Production Test Data') {

          this.sb.loadColunmSource(this.affiliate, this.selectedTarget, this.selectedTargetSource, this.wellType);
          this.sb.getTMoreColumnInfo(this.affiliate, this.selectedTarget, this.selectedTargetSource, this.wellType, this.colunmSourceValue).subscribe(res => {
            if (res.response.column_name != '' && res.response.tmore_table_name != '')
              this.tMoreCloumnInfo = res.response.tmore_table_name + "; " + res.response.column_name;
          });
        }
        else if (!this.colunmSourceValue && this.selectedTargetSource != '' && this.selectedTargetSource == 'PS Production Test Data') {
          if (this.addRemovePicker) {
            this.sb.loadTrainingVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.selectedTarget, this.wellType, this.selectedTargetSource, this.wellStatus, this.colunmSourceValue, 
              this.filterData.date_max, this.filterData.date_min, 
              (this.countddl>=2 ? this.filterData.date_max1:undefined), (this.countddl>=2 ? this.filterData.date_min1:undefined),
              (this.countddl>=3 ? this.filterData.date_max2:undefined), (this.countddl>=3 ? this.filterData.date_min2:undefined),
              (this.countddl>3 ? this.filterData.date_max3:undefined), (this.countddl >3 ? this.filterData.date_min3:undefined), 
              this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
          } else {
            this.sb.loadTrainingVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.selectedTarget, this.wellType, this.selectedTargetSource, this.wellStatus, this.colunmSourceValue, 
              this.filterData.date_max, this.filterData.date_min, 
              (this.countddl>=2 ? this.filterData.date_max1:undefined), (this.countddl>=2 ? this.filterData.date_min1:undefined),
              (this.countddl>=3 ? this.filterData.date_max2:undefined), (this.countddl>=3 ? this.filterData.date_min2:undefined),
              (this.countddl>3 ? this.filterData.date_max3:undefined), (this.countddl >3 ? this.filterData.date_min3:undefined),
              this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
          }
          this.sb.loadColunmSource(this.affiliate, this.selectedTarget, this.selectedTargetSource, this.wellType);
          this.sb.getTMoreColumnInfo(this.affiliate, this.selectedTarget, this.selectedTargetSource, this.wellType, this.colunmSourceValue).subscribe(res => {
            if (res.response.column_name != '' && res.response.tmore_table_name != '')
              this.tMoreCloumnInfo = res.response.tmore_table_name + "; " + res.response.column_name;
          });
        }
        else if (this.selectedTarget != '' && this.selectedTargetSource != '' && this.selectedTargetSource !== 'PS Production Test Data') {

          this.sb.loadColunmSource(this.affiliate, this.selectedTarget, this.selectedTargetSource, this.wellType).subscribe((res: any) => {

            this.columSourceList = res;
          });

        }
        else if (this.selectedTarget != '' && this.selectedTargetSource != '' && this.selectedTargetSource == 'PS Pressure Static' || this.selectedTargetSource == 'PS Injection Data' || this.selectedTargetSource == 'PS Injection Monitoring' || this.selectedTargetSource == 'PS Inj Allocation') {

          this.sb.loadColunmSource(this.affiliate, this.selectedTarget, this.selectedTargetSource, this.wellType).subscribe((res: any) => {

            this.columSourceList = res;
          });

        }
      }

    } else {
      this.trainingDataGraph.forEach(plotData => {
        plotData.x = undefined;
        plotData.y = undefined;
        plotData.yaxis = "";


      })
    }

  }

  targetSourceValueChange(event: ValueSelect) {
    this.wellStatus = '';
    this.tMoreCloumnInfo = '';
    if (event.value != null) {
      this.selectedTargetSource = event.value;
      if (this.mlRunParams.min_date != undefined && this.mlRunParams.max_date != undefined) {
        this.selectedTargetSource = event.value;
        if (this.selectedTarget != '' && this.selectedTargetSource != '' && this.selectedTargetSource == 'PS Production Test Data') {
          if (this.addRemovePicker) {
            this.sb.loadTrainingVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.selectedTarget, this.wellType, this.selectedTargetSource, this.wellStatus, this.colunmSourceValue, 
              this.mlRunParams.max_date, this.mlRunParams.min_date, 
              (this.countddl>=2 ? this.mlRunParams.max_date1:undefined), (this.countddl>=2 ? this.mlRunParams.min_date1:undefined),
            (this.countddl>=3 ? this.mlRunParams.max_date2:undefined), (this.countddl>=3 ? this.mlRunParams.min_date2:undefined),
            (this.countddl>3 ? this.mlRunParams.max_date3:undefined), (this.countddl >3 ? this.mlRunParams.min_date3:undefined), 
              this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
          }
          else {
            this.sb.loadTrainingVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.selectedTarget, this.wellType, this.selectedTargetSource, this.wellStatus, this.colunmSourceValue, 
              this.mlRunParams.max_date, this.mlRunParams.min_date,
              (this.countddl>=2 ? this.mlRunParams.max_date1:undefined), (this.countddl>=2 ? this.mlRunParams.min_date1:undefined),
              (this.countddl>=3 ? this.mlRunParams.max_date2:undefined), (this.countddl>=3 ? this.mlRunParams.min_date2:undefined),
              (this.countddl>3 ? this.mlRunParams.max_date3:undefined), (this.countddl >3 ? this.mlRunParams.min_date3:undefined), 
               this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
          }
          this.sb.getTMoreColumnInfo(this.affiliate, this.selectedTarget, this.selectedTargetSource, this.wellType, this.colunmSourceValue).subscribe(res => {
            if (res.response.column_name != '' && res.response.tmore_table_name != '')
              this.tMoreCloumnInfo = res.response.tmore_table_name + "; " + res.response.column_name;
          });
        }
        else if (this.colunmSourceValue != '' && this.selectedTargetSource != '' && this.selectedTargetSource !== 'PS Production Test Data') {
          if (this.addRemovePicker) {
            this.sb.loadTrainingVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.selectedTarget, this.wellType, this.selectedTargetSource, this.wellStatus, this.colunmSourceValue,
               this.mlRunParams.max_date, this.mlRunParams.min_date, 
               (this.countddl>=2 ? this.mlRunParams.max_date1:undefined), (this.countddl>=2 ? this.mlRunParams.min_date1:undefined),
               (this.countddl>=3 ? this.mlRunParams.max_date2:undefined), (this.countddl>=3 ? this.mlRunParams.min_date2:undefined),
               (this.countddl>3 ? this.mlRunParams.max_date3:undefined), (this.countddl >3 ? this.mlRunParams.min_date3:undefined), 
              this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
          }
          else {
            this.sb.loadTrainingVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.selectedTarget, this.wellType, this.selectedTargetSource, this.wellStatus, this.colunmSourceValue, 
              this.mlRunParams.max_date, this.mlRunParams.min_date,
              (this.countddl>=2 ? this.mlRunParams.max_date1:undefined), (this.countddl>=2 ? this.mlRunParams.min_date1:undefined),
            (this.countddl>=3 ? this.mlRunParams.max_date2:undefined), (this.countddl>=3 ? this.mlRunParams.min_date2:undefined),
            (this.countddl>3 ? this.mlRunParams.max_date3:undefined), (this.countddl >3 ? this.mlRunParams.min_date3:undefined), 
               this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
          }
          this.sb.getTMoreColumnInfo(this.affiliate, this.selectedTarget, this.selectedTargetSource, this.wellType, this.colunmSourceValue).subscribe(res => {
            if (res.response.column_name != '' && res.response.tmore_table_name != '')
              this.tMoreCloumnInfo = res.response.tmore_table_name + "; " + res.response.column_name;
          });
        }

      } else {
        this.selectedTargetSource = event.value;
        if (this.selectedTarget != '' && this.selectedTargetSource != '' && this.selectedTargetSource == 'PS Production Test Data') {
          if (this.addRemovePicker) {
            this.sb.loadTrainingVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.selectedTarget, this.wellType, this.selectedTargetSource, this.wellStatus, this.colunmSourceValue,
               this.filterData.date_max, this.filterData.date_min, 
              (this.countddl>=2 ? this.filterData.date_max1:undefined), (this.countddl>=2 ? this.filterData.date_min1:undefined),
              (this.countddl>=3 ? this.filterData.date_max2:undefined), (this.countddl>=3 ? this.filterData.date_min2:undefined),
              (this.countddl>3 ? this.filterData.date_max3:undefined), (this.countddl >3 ? this.filterData.date_min3:undefined),
              this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
          } else {
            this.sb.loadTrainingVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.selectedTarget, this.wellType, this.selectedTargetSource, this.wellStatus, this.colunmSourceValue, 
              this.filterData.date_max, this.filterData.date_min, 
              (this.countddl>=2 ? this.filterData.date_max1:undefined), (this.countddl>=2 ? this.filterData.date_min1:undefined),
              (this.countddl>=3 ? this.filterData.date_max2:undefined), (this.countddl>=3 ? this.filterData.date_min2:undefined),
              (this.countddl>3 ? this.filterData.date_max3:undefined), (this.countddl >3 ? this.filterData.date_min3:undefined),
              this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
          }
          this.sb.getTMoreColumnInfo(this.affiliate, this.selectedTarget, this.selectedTargetSource, this.wellType, this.colunmSourceValue).subscribe(res => {
            if (res.response.column_name != '' && res.response.tmore_table_name != '')
              this.tMoreCloumnInfo = res.response.tmore_table_name + "; " + res.response.column_name;
          });
        }
        else if (this.colunmSourceValue != '' && this.selectedTargetSource != '' && this.selectedTargetSource !== 'PS Production Test Data') {
          if (this.addRemovePicker) {
            this.sb.loadTrainingVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.selectedTarget, this.wellType, this.selectedTargetSource, this.wellStatus, this.colunmSourceValue, 
              this.filterData.date_max, this.filterData.date_min, 
              (this.countddl>=2 ? this.filterData.date_max1:undefined), (this.countddl>=2 ? this.filterData.date_min1:undefined),
              (this.countddl>=3 ? this.filterData.date_max2:undefined), (this.countddl>=3 ? this.filterData.date_min2:undefined),
              (this.countddl>3 ? this.filterData.date_max3:undefined), (this.countddl >3 ? this.filterData.date_min3:undefined),
              this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
          } else {
            this.sb.loadTrainingVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.selectedTarget, this.wellType, this.selectedTargetSource, this.wellStatus, this.colunmSourceValue, 
              this.filterData.date_max, this.filterData.date_min, 
              (this.countddl>=2 ? this.filterData.date_max1:undefined), (this.countddl>=2 ? this.filterData.date_min1:undefined),
              (this.countddl>=3 ? this.filterData.date_max2:undefined), (this.countddl>=3 ? this.filterData.date_min2:undefined),
              (this.countddl>3 ? this.filterData.date_max3:undefined), (this.countddl >3 ? this.filterData.date_min3:undefined),
              this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
          }
          this.sb.getTMoreColumnInfo(this.affiliate, this.selectedTarget, this.selectedTargetSource, this.wellType, this.colunmSourceValue).subscribe(res => {
            if (res.response.column_name != '' && res.response.tmore_table_name != '')
              this.tMoreCloumnInfo = res.response.tmore_table_name + "; " + res.response.column_name;
          });
        }

      }
    } else {
      this.trainingDataGraph.forEach(plotData => {
        plotData.x = undefined;
        plotData.y = undefined;
        plotData.yaxis = "";
      })
    }

  }



  wellStatusValueChange(event: ValueSelect) {

    this.WellTestStatus = event.value;
    if (event.value !== '') {
      if (this.mlRunParams.min_date != undefined && this.mlRunParams.max_date != undefined) {
        if (this.selectedTarget != '' && this.selectedTargetSource != '') {
          this.wellStatus = event.value;
          if (this.addRemovePicker)
            this.sb.loadTrainingVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.selectedTarget, this.wellType, this.selectedTargetSource, this.wellStatus, this.colunmSourceValue, 
          this.mlRunParams.max_date, this.mlRunParams.min_date, 
            (this.countddl>=2 ? this.mlRunParams.max_date1:undefined), (this.countddl>=2 ? this.mlRunParams.min_date1:undefined),
            (this.countddl>=3 ? this.mlRunParams.max_date2:undefined), (this.countddl>=3 ? this.mlRunParams.min_date2:undefined),
            (this.countddl>3 ? this.mlRunParams.max_date3:undefined), (this.countddl >3 ? this.mlRunParams.min_date3:undefined),  
          this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
          else
            this.sb.loadTrainingVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.selectedTarget, this.wellType, this.selectedTargetSource, this.wellStatus, this.colunmSourceValue,
           this.mlRunParams.max_date, this.mlRunParams.min_date, 
           (this.countddl>=2 ? this.mlRunParams.max_date1:undefined), (this.countddl>=2 ? this.mlRunParams.min_date1:undefined),
           (this.countddl>=3 ? this.mlRunParams.max_date2:undefined), (this.countddl>=3 ? this.mlRunParams.min_date2:undefined),
           (this.countddl>3 ? this.mlRunParams.max_date3:undefined), (this.countddl >3 ? this.mlRunParams.min_date3:undefined), 
          this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
        }
      } else {
        if (this.selectedTarget != '' && this.selectedTargetSource != '') {
          this.wellStatus = event.value;
          if (this.addRemovePicker)
            this.sb.loadTrainingVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.selectedTarget, this.wellType, this.selectedTargetSource, this.wellStatus, this.colunmSourceValue, this.filterData.date_max, this.filterData.date_min, 
              (this.countddl>=2 ? this.filterData.date_max1:undefined), (this.countddl>=2 ? this.filterData.date_min1:undefined),
              (this.countddl>=3 ? this.filterData.date_max2:undefined), (this.countddl>=3 ? this.filterData.date_min2:undefined),
              (this.countddl>3 ? this.filterData.date_max3:undefined), (this.countddl >3 ? this.filterData.date_min3:undefined),
          this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
          else
            this.sb.loadTrainingVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.selectedTarget, this.wellType, this.selectedTargetSource, this.wellStatus, this.colunmSourceValue, 
          this.filterData.date_max, this.filterData.date_min,
          (this.countddl>=2 ? this.filterData.date_max1:undefined), (this.countddl>=2 ? this.filterData.date_min1:undefined),
          (this.countddl>=3 ? this.filterData.date_max2:undefined), (this.countddl>=3 ? this.filterData.date_min2:undefined),
          (this.countddl>3 ? this.filterData.date_max3:undefined), (this.countddl >3 ? this.filterData.date_min3:undefined),
           this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);

        }

      }
    }

  }

  ColunmSourceSelectedChange(event: ValueSelect) {
    this.colunmSourceValue = event.value;
    if (event.value !== '') {
      if (this.mlRunParams.min_date != undefined && this.mlRunParams.max_date != undefined) {
        if (this.colunmSourceValue != '' && this.selectedTargetSource != '') {
          this.colunmSourceValue = event.value;
          if (this.addRemovePicker)
            this.sb.loadTrainingVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.selectedTarget, this.wellType, this.selectedTargetSource, this.wellStatus, this.colunmSourceValue, this.mlRunParams.max_date, this.mlRunParams.min_date, 
              (this.countddl>=2 ? this.mlRunParams.max_date1:undefined), (this.countddl>=2 ? this.mlRunParams.min_date1:undefined),
              (this.countddl>=3 ? this.mlRunParams.max_date2:undefined), (this.countddl>=3 ? this.mlRunParams.min_date2:undefined),
              (this.countddl>3 ? this.mlRunParams.max_date3:undefined), (this.countddl >3 ? this.mlRunParams.min_date3:undefined),
          this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
          else
            this.sb.loadTrainingVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.selectedTarget, this.wellType, this.selectedTargetSource, this.wellStatus, this.colunmSourceValue, 
          this.mlRunParams.max_date, this.mlRunParams.min_date, 
          (this.countddl>=2 ? this.mlRunParams.max_date1:undefined), (this.countddl>=2 ? this.mlRunParams.min_date1:undefined),
            (this.countddl>=3 ? this.mlRunParams.max_date2:undefined), (this.countddl>=3 ? this.mlRunParams.min_date2:undefined),
            (this.countddl>3 ? this.mlRunParams.max_date3:undefined), (this.countddl >3 ? this.mlRunParams.min_date3:undefined),
          this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
          this.sb.getTMoreColumnInfo(this.affiliate, this.selectedTarget, this.selectedTargetSource, this.wellType, this.colunmSourceValue).subscribe(res => {
            if (res.response.column_name != '' && res.response.tmore_table_name != '')
              this.tMoreCloumnInfo = res.response.tmore_table_name + "; " + res.response.column_name;
          });
        }
      } else {
        if (this.colunmSourceValue != '' && this.selectedTargetSource != '') {
          this.colunmSourceValue = event.value;
          if (this.addRemovePicker)
            this.sb.loadTrainingVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.selectedTarget, this.wellType, this.selectedTargetSource, this.wellStatus, this.colunmSourceValue, 
          this.filterData.date_max, this.filterData.date_min, 
          (this.countddl>=2 ? this.filterData.date_max1:undefined), (this.countddl>=2 ? this.filterData.date_min1:undefined),
          (this.countddl>=3 ? this.filterData.date_max2:undefined), (this.countddl>=3 ? this.filterData.date_min2:undefined),
          (this.countddl>3 ? this.filterData.date_max3:undefined), (this.countddl >3 ? this.filterData.date_min3:undefined), 
          this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
          else
            this.sb.loadTrainingVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.selectedTarget, this.wellType, this.selectedTargetSource, this.wellStatus, this.colunmSourceValue, 
          this.filterData.date_max, this.filterData.date_min, 
          (this.countddl>=2 ? this.filterData.date_max1:undefined), (this.countddl>=2 ? this.filterData.date_min1:undefined),
        (this.countddl>=3 ? this.filterData.date_max2:undefined), (this.countddl>=3 ? this.filterData.date_min2:undefined),
        (this.countddl>3 ? this.filterData.date_max3:undefined), (this.countddl >3 ? this.filterData.date_min3:undefined), 
          this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
          this.sb.getTMoreColumnInfo(this.affiliate, this.selectedTarget, this.selectedTargetSource, this.wellType, this.colunmSourceValue).subscribe(res => {
            if (res.response.column_name != '' && res.response.tmore_table_name != '')
              this.tMoreCloumnInfo = res.response.tmore_table_name + "; " + res.response.column_name;
          });
        }

      }
    }


  }

  valueSelectedSourceChange(event: ValueSelect) {

    if (event.value !== '') {
      this.sb.loadColunmSource(this.affiliate, this.selectedTarget, this.selectedTargetSource, this.wellType).subscribe((res: any) => {

        this.columSourceList = res;
      });
    }
  }

 onTableValueChange(event: ValueSelect) {

    if (event.value !== '') {
      this.tmoreTableName=event.value;
      let max_date: string;
      
      max_date = moment(this.filterData.date_max).format(this.format);
      let min_date = moment(this.filterData.date_min).format(this.format);
      console.log(max_date);
      
      this.sb.loadFeatureList(this.affiliate, event.value, this.selectedWell.field, this.selectedWell.asset,  max_date,  min_date, this.selectedWell.well).subscribe((res: any) => {
       // res.column_name_list.forEach((value: any) =>{
         this.featuresList=res.column_name_list;
         this.percentageList = res.column_name_with_percentage;
       // });

      });
    }
  }

  onWellFetch(): void {
    this.isModelName = false;
    this.machineLearningGraph.forEach(plotData => {
      plotData.x = undefined;
      plotData.y = undefined;
      plotData.yaxis = "";
    })


    this.mlLearningCurvesGraph.forEach(plotData => {
      plotData.x = undefined;
      plotData.y = undefined;
    })

    if (this.mlConfidenceData != undefined) {
      this.mlConfidenceData.metrics = undefined;
    }

    this.mlMetricsGraph = [];

    this.mlMetricsDispersionGraph.forEach((PlotData) => {
      PlotData.x = undefined;
      PlotData.y = undefined;
    })

    if (this.mlMetricsDispersionLayout.yaxis && this.mlMetricsDispersionLayout.xaxis) {
      this.mlMetricsDispersionLayout.yaxis.title = `Prediction value (- %)`;
      this.mlMetricsDispersionLayout.yaxis.range = [];
      this.mlMetricsDispersionLayout.xaxis.range = [];
    }
    if (this.mlLearningCurvesLayout.yaxis && this.mlLearningCurvesLayout.xaxis) {
      this.mlLearningCurvesLayout.yaxis.title = `MAE`;
      this.mlLearningCurvesLayout.yaxis.range = [];
      this.mlLearningCurvesLayout.xaxis.range = [];
    }

      this.mlCorelationGraph[0].x = undefined;
      this.mlCorelationGraph[0].y = undefined;
      this.mlCorelationGraph[0].z = undefined;

      this.mlCorelationLayout={};
      this.selectedUserAdded = [];

    if (this.mlFeaturesImportanceLayout.yaxis && this.mlFeaturesImportanceLayout.xaxis) {
      this.mlFeaturesImportanceLayout.yaxis.range = []
      this.mlFeaturesImportanceLayout.xaxis.range = []
      this.mlFeaturesImportanceGraph[0].x = [];
      this.mlFeaturesImportanceGraph[0].y = [];
    }

    this.mlMetricsBoxGraph.forEach((PlotData) => {
      PlotData.x = undefined;
      PlotData.y = undefined;
      PlotData.yaxis = "";
    })
    this.mlFeaturesGraph = [];
    this.mlFeaturesLayout = [];
    this.mlFeatureGraphTitle = [];
    this.mlFeatureXtitle = [];



    this.monitoringService.logEvent('wellFetch', this.filterData);
    if (this.isInjector === true && this.isProducer === false) {
      this.onlyInjector = true;
      this.wellType = "injector"
      this.sb.loadFieldRangeDatesInjector(this.affiliate, this.filterData, 'injector');
    } else {
      this.onlyInjector = false;
      this.wellType = "producer"
      this.sb.loadFieldRangeDates(this.affiliate, this.filterData);
    }

    this.userName$.subscribe(userName => {
      this.userName = userName;
    })
    this.isWellSelected = true;
    if (this.isShowMyModelsOnly)
      this.sb.loadSavedModelTableData(this.affiliate, this.selectedWell.field, this.selectedWell.asset, this.selectedWell.well, this.userName);
    else
      this.sb.loadSavedModelTableData(this.affiliate, this.selectedWell.field, this.selectedWell.asset, this.selectedWell.well);

    this.clearMLSettings();


  }


  async onWellFetchCatalog() {

    const wellData = await this.FetchWellData();
    setTimeout(() => {
      this.onClickSavedModelItemCatalog(this.passedData);
    }, 9000);

  }
  async FetchWellData() {
    this.monitoringService.logEvent('wellFetch', this.filterData);
    if (this.isInjector === true && this.isProducer === false) {
      this.onlyInjector = true;
      this.wellType = "injector"
      this.sb.loadFieldRangeDatesInjector(this.affiliate, this.filterData, 'injector');
    } else {
      this.onlyInjector = false;
      this.wellType = "producer"
      this.sb.loadFieldRangeDates(this.affiliate, this.filterData);
    }

    this.userName$.subscribe(userName => {
      this.userName = userName;
    })
    this.isWellSelected = true;
    if (this.isShowMyModelsOnly)
      this.sb.loadSavedModelTableData(this.affiliate, this.selectedWell.field, this.selectedWell.asset, this.selectedWell.well, this.userName);
    else
      this.sb.loadSavedModelTableData(this.affiliate, this.selectedWell.field, this.selectedWell.asset, this.selectedWell.well);

  }

  onDateChange(dateRange: FieldRangeDates): void {

    this.filterData = {
      ...this.filterData,
      date_min: new Date(dateRange.startDate),
      date_max: new Date(dateRange.endDate)
    };

    this.sb.setFieldRangeDates(dateRange);

    if (this.selectedInputSource != '' && this.selectedFeatureSource != '') {
      if (this.addRemovePicker)
        this.sb.loadFeaturesVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.wellType, this.selectedInputSource, this.selectedFeatureSource, this.selectedUserAdded,this.tmoreTableName, 
      this.mlRunParams.max_date, this.mlRunParams.min_date, 
      (this.countddl>=2 ? this.mlRunParams.max_date1:undefined), (this.countddl>=2 ? this.mlRunParams.min_date1:undefined),
      (this.countddl>=3 ? this.mlRunParams.max_date2:undefined), (this.countddl>=3 ? this.mlRunParams.min_date2:undefined),
      (this.countddl>3 ? this.mlRunParams.max_date3:undefined), (this.countddl >3 ? this.mlRunParams.min_date3:undefined),  
      this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
      else
        this.sb.loadFeaturesVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.wellType, this.selectedInputSource, this.selectedFeatureSource, this.selectedUserAdded,this.tmoreTableName, 
      this.mlRunParams.max_date, this.mlRunParams.min_date, 
      (this.countddl>=2 ? this.mlRunParams.max_date1:undefined), (this.countddl>=2 ? this.mlRunParams.min_date1:undefined),
        (this.countddl>=3 ? this.mlRunParams.max_date2:undefined), (this.countddl>=3 ? this.mlRunParams.min_date2:undefined),
        (this.countddl>3 ? this.mlRunParams.max_date3:undefined), (this.countddl >3 ? this.mlRunParams.min_date3:undefined), 
      this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
    }
    if (this.selectedTarget != '' && this.selectedTargetSource != '')
      if (this.addRemovePicker)
        this.sb.loadTrainingVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.selectedTarget, this.wellType, this.selectedTargetSource, this.wellStatus, this.colunmSourceValue, 
      this.mlRunParams.max_date, this.mlRunParams.min_date, 
      (this.countddl>=2 ? this.mlRunParams.max_date1:undefined), (this.countddl>=2 ? this.mlRunParams.min_date1:undefined),
      (this.countddl>=3 ? this.mlRunParams.max_date2:undefined), (this.countddl>=3 ? this.mlRunParams.min_date2:undefined),
      (this.countddl>3 ? this.mlRunParams.max_date3:undefined), (this.countddl >3 ? this.mlRunParams.min_date3:undefined),   
      this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
      else
        this.sb.loadTrainingVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.selectedTarget, this.wellType, this.selectedTargetSource, this.wellStatus, this.colunmSourceValue,
       this.mlRunParams.max_date, this.mlRunParams.min_date, 
       (this.countddl>=2 ? this.mlRunParams.max_date1:undefined), (this.countddl>=2 ? this.mlRunParams.min_date1:undefined),
       (this.countddl>=3 ? this.mlRunParams.max_date2:undefined), (this.countddl>=3 ? this.mlRunParams.min_date2:undefined),
       (this.countddl>3 ? this.mlRunParams.max_date3:undefined), (this.countddl >3 ? this.mlRunParams.min_date3:undefined), 
      this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
  }
  onDateChange1(dateRange: FieldRangeDates): void {
    
    this.filterData = {
      ...this.filterData,
      date_min1: new Date(dateRange.startDate),
      date_max1: new Date(dateRange.endDate)
    };
    this.sb.setFieldRangeDates1(dateRange);

  

    if (this.selectedInputSource != '' && this.selectedFeatureSource != '') {
      
      if (this.addRemovePicker) {
        if (this.mlRunParams.max_date1 != undefined) { this.mlRunParams.max_date1 = this.filterData.date_max1 };
        if (this.mlRunParams.min_date1 != undefined) { this.mlRunParams.min_date1 = this.filterData.date_min1 };
      }

      if (this.addRemovePicker)
        this.sb.loadFeaturesVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.wellType, this.selectedInputSource, this.selectedFeatureSource, this.selectedUserAdded,this.tmoreTableName,
       this.mlRunParams.max_date, this.mlRunParams.min_date, 
       this.filterData.date_max1, this.filterData.date_min1, 
     (this.countddl>=3 ? (this.mlRunParams.max_date2!=undefined?this.mlRunParams.max_date2:this.filterData.date_max2):undefined), 
     (this.countddl>=3 ? (this.mlRunParams.min_date2!=undefined?this.mlRunParams.min_date2:this.filterData.date_min2):undefined),
     (this.countddl>3 ? (this.mlRunParams.max_date3!=undefined?this.mlRunParams.max_date3:this.filterData.date_max3):undefined),
      (this.countddl >3 ? (this.mlRunParams.min_date3!=undefined?this.mlRunParams.min_date3:this.filterData.date_min3):undefined), 
      this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
      else
        this.sb.loadFeaturesVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.wellType, this.selectedInputSource, this.selectedFeatureSource, this.selectedUserAdded,this.tmoreTableName, 
      this.mlRunParams.max_date, this.mlRunParams.min_date, 
      (this.countddl>=2 ? (this.mlRunParams.max_date1!=undefined?this.mlRunParams.max_date1:this.filterData.date_max1):undefined),
       (this.countddl>=2 ? (this.mlRunParams.min_date1!=undefined?this.mlRunParams.min_date1:this.filterData.date_min1):undefined),
       (this.countddl>=3 ? (this.mlRunParams.max_date2!=undefined?this.mlRunParams.max_date2:this.filterData.date_max2):undefined), 
     (this.countddl>=3 ? (this.mlRunParams.min_date2!=undefined?this.mlRunParams.min_date2:this.filterData.date_min2):undefined),
     (this.countddl>3 ? (this.mlRunParams.max_date3!=undefined?this.mlRunParams.max_date3:this.filterData.date_max3):undefined),
      (this.countddl >3 ? (this.mlRunParams.min_date3!=undefined?this.mlRunParams.min_date3:this.filterData.date_min3):undefined),
      this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
    }
    if (this.selectedTarget != '' && this.selectedTargetSource != '')
    {
      if (this.addRemovePicker) {
        if (this.mlRunParams.max_date1 != undefined) { this.mlRunParams.max_date1 = this.filterData.date_max1 };
        if (this.mlRunParams.min_date1 != undefined) { this.mlRunParams.min_date1 = this.filterData.date_min1 };
      }
      if (this.addRemovePicker)
       
        this.sb.loadTrainingVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.selectedTarget, this.wellType, this.selectedTargetSource, this.wellStatus, this.colunmSourceValue, 
          this.mlRunParams.max_date, this.mlRunParams.min_date, 
          this.filterData.date_max1, this.filterData.date_min1, 
          (this.countddl>=3 ? (this.mlRunParams.max_date2!=undefined?this.mlRunParams.max_date2:this.filterData.date_max2):undefined), 
          (this.countddl>=3 ? (this.mlRunParams.min_date2!=undefined?this.mlRunParams.min_date2:this.filterData.date_min2):undefined),
          (this.countddl>3 ? (this.mlRunParams.max_date3!=undefined?this.mlRunParams.max_date3:this.filterData.date_max3):undefined),
           (this.countddl >3 ? (this.mlRunParams.min_date3!=undefined?this.mlRunParams.min_date3:this.filterData.date_min3):undefined), 
      this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
      else
        this.sb.loadTrainingVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.selectedTarget, this.wellType, this.selectedTargetSource, this.wellStatus, this.colunmSourceValue,
       this.mlRunParams.max_date, this.mlRunParams.min_date, 
       (this.countddl>=2 ? (this.mlRunParams.max_date1!=undefined?this.mlRunParams.max_date1:this.filterData.date_max1):undefined),
       (this.countddl>=2 ? (this.mlRunParams.min_date1!=undefined?this.mlRunParams.min_date1:this.filterData.date_min1):undefined),
       (this.countddl>=3 ? (this.mlRunParams.max_date2!=undefined?this.mlRunParams.max_date2:this.filterData.date_max2):undefined), 
     (this.countddl>=3 ? (this.mlRunParams.min_date2!=undefined?this.mlRunParams.min_date2:this.filterData.date_min2):undefined),
     (this.countddl>3 ? (this.mlRunParams.max_date3!=undefined?this.mlRunParams.max_date3:this.filterData.date_max3):undefined),
      (this.countddl >3 ? (this.mlRunParams.min_date3!=undefined?this.mlRunParams.min_date3:this.filterData.date_min3):undefined),
      this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);

        }
  }


  onDateChange2(dateRange: FieldRangeDates): void {
    this.filterData = {
      ...this.filterData,
      date_min2: new Date(dateRange.startDate),
      date_max2: new Date(dateRange.endDate)
    };
    this.sb.setFieldRangeDates2(dateRange);

    if (this.selectedInputSource != '' && this.selectedFeatureSource != '') {

      if (this.addRemovePicker) {
        if (this.mlRunParams.max_date2 != undefined) { this.mlRunParams.max_date2 = this.filterData.date_max2 };
        if (this.mlRunParams.min_date2 != undefined) { this.mlRunParams.min_date2 = this.filterData.date_min2 };
      }

      if (this.addRemovePicker)
         this.sb.loadFeaturesVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.wellType, this.selectedInputSource, this.selectedFeatureSource, this.selectedUserAdded,this.tmoreTableName, 
          this.mlRunParams.max_date, this.mlRunParams.min_date, 
        (this.mlRunParams.max_date1!=undefined?this.mlRunParams.max_date1:this.filterData.date_max1), 
        (this.mlRunParams.min_date1!=undefined?this.mlRunParams.min_date1:this.filterData.date_min1),
          this.filterData.date_max2, this.filterData.date_min2, 
      (this.countddl>3 ? this.mlRunParams.max_date3:undefined), (this.countddl >3 ? this.mlRunParams.min_date3:undefined),  
          this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
      else
        this.sb.loadFeaturesVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.wellType, this.selectedInputSource, this.selectedFeatureSource, this.selectedUserAdded,this.tmoreTableName, 
          this.mlRunParams.max_date, this.mlRunParams.min_date, 
          (this.mlRunParams.max_date1!=undefined?this.mlRunParams.max_date1:this.filterData.date_max1), 
          (this.mlRunParams.min_date1!=undefined?this.mlRunParams.min_date1:this.filterData.date_min1),
      (this.countddl>=3 ? this.mlRunParams.max_date2:undefined), (this.countddl>=3 ? this.mlRunParams.min_date2:undefined),
      (this.countddl>3 ? this.mlRunParams.max_date3:undefined), (this.countddl >3 ? this.mlRunParams.min_date3:undefined), 
      this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
    }
    if (this.selectedTarget != '' && this.selectedTargetSource != '')
    {
      if (this.addRemovePicker) {
        if (this.mlRunParams.max_date2 != undefined) { this.mlRunParams.max_date2 = this.filterData.date_max2 };
        if (this.mlRunParams.min_date2 != undefined) { this.mlRunParams.min_date2 = this.filterData.date_min2 };
      }

      if (this.addRemovePicker)
        this.sb.loadTrainingVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.selectedTarget, this.wellType, this.selectedTargetSource, this.wellStatus, this.colunmSourceValue, 
          this.mlRunParams.max_date, this.mlRunParams.min_date, 
          (this.mlRunParams.max_date1!=undefined?this.mlRunParams.max_date1:this.filterData.date_max1), 
          (this.mlRunParams.min_date1!=undefined?this.mlRunParams.min_date1:this.filterData.date_min1),
          this.filterData.date_max2, this.filterData.date_min2, 
          (this.countddl>3 ? this.mlRunParams.max_date3:undefined), (this.countddl >3 ? this.mlRunParams.min_date3:undefined),  
      this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
      else
        this.sb.loadTrainingVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.selectedTarget, this.wellType, this.selectedTargetSource, this.wellStatus, this.colunmSourceValue, 
          this.mlRunParams.max_date, this.mlRunParams.min_date, 
          (this.mlRunParams.max_date1!=undefined?this.mlRunParams.max_date1:this.filterData.date_max1), 
          (this.mlRunParams.min_date1!=undefined?this.mlRunParams.min_date1:this.filterData.date_min1),
      (this.countddl>=3 ? this.mlRunParams.max_date2:undefined), (this.countddl>=3 ? this.mlRunParams.min_date2:undefined),
      (this.countddl>3 ? this.mlRunParams.max_date3:undefined), (this.countddl >3 ? this.mlRunParams.min_date3:undefined), 
      this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
        }

  }
  onDateChange3(dateRange: FieldRangeDates): void {
    this.filterData = {
      ...this.filterData,
      date_min3: new Date(dateRange.startDate),
      date_max3: new Date(dateRange.endDate)
    };
    this.sb.setFieldRangeDates3(dateRange);

    if (this.selectedInputSource != '' && this.selectedFeatureSource != '') {
      if (this.addRemovePicker)
        this.sb.loadFeaturesVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.wellType, this.selectedInputSource, this.selectedFeatureSource, this.selectedUserAdded,this.tmoreTableName, 
          this.mlRunParams.max_date, this.mlRunParams.min_date, 
          (this.mlRunParams.max_date1!=undefined?this.mlRunParams.max_date1:this.filterData.date_max1), 
          (this.mlRunParams.min_date1!=undefined?this.mlRunParams.min_date1:this.filterData.date_min1), 
          (this.mlRunParams.max_date2!=undefined?this.mlRunParams.max_date2:this.filterData.date_max2), 
          (this.mlRunParams.min_date2!=undefined?this.mlRunParams.min_date2:this.filterData.date_min2),
          this.filterData.date_max3, this.filterData.date_min3, 
          this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
      else
        this.sb.loadFeaturesVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.wellType, this.selectedInputSource, this.selectedFeatureSource, this.selectedUserAdded,this.tmoreTableName, 
      this.mlRunParams.max_date, this.mlRunParams.min_date, 
      (this.mlRunParams.max_date1!=undefined?this.mlRunParams.max_date1:this.filterData.date_max1), 
      (this.mlRunParams.min_date1!=undefined?this.mlRunParams.min_date1:this.filterData.date_min1), 
      (this.mlRunParams.max_date2!=undefined?this.mlRunParams.max_date2:this.filterData.date_max2), 
      (this.mlRunParams.min_date2!=undefined?this.mlRunParams.min_date2:this.filterData.date_min2),
      (this.countddl>3 ? this.mlRunParams.max_date3:undefined), (this.countddl >3 ? this.mlRunParams.min_date3:undefined), 
      this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
    }
    if (this.selectedTarget != '' && this.selectedTargetSource != '')
      if (this.addRemovePicker)
        this.sb.loadTrainingVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.selectedTarget, this.wellType, this.selectedTargetSource, this.wellStatus, this.colunmSourceValue, 
         this.mlRunParams.max_date, this.mlRunParams.min_date, 
         (this.mlRunParams.max_date1!=undefined?this.mlRunParams.max_date1:this.filterData.date_max1), 
          (this.mlRunParams.min_date1!=undefined?this.mlRunParams.min_date1:this.filterData.date_min1), 
          (this.mlRunParams.max_date2!=undefined?this.mlRunParams.max_date2:this.filterData.date_max2), 
          (this.mlRunParams.min_date2!=undefined?this.mlRunParams.min_date2:this.filterData.date_min2),
          this.filterData.date_max3, this.filterData.date_min3, 
      this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
      else
        this.sb.loadTrainingVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.selectedTarget, this.wellType, this.selectedTargetSource, this.wellStatus, this.colunmSourceValue, 
      this.mlRunParams.max_date, this.mlRunParams.min_date, 
      (this.mlRunParams.max_date1!=undefined?this.mlRunParams.max_date1:this.filterData.date_max1), 
      (this.mlRunParams.min_date1!=undefined?this.mlRunParams.min_date1:this.filterData.date_min1), 
      (this.mlRunParams.max_date2!=undefined?this.mlRunParams.max_date2:this.filterData.date_max2), 
      (this.mlRunParams.min_date2!=undefined?this.mlRunParams.min_date2:this.filterData.date_min2),
      (this.countddl>3 ? this.mlRunParams.max_date3:undefined), (this.countddl >3 ? this.mlRunParams.min_date3:undefined), 
      this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);


  }

  setNumber_TrainingPeriodDropdown(count:number):void
  {
    this.countddl=count;
  }

  getNumber_TrainingPeriodDropdown():number
  {
    return this.countddl;
  }

  onQcDateChange(dateRange: FieldRangeDates): void {
    this.sb.setQcRangeDates(dateRange);
  }
  onInferenceDateChange(dateRange: FieldRangeDates): void {

    this.sb.setInferenceRangeDates(dateRange);

    if (this.selectedInputSource != '' && this.selectedFeatureSource != '') {
      if (this.addRemovePicker)
        this.sb.loadFeaturesVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.wellType, this.selectedInputSource, this.selectedFeatureSource, this.selectedUserAdded,this.tmoreTableName, this.filterData.date_max, this.filterData.date_min, 
          (this.countddl>=2 ? this.filterData.date_max1:undefined), (this.countddl>=2 ? this.filterData.date_min1:undefined),
          (this.countddl>=3 ? this.filterData.date_max2:undefined), (this.countddl>=3 ? this.filterData.date_min2:undefined),
          (this.countddl>3 ? this.filterData.date_max3:undefined), (this.countddl >3 ? this.filterData.date_min3:undefined),
      this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
      else
        this.sb.loadFeaturesVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.wellType, this.selectedInputSource, this.selectedFeatureSource, this.selectedUserAdded, this.tmoreTableName,
      this.filterData.date_max, this.filterData.date_min,
      (this.countddl>=2 ? this.filterData.date_max1:undefined), (this.countddl>=2 ? this.filterData.date_min1:undefined),
      (this.countddl>=3 ? this.filterData.date_max2:undefined), (this.countddl>=3 ? this.filterData.date_min2:undefined),
      (this.countddl>3 ? this.filterData.date_max3:undefined), (this.countddl >3 ? this.filterData.date_min3:undefined),
       this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
    }
    if (this.selectedTarget != '' && this.selectedTargetSource != '')
      if (this.addRemovePicker)
        this.sb.loadTrainingVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.selectedTarget, this.wellType, this.selectedTargetSource, this.wellStatus, this.colunmSourceValue, this.filterData.date_max, this.filterData.date_min,
          (this.countddl>=2 ? this.filterData.date_max1:undefined), (this.countddl>=2 ? this.filterData.date_min1:undefined),
          (this.countddl>=3 ? this.filterData.date_max2:undefined), (this.countddl>=3 ? this.filterData.date_min2:undefined),
          (this.countddl>3 ? this.filterData.date_max3:undefined), (this.countddl >3 ? this.filterData.date_min3:undefined),
        this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
      else
        this.sb.loadTrainingVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.selectedTarget, this.wellType, this.selectedTargetSource, this.wellStatus, this.colunmSourceValue, 
      this.filterData.date_max, this.filterData.date_min, 
      (this.countddl>=2 ? this.filterData.date_max1:undefined), (this.countddl>=2 ? this.filterData.date_min1:undefined),
      (this.countddl>=3 ? this.filterData.date_max2:undefined), (this.countddl>=3 ? this.filterData.date_min2:undefined),
      (this.countddl>3 ? this.filterData.date_max3:undefined), (this.countddl >3 ? this.filterData.date_min3:undefined),
      this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
  }

  onClickRunML(mlRunParams: MLRunParams): void {

    if (this.wellType === 'producer') {
      this.child.clearCheck();
    }
    if (this.wellType === 'injector') {
      this.childInjector.clearCheckInjector();
    }
    let target_source: string;
    let well_type: string;


    if ((this.filterData.date_min && this.filterData.date_max) && this.filterData.date_min1 && this.filterData.date_max1) {
      if (this.isSavedItemclick === true) {

        if (this.addRemovePicker) {
          mlRunParams = {
            ...mlRunParams,
            min_date: this.mlRunParams.min_date,
            max_date: this.mlRunParams.max_date,
            min_date1: (this.countddl>=2 ? this.mlRunParams.min_date1:undefined),
            max_date1: (this.countddl>=2 ? this.mlRunParams.max_date1: undefined),
            min_date2: (this.countddl>=3 ? this.mlRunParams.min_date2:undefined),
            max_date2: (this.countddl>=3 ? this.mlRunParams.max_date2:undefined),
            min_date3: (this.countddl>3 ? this.mlRunParams.min_date3:undefined),
            max_date3: (this.countddl>3 ? this.mlRunParams.max_date3:undefined),
            inference_min_date: this.mlRunParams.inference_min_date,
            inference_max_date: this.mlRunParams.inference_max_date,
            log_in_mlflow: 'false',
            well_type: this.mlRunParams.well_type
          };
        }
        else {
          mlRunParams = {
            ...mlRunParams,
            min_date: this.mlRunParams.min_date,
            max_date: this.mlRunParams.max_date,
            min_date1: (this.countddl>=2 ? this.mlRunParams.min_date1:undefined),
            max_date1: (this.countddl>=2 ? this.mlRunParams.max_date1: undefined),
            min_date2: (this.countddl>=3 ? this.mlRunParams.min_date2:undefined),
            max_date2: (this.countddl>=3 ? this.mlRunParams.max_date2:undefined),
            min_date3: (this.countddl>3 ? this.mlRunParams.min_date3:undefined),
            max_date3: (this.countddl>3 ? this.mlRunParams.max_date3:undefined),
            inference_min_date: this.mlRunParams.inference_min_date,
            inference_max_date: this.mlRunParams.inference_max_date,
            log_in_mlflow: 'false',
            well_type: this.mlRunParams.well_type
          };
        }
      } else {
        if (this.addRemovePicker) {
          mlRunParams = {
            ...mlRunParams,
            min_date: this.filterData.date_min,
            max_date: this.filterData.date_max,
            min_date1: (this.countddl>=2 ? this.filterData.date_min1:undefined),
            max_date1: (this.countddl>=2 ? this.filterData.date_max1: undefined),
            min_date2: (this.countddl>=3 ? this.filterData.date_min2:undefined),
            max_date2: (this.countddl>=3 ? this.filterData.date_max2:undefined),
            min_date3: (this.countddl>3 ? this.filterData.date_min3:undefined),
            max_date3: (this.countddl>3 ? this.filterData.date_max3:undefined),
            inference_min_date: this.mlRunParams.inference_min_date,
            inference_max_date: this.mlRunParams.inference_max_date,
            log_in_mlflow: 'false',
            well_type: this.wellType,
            target_source: this.selectedTargetSource

          };
        } else {
          mlRunParams = {
            ...mlRunParams,
            min_date: this.filterData.date_min,
            max_date: this.filterData.date_max,
            min_date1: (this.countddl>=2 ? this.filterData.date_min1:undefined),
            max_date1: (this.countddl>=2 ? this.filterData.date_max1: undefined),
            min_date2: (this.countddl>=3 ? this.filterData.date_min2:undefined),
            max_date2: (this.countddl>=3 ? this.filterData.date_max2:undefined),
            min_date3: (this.countddl>3 ? this.filterData.date_min3:undefined),
            max_date3: (this.countddl>3 ? this.filterData.date_max3:undefined),
            inference_min_date: this.mlRunParams.inference_min_date,
            inference_max_date: this.mlRunParams.inference_max_date,
            log_in_mlflow: 'false',
            well_type: this.wellType,
            target_source: this.selectedTargetSource

          };
        }

      }

    }
mlRunParams.featureVisualizationKey = this.featuresVisualizationRedisKey;
mlRunParams.wellTestVisualizationKey = this.wellTestVisualizationKey;

    this.sb.loadMLResult(this.affiliate, this.selectedWell, mlRunParams, this.flagStatus);
  }

  onClickCheckbox(event: any): void {
    let myAttrArray = event.attribute.split("-", 1);

    if (event.checked) {
      const inferenceDataFilter: DataFiltersInference = {
        asset: this.filterData.asset,
        field: this.filterData.field,
        well: this.filterData.well
      };
      if (this.isSavedItemclick) {
        inferenceDataFilter.date_min = new Date(this.mlRunParams.inference_min_date)
        inferenceDataFilter.date_max = new Date(this.mlRunParams.inference_max_date)
      }
      else {
        inferenceDataFilter.date_max = this.mlRunParams.inference_max_date;
        inferenceDataFilter.date_min = this.mlRunParams.inference_min_date;
      }

      this.sb.loadDataForMLGraph(this.affiliate, myAttrArray, inferenceDataFilter, event.attributeType).subscribe(response => {
        if (BBL_UNIT.includes(event.attribute)) {
          const tempValue = response.response.values;
          const tempYValue = tempValue.map((d: any) => {
            return d * M3_TO_BBL;
          })

          if (event.attribute.includes("test")) {

            if((event.attribute.includes("Qoil") && this.selectedTarget=="Qoil" || this.selectedTarget=="Qliq" || this.selectedTarget=="Qwat")||(event.attribute.includes("Qliq") && this.selectedTarget=="Qliq" || this.selectedTarget=="Qoil" || this.selectedTarget=="Qwat")||(event.attribute.includes("Qwat") && this.selectedTarget=="Qwat" || this.selectedTarget=="Qoil" || this.selectedTarget=="Qliq")){

              const plot: any = {
              name: event.attribute,
              type: "scatter",
              x: response.response.dates,
              y: tempYValue,
              mode: 'markers',
              line: { dash: mlPlotColorMap.get(event.attribute)?.line },
              marker: { color: mlPlotColorMap.get(event.attribute)?.color },
              yaxis: "y1",

            };
            this.machineLearningGraph.push(plot);
          }
          else{

            const plot: any = {
              name: event.attribute,
              type: "scatter",
              x: response.response.dates,
              y: tempYValue,
              mode: 'markers',
              line: { dash: mlPlotColorMap.get(event.attribute)?.line },
              marker: { color: mlPlotColorMap.get(event.attribute)?.color },
              yaxis: mlPlotColorMap.get(event.attribute)?.yaxis,

            };
            this.machineLearningGraph.push(plot);
          }
          }
          else {
            if((event.attribute.includes("Qoil") && this.selectedTarget=="Qoil" || this.selectedTarget=="Qliq" || this.selectedTarget=="Qwat")||(event.attribute.includes("Qliq") && this.selectedTarget=="Qliq" || this.selectedTarget=="Qoil" || this.selectedTarget=="Qwat")||(event.attribute.includes("Qwat") && this.selectedTarget=="Qwat" || this.selectedTarget=="Qoil" || this.selectedTarget=="Qliq")){

              const plot: any = {
              name: event.attribute,
              type: "scatter",
              x: response.response.dates,
              y: tempYValue,
              mode: 'lines',
              line: { dash: mlPlotColorMap.get(event.attribute)?.line },
              marker: { color: mlPlotColorMap.get(event.attribute)?.color },
              yaxis: "y1",

            };
            this.machineLearningGraph.push(plot);
          }
          else{

            const plot: any = {
              name: event.attribute,
              type: "scatter",
              x: response.response.dates,
              y: tempYValue,
              mode: 'lines',
              line: { dash: mlPlotColorMap.get(event.attribute)?.line },
              marker: { color: mlPlotColorMap.get(event.attribute)?.color },
              yaxis: mlPlotColorMap.get(event.attribute)?.yaxis,

            };
            this.machineLearningGraph.push(plot);
          }
          }
        }

        else {
          if (event.attribute.includes("test")) {
          if((this.selectedTarget=="Qgas" && event.attribute.includes("Qgas") || event.attribute.includes("Qgl"))||(event.attribute.includes("GOR") && this.selectedTarget=="GOR" || this.selectedTarget=="GLR")||(event.attribute.includes("GLR") && this.selectedTarget=="GLR" || this.selectedTarget=="GOR")||(event.attribute.includes("WCT") && this.selectedTarget=="WCT"))
            {
            const plot: any = {
              name: event.attribute,
              type: "scatter",
              x: response.response.dates,
              y: response.response.values,
              mode: 'markers',
              line: { dash: mlPlotColorMap.get(event.attribute)?.line },
              marker: { color: mlPlotColorMap.get(event.attribute)?.color },
              yaxis: "y1",

            };
            this.machineLearningGraph.push(plot);
          }

          else{
            const plot: any = {
              name: event.attribute,
              type: "scatter",
              x: response.response.dates,
              y: response.response.values,
              mode: 'markers',
              line: { dash: mlPlotColorMap.get(event.attribute)?.line },
              marker: { color: mlPlotColorMap.get(event.attribute)?.color },
              yaxis: mlPlotColorMap.get(event.attribute)?.yaxis,

            };


            this.machineLearningGraph.push(plot);
          }

          }
          else {
          if((this.selectedTarget=="Qgas" && event.attribute.includes("Qgas") || event.attribute.includes("Qgl"))||(event.attribute.includes("GOR") && this.selectedTarget=="GOR" || this.selectedTarget=="GLR")||(event.attribute.includes("GLR") && this.selectedTarget=="GLR" || this.selectedTarget=="GOR")||(event.attribute.includes("WCT") && this.selectedTarget=="WCT"))

          {

            const plot: any = {
              name: event.attribute,
              type: "scatter",
              x: response.response.dates,
              y: response.response.values,
              mode: 'line',
              line: { dash: mlPlotColorMap.get(event.attribute)?.line },
              marker: { color: mlPlotColorMap.get(event.attribute)?.color },
              yaxis: "y1",

            };

            this.machineLearningGraph.push(plot);
           }
          else {

            const plot: any = {
              name: event.attribute,
              type: "scatter",
              x: response.response.dates,
              y: response.response.values,
              mode: 'line',
              line: { dash: mlPlotColorMap.get(event.attribute)?.line },
              marker: { color: mlPlotColorMap.get(event.attribute)?.color },
              yaxis: mlPlotColorMap.get(event.attribute)?.yaxis,

            };
            this.machineLearningGraph.push(plot);
           }
          }
        }
      })
    }
    else
      this.removeAttributeFromGraph(event);

  }

  clickInjectorCheckbox(event: any): void {
    let myAttrArrayInj = event.attribute.split("-", 1);
    if (event.checked) {
      const inferenceDataFilter: DataFiltersInference = {
        asset: this.filterData.asset,
        field: this.filterData.field,
        well: this.filterData.well
      };
      if (this.isSavedItemclick) {
        inferenceDataFilter.date_min = new Date(this.mlRunParams.inference_min_date)
        inferenceDataFilter.date_max = new Date(this.mlRunParams.inference_max_date)
      }
      else {
        inferenceDataFilter.date_max = this.mlRunParams.inference_max_date;
        inferenceDataFilter.date_min = this.mlRunParams.inference_min_date;
      }

      this.sb.loadDataForMLGraphInjector(this.affiliate, myAttrArrayInj, inferenceDataFilter, event.attributeTypeInjector).subscribe(response => {
      this.AtrributeArray = ['PI_II-(Sm3/d/bar)', 'BHP_Gauge-(bar)', 'BHFP_Z_Gauge-(bar)', 'BHP_Datum_Manual-(Sm3/Sm3)', 'winj_FallOff-(bbl/d)'];
        if (BBL_UNIT.includes(event.attribute)) {
          const tempValue = response.response.values;
          const tempYValue = tempValue.map((d: any) => {
            return d * M3_TO_BBL;
          })


          if (this.AtrributeArray.includes(event.attribute)) {


            if(this.AtrributeArray.includes(event.attribute) && this.selectedTarget=="Qwinj" && event.attribute.includes("Winj") || event.attribute.includes("Qwinj"))
            {

             const plot: any = {
              name: event.attribute,
              type: "scatter",
              x: response.response.dates,
              y: tempYValue,
              mode: 'markers',
              line: { dash: mlPlotColorMap.get(event.attribute)?.line },
              marker: { color: mlPlotColorMap.get(event.attribute)?.color },
              yaxis: "y1"
            };
            this.machineLearningGraph.push(plot);
          }

          else{

            const plot: any = {
              name: event.attribute,
              type: "scatter",
              x: response.response.dates,
              y: tempYValue,
              mode: 'markers',
              line: { dash: mlPlotColorMap.get(event.attribute)?.line },
              marker: { color: mlPlotColorMap.get(event.attribute)?.color },
              yaxis: mlPlotColorMap.get(event.attribute)?.yaxis
            };
            this.machineLearningGraph.push(plot);
          }
        }
          else {

            if(this.selectedTarget=="Qwinj" && event.attribute.includes("Winj") || event.attribute.includes("Qwinj"))
          {

            const plot: any = {
              name: event.attribute,
              type: "scatter",
              x: response.response.dates,
              y: tempYValue,
              mode: 'line',
              line: { dash: mlPlotColorMap.get(event.attribute)?.line },
              marker: { color: mlPlotColorMap.get(event.attribute)?.color },
              yaxis: "y1"
            };
            this.machineLearningGraph.push(plot);
          }
          else if(this.selectedTarget=="Qginj" && event.attribute.includes("Ginj") || event.attribute.includes("Qginj"))
            {

            const plot: any = {
              name: event.attribute,
              type: "scatter",
              x: response.response.dates,
              y: tempYValue,
              mode: 'line',
              line: { dash: mlPlotColorMap.get(event.attribute)?.line },
              marker: { color: mlPlotColorMap.get(event.attribute)?.color },
              yaxis: "y1"
            };
            this.machineLearningGraph.push(plot);
          }
          else{

            const plot: any = {
              name: event.attribute,
              type: "scatter",
              x: response.response.dates,
              y: tempYValue,
              mode: 'line',
              line: { dash: mlPlotColorMap.get(event.attribute)?.line },
              marker: { color: mlPlotColorMap.get(event.attribute)?.color },
              yaxis: mlPlotColorMap.get(event.attribute)?.yaxis
            };
            this.machineLearningGraph.push(plot);
          }

          }
        }

        else {
          if (this.AtrributeArray.includes(event.attribute)) {

            if(this.selectedTarget=="Qwinj" && event.attribute.includes("Winj") || event.attribute.includes("Qwinj"))
            {

            const plot: any = {
              name: event.attribute,
              type: "scatter",
              x: response.response.dates,
              y: response.response.values,
              mode: 'markers',
              line: { dash: mlPlotColorMap.get(event.attribute)?.line },
              marker: { color: mlPlotColorMap.get(event.attribute)?.color },
              yaxis: "y1"
            };
            this.machineLearningGraph.push(plot);
           }
           else if(this.selectedTarget=="Qginj" && event.attribute.includes("Ginj") || event.attribute.includes("Qginj"))
            {

            const plot: any = {
              name: event.attribute,
              type: "scatter",
              x: response.response.dates,
              y: response.response.values,
              mode: 'markers',
              line: { dash: mlPlotColorMap.get(event.attribute)?.line },
              marker: { color: mlPlotColorMap.get(event.attribute)?.color },
              yaxis: "y1"
            };
            this.machineLearningGraph.push(plot);
           }
          else{

            const plot: any = {
              name: event.attribute,
              type: "scatter",
              x: response.response.dates,
              y: response.response.values,
              mode: 'markers',
              line: { dash: mlPlotColorMap.get(event.attribute)?.line },
              marker: { color: mlPlotColorMap.get(event.attribute)?.color },
              yaxis: mlPlotColorMap.get(event.attribute)?.yaxis
            };
            this.machineLearningGraph.push(plot);
          }
        }
          else {

            if(this.selectedTarget=="Qwinj" && event.attribute.includes("Winj")|| event.attribute.includes("Qwinj"))
            {

            const plot: any = {
              name: event.attribute,
              type: "scatter",
              x: response.response.dates,
              y: response.response.values,
              mode: 'lines',
              line: { dash: mlPlotColorMap.get(event.attribute)?.line },
              marker: { color: mlPlotColorMap.get(event.attribute)?.color },
              yaxis: "y1"
            };
            this.machineLearningGraph.push(plot);
          }
          else if(this.selectedTarget=="Qginj" && event.attribute.includes("Ginj") || event.attribute.includes("Qginj"))
          {

          const plot: any = {
            name: event.attribute,
            type: "scatter",
            x: response.response.dates,
            y: response.response.values,
            mode: 'lines',
            line: { dash: mlPlotColorMap.get(event.attribute)?.line },
            marker: { color: mlPlotColorMap.get(event.attribute)?.color },
            yaxis: "y1"
          };
          this.machineLearningGraph.push(plot);
        }
          else{

            const plot: any = {
              name: event.attribute,
              type: "scatter",
              x: response.response.dates,
              y: response.response.values,
              mode: 'lines',
              line: { dash: mlPlotColorMap.get(event.attribute)?.line },
              marker: { color: mlPlotColorMap.get(event.attribute)?.color },
              yaxis: mlPlotColorMap.get(event.attribute)?.yaxis
            };
            this.machineLearningGraph.push(plot);
          }
        }
      }
      })
    }
    else
      this.removeAttributeFromGraph(event);

  }

  private removeAttributeFromGraph(event: any): void {

    const indexToRemove = this.machineLearningGraph.findIndex(plot => plot.name === event.attribute);
    if (indexToRemove !== -1) this.machineLearningGraph.splice(indexToRemove, 1);
  }

  private resetMLGraphData(): void {
    this.machineLearningGraph = this.machineLearningGraph.filter(
      (plot: PlotData) => plot.name === 'Model Prediction' || plot.name === 'Model Target');
  }

  private setTargetPlotLayout(plot: PlotData): void {

    switch (this.mlRunParams.target) {
      case 'Qoil':
        if (plot.name === 'Model Prediction') { plot.marker = { color: '#BFBFBF' }; plot.yaxis = "y1"; this.machineLearningLayout.yaxis.title.font.color = '#009E00'; this.machineLearningLayout.yaxis.tickfont.color = '#009E00' }
        else if (plot.name === 'Model Target') { plot.marker = { color: '#000000' }; plot.yaxis = "y1"; this.machineLearningLayout.yaxis.title.font.color = '#009E00'; this.machineLearningLayout.yaxis.tickfont.color = '#009E00' }
        break;
      case 'Qwat':
        if (plot.name === 'Model Prediction') { plot.marker = { color: '#BFBFBF' }; plot.yaxis = "y1"; this.machineLearningLayout.yaxis.title.font.color = '#0000FF'; this.machineLearningLayout.yaxis.tickfont.color = '#0000FF'}
        else if (plot.name === 'Model Target') { plot.marker = { color: '#000000' }; plot.yaxis = "y1"; this.machineLearningLayout.yaxis.title.font.color = '#0000FF'; this.machineLearningLayout.yaxis.tickfont.color = '#0000FF'}
        break;
      case 'Qliq':
        if (plot.name === 'Model Prediction') { plot.marker = { color: '#BFBFBF' }; plot.yaxis = "y1"; this.machineLearningLayout.yaxis.title.font.color = '#7A007A'; this.machineLearningLayout.yaxis.tickfont.color = '#7A007A'}
        else if (plot.name === 'Model Target') { plot.marker = { color: '#000000' }; plot.yaxis = "y1"; this.machineLearningLayout.yaxis.title.font.color = '#7A007A'; this.machineLearningLayout.yaxis.tickfont.color = '#7A007A'}
        break;
      case 'Qgas':
        if (plot.name === 'Model Prediction') { plot.marker = { color: '#BFBFBF' }; plot.yaxis = "y1"; this.machineLearningLayout.yaxis.title.font.color = '#FF0000'; this.machineLearningLayout.yaxis.tickfont.color = '#FF0000' }
        else if (plot.name === 'Model Target') { plot.marker = { color: '#000000' }; plot.yaxis = "y1"; this.machineLearningLayout.yaxis.title.font.color = '#FF0000'; this.machineLearningLayout.yaxis.tickfont.color = '#FF0000' }
        break;

      case 'GOR':
        if (plot.name === 'Model Prediction') { plot.marker = { color: '#BFBFBF' }; plot.yaxis = "y1"; this.machineLearningLayout.yaxis.title.font.color = '#FFC000'; this.machineLearningLayout.yaxis.tickfont.color = '#FFC000' }
        else if (plot.name === 'Model Target') { plot.marker = { color: '#000000' }; plot.yaxis = "y1"; this.machineLearningLayout.yaxis.title.font.color = '#FFC000'; this.machineLearningLayout.yaxis.tickfont.color = '#FFC000' }
        break;

      case 'GLR':
        if (plot.name === 'Model Prediction') { plot.marker = { color: '#BFBFBF' }; plot.yaxis = "y1"; this.machineLearningLayout.yaxis.title.font.color = '#FF33CC'; this.machineLearningLayout.yaxis.tickfont.color = '#FF33CC' }
        else if (plot.name === 'Model Target') { plot.marker = { color: '#000000' }; plot.yaxis = "y1"; this.machineLearningLayout.yaxis.title.font.color = '#FF33CC'; this.machineLearningLayout.yaxis.tickfont.color = '#FF33CC' }
        break;

      case 'WCT':
        if (plot.name === 'Model Prediction') { plot.marker = { color: '#BFBFBF' }; plot.yaxis = "y1"; this.machineLearningLayout.yaxis.title.font.color = '#64b5f6'; this.machineLearningLayout.yaxis.tickfont.color = '#64b5f6' }
        else if (plot.name === 'Model Target') { plot.marker = { color: '#000000' }; plot.yaxis = "y1"; this.machineLearningLayout.yaxis.title.font.color = '#64b5f6'; this.machineLearningLayout.yaxis.tickfont.color = '#64b5f6' }
        break;

      case 'Qwinj':
          if (plot.name === 'Model Prediction') { plot.marker = { color: '#BFBFBF' }; plot.yaxis = "y1"; this.machineLearningLayout.yaxis.title.font.color = '#0000FF'; this.machineLearningLayout.yaxis.tickfont.color = '#0000FF' }
          else if (plot.name === 'Model Target') { plot.marker = { color: '#000000' }; plot.yaxis = "y1"; this.machineLearningLayout.yaxis.title.font.color = '#0000FF'; this.machineLearningLayout.yaxis.tickfont.color = '#0000FF' }
          break;

      case 'Qginj':
            if (plot.name === 'Model Prediction') { plot.marker = { color: '#BFBFBF' }; plot.yaxis = "y1"; this.machineLearningLayout.yaxis.title.font.color = '#FF0000'; this.machineLearningLayout.yaxis.tickfont.color = '#FF0000' }
            else if (plot.name === 'Model Target') { plot.marker = { color: '#000000' }; plot.yaxis = "y1"; this.machineLearningLayout.yaxis.title.font.color = '#FF0000'; this.machineLearningLayout.yaxis.tickfont.color = '#FF0000' }
            break;
      default:
        break;
    }
  }

  onSwitchMetricsGraph(graphName: string): void {
    this.displayedMetricsGraph = graphName;

    if (this.displayedMetricsGraph === 'dispersion') {
      this.mlMetricsGraph = JSON.parse(JSON.stringify(this.mlMetricsDispersionGraph));
      this.mlMetricsLayout = JSON.parse(JSON.stringify(this.mlMetricsDispersionLayout));
    } else {
      this.mlMetricsGraph = JSON.parse(JSON.stringify(this.mlMetricsBoxGraph));
      this.mlMetricsLayout = JSON.parse(JSON.stringify(this.mlMetricsBoxLayout));
    }
  }

  ngOnDestroy(): void {
    this.dateRangeSubscription.unsubscribe();
    this.dateRangeSubscription1.unsubscribe();
    this.dateRangeSubscription2.unsubscribe();
    this.dateRangeSubscription3.unsubscribe();
    this.qcDateRangeSubscription.unsubscribe();
    this.inferenceDateRangeSubscription.unsubscribe();
    this.selectedWellSubscription.unsubscribe();
    this.mlRunParamsSubscription.unsubscribe();
    this.machineLearningDataSubscription.unsubscribe();
    this.featureDataVisualizationSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
    let mlPrams: MLRunParams | undefined = undefined;
    this.sb.setMLParamsOnclickSavedItem(mlPrams);



  }

  onClickExportExcel() {
    const TARGET_NAME = this.mlRunParams.target;
    const WEL_NAME = this.selectedWell.well;
    let graphData: PlotData[] = this.machineLearningGraph.filter(data => {
      return data.name === "Model Prediction";
    })
    this.exportAsExcelFile(graphData[0].x, graphData[0].y, WEL_NAME, TARGET_NAME);
  }

  public exportAsExcelFile(dateData: any, valueData: any, wellName: string, modelName: string): void {
    let SrNo = 0;
    let keyName = modelName;
    let welName = wellName;
    let csvJsonData: any[] = [];
    if (dateData.length == valueData.length) {

      if (this.mlRunParams.target === "Qoil" || this.mlRunParams.target === "Qliq" || this.mlRunParams.target === "Qwat" || this.mlRunParams.target === "Qwinj") {
        keyName = keyName + " (bbl/d)";
      }
      else if (this.mlRunParams.target === "Qgas") {
        keyName = keyName + '(kSm3/d)';
      }
      else if (this.mlRunParams.target === "Qginj") {
        keyName = keyName + '(kSm3/d)';
      }
      else if (this.mlRunParams.target === "GOR") {
        keyName = keyName + '(Sm3/Sm3)';
      }
      else if (this.mlRunParams.target === "WCT") {
        keyName = keyName + '(%)';
      } else {
        keyName = keyName +'';
      }

      csvJsonData = dateData.map((element: any, index: number) => {
        return {
          SrNo: index + 1,
          wellName: welName,
          Date:  moment(element).format('DD/MM/YYYY'),
          [keyName]: valueData[index],
        };

      });

    }

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(csvJsonData);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    this.saveAsExcelFile(excelBuffer);
  }
  private saveAsExcelFile(buffer: any): void {

    let fileName = this.selectedWell.asset + '_' + this.selectedWell.well + '_' + this.mlRunParams.target + '_' + moment().format('YYYY-MM-DD');

    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
  generateJSONData(dateData: any, valueData: any, wellName: string, modelName: string) {

    let keyName = modelName;
    let welName = wellName;
    let csvJsonData: any[] = [];


    if (dateData.length == valueData.length) {
      csvJsonData = dateData.map((element: any, index: number) => {
        return {
          Date: element,
          wellName: welName,
          [keyName]: valueData[index],
        };
      });

    }
    this.downloadFile(csvJsonData, ['wellName', 'Date', keyName], 'data');
  }

  downloadFile(data: any, headerArray: string[], filename = '') {


    let csvData = this.ConvertToCSV(data, headerArray);
    let blob = new Blob(['\ufeff' + csvData], {
      type: 'text/csv;charset=utf-8;',
    });
    filename = this.getFileName();
    let dwldLink = document.createElement('a');
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf('Safari') != -1 &&
      navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {
      dwldLink.setAttribute('target', '_blank');
    }

    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);

  }

  ConvertToCSV(objArray: any, headerList: string[]) {

    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';

    for (let index in headerList) {

      if (index === "2" && (this.mlRunParams.target === "Qoil" || this.mlRunParams.target === "Qwinj" || this.mlRunParams.target === "Qliq" || this.mlRunParams.target === "Qwat")) {
        row += headerList[index] + '(bbl/d)' + ',';
      }
      else if (index === "2" && (this.mlRunParams.target === "Qgas")) {
        row += headerList[index] + '(kSm3/d)' + ',';
      }
      else if (index === "2" && (this.mlRunParams.target === "Qginj")) {
        row += headerList[index] + '(kSm3/d)' + ',';
      }
      else if (index === "2" && (this.mlRunParams.target === "GOR")) {
        row += headerList[index] + '(Sm3/Sm3)' + ',';
      }
      else if (index === "2" && (this.mlRunParams.target === "WCT")) {
        row += headerList[index] + '(%)' + ',';
      } else {
        row += headerList[index] + ',';
      }

    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];

        line += ',' + array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
  }

  getFileName(): any {
    let fileName: string;
    return fileName = this.selectedWell.asset + '_' + this.selectedWell.well + '_' + this.mlRunParams.target + '_' + moment().format('YYYY-MM-DD');

  }

  onClickSaveModel() {

    this.userName$.subscribe(data => {
      this.userName = data;
    })
    let saveModelParams: SaveModelParams = {
      algorithm: this.mlRunParams.ml_algorithm,
      asset: this.selectedWell.asset,
      field: this.selectedWell.field,
      well: this.selectedWell.well,
      features: this.mlRunParams.inputFeatures,
      save_model_time: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
      model_name: this.selectedWell.asset + '_' + this.selectedWell.well + '_' + this.mlRunParams.target + '_' + moment().format('YYYY-MM-DD') + '_',
      model_description: "",
      run_id: this.run_id,
      user: this.userName,
      target_value: this.mlRunParams.target,
      min_date: this.mlRunParams.min_date,
      max_date: this.mlRunParams.max_date,
      min_date1: this.mlRunParams.min_date1,
      max_date1: this.mlRunParams.max_date1,
      min_date2: this.mlRunParams.min_date2,
      max_date2: this.mlRunParams.max_date2,
      min_date3: this.mlRunParams.min_date3,
      max_date3: this.mlRunParams.max_date3,
      inference_min_date: this.mlRunParams.inference_min_date,
      inference_max_date: this.mlRunParams.inference_max_date,
      ml_training_set_size: this.mlRunParams.ml_training_set_size,
      data_input_source: this.mlRunParams.inputSource,
      well_test_status: this.mlRunParams.wellTestStatus,
      target_source: this.mlRunParams.target_source,
      validFlagcheck: this.mlRunParams.validFlagcheck,
      computeLearning: this.mlRunParams.computeLearning,
      threshold: this.mlRunParams.threshold,
      well_type: this.mlRunParams.well_type,
      optimize: this.mlRunParams.optimize,
      column_source: this.mlRunParams.column_source,
      forceOptime: this.mlRunParams.forceOptime,
      outliers: this.mlRunParams.outliers,
      ColumnValue: this.mlRunParams.added_features
    };


    const dialogRef = this.dialog.open(SaveMyModelComponent, {
      panelClass: 'popup-class-apnnel',
      data: { 'data': saveModelParams, 'showMyOnly': this.isShowMyModelsOnly, 'Myflag': this.flagStatus, 'action': 'Add' },
      disableClose: false
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result.flag === true) {
        this.isModelName = true;
      }

      else {
        this.isModelName = false;
      }


    });
  }

  onClickSavedModelItem(item: SaveModelTableData) {

    this.machineLearningGraph.forEach(plotData => {
      plotData.x = undefined;
      plotData.y = undefined;
      plotData.yaxis = "";
    })

    this.mlLearningCurvesGraph.forEach(plotData => {
      plotData.x = undefined;
      plotData.y = undefined;
    })

    if (this.mlConfidenceData != undefined) {
      this.mlConfidenceData.metrics = undefined;
    }

    this.mlMetricsGraph = [];

    this.mlMetricsDispersionGraph.forEach((PlotData) => {
      PlotData.x = undefined;
      PlotData.y = undefined;
    })

    if (this.mlMetricsDispersionLayout.yaxis && this.mlMetricsDispersionLayout.xaxis) {
      this.mlMetricsDispersionLayout.yaxis.title = `Prediction value (- %)`;
      this.mlMetricsDispersionLayout.yaxis.range = [];
      this.mlMetricsDispersionLayout.xaxis.range = [];
    }
    if (this.mlLearningCurvesLayout.yaxis && this.mlLearningCurvesLayout.xaxis) {
      this.mlLearningCurvesLayout.yaxis.title = `MAE`;
      this.mlLearningCurvesLayout.yaxis.range = [];
      this.mlLearningCurvesLayout.xaxis.range = [];
    }

    if (this.mlFeaturesImportanceLayout.yaxis && this.mlFeaturesImportanceLayout.xaxis) {
      this.mlFeaturesImportanceLayout.yaxis.range = []
      this.mlFeaturesImportanceLayout.xaxis.range = []
      this.mlFeaturesImportanceGraph[0].x = [];
      this.mlFeaturesImportanceGraph[0].y = [];
    }

    this.mlMetricsBoxGraph.forEach((PlotData) => {
      PlotData.x = undefined;
      PlotData.y = undefined;
      PlotData.yaxis = "";
    })
    this.mlFeaturesGraph = [];
    this.mlFeaturesLayout = [];
    this.mlFeatureGraphTitle = [];
    this.mlFeatureXtitle = [];

    if (this.wellType === 'producer') {
      this.child.clearCheck();
    }
    if (this.wellType === 'injector') {
      this.childInjector.clearCheckInjector();
    }
    this.selectedTargetSource = '';
    let targetList;
    let targetSourceList;
    let min_date: any;
    let max_date: any;
    let min_date1: any;
    let max_date1: any;
    let min_date2: any;
    let max_date2: any;
    let min_date3: any;
    let max_date3: any;
    if (this.wellType === 'injector') {
      targetSourceList = Object.keys(MlTargetSourceLabelInjector)[Object.values(MlTargetSourceLabelInjector).indexOf(item.target_source as unknown as MlTargetSourceLabelInjector)]
    } else {
      targetSourceList = Object.keys(MlTargetSourceLabel)[Object.values(MlTargetSourceLabel).indexOf(item.target_source as unknown as MlTargetSourceLabel)];
    }
    if (Array.isArray(item.min_date) && Array.isArray(item.max_date)) {
      min_date = item.min_date[0];
      max_date = item.max_date[0];
      // min_date1 = item.min_date[1];
      // max_date1 = item.max_date[1];
      this.countddl=0;
      this.countddl=item.min_date.length;
      min_date1 =  (this.countddl>=2 ? item.min_date[1]:undefined);
      max_date1 =  (this.countddl>=2 ? item.max_date[1]:undefined);
      min_date2 =  (this.countddl>=3 ? item.min_date[2]:undefined);
      max_date2 =  (this.countddl>=3 ? item.max_date[2]:undefined);
      min_date3 =  (this.countddl>3 ? item.min_date[3]:undefined);
      max_date3 =  (this.countddl>3 ? item.max_date[3]:undefined);
    }
    else {
      min_date = item.min_date;
      max_date = item.max_date;
      min_date1 = undefined;
      max_date1 = undefined;
      min_date2 = undefined;
      max_date2 = undefined;
      min_date3 = undefined;
      max_date3 = undefined;
      
    }
    const runMLPars: MLRunParams = {
      inputSource: Object.keys(MlInputSourceLabel)[Object.values(MlInputSourceLabel).indexOf(item.data_input_source as unknown as MlInputSourceLabel)],
      inputFeatures: item.features.split(','),
      target: item.target_value,
      max_date: max_date,
      min_date: min_date,
      min_date1: min_date1,
      max_date1: max_date1,
      min_date2: min_date2,
      max_date2: max_date2,
      min_date3: min_date3,
      max_date3: max_date3,
      ml_algorithm: item.algorithm,
      ml_training_set_size: item.ml_training_set_size,
      wellTestStatus: item.well_test_status?.split(','),
      inference_min_date: item.inference_min_date,
      inference_max_date: item.inference_max_date,
      target_source: targetSourceList,
      log_in_mlflow: 'false',
      validFlagcheck: item.valid_flag,
      well_type: item.well_type,
      optimize: item.optimize,
      // added_features:item.added_features.split(','),
      column_source: item.column_source,
      computeLearning: item.learning_curve,
      threshold: item.threshold,
      forceOptime:item.force_optime,
      outliers:item.removing_outliers,
    }
    this.mlRunParams = runMLPars;
    this.sb.setMLParamsOnclickSavedItem(runMLPars);
    this.isSavedItemclick = true;
    this.isModelName = true;
    this.LatestName = item.model_name;
  }
  onClickSavedModelItemCatalog(items: SaveModelTableData) {

    let item: SaveModelTableData = items;
    let targetList;
    let targetSourceList;
    let min_date: any;
    let max_date: any;
    let min_date1: any;
    let max_date1: any;
    let min_date2: any;
    let max_date2: any;
    let min_date3: any;
    let max_date3: any;
    if (Array.isArray(item.min_date) && Array.isArray(item.max_date)) {
      min_date = item.min_date[0];
      max_date = item.max_date[0];
      min_date1 = item.min_date[1];
      max_date1 = item.max_date[1];
      min_date2 = item.min_date[2];
      max_date2 = item.max_date[2];
      min_date3 = item.min_date[3];
      max_date3 = item.max_date[3];
    }
    else {
      min_date = item.min_date;
      max_date = item.max_date;
      min_date1 = undefined;
      max_date1 = undefined;
      min_date2 = undefined;
      max_date2 = undefined;
      min_date3 = undefined;
      max_date3 = undefined;
    }
    if (this.wellType === 'injector') {
      targetSourceList = Object.keys(MlTargetSourceLabelInjector)[Object.values(MlTargetSourceLabelInjector).indexOf(item.target_source as unknown as MlTargetSourceLabelInjector)]
    } else {
      targetSourceList = Object.keys(MlTargetSourceLabel)[Object.values(MlTargetSourceLabel).indexOf(item.target_source as unknown as MlTargetSourceLabel)];
    }

    const runMLPars: MLRunParams = {
      inputSource: Object.keys(MlInputSourceLabel)[Object.values(MlInputSourceLabel).indexOf(item.data_input_source as unknown as MlInputSourceLabel)],
      inputFeatures: item.features.split(','),
      target: item.target_value,
      max_date: max_date,
      min_date: min_date,
      min_date1: min_date1,
      max_date1: max_date1,
      min_date2: min_date2,
      max_date2: max_date2,
      min_date3: min_date3,
      max_date3: max_date3,
      ml_algorithm: item.algorithm,
      ml_training_set_size: item.ml_training_set_size,
      wellTestStatus: item.well_test_status?.split(','),
      inference_min_date: item.inference_min_date,
      inference_max_date: item.inference_max_date,
      target_source: targetSourceList,
      log_in_mlflow: 'false',
      validFlagcheck: item.valid_flag,
      well_type: item.well_type,
      optimize: item.optimize,
      //added_features:item.added_features.split(','),
      column_source: item.column_source,
      computeLearning: item.learning_curve,
      threshold: item.threshold,
      forceOptime:item.force_optime,
      outliers:item.removing_outliers,
    }
    this.mlRunParams = runMLPars;
    this.sb.setMLParamsOnclickSavedItem(runMLPars);
    this.isSavedItemclick = true;
    this.isModelName = false;
  }
  setMetricsDispersionGraph() {
    switch (this.mlRunParams.target) {
      case 'Qoil':
      case 'Qwat':
      case 'Qliq':

        if (this.mlMetricsDispersionLayout.yaxis) this.mlMetricsDispersionLayout.yaxis.title = `Prediction Value (${this.mlRunParams.target} - bbl/d)`;
        break;

      case 'Qgas':

        if (this.mlMetricsDispersionLayout.yaxis) this.mlMetricsDispersionLayout.yaxis.title = `Prediction value (${this.mlRunParams.target} - kSm3/d)`;
        break;

      case 'GOR':

        if (this.mlMetricsDispersionLayout.yaxis) this.mlMetricsDispersionLayout.yaxis.title = `Prediction value (${this.mlRunParams.target} - Sm3/Sm3)`;
        break;

      case 'GLR':

        if (this.mlMetricsDispersionLayout.yaxis) this.mlMetricsDispersionLayout.yaxis.title = `Prediction value (${this.mlRunParams.target} - Sm3/Sm3)`;
        break;

      case 'WCT':

        if (this.mlMetricsDispersionLayout.yaxis) this.mlMetricsDispersionLayout.yaxis.title = `Prediction value (${this.mlRunParams.target} - %)`;
        break;

      default:
        break;
    }
  }
  setMLLearningCurve() {
    switch (this.mlRunParams.target) {
      case 'Qoil':
      case 'Qwat':
      case 'Qliq':
      case 'Qwinj':

        if (this.mlLearningCurvesLayout.yaxis) this.mlLearningCurvesLayout.yaxis.title = `MAE (${this.mlRunParams.target} - bbl/d)`;
        break;

      case 'Qgas':
        if (this.mlLearningCurvesLayout.yaxis) this.mlLearningCurvesLayout.yaxis.title = `MAE (${this.mlRunParams.target} - kSm3/d)`;

        break;
      case 'Qginj':
        if (this.mlLearningCurvesLayout.yaxis) this.mlLearningCurvesLayout.yaxis.title = `MAE (${this.mlRunParams.target} - kSm3/d)`;

        break;

      case 'GOR':
        if (this.mlLearningCurvesLayout.yaxis) this.mlLearningCurvesLayout.yaxis.title = `MAE (${this.mlRunParams.target} - Sm3/Sm3)`;

        break;

      case 'GLR':
        if (this.mlLearningCurvesLayout.yaxis) this.mlLearningCurvesLayout.yaxis.title = `MAE (${this.mlRunParams.target} - Sm3/Sm3)`;

        break;

      case 'WCT':
        if (this.mlLearningCurvesLayout.yaxis) this.mlLearningCurvesLayout.yaxis.title = `MAE (${this.mlRunParams.target} - %)`;

        break;

      default:
        break;
    }
  }
  setTrainingDataTarget(target: string) {
    switch (target) {
      case 'Qoil':
      case 'Qwat':
      case 'Qliq':
      case 'Qwinj':

        if (this.trainingDataLayout.yaxis) this.trainingDataLayout.yaxis.title = ` (${target} - bbl/d)`;
        break;

      case 'Qgas':
        if (this.trainingDataLayout.yaxis) this.trainingDataLayout.yaxis.title = ` (${target} - kSm3/d)`;

        break;

      case 'Qginj':
        if (this.trainingDataLayout.yaxis) this.trainingDataLayout.yaxis.title = ` (${target} - kSm3/d)`;

        break;

      case 'GOR':
        if (this.trainingDataLayout.yaxis) this.trainingDataLayout.yaxis.title = `(${target} - Sm3/Sm3)`;

        break;

      case 'GLR':
        if (this.trainingDataLayout.yaxis) this.trainingDataLayout.yaxis.title = `(${target} - Sm3/Sm3)`;

        break;
      case 'WCT':
        if (this.trainingDataLayout.yaxis) this.trainingDataLayout.yaxis.title = `(${target} - %)`;

        break;

      default:
        break;
    }
  }

  onClickCheckboxOfWellType(event: any) {

    if (event.value === "Injector") {
      this.isInjector = event.checked;
    } else if (event.value === "Producer") {
      this.isProducer = event.checked;
    }
    this.callWellSelection(this.filterData.asset, this.filterData.field);
  }

  clearMLSettings() {
    this.selectedTarget = '';
    this.selectedTargetSource = '';
    this.selectedInputSource = '';
    this.selectedFeatureSource = '';
    this.wellStatus = '';
    this.columSourceList=[];
    if(this.wellType==='producer'){
      if(this.child!==undefined){
        this.child.clearCheck();
      }
    }
    if(this.wellType === 'injector'){
      if(this.childInjector!==undefined){
       this.childInjector.clearCheckInjector();
      }
    }
    this.sb.setClearMlSettings(true);
  }
  onClickOnMyModelsOnly(event: ValueSelect) {
    this.isShowMyModelsOnly = JSON.parse(event.value);
    if (this.isShowMyModelsOnly)
      this.sb.loadSavedModelTableData(this.affiliate, this.selectedWell.field, this.selectedWell.asset, this.selectedWell.well, this.userName);
    else
      this.sb.loadSavedModelTableData(this.affiliate, this.selectedWell.field, this.selectedWell.asset, this.selectedWell.well);
  }


  onClickLearning(event: any) {

  }

  onClickZeroValue(event: any) {

  }

  onClickValidFlag(event: any) {

    if (event.checked) {
      this.flagStatus = 'true';

      this.sb.loadWellTestStatus(this.affiliate, this.filterData, this.flagStatus);

      if (this.addRemovePicker){
      this.sb.loadTrainingVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.selectedTarget, this.wellType, this.selectedTargetSource, this.wellStatus, this.colunmSourceValue, this.filterData.date_max, this.filterData.date_min, 
        (this.countddl>=2 ? this.filterData.date_max1:undefined), (this.countddl>=2 ? this.filterData.date_min1:undefined),
        (this.countddl>=3 ? this.filterData.date_max2:undefined), (this.countddl>=3 ? this.filterData.date_min2:undefined),
        (this.countddl>3 ? this.filterData.date_max3:undefined), (this.countddl >3 ? this.filterData.date_min3:undefined),
        this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date, this.flagStatus);
      }
      else{
       this.sb.loadTrainingVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.selectedTarget, this.wellType, this.selectedTargetSource, this.wellStatus, this.colunmSourceValue, this.filterData.date_max, this.filterData.date_min,
        (this.countddl>=2 ? this.filterData.date_max1:undefined), (this.countddl>=2 ? this.filterData.date_min1:undefined),
        (this.countddl>=3 ? this.filterData.date_max2:undefined), (this.countddl>=3 ? this.filterData.date_min2:undefined),
        (this.countddl>3 ? this.filterData.date_max3:undefined), (this.countddl >3 ? this.filterData.date_min3:undefined),
        this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date,this.flagStatus);
      }
    }
    else {
      this.sb.loadWellTestStatus(this.affiliate, this.filterData);
      if (this.addRemovePicker){
        this.sb.loadTrainingVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.selectedTarget, this.wellType, this.selectedTargetSource, this.wellStatus, this.colunmSourceValue, this.filterData.date_max, this.filterData.date_min, 
          (this.countddl>=2 ? this.filterData.date_max1:undefined), (this.countddl>=2 ? this.filterData.date_min1:undefined),
          (this.countddl>=3 ? this.filterData.date_max2:undefined), (this.countddl>=3 ? this.filterData.date_min2:undefined),
          (this.countddl>3 ? this.filterData.date_max3:undefined), (this.countddl >3 ? this.filterData.date_min3:undefined), 
          this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
        }
        else{
         this.sb.loadTrainingVisualzeData(this.affiliate, this.selectedWell.asset, this.selectedWell.field, this.selectedWell.well, this.selectedTarget, this.wellType, this.selectedTargetSource, this.wellStatus, this.colunmSourceValue, this.filterData.date_max, this.filterData.date_min,
          (this.countddl>=2 ? this.filterData.date_max1:undefined), (this.countddl>=2 ? this.filterData.date_min1:undefined),
          (this.countddl>=3 ? this.filterData.date_max2:undefined), (this.countddl>=3 ? this.filterData.date_min2:undefined),
          (this.countddl>3 ? this.filterData.date_max3:undefined), (this.countddl >3 ? this.filterData.date_min3:undefined),
          this.mlRunParams.inference_min_date, this.mlRunParams.inference_max_date);
        }
    }
  }

  onClicAddkRemovePicker(event: any) {
    this.addRemovePicker = event;
  }

}
