import {PlotData} from 'src/modules/types';
import {Layout} from 'plotly.js';

export const ML_LEARNING_CURVES_PLOT_DATA: PlotData[] = [
  {name: "Test_MAE", type: "scatter", mode: "lines", line: {dash: 'dot'}},
  {name: "Train_MAE", type: "scatter", mode: "lines", line: {dash: 'solid'}},
];

export const ML_LEARNING_CURVES_LAYOUT: Partial<Layout> = {
  legend: {
    orientation: 'h',
    xanchor: 'center',
    x: 0.5
  },
  hovermode: 'x',
  yaxis: {
    title: 'MAE',
    showgrid: true,
    zeroline: true,
    showline: true,
    visible: true,
  }
};
