import { Component, OnInit } from '@angular/core';
import { AppSandbox } from '../../app.sandbox';
import { DynamicDataSource, DynamicFlatNode, MENU_LEVEL } from './tree-datasource/tree-datasource';
import { FlatTreeControl } from '@angular/cdk/tree';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'wag-sidenav',
  template: `
    <mat-tree  [dataSource]="dataSource" [treeControl]="treeControl" >
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding matTreeNodePaddingIndent="20">
        <a [routerLink]="[node.route]" [routerLinkActive]="'active'"
          [ngClass]="{'is-monitoring-active': node.item === 'MONITORING' && isMonitoringActive}">
          <mat-icon *ngIf="node.level === MENU_LEVEL.ROOT">{{node.icon}}</mat-icon>
          <span>{{node.item}}</span>
        </a>
      </mat-tree-node>

      <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding
       [routerLinkActive]="'active'" matTreeNodePaddingIndent="20">
        <a [routerLink]="[node.route]" [routerLinkActive]="'active'"
          [ngClass]="{'is-monitoring-active': node.item === 'MONITORING' && isMonitoringActive}">
          <mat-icon *ngIf="node.level === MENU_LEVEL.ROOT">{{node.icon}}</mat-icon>
          <span>{{node.item}}</span>
        </a>
        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.item">
          <mat-icon >
            {{treeControl.isExpanded(node) ? 'arrow_drop_down' : 'arrow_right'}}
          </mat-icon>
        </button>
      </mat-tree-node>
    </mat-tree>
`,
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  INITIAL_DATA: DynamicFlatNode[] = [
    {
      item: 'HOMEPAGE',
      level: MENU_LEVEL.ROOT,
      expandable: false,
      icon: 'home',
      route: '/home'
    },
    {
      item: 'MONITORING',
      level: MENU_LEVEL.ROOT,
      expandable: true,
      icon: 'laptop'
    }
    //,
    // {
    //   item: 'ANALYSIS',
    //   level: MENU_LEVEL.ROOT,
    //   expandable: false,
    //   icon: 'location_on'
    // }
    ,
    {
      item: 'MODEL BUILD',
      level: MENU_LEVEL.ROOT,
      expandable: false,
      icon: 'settings',
      route: '/model'
    },
    {
      item: 'MODEL CATALOGUE',
      level: MENU_LEVEL.ROOT,
      expandable: false,
      icon: 'folder_open',
      route: '/modelcatalogue'
    },
    {
      item: 'BACK ALLOCATION',
      level: MENU_LEVEL.ROOT,
      expandable: false,
      icon: 'history',
      route: '/allocation'
    },
  ];

  treeControl!: FlatTreeControl<DynamicFlatNode>;

  dataSource!: DynamicDataSource;

  MENU_LEVEL = MENU_LEVEL;

  isMonitoringActive = false;

  constructor(
    private sb: AppSandbox,
    private router: Router,
  ) {

  }

  ngOnInit(): void {
    this.sb.selectedAffiliate$.subscribe(affiliate => {
     
      this.sb.loadAssets(affiliate).subscribe(data => {
        this.dataSource.toggleNode(this.INITIAL_DATA[1], false);
      });
      this.loadData();
    })
  }

  loadData() {
    this.treeControl = new FlatTreeControl<DynamicFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new DynamicDataSource(this.treeControl, this.sb);
    this.dataSource.data = [];
    this.dataSource.data = this.INITIAL_DATA;

    if (this.router.url.startsWith('/asset')) this.isMonitoringActive = true;

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        if (event.url.startsWith('/asset')) this.isMonitoringActive = true;
        else this.isMonitoringActive = false;
      });
  }

  getLevel = (node: DynamicFlatNode): MENU_LEVEL => node.level;

  isExpandable = (node: DynamicFlatNode): boolean => node.expandable;

  hasChild = (_: number, _nodeData: DynamicFlatNode): boolean => _nodeData.expandable;

  onMenuSelect(menu: DynamicFlatNode): void {
    if (menu.route) this.router.navigateByUrl(menu.route);
  }
}

