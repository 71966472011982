import {Action, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {WellOptimesData} from 'src/modules/types';
import {GetInjectorWellsOptimesData} from './actions';


@State<WellOptimesData[]>({
  name: 'injectorWellsOptimesData',
  defaults: []
})
@Injectable()
export class InjectorWellsOptimesDataState {
  @Action(GetInjectorWellsOptimesData)
  getProducerWellsDailyData(context: StateContext<WellOptimesData[]>, {payload}: GetInjectorWellsOptimesData): void {
    context.setState([...payload]);
  }
}
