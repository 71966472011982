import { Component, Input } from '@angular/core';
import { Layout } from 'plotly.js';
import { PlotData } from 'src/modules/types';


@Component({
  selector: 'wag-model-interpretability',
  template: `
   <h3>Features Importance <img src="./../../../../assets/info.svg" class="info-icon" [mdePopoverTriggerFor]="FeaturesDataPopOver"
        mdePopoverTriggerOn="hover" mdePopoverPositionY="above" mdePopoverPositionX="after"></h3>
   <wag-well-graph [data]="mlFeaturesImportanceGraph"
     [layout]="mlFeaturesImportanceLayout">
   </wag-well-graph>

   <h3>Corelation Coefficient <img src="./../../../../assets/info.svg" class="info-icon"
        mdePopoverTriggerOn="hover" mdePopoverPositionY="above" mdePopoverPositionX="after"></h3>
   <wag-well-graph [data]="mlCorelationGraph"
     [layout]="mlCorelationLayout">
   </wag-well-graph>

   <mde-popover #FeaturesDataPopOver="mdePopover" [mdePopoverOverlapTrigger]="false">
    <mat-card class="card-width" >
      <mat-card-content>
        <ul>
          <li> This graph shows the impact of each variable on the prediction. In other words, the higher is the absolute <br>value of the impact, the more important the feature is. The values are not related to the prediction, <br> it is statistical. Take only the magnitude of the impact. </li>
</ul>
   </mat-card-content>
  </mat-card>
</mde-popover>
   <div class="section">
     <div class="chart-section" *ngFor="let graph of mlFeaturesGraph;let i = index;">
     <div class="title-container">
     <span class="feature-title">
     {{mlFeatureGraphTitle[i]}}
     </span>
     </div>
     <ng-container *ngIf="mlFeatureGraphTitle[i]!==undefined">
     <wag-well-graph [id]="i" [data]="graph" 
     [layout]="mlFeaturesLayout[i]">
   </wag-well-graph>
     </ng-container>
     <span class="x-feature-title">
     {{mlFeatureXtitle[i]}}
     </span>
     </div>
   </div>
  `,
  styleUrls: ['./model-interpretability.component.scss']
})
export class ModelInterpretabilityComponent {

  @Input() mlFeaturesImportanceGraph!: PlotData[];
  @Input() mlFeaturesImportanceLayout!: Partial<Layout>;

  @Input() mlCorelationGraph!: PlotData[];
  @Input() mlCorelationLayout!: Partial<Layout>;

  @Input() mlFeaturesGraph!: PlotData[][];
  @Input() mlFeaturesLayout!: any[];
  @Input() mlFeatureGraphTitle!: string[];
  @Input() mlFeatureXtitle!: string[];

}
