import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppSandbox } from 'src/modules/app/app.sandbox';
import { BuildModelService, LoaderService } from 'src/modules/app/services';
import { MLRunParams, SelectedWellTypeInterface } from 'src/modules/types';

@Component({
  selector: 'wag-save-my-model',
  template: `
  <wag-loader></wag-loader>
  <form [formGroup]="saveModelForm">
  <mat-dialog-content class="mat-typography">
  <div>
      <h1 mat-dialog-title>
          <img src="./../../../../assets/save.svg" class="save-icon">
          Save Model
          <img src="./../../../../assets/close.svg" class="close-icon" mat-dialog-close>
      </h1>
  </div>

      <div class="field-container">
          <mat-form-field appearance="outline">
              <mat-label>Model name</mat-label>
              <input matInput placeholder="Model name ( e.g.<field_name>_<wel_name>_<target>)" formControlName="model_name">
              <mat-error *ngIf="duplicateError">Model name should be unique</mat-error>
              </mat-form-field>
      </div>
      <div class="field-container">
          <mat-form-field appearance="outline">
              <mat-label>Description</mat-label>
              <textarea matInput placeholder="Description" formControlName="model_description"></textarea>
          </mat-form-field>
      </div>
  </mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="secondary-btn" mat-dialog-close style="margin-right: 16px" (click)="OnCancelClick()">Cancel</button>
  <button class="primary-btn"  [disabled]="saveModelForm.invalid" (click)="onClickSaveMyModel()">Save Model</button>
</mat-dialog-actions>

</form>

  `,
  styleUrls: ['./save-my-model.component.scss']
})
export class SaveMyModelComponent implements OnInit {

  saveModelForm: FormGroup = new FormGroup({});
  duplicateError!: boolean;
  affiliate!: string;
  action!: string;
  constructor(
    private formBuilder: FormBuilder,
    private sb: AppSandbox,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SaveMyModelComponent>, private buildService: BuildModelService, private loader: LoaderService) {
    this.sb.selectedAffiliate$.subscribe(affiliate => { this.affiliate = affiliate; });
    if (this.data) {
      this.action = this.data.action;
    }
  }

  ngOnInit() {
    this.initForm();

  }

  initForm() {
    this.saveModelForm = this.formBuilder.group({
      'model_name': [this.data.data.model_name, [Validators.required]],
      'model_description': [this.data.data.model_description, [Validators.required]]
    });
  }
  onClickSaveMyModel() {

    if (this.action === 'Add') {
      this.callSaveModelApi();
    }
    else {
      this.callEditModelApi();
    }
  }

  callEditModelApi() {
    this.sb.updateSaveModel(this.affiliate, this.saveModelForm.get('model_name')?.value, this.saveModelForm.get('model_description')?.value, this.data.data.run_id).subscribe(response => {

      setTimeout(() => {
        if (response.response === "Model name should be unique" || response.response === "Run id should be unique") {
          this.duplicateError = true;
          this.saveModelForm.controls['model_name'].setErrors({ 'nameExsist': true });
        } else {
          this.duplicateError = false;
          this.dialogRef.close();
          this.sb.loadModelCatalogueTableData(this.affiliate, this.data.data.asset_id, this.data.data.field_id);
        }
      }, 200);

    })

  }
  callSaveModelApi() {
    
    this.data.data.model_description = this.saveModelForm.get('model_description')?.value;
    this.data.data.model_name = this.saveModelForm.get('model_name')?.value;

    const selectedWell: SelectedWellTypeInterface = {
      asset: this.data.data.asset,
      field: this.data.data.field,
      well: this.data.data.well
    }

    const runMl: MLRunParams = {
      inference_max_date: this.data.data.inference_max_date,
      inference_min_date: this.data.data.inference_min_date,
      inputFeatures: this.data.data.features,
      added_features:this.data.data.ColumnValue,
      inputSource: this.data.data.data_input_source,
      log_in_mlflow: 'true',
      max_date: this.data.data.max_date,
      min_date: this.data.data.min_date,
      min_date1:this.data.data.min_date1,
      max_date1:this.data.data.max_date1,
      ml_algorithm: this.data.data.algorithm,
      ml_training_set_size: this.data.data.ml_training_set_size,
      target: this.data.data.target_value,
      target_source: this.data.data.target_source,
      wellTestStatus: this.data.data.well_test_status,
      validFlagcheck:this.data.data.validFlagcheck,
      computeLearning:this.data.data.computeLearning ,
      threshold:this.data.data.threshold,
      well_type:this.data.data.well_type,
      optimize:this.data.data.optimize,
      column_source:this.data.data.column_source,
     // TmoreColumn:this.data.data.TmoreColumn,
      forceOptime:this.data.data.forceOptime,
      outliers:this.data.data.outliers,
    }

    this.loader.isLoading.next(true);
    this.buildService.getMLResult(this.affiliate, selectedWell, runMl, this.data.data.validFlagcheck).subscribe((response: any) => {
      this.data.data = {
        ...this.data.data,
        run_id: response.response.base.run_id
      };
      this.loader.isLoading.next(false);
      this.sb.loadSaveModelData(this.affiliate, this.data.data, this.data.data.validFlagcheck);
      setTimeout(() => {
        this.sb.saveModelData$.subscribe(message => {
          if (message === "Model name should be unique" || message === "Run id should be unique") {
            this.duplicateError = true;
            this.saveModelForm.controls['model_name'].setErrors({ 'nameExsist': true });
          } else {
            this.duplicateError = false;
            this.dialogRef.close({ "flag": true });
            if (this.data.showMyOnly)
              this.sb.loadSavedModelTableData(this.affiliate, this.data.data.field, this.data.data.asset, this.data.data.well, this.data.data.user, this.data.data.validFlagcheck);
            else this.sb.loadSavedModelTableData(this.affiliate, this.data.data.field, this.data.data.asset, this.data.data.well, this.data.data.validFlagcheck);
          }
        })
      }, 2000)
      this.sb.loadMLResultFromSaveMl(response.response, runMl);
    })

  }

  OnCancelClick(){

    this.dialogRef.close({ "flag": false });
  }
}
