import {ChangeContext, LabelType, Options} from '@angular-slider/ngx-slider';
import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {FieldRangeDates} from 'src/modules/types';
import { DateAdapter,MatDateFormats, MAT_NATIVE_DATE_FORMATS, MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';


export const MyDateFormat:MatDateFormats = {
  parse: {
      dateInput: ['DD/MM/YYYY']
  },            display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
  },        
};


@Component({
  selector: 'wag-date-filters',
  styleUrls: ['./date-filters.component.scss'],
  template: `
    <div class="section-container">
      <h2 *ngIf="title">{{title}}</h2>
      
      <div class="selectors-container">
      
        <mat-form-field appearance="outline" floatLabel="always">
       
          <mat-label>Start date (dd/mm/YYYY)</mat-label>
          <input matInput
                 [matDatepicker]="startDatePickerElement"
                 placeholder="dd/mm/yyyy"
                 [ngModel]="datePickerStart"
                 [max]="datePickerEnd"
                 (dateChange)="onDateChange('startDate', $event.target.value)">
          <mat-datepicker-toggle matSuffix [for]="startDatePickerElement">
            <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #startDatePickerElement></mat-datepicker>
        </mat-form-field>

        <div *ngIf="options.floor && options.ceil && dateRange" class="slider-box">
          <ngx-slider [value]="dateRange.startDate"
                      [highValue]="dateRange.endDate"
                      [options]="options"
                      (userChangeEnd)="onUserChange($event)">
          </ngx-slider>
        </div>

        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>End date (dd/mm/YYYY)</mat-label>
          <input matInput
                 [matDatepicker]="endDatePickerElement"
                 placeholder="dd/mm/yyyy"
                 [ngModel]="datePickerEnd"
                 [min]="datePickerStart"
                 (dateChange)="onDateChange('endDate', $event.target.value)"
                 [max]="datePickerMax">
          <mat-datepicker-toggle matSuffix [for]="endDatePickerElement">
            <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #endDatePickerElement></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  `
})
// ,
//   providers:[{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
//   { provide: MAT_DATE_FORMATS, useValue: MyDateFormat }]
export class DateFiltersComponent implements OnChanges {
  @Input() dateRange!: FieldRangeDates | null;
  @Input() minMaxRange!: FieldRangeDates | null;
  @Input() title?: string;
  @Output() dateRangeChange: EventEmitter<FieldRangeDates> = new EventEmitter<FieldRangeDates>();

  datePickerStart!: Date;
  datePickerEnd!: Date;

  datePickerMin!: Date;
  datePickerMax!: Date;

  options: Options = {
    floor: undefined,
    ceil: undefined,
    translate: (value: number, label: LabelType): string => {
      return new Date(value).toLocaleDateString('fr-FR');
    }
  };
 
  ngOnChanges(changes: SimpleChanges): void {
 
    if (changes.minMaxRange?.currentValue) {
      this.options.floor = changes.minMaxRange.currentValue.trueStartDate;
      this.options.ceil = changes.minMaxRange.currentValue.endDate;
      this.datePickerMin = new Date(changes.minMaxRange.currentValue.startDate);
      this.datePickerMax = new Date(changes.minMaxRange.currentValue.endDate);
    }
    if (changes.dateRange?.currentValue && Object.keys(changes.dateRange.currentValue).length) {
      this.datePickerStart = new Date(changes.dateRange.currentValue.startDate);
      this.datePickerEnd = new Date(changes.dateRange.currentValue.endDate);
    }
    if (changes.dateRange?.currentValue?.endDate && this.options.floor && changes.dateRange?.currentValue?.trueStartDate) {
      this.options = {...this.options, floor: changes.dateRange.currentValue.trueStartDate,ceil:changes.dateRange.currentValue.endDate};
    }
  }

  
    onDateChange(dateType: string, date: Date): void {
      if (this.dateRange) {
        if (dateType === 'startDate') this.dateRangeChange.emit({startDate: Date.parse(date.toDateString()), endDate: this.dateRange.endDate});
        if (dateType === 'endDate') this.dateRangeChange.emit({startDate: this.dateRange.startDate, endDate: Date.parse(date.toDateString())});
      }
    }
  // onDateChange(dateType: string, date: string): void {
  //   if (this.dateRange) {
  //       if (dateType === 'startDate') this.dateRangeChange.emit({startDate: Date.parse(date), endDate: this.dateRange.endDate});
  //       if (dateType === 'endDate') this.dateRangeChange.emit({startDate: this.dateRange.startDate, endDate: Date.parse(date)});
  //   }
  //}

  onUserChange(change: ChangeContext): void {
    if (this.dateRange && change.highValue) this.dateRangeChange.emit({startDate: change.value, endDate: change.highValue});
  }

}
