import {PlotData} from 'src/modules/types';


export const MACHINE_LEARNING_PLOT_DATA: PlotData[] = [
  {name: 'Model Prediction', type: 'scatter', mode: 'lines'},
  {name: 'Model Target', type: 'scatter', mode: 'markers'},
];

export const MACHINE_LEARNING_LAYOUT: any = {
  title: {
    text: '',
    font: {
      family: `Roboto, "Helvetica Neue", sans- serif`,
      size: 11
    },
    x: 0.5
  },
  legend: {
    orientation: 'h',
    xanchor: 'center',
    x: 0.5
  },
  hovermode: 'x',
  xaxis: {
    domain: [0.21, 0.79]
  },
  
  yaxis: {
    title: "",
    side: "left",
    position: 0.00,
    titlefont: {
      color: "#7A007A",
      size: 11
    },
    tickfont: {
      color: "#7A007A"
    },
    showgrid: true,
    zeroline: true,
    showline: false,
    visible: true,
    autorange:true,
    
    
  },
  yaxis2: {
    title: "",
    side: "left",
    overlaying: "y",
    position: 0.04,
    titlefont: {
      color: "#009E00",
      size: 11
    },
    tickfont: {
      color: "#009E00"
    },
    showgrid: true,
    zeroline: true,
    showline: false,
    visible: true,
    autorange:true
  },
  yaxis3: {
    title: "",
    side: "left",
    overlaying: "y",
    position: 0.08,
    titlefont: {
      color: "#e91e63",
      size: 11
    },
    tickfont: {
      color: "#e91e63"
    },
    showgrid: true,
    zeroline: true,
    showline: false,
    visible: true,
    autorange:true
  },
  yaxis4: {
    title: "",
    side: "left",
    overlaying: "y",
    position: 0.12,
    titlefont: {
      color: "#FF0000",
      size: 11
    },
    tickfont: {
      color: "#FF0000"
    },
    showgrid: true,
    zeroline: true,
    showline: false,
    visible: true,
    autorange:true
  },
  yaxis5: {
    title: "",
    side: "right",
    overlaying: "y",
    position: 0.79,
    titlefont: {
      color: "#6C7678",
      size: 11
    },
    tickfont: {
      color: "#6C7678"
    },
    showgrid: true,
    zeroline: true,
    showline: false,
    visible: true,
    autorange:true
  },
  yaxis6: {
    title: "",
    side: "left",
    overlaying: "y",
    position: 0.16,
    titlefont: {
      color: "#64b5f6",
      size: 11
    },
    tickfont: {
      color: "#64b5f6"
    },
    showgrid: true,
    zeroline: true,
    showline: false,
    visible: true,
    autorange:true
  },
  yaxis7: {
    title: "",
    side: "right",
    position: 0.93,
    overlaying: 'y',
    titlefont: {
      color: "#FF33CC",
      size: 11
    },
    tickfont: {
      color: "#FF33CC"
    },
    showgrid: true,
    zeroline: true,
    showline: false,
    visible: true,
    autorange:true,
  },
  yaxis8: {
    title: "",
    side: "right",
    position: 0.99,
    overlaying: 'y',
    titlefont: {
      color: "#ff5722",
      size: 11
    },
    tickfont: {
      color: "#ff5722"
    },
    showgrid: true,
    zeroline: true,
    showline: false,
    visible: true,
    autorange:true,
  },
  yaxis9: {
    title: "",
    side: "left",
    position: 0.20,
    overlaying: 'y',
    titlefont: {
      color: "#505758",
      size: 11
    },
    tickfont: {
      color: "#505758"
    },
    showgrid: true,
    zeroline: true,
    showline: false,
    visible: true,
    autorange:true,
  },
  yaxis10: {
    title: "",
    side: "right",
    position: 103,
    overlaying: 'y',
    titlefont: {
      color: "#0d47a1",
      size: 11
    },
    tickfont: {
      color: "#0d47a1"
    },
    showgrid: true,
    zeroline: true,
    showline: false,
    visible: true,
    autorange:true,
  },
  yaxis11: {
    title: "",
    side: "left",
    position: 0.21,
    overlaying: 'y',
    titlefont: {
      color: "#ff9800",
      size: 11
    },
    tickfont: {
      color: "#ff9800"
    },
    showgrid: true,
    zeroline: true,
    showline: false,
    visible: true,
    autorange:true,
  },
  yaxis12: {
    title: "",
    side: "right",
    position: 107,
    overlaying: 'y',
    titlefont: {
      color: "#E2BC00",
      size: 11
    },
    tickfont: {
      color: "#E2BC00"
    },
    showgrid: true,
    zeroline: true,
    showline: false,
    visible: true,
    autorange:true,
  },
  yaxis13: {
    title: "-",
    side: "left",
    position: 0.23,
    overlaying: 'y',
    titlefont: {
      color: "#4806A8",
      size: 11
    },
    tickfont: {
      color: "#4806A8"
    },
    showgrid: true,
    zeroline: true,
    showline: false,
    visible: true,
    autorange:true,
  },
  yaxis14: {
    title: "",
    side: "left",
    position: 0.06,
    overlaying: 'y',
    titlefont: {
      color: "#2196f3",
      size: 11
    },
    tickfont: {
      color: "#2196f3"
    },
    showgrid: true,
    zeroline: true,
    showline: false,
    visible: true,
    autorange:true,
  },
  yaxis15: {
    title: "",
    side: "left",
    position: 0.20,
    overlaying: 'y',
    titlefont: {
      color: "#e91e63",
      size: 11
    },
    tickfont: {
      color: "#e91e63"
    },
    showgrid: true,
    zeroline: true,
    showline: false,
    visible: true,
    autorange:true,
  },
  yaxis16: {
    title: "",
    side: "right",
    position: 80,
    overlaying: 'y',
    titlefont: {
      color: "#6C7678",
      size: 11
    },
    tickfont: {
      color: "#6C7678"
    },
    showgrid: true,
    zeroline: true,
    showline: false,
    visible: true,
    autorange:true,
  },
  yaxis17: {
    title: "",
    side: "left",
    position: 0.28,
    overlaying: 'y',
    titlefont: {
      color: "#505758",
      size: 11
    },
    tickfont: {
      color: "#505758"
    },
    showgrid: true,
    zeroline: true,
    showline: false,
    visible: true,
    autorange:true,
  },
  yaxis18: {
    title: "",
    side: "left",
    position: 0.18,
    overlaying: 'y',
    titlefont: {
      color: "#965900",
      size: 11
    },
    tickfont: {
      color: "#965900"
    },
    showgrid: true,
    zeroline: true,
    showline: false,
    visible: true,
    autorange:true,
  },
  yaxis19: {
    title: "",
    side: "left",
    position: 0.17,
    overlaying: 'y',
    titlefont: {
      color: "#051939",
      size: 11
    },
    tickfont: {
      color: "#051939"
    },
    showgrid: true,
    zeroline: true,
    showline: false,
    visible: true,
    autorange:true,
  },
  yaxis20: {
    title: "",
    side: "left",
    position: 0.18,
    overlaying: 'y',
    titlefont: {
      color: "#00B050",
      size: 11
    },
    tickfont: {
      color: "#00B050"
    },
    showgrid: true,
    zeroline: true,
    showline: false,
    visible: true,
    autorange:true,
  },
  yaxis21: {
    title: "",
    side: "left",
    position: 0.16,
    overlaying: 'y',
    titlefont: {
      color: "#7A007A",
      size: 11
    },
    tickfont: {
      color: "#7A007A"
    },
    showgrid: true,
    zeroline: true,
    showline: false,
    visible: true,
    autorange:true,
  },
  yaxis22: {
    title: "",
    side: "left",
    position: 0.16,
    overlaying: 'y',
    titlefont: {
      color: "#FFC000",
      size: 11
    },
    tickfont: {
      color: "#FFC000"
    },
    showgrid: true,
    zeroline: true,
    showline: false,
    visible: true,
    autorange:true,
  },
};
export const BBL_UNIT:string[]=['Qliq_c_e-(bbl/d)','Qliq-(bbl/d)','Qwat-(bbl/d)','Qliq_test-(bbl/d)','Qliq_alloc-(bbl/d)','Qoil_c_e-(bbl/d)','Qoil-(bbl/d)','Qoil_test-(bbl/d)','Qoil_alloc-(bbl/d)','Qliq_mon-(bbl/d)','Qoil_mon-(bbl/d)','Winj_d_alloc-(bbl/d)','Qwinj-(bbl/d)','Winj_e-(bbl/d)','Qwinj_mon-(bbl/d)','winj_FallOff-(bbl/d)','Qwat_c_e-(bbl/d)', 'Qwat_mon-(bbl/d)', 'Qwat_test-(bbl/d)','Qwat_alloc-(bbl/d)']

export const mlPlotColorMap = new Map<string, {color: string; line?: string; name?:string, type?:string; mode?:string; yaxis: string;}>([
  ['Qliq_c_e-(bbl/d)', {color: '#7A007A', line: 'solid', yaxis: 'y2'}],
  ['Qliq-(bbl/d)', {color: '#7A007A', line: 'longdash', yaxis: 'y2'}],
  ['Qliq_test-(bbl/d)', {color: '#7A007A', type:"scatter", yaxis: 'y2'}],
  ['Qliq_alloc-(bbl/d)', {color: '#7A007A', line: 'dashdot', yaxis: 'y2'}],
  ['Qliq_mon-(bbl/d)', {color: '#7A007A', line: 'dash', yaxis: 'y2'}],

  ['Qoil_c_e-(bbl/d)', {color: '#009E00', line: 'solid', yaxis: 'y2'}],
  ['Qoil-(bbl/d)', {color: '#009E00', line: 'longdash', yaxis: 'y2'}],
  ['Qoil_test-(bbl/d)', {color: '#009E00', line: 'dot', yaxis: 'y2'}],
  ['Qoil_alloc-(bbl/d)', {color: '#009E00', line: 'dashdot', yaxis: 'y2'}],
  ['Qoil_mon-(bbl/d)', {color: '#009E00', line: 'dash', yaxis: 'y2'}],

  ['Winj_e-(bbl/d)', {color: '#0000FF', line: 'solid',yaxis: 'y14'}],
  ['Qwinj-(bbl/d)', {color: '#0000FF', line: 'longdash', yaxis: 'y14'}],
  ['Qwinj_mon-(bbl/d)', {color: '#0000FF', line: 'dash', yaxis: 'y14'}],
  ['winj_FallOff-(bbl/d)', {color: '#0000FF', line: 'dot', yaxis: 'y14'}],
  ['Winj_d_alloc-(bbl/d)', {color: '#0000FF', line: 'dashdot',yaxis: 'y14'}],
  ['Qwat_c_e-(bbl/d)', {color: '#0000FF', line: 'solid',yaxis: 'y2'}],
  ['Qwat-(bbl/d)', {color: '#0000FF', line: 'longdash',yaxis: 'y2'}],
  ['Qwat_mon-(bbl/d)', {color: '#0000FF', line: 'dash',yaxis: 'y2'}],
  ['Qwat_test-(bbl/d)', {color: '#0000FF', line: 'dot',yaxis: 'y2'}],
  ['Qwat_alloc-(bbl/d)', {color: '#0000FF', line: 'dashdot',yaxis: 'y2'}],

  ['Qgas_c_e-(kSm3/d)', {color: '#FF0000', line: 'solid', yaxis: 'y4'}],
  ['Qgas-(kSm3/d)', {color: '#FF0000', line: 'longdash', yaxis: 'y4'}],
  ['Qgas_test-(kSm3/d)', {color: '#FF0000', line: 'dot', yaxis: 'y4'}],
  ['Qgas_alloc-(kSm3/d)', {color: '#FF0000', line: 'dashdot', yaxis: 'y4'}],
  ['Qgas_mon-(kSm3/d)', {color: '#FF0000', line: 'dash', yaxis: 'y4'}],
  ['Ginj_e-(kSm3/d)', {color: '#FF0000', line: 'solid', yaxis: 'y4'}],
  ['Qginj-(kSm3/d)', {color: '#FF0000', line: 'longdash', yaxis: 'y4'}],
  ['Ginj_d_alloc-(kSm3/d)', {color: '#FF0000', line: 'dashdot',yaxis: 'y4'}],
  ['Qginj_mon-(kSm3/d)', {color: '#FF0000', line: 'dash', yaxis: 'y4'}],
  ['Qgl_mon-(kSm3/d)', {color: '#FFD915', line: 'dash', yaxis: 'y4'}],


  ['GOR_e-(Sm3/Sm3)', {color: '#FFC000', line: 'solid', yaxis: 'y7' }],
  ['GOR-(Sm3/Sm3)', {color: '#FFC000', line: 'longdash', yaxis: 'y7'}],
  ['GOR_test-(Sm3/Sm3)', {color: '#FFC000', line: 'dot', yaxis: 'y7'}],
  ['GOR_alloc-(Sm3/Sm3)', {color: '#FFC000', line: 'dashdot', yaxis: 'y7'}],
  ['GOR_mon-(Sm3/Sm3)', {color: '#FFC000', line: 'dash', yaxis: 'y7'}],

  

  ['WCT_e-(%)', {color: '#64b5f6', line: 'solid', yaxis: 'y6'}],
  ['WCT-(%)', {color: '#64b5f6', line: 'longdash', yaxis: 'y6'}],
  ['WCT_test-(%)', {color: '#64b5f6', line: 'dot', yaxis: 'y6'}],
  ['WCT_alloc-(%)', {color: '#64b5f6', line: 'dashdot', yaxis: 'y6'}],
  ['WCT_mon-(%)', {color: '#64b5f6', line: 'dash', yaxis: 'y6'}],

  ['GLR_e-(Sm3/Sm3)', {color: '#FF33CC', line: 'solid', yaxis: 'y7'}],
  ['GLR-(Sm3/Sm3)', {color: '#FF33CC', line: 'longdash', yaxis: 'y7'}],
  ['GLR_test-(Sm3/Sm3)', {color: '#FF33CC', line: 'dot', yaxis: 'y7'}],
  ['GLR_alloc-(Sm3/Sm3)', {color: '#FF33CC', line: 'dashdot', yaxis: 'y7'}],
  ['GLR_mon', {color: '#FF33CC', line: 'dash', yaxis: 'y7'}],
  ['OpTime_d_alloc-(hr)', {color: '#FF33CC', line: 'dashdot', yaxis: 'y7'}],

  ['BHT-(°C)', {color: '#ff5722', line: 'solid', yaxis: 'y8'}],
  ['BHT_test-(°C)', {color: '#ff5722', line: 'dot', yaxis: 'y8'}],
  ['BHT_mon-(°C)', {color: '#ff5722', line: 'dash', yaxis: 'y8'}],
  ['BHP_test-(bar)', {color: '#ff5722', line: 'dot', yaxis: 'y8'}],

  ['Topside T-(°C)', {color: '#FF906D', line: 'solid', yaxis: 'y8'}],

  ['WHT_test-(bar)', {color: '#0d47a1', line: 'dot', yaxis: 'y10'}],
  ['BHP-(bar)', {color: '#0d47a1', line: 'solid', yaxis: 'y10'}],
  ['BHP_mon-(bar)', {color: '#0d47a1', line: 'dash', yaxis: 'y10'}],
  ['BHFP_Z_Gauge-(bar)', {color: '#0d47a1', line: 'dot', yaxis: 'y10'}],

  ['WHT-(°C)', {color: '#ff9800', line: 'solid', yaxis: 'y8'}],
  ['WHT_mon-(°C)', {color: '#ff9800', line: 'dash', yaxis: 'y8'}],

  ['Qgl_c_e-(kSm3/d)', {color: '#E2BC00', line: 'solid', yaxis: 'y4'}],

  ['Topside P-(bar)', {color: '#4806A8', line: 'solid', yaxis: 'y10'}],

  ['WHP-(bar)', {color: '#2196f3', line: 'solid', yaxis: 'y10'}],
  ['WHP_test-(bar)', {color: '#2196f3', line: 'dot', yaxis: 'y10'}],
  ['WHP_mon-(bar)', {color: '#2196f3', line: 'dash', yaxis: 'y10'}],
  ['BHP_Gauge-(bar)', {color: '#2196f3', line: 'dot', yaxis: 'y10'}],

  ['Optime-(hr)', {color: '#e91e63', line: 'solid', yaxis: 'y3'}],
  ['Optime_test-(hr)', {color: '#e91e63', line: 'dash', yaxis: 'y3'}],
  ['Optime_mon-(hr)', {color: '#e91e63', line: 'dash',yaxis: 'y3'}],

  ['Choke Opening-(%)', {color: '#6C7678', line: 'solid', yaxis: 'y5'}],
  ['Choke Opening_test-(%)', {color: '#6C7678', line: 'dot', yaxis: 'y5'}],
  ['Choke Opening_mon-(%)', {color: '#6C7678', line: 'dash', yaxis: 'y5'}],

  ['Choke size-(inch)', {color: '#505758', line: 'solid', yaxis: 'y9'}],
  ['Choke size_test-(inch)', {color: '#505758', line: 'dot', yaxis: 'y9'}],
  ['Choke size_mon-(inch)', {color: '#505758', line: 'dot', yaxis: 'y9'}],

  ['Jumper_T_mon-(°C)', {color: '#965900', line: 'dash', yaxis: 'y8'}],

  ['Jumper_P_mon-(bar)', {color: '#051939', line: 'dash', yaxis: 'y10'}],
  ['BHP_Datum_Manual-(Sm3/Sm3)', {color: '#051939', line: 'dot',yaxis: 'y7'}],
  
  ['PI_II-(Sm3/d/bar)', {color: '#00B050', line: 'dot', yaxis: 'y7'}]
]);
 
