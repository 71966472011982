
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { PlotData} from 'src/modules/types';
import { DEFAULT_LAYOUT } from './default-layout.const';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Component({
  selector: 'wag-well-graph',
  template: `
    <plotly-plot [data]="data" [layout]="layout" [style]="style" [useResizeHandler]="true" [config]="config"
      (legendClick)="onLegendClick($event)" (legendDoubleClick)="onLegendDoubleClick($event)"></plotly-plot>
  `,
})
export class WellGraphComponent implements OnInit {
  @Input() data: PlotData[] = [];
  @Input() title?: string = '';
  @Input() layout?= JSON.parse(JSON.stringify(DEFAULT_LAYOUT));
  @Input() style?= {};
  @Input() config?= {};
  @Input() isFieldProd?= false;
  @Input() responseData: any;
  @Output() downloadChart = new EventEmitter();
  @Input() graphTitle?:any;
  @Input() selectedWell!:any;
  @Input() featureTitle!:any;
  lockIcon = { 'width': 1000, color: 'rgba(0,0,0,.87)', 'path': 'M15.608,6.262h-2.338v0.935h2.338c0.516,0,0.934,0.418,0.934,0.935v8.879c0,0.517-0.418,0.935-0.934,0.935H4.392c-0.516,0-0.935-0.418-0.935-0.935V8.131c0-0.516,0.419-0.935,0.935-0.935h2.336V6.262H4.392c-1.032,0-1.869,0.837-1.869,1.869v8.879c0,1.031,0.837,1.869,1.869,1.869h11.216c1.031,0,1.869-0.838,1.869-1.869V8.131C17.478,7.099,16.64,6.262,15.608,6.262z M9.513,11.973c0.017,0.082,0.047,0.162,0.109,0.226c0.104,0.106,0.243,0.143,0.378,0.126c0.135,0.017,0.274-0.02,0.377-0.126c0.064-0.065,0.097-0.147,0.115-0.231l1.708-1.751c0.178-0.183,0.178-0.479,0-0.662c-0.178-0.182-0.467-0.182-0.645,0l-1.101,1.129V1.588c0-0.258-0.204-0.467-0.456-0.467c-0.252,0-0.456,0.209-0.456,0.467v9.094L8.443,9.553c-0.178-0.182-0.467-0.182-0.645,0c-0.178,0.184-0.178,0.479,0,0.662L9.513,11.973z', 'transform': 'scale(0.84) translate(-1, -1)' }
  static graphTitleNew='';

  ngOnInit(): void {
     let _self=this;

    WellGraphComponent.graphTitleNew=this.graphTitle;
    if (this.title) {this.layout.title.text = this.title;}
    if(this.featureTitle!=undefined){
      this.layout.title.text = this.featureTitle;
    }
    this.downloadChart.emit()
    if(this.graphTitle=="ProductionData" || this.graphTitle=="ProductionMonitoring"){
    this.config = {
      modeBarButtonsToAdd: [{
        name: 'Download as Excel File', icon: this.lockIcon, click: function ($event: any) {

             _self.downloadData($event.data);

        }
      }],
    }
  }
  }

  downloadData(data: any) {
  if(this.graphTitle=="ProductionData" || this.graphTitle=="ProductionMonitoring")
  {
    let csvJsonData: any[] = [];
    let graphData: PlotData[] = data
    WellGraphComponent.exportAsExcelFile(graphData, csvJsonData,this.graphTitle,this.selectedWell);
  }
  }

  public static exportAsExcelFile(graphData: any,csvJsonData: any[],graphTitle:string, selectedWell:any): void {
    let wellName=selectedWell;
    let qgl ="";
    let finalqgl="";
    let ArrayList1: any[][] = [];
    ArrayList1.push(graphData);
    let elemCount;
    let finalData: any[] = [];
    for (let [key, value] of Object.entries(ArrayList1[0])) {

      elemCount = value.y.length;

      let blankCell = "";
      if(graphTitle=="ProductionData")
      {
        qgl ="qgl_c_e";
        finalqgl="Qgl_c_e";
      }
      else if(graphTitle=="ProductionMonitoring")
      {
        qgl ="qgl";
        finalqgl="Qgl";
      }

      for (let i = 0; i < elemCount; i++) {
        if (!finalData[i]) {
          finalData[i] = {
            Date:'null',
            optime: 'null',
            whp: 'null',
            wht: 'null',
            bhp: 'null',
            bht: 'null',
            choke_opening: 'null',
            [qgl]: 'null',
            [blankCell]: '',
            DateTest:'null',
            qoil_test: 'null',
            qwat_test: 'null',
            qgas_test: 'null',
          };
        }

        if (value.y != null && value.y[i] != "" && value.y[i] != " ") {
          if (value.name.replace("-(%)",'') == "optime" || value.name.replace("-(bar)",'') == "whp"|| value.name.replace("-(°C)",'') == "wht"|| value.name.replace("-(bar)",'') =="bhp"|| value.name.replace("-(°C)",'') == "bht"
          || value.name.replace("-(%)",'') == "choke_opening")
           {
            key = value.name.replace("-(%)",'').replace("-(bar)",'').replace("-(°C)",'').replace("-(bar)",'');
            if(value.y[i]==null || value.y[i]==undefined)
            {
              finalData[i][key]="null";
            }
            else
            {
              finalData[i][key] = value.y[i];
              finalData[i]["Date"] = moment(value.x[i]).format('DD/MM/YYYY');

            }

          }

         else if (value.name.replace("-(bbl/d)",'') == "qgl_c_e" || value.name.replace("-(kSm3/d)",'') == "qgl" ) {

            key = value.name.replace("-(bbl/d)",'').replace("-(kSm3/d)",'');
            if(value.y[i]==null || value.y[i]==undefined)
            {
              finalData[i][key]="null";
            }
            else
            {
              finalData[i][key] = value.y[i];
            }

          }
          else if( value.name.replace("-(bbl/d)",'') == "qoil_test"|| value.name.replace("-(bbl/d)",'') == "qwat_test"|| value.name.replace("-(kSm3/d)",'') == "qgas_test")
          {

            key = value.name.replace("-(bbl/d)",'').replace("-(kSm3/d)",'');
            if(value.y[i]==null || value.y[i]==undefined)
            {
              finalData[i][key]="null";
            }
            else
            {
              finalData[i]["DateTest"] =  moment(value.x[i]).format('DD/MM/YYYY');
              finalData[i][key] = value.y[i];

            }
          }


        }

      }

    }

    finalData.sort(function(a, b){
      let x = a.Date;
      let y = b.Date;
      if (y==="null") {return -1;}
      if (x < y) {return -1;}
      if (x > y) {return 1;}
      return 0;
    });

    let excelJsonData = finalData;
    let Heading = [['Date','Optime', 'WHP', 'WHT', 'BHP', 'BHT', 'Choke Opening',[finalqgl],'','Date Test','Qoil Test', 'Qwat Test', 'Qgas Test'],
    ['-','HR', 'Pressure, Bar', 'Temprature, Degree c', 'Pressure, Bar', 'Temprature, Degree c', '-','kSm3/d','','-', 'bbl/d', 'bbl/d', 'kSm3/d']];

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelJsonData);
    XLSX.utils.sheet_add_json(worksheet, excelJsonData, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(worksheet, Heading, { origin: "A1" });
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    WellGraphComponent.saveAsExcelFile(excelBuffer, wellName);
  }
  private static saveAsExcelFile(buffer: any, selectedWell:any): void {
    let fileName = selectedWell.asset + '_' + selectedWell.well + '_'+moment().format('YYYY-MM-DD');
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
  onLegendClick(event: any): void {
    if (this.title && (this.title === 'Production Data - PDMS Data' || this.title === 'Production Monitoring - PI Data')) return;
    const yaxisList = Object.keys(this.layout).filter(element => element.startsWith('yaxis'));
    if (yaxisList.length > 1) {
      let selectedPlot = yaxisList[event.curveNumber];

      if (this.isFieldProd === true) {
        if (event.curveNumber == 0 && event.data[1].visible === true) {
          selectedPlot = 'yaxis';
          this.layout[selectedPlot].visible = this.layout[selectedPlot].visible;
        }

        else if (event.curveNumber == 1 && event.data[0].visible === true) {
          selectedPlot = 'yaxis';
          this.layout[selectedPlot].visible = this.layout[selectedPlot].visible;
        }

        else if (event.curveNumber == 0 && event.data[1].visible == 'legendonly') {
          selectedPlot = 'yaxis';
          this.layout[selectedPlot].visible = !this.layout[selectedPlot].visible;
        }

        else if (event.curveNumber == 1 && event.data[0].visible == 'legendonly') {
          selectedPlot = 'yaxis';
          this.layout[selectedPlot].visible = !this.layout[selectedPlot].visible;
        }

        else if (event.curveNumber == 2) {
          selectedPlot = 'yaxis2';
          this.layout[selectedPlot].visible = !this.layout[selectedPlot].visible;
        }

        else if (event.curveNumber == 3) {
          selectedPlot = 'yaxis3';
          this.layout[selectedPlot].visible = !this.layout[selectedPlot].visible;
        }
        else if (event.curveNumber == 4) {
          selectedPlot = 'yaxis4';
          this.layout[selectedPlot].visible = !this.layout[selectedPlot].visible;
        }
      }
      else {
        this.layout[selectedPlot].visible = !this.layout[selectedPlot].visible;
      }

    }
  }


  onLegendDoubleClick(event: any): void {

  }
}
