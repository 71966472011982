export const INJ_DATA_ATTR = [
  'Winj_e-(bbl/d)',
  'Ginj_e-(kSm3/d)',
  'BHP-(bar)',
  'BHT-(°C)',
  'WHP-(bar)',
  'WHT-(°C)',
  'Optime-(hr)',
  'Choke Opening-(%)',
  'Choke size',
];

export const POTENTIAL_INJ_ATTR = [
  'Qginj-(kSm3/d)',
  'Qwinj-(bbl/d)',
];

export const PRESSURE_STATIC_ATTR = [
  'PI_II-(Sm3/d/bar)',
  'BHP_Gauge-(bar)',
  'BHFP_Z_Gauge-(bar)',
  'BHP_Datum_Manual-(Sm3/Sm3)',
  'winj_FallOff-(bbl/d)',
];

export const INJ_MONITORING_ATTR = [
  'Qwinj_mon-(bbl/d)',
  'Qginj_mon-(kSm3/d)',
  'Qgas_mon-(kSm3/d)',
  'Jumper_P_mon-(bar)',
  'Jumper_T_mon-(°C)',
  'BHP_mon-(bar)',
  'BHT_mon-(°C)',
  'WHP_mon-(bar)',
  'WHT_mon-(°C)',
  'Optime_mon-(hr)',
  'Choke Opening_mon-(%)',
];

export const INJ_ALLOCATION_ATTR = [
  'Ginj_d_alloc-(kSm3/d)',
  'Winj_d_alloc-(bbl/d)',
  'OpTime_d_alloc-(hr)',
];
