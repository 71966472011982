export const TARGET_LIST_PRRODUCER = ['Qoil', 'Qgas', 'Qwat', 'Qliq', 'GOR', 'GLR', 'WCT'];
export const TARGET_LIST_INJECTOR = ['Qwinj', 'Qginj'];

export const INPUT_FEATURE_LIST_PRODUCER = ['WHT', 'BHT', 'WHP', 'BHP', 'Choke Opening', 'Topside T', 'Topside P','Qgl_c_e', 'Choke Size'];
export const INPUT_FEATURE_LIST_INJECTOR = ['WHT', 'BHT', 'WHP', 'BHP', 'Choke Opening', 'Choke Size'];

export const INPUT_SOURCE_LIST = ['PI', 'PDMS'];

export const ML_ALGO_LIST = ['Linear - Ridge', 'Linear - Lasso', 'Linear - Elastic', 'Random Forest', 'SVM', 'Extreme Gradient Boosting'];

export const OptimizeList= [{ name:'Default Parameters', defaultValue:'False'}, { name:'Advanced Optimization', defaultValue:'True', tooltip:'Hyperparameter optimization helps you improve the performance of a model, gives better generalization and avoid the underfitting/Overfitting risk. By default models are optimized but you can use this option as an advanced feature.'}];

export const ML_TRAINING_SET_SIZE = ['0.5', '0.6', '0.7', '0.8', '0.9'];

export const ML_TARGET_SOURCE_PRODUCER = ['PS Production Test Data', 'PS Production Data', 'PS Production Monitoring', 'PS Daily Production Allocation'];//New
export const ML_TARGET_SOURCE_INJECTOR = ['Injection FallOff Test','PS Injection Data', 'PS Injection Monitoring', 'PS Daily Injection Allocation'];
export const ML_TARGET_SOURCE_INJECTORQGINJ = ['PS Injection Data', 'PS Injection Monitoring', 'PS Daily Injection Allocation'];
