import { Component, OnInit } from '@angular/core';
import { AdditionalLinks, Affiliate } from 'src/modules/types';
import { AppSandbox } from '../../app.sandbox';
import { ADDITIONALLINKS, AFFILIATELIST } from './home-constant';


@Component({
  selector: 'wag-home',
  template: `
   <div class="mr-t-24">
   <wag-additional-link [affiliate]="selectedAffilate" [addtionalLinks]="addtionalLinks"></wag-additional-link>
   </div> 
  `,
  styleUrls: ['./home-container.component.scss']
})
export class HomeContainer  implements OnInit{
  addtionalLinksAll: AdditionalLinks[] = ADDITIONALLINKS;
  addtionalLinks: AdditionalLinks[] = [];
  selectedAffilate: any = ''
  // routeSubscription!: Subscription;
  // @Input() passedData: any;
  constructor(private sb: AppSandbox) {

  }
  
  ngOnInit(): void {

    this.sb.selectedAffiliate$.subscribe(aff => {
      if (aff !== '') {
        
        this.addtionalLinks = this.addtionalLinksAll.filter(link => link.aff === aff);
        if(this.addtionalLinks.length>0){
          this.selectedAffilate = this.addtionalLinks[0].affiliates;
        }else{
          let affarray:Affiliate[]=AFFILIATELIST.filter(affilate=> affilate.value ===aff);
          this.selectedAffilate=affarray[0].displayName;
        }
      }

      this.sb.loadUserName(); 

    })

        
  }
 
}
