import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { Layout } from 'plotly.js';
import { FieldRangeDates, MLConfidenceData, MLRunParams, PlotData, ValueSelect } from 'src/modules/types';
import { MlVisualizeInjectorComponent } from './ml-visualize-injector/ml-visualize-injector.component';
import { MlVisualizeComponent } from './ml-visualize/ml-visualize.component';
@Component({
  selector: 'wag-machine-learning',
  styleUrls: ['./machine-learning.component.scss'],
  template: `
    <div class="section-container">
      <h2>Machine Learning</h2>

      <mat-accordion multi="true">
        <mat-expansion-panel>
          <mat-expansion-panel-header>Settings</mat-expansion-panel-header>
          <wag-ml-settings [dateRange]="dateRange"
                           [minMaxRange]="minMaxRange"
                           (dateRangeChange)="dateRangeChange.emit($event)"
                           [dateRange1]="dateRange1"
                           [minMaxRange1]="minMaxRange1"
                           (dateRangeChange1)="dateRangeChange1.emit($event)"
                           (clickValidFlag)="clickValidFlag.emit($event)"
                           (clicklearnigFlag)="clicklearnigFlag.emit($event)"
                           (valueSelectedSource)="valueSelectedSource.emit($event)"
                           (clickZeroValue)="clickZeroValue.emit($event)"
                           (clickAddRemovePicker)="clickAddRemovePicker.emit($event)"
                           [inferenceDateRange]="inferenceDateRange"
                           [inferenceMinMaxRange]="inferenceMinMaxRange"
                           (inferenceDateRangeChange)="inferenceDateRangeChange.emit($event)"
                           (clickRunML)="onClickRunML($event)"
                           [wellTestStatus]="wellTestStatus"
                           [trainingDataGraph]="trainingDataGraph"
                           [trainingDataLayout]="trainingDataLayout"
                           [featureDataLayout]="featureDataLayout"
                           [featureDataGraph]="featureDataGraph"
                           (targetValueSelected)="targetValueSelected.emit($event)"
                           (inputSourceValueSelected)="inputSourceValueSelected.emit($event)"
                           (targetSourceValueSelected)="targetSourceValueSelected.emit($event)"
                           (ClickInputFeature)="ClickInputFeature.emit($event)"
                           (ClickUserAddedFeature)="ClickUserAddedFeature.emit($event)"
                           (wellStatusSelected)="wellStatusSelected.emit($event)"
                           (ColunmSourceSelected)="ColunmSourceSelected.emit($event)"
                           (TableValueSelected)="TableValueSelected.emit($event)"
                           [tMoreCloumnInfo]="tMoreCloumnInfo"
                           [TmoreTableList]="TmoreTableList"
                           [columSourceList]="columSourceList"
                           [featuresList]="featuresList"
                           [percentageList]= "percentageList"
                           [wellType]="wellType"
                          >
                           </wag-ml-settings>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>Confidence</mat-expansion-panel-header>
          <wag-ml-confidence  [mlConfidenceData]="mlConfidenceData"
                              [mlMetricsGraph]="mlMetricsGraph"
                              [mlMetricsLayout]="mlMetricsLayout"
                              [displayedMetricsGraph]="displayedMetricsGraph"
                              (switchMetricsGraph)="switchMetricsGraph.emit($event)"
                              [mlLearningCurvesGraph]="mlLearningCurvesGraph"
                              [mlLearningCurvesLayout]="mlLearningCurvesLayout"
                              [mlFeaturesImportanceGraph]="mlFeaturesImportanceGraph"
                              [mlFeaturesImportanceLayout]="mlFeaturesImportanceLayout"
                              [mlCorelationGraph]="mlCorelationGraph"
                              [mlCorelationLayout]="mlCorelationLayout"
                              [target]="target"
                              [mlFeaturesGraph]="mlFeaturesGraph"
                              [mlFeaturesLayout]="mlFeaturesLayout"
                              [mlFeatureGraphTitle]="mlFeatureGraphTitle"
                              [mlFeatureXtitle]="mlFeatureXtitle"></wag-ml-confidence>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>Visualisation</mat-expansion-panel-header>
          <button [disabled]="isRunMlClicked" class="primary-btn" (click)="downloadCSV.emit()" style="margin-right:16px">Export</button>
          <button  [disabled]="isRunMlClicked" class="primary-btn" (click)="clickSaveModel.emit()">Save Model</button>
          <wag-ml-visualize *ngIf="wellTypeProducer===true" [machineLearningGraph]="machineLearningGraph"
                            [machineLearningLayout]="machineLearningLayout"
                            (clickCheckbox)="clickCheckbox.emit($event)">
                            <!-- [selectedUserAdded]="selectedUserAdded" -->
                          </wag-ml-visualize>

          <wag-ml-visualize-injector *ngIf="wellTypeInjector===true && wellTypeProducer===false" [machineLearningGraph]="machineLearningGraph"
                            [machineLearningLayout]="machineLearningLayout"
                            (clickInjectorCheckbox)="clickInjectorCheckbox.emit($event)">
</wag-ml-visualize-injector>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  `,
})
export class MachineLearningComponent {
  @ViewChild(MlVisualizeComponent) child1!: MlVisualizeComponent;
  @ViewChild(MlVisualizeInjectorComponent) child2!: MlVisualizeInjectorComponent;
  
  @Output() clickRunML: EventEmitter<MLRunParams> = new EventEmitter<MLRunParams>();
  @Input() machineLearningGraph!: PlotData[];
  @Input() machineLearningLayout!: Partial<Layout>;
  @Input() dateRange!: FieldRangeDates | null;
  @Input() minMaxRange!: FieldRangeDates | null;
  @Output() dateRangeChange: EventEmitter<FieldRangeDates> = new EventEmitter<FieldRangeDates>();

  @Input() dateRange1!: FieldRangeDates | null;
  @Input() minMaxRange1!: FieldRangeDates | null;
  @Output() dateRangeChange1: EventEmitter<FieldRangeDates> = new EventEmitter<FieldRangeDates>();

  @Input() inferenceDateRange!: FieldRangeDates | null;
  @Input() inferenceMinMaxRange!: FieldRangeDates | null;
  @Output() inferenceDateRangeChange: EventEmitter<FieldRangeDates> = new EventEmitter<FieldRangeDates>();

  @ViewChild(MatAccordion) accordion!: MatAccordion;
  @Output() clickCheckbox: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickInjectorCheckbox: EventEmitter<any> = new EventEmitter<any>();
  @Input() mlConfidenceData!: MLConfidenceData;
  @Input() mlMetricsGraph!: PlotData[];
  @Input() mlMetricsLayout!: Partial<Layout>;
  @Input() displayedMetricsGraph!: string;
  @Output() switchMetricsGraph: EventEmitter<string> = new EventEmitter<string>();
  @Input() mlLearningCurvesGraph!: PlotData[];
  @Input() mlLearningCurvesLayout!: Partial<Layout>;
  @Input() wellTestStatus!: string[] | null;
  @Input() mlFeaturesImportanceLayout!: Partial<Layout>;
  @Input() mlFeaturesImportanceGraph!: PlotData[];

    @Input() mlCorelationGraph!: PlotData[];
  @Input() mlCorelationLayout!: Partial<Layout>;
  @Output() downloadCSV: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickSaveModel:EventEmitter<any>=new EventEmitter<any>();
  @Input() isRunMlClicked?:boolean;
  @Input() target!:string;
  @Input() tMoreCloumnInfo!:string;
  @Input() colunmSourceValue!:string;
  @Input() columSourceList!:any;
  @Input() TmoreTableList!:any;
  @Input() featuresList !:any;
  @Input() percentageList!: any;
  @Input() trainingDataLayout!:any;
  @Input() trainingDataGraph!: PlotData[];
  @Input() featureDataLayout!:any;
  @Input() featureDataGraph!:PlotData[];
  @Input() wellType!:string;
  @Input() selectedUserAdded!:any;
  @Output() targetValueSelected = new EventEmitter<ValueSelect>();
  @Output() valueSelectedSource=new EventEmitter<ValueSelect>();
  @Output() inputSourceValueSelected= new EventEmitter<ValueSelect>();
  @Output() targetSourceValueSelected = new EventEmitter<ValueSelect>();
  @Output() ClickInputFeature: EventEmitter<any> = new EventEmitter<any>();
  @Output() ClickUserAddedFeature: EventEmitter<any>=new EventEmitter<any>();
  @Output() wellStatusSelected = new EventEmitter<ValueSelect>();
  @Output() ColunmSourceSelected = new EventEmitter<ValueSelect>();
  @Output() TableValueSelected = new EventEmitter<ValueSelect>();
  
  @Output() clickValidFlag: EventEmitter<ValueSelect> = new EventEmitter<ValueSelect>();
  @Output() clicklearnigFlag:EventEmitter<ValueSelect>=new EventEmitter<ValueSelect>();
  @Input() wellTypeInjector!:boolean;
  @Input() wellTypeProducer!:boolean;
  @Output() clickZeroValue:EventEmitter<ValueSelect>=new EventEmitter<ValueSelect>();

  @Input() mlFeaturesGraph!: PlotData[][];
  @Input() mlFeaturesLayout!: any[];
  @Input() mlFeatureGraphTitle!:string[]
  @Input() mlFeatureXtitle!:string[];

  @Output() clickAddRemovePicker = new EventEmitter<boolean>();

  clearCheck(){
  
      this.child1.clearAll();
    
  
  }

  clearCheckInjector(){
  
    this.child2.clearAllAttr();
    
  }
  onClickRunML(mLRunParams: MLRunParams): void {
    this.clickRunML.emit(mLRunParams);
    this.accordion.openAll();
  }

}
