import { GetAllocatioCatalogueTableData } from './../statemanagement/allocation-table-data/actions';
import { AllocationRangeDatesState } from './../statemanagement/allocation-range-dates/state';
import { GetAllocationRangeDates } from './../statemanagement/allocation-range-dates/actions';
import { BackAllocationService } from './services/back-allocation/back-allocation.service';
import { GetFieldRangeDates1 } from './../statemanagement/field-range-dates1/actions';
import { GetFieldRangeDates2 } from './../statemanagement/field-range-dates2/actions';
import { GetFieldRangeDates3 } from './../statemanagement/field-range-dates3/actions';
import { Injectable } from '@angular/core';
import { AccountInfo } from '@azure/msal-browser';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  AssetsState, AttributeDataState, DailyDataState,
  FieldGasInjectionState,
  FieldOilProductionState,
  FieldRangeDatesState,
  FieldGasProductionState,
  FieldLiquidProductionState,
  FieldsState,
  FieldWaterInjectionState,
  GetAssets, GetAttributeData, GetDailyData,
  GetFieldGasInjection,
  GetFieldOilProduction,
  GetFieldRangeDates,
  GetFields,
  GetFieldWaterInjection, GetInferenceRangeDates, GetInjectorWellsDailyData,
  GetInjectorWellsOptimesData,
  GetMachineLearningData, GetMLRunParams, GetModelCatalogueTableData, GetProducerWellsDailyData,
  GetProducerWellsOptimesData,
  GetProductionAllocated, GetQcRangeDates, GetCatalogRangeDates, GetSaveModelData, GetSaveModelTableData, GetSelectedWell, GetTrainingDataVisualization, GetFeatureDataVisualization, GetUserName, GetWellQcTableData, GetWells, GetWellTestStatus, InferenceRangeDatesState, InjectorWellsDailyDataState,
  InjectorWellsOptimesDataState,
  MachineLearningDataState, ModelCatalogueTableDataState, ProducerWellsDailyDataState,
  ProducerWellsOptimesDataState, CatalogRangeDatesState,
  ProductionAllocatedState, QcRangeDatesState, SaveModelDataState, SaveModelTableDataState, SelectedWellState, TrainingDataVisualizationState, FeatureDataVisualizationState, UserNameState, WellQcTableDataState, WellsState, WellTestStatusState, AllocationCatalogueTableDataState
} from '../statemanagement';
import { GetFieldGasProduction } from '../statemanagement/field-gas-production';
import { GetFieldLiquidProduction } from '../statemanagement/field-liquid-production';
import { FieldRangeDatesState1 } from '../statemanagement/field-range-dates1';
import { FieldRangeDatesState2 } from '../statemanagement/field-range-dates2';
import { FieldRangeDatesState3 } from '../statemanagement/field-range-dates3';
import { MLRunParamsState } from '../statemanagement/ml-run-params';
import {
  AttributeData,
  AttributeType, AttributeTypeInjector, DailyDataFilters,
  DataFilters, DataFiltersInference, DataType,
  FieldGraphData,
  FieldRangeDates,
  MachineLearningData,
  MLRunParams,
  ProductionAllocated,
  ProductionData,
  ProductionDataInjector,
  ProductionMonitoring,
  ProductionMonitoringInjector,
  ResponseI, SaveModelParams,
  SaveModelTableData, SelectedWellTypeInterface, TMoreColumnsInfo, TrainingDataVisualizationInterface, FeatureDataVisualizationInterface, WellDailyData,
  WellOptimesData, WellQcTableData, WellType, StageResponse, VFMModel, SaveAllocationData
} from '../types';
import { ModelCatalogueData} from '../types/model-catalogue-data';
import { DailyData } from './containers';
import {
  AuthService,
  BuildModelService,
  FieldMonitoringService,
  WellMonitoringService,
  MonitoringService,
  WellProductionService,
  WellSelectionService
} from './services';
import { MergeModelService } from './services/merge-model/merge-model.service';
import { GetMergeModelData, MergeModelDataState } from '../statemanagement/merge-model-data';
import { SaveMergeModel } from '../types/merge-model.interface';



@Injectable({
  providedIn: 'root'
})
export class AppSandbox {
  @Select(AssetsState) assets$!: Observable<string[]>;
  @Select(FieldsState) fields$!: Observable<string[]>;
  @Select(WellsState) wells$!: Observable<string[]>;
  @Select(DailyDataState) dailyData$!: Observable<DailyData>;
  @Select(ProductionAllocatedState) productionAllocated$!: Observable<ProductionAllocated>;
  @Select(FieldOilProductionState) fieldOilProduction$!: Observable<FieldGraphData>;
  @Select(FieldGasInjectionState) fieldGasInjection$!: Observable<FieldGraphData>;
  @Select(FieldWaterInjectionState) fieldWaterInjection$!: Observable<FieldGraphData>;
  @Select(FieldGasProductionState) fieldGasProdInjection$!: Observable<FieldGraphData>;
  @Select(FieldLiquidProductionState) fieldLiquidInjection$!: Observable<FieldGraphData>;
  @Select(UserNameState) userName$!: Observable<string>;
  @Select(ProducerWellsDailyDataState) producerWellsDailyData$!: Observable<WellDailyData[]>;
  @Select(ProducerWellsOptimesDataState) producerWellsOptimesData$!: Observable<WellOptimesData[]>;
  @Select(InjectorWellsOptimesDataState) injectorWellsOptimesData$!: Observable<WellOptimesData[]>;
  @Select(InjectorWellsDailyDataState) injectorWellsDailyData$!: Observable<WellDailyData[]>;
  @Select(FieldRangeDatesState)  fieldRangeDates$!: Observable<FieldRangeDates>;
  @Select(FieldRangeDatesState1) fieldRangeDates1$!: Observable<FieldRangeDates>;
  @Select(FieldRangeDatesState2) fieldRangeDates2$!: Observable<FieldRangeDates>;
  @Select(FieldRangeDatesState3) fieldRangeDates3$!: Observable<FieldRangeDates>;

  @Select(MachineLearningDataState) machineLearningData$!: Observable<MachineLearningData>;
  @Select(SelectedWellState) selectedWell$!: Observable<SelectedWellTypeInterface>;
  @Select(AttributeDataState) attributeDataState$!: Observable<AttributeData>;
  @Select(MLRunParamsState) mlRunParams$!: Observable<MLRunParams>;
  @Select(WellTestStatusState) wellTestStatus$!: Observable<string[]>;
  @Select(WellQcTableDataState) wellQcTableData$!: Observable<WellQcTableData[]>;
  @Select(QcRangeDatesState) qcRangeDates$!: Observable<FieldRangeDates>;
  @Select(CatalogRangeDatesState) catalogRangeDates$!: Observable<FieldRangeDates>;
  @Select(AllocationRangeDatesState) allocationRangeDates$!: Observable<FieldRangeDates>;

  @Select(SaveModelDataState) saveModelData$!: Observable<string>;
  @Select(SaveModelTableDataState) saveModelTableData$!: Observable<SaveModelTableData[]>;
  @Select(AllocationCatalogueTableDataState) saveAllocationTableData$!: Observable<SaveAllocationData[]>;
  @Select(ModelCatalogueTableDataState) modelCatalogueTableData$!: Observable<ModelCatalogueData[]>;
  @Select(InferenceRangeDatesState) inferenceRangeDates$!: Observable<FieldRangeDates>;
  @Select(TrainingDataVisualizationState) trainingDataVisualization$!: Observable<TrainingDataVisualizationInterface>;
  @Select(FeatureDataVisualizationState) featureDataVisualization$!: Observable<FeatureDataVisualizationInterface>;
  @Select(MergeModelDataState) mergeModelTableData$!: Observable<SaveMergeModel[]>;

  fieldSelectChange = new Subject<void>();
  selectedRunMLParams = new BehaviorSubject<any>({});
  selectedRunMLParams$ = this.selectedRunMLParams.asObservable();
  selectedAffiliate = new BehaviorSubject<string>('');
  selectedAffiliate$ = this.selectedAffiliate.asObservable();

  clearMLSettings = new BehaviorSubject(false);
  clearMLSettings$ = this.clearMLSettings.asObservable();

  constructor(private store: Store,
    private wellSelectionService: WellSelectionService,
    private wellProductionService: WellProductionService,
    private fieldMonitoringService: FieldMonitoringService,
    private wellMonitoringService:WellMonitoringService,
    private authService: AuthService,
    private buildModelService: BuildModelService,
    private moniterService: MonitoringService,
    private backAllocationService:BackAllocationService,
    private mergeModelService: MergeModelService) {
  }

  loadAssets(aff: string): Observable<string[]> {
    return this.wellSelectionService.getAssets(aff).pipe(

      tap((assets: string[]) => {
        let tempAssest: string[] = assets.filter((ass => ass !== "UnKnown"));
        this.store.dispatch(new GetAssets(tempAssest))
      })
    );
  }

  loadFields(aff: string, asset: string): Observable<string[]> {
    return this.wellSelectionService.getFields(aff, asset).pipe(
      tap((fields: string[]) => this.store.dispatch(new GetFields(fields)))
    );
  }

  loadWells(aff: string, asset: string, field: string, wellType?: string): Observable<string[]> {
    return this.wellSelectionService.getWells(aff, asset, field, wellType).pipe(
      tap((wells: string[]) => this.store.dispatch(new GetWells(wells)))
    );
  }
  loadWellType(aff: string, well: string):  Observable<ResponseI<any>> {
    return this.wellMonitoringService.getWellType(aff, well);
  }
  loadProductionData(aff: string, filterData: DataFilters): Observable<ProductionData> {
    return this.wellProductionService.getProductionData(aff, filterData);
  }

  loadProductionMonitoring(aff: string, filterData: DataFilters): Observable<ProductionMonitoring> {
    return this.wellProductionService.getProductionMonitoring(aff, filterData);
  }

  loadProductionDataInjector(aff: string, filterData: DataFilters): Observable<ProductionDataInjector> {
    return this.wellProductionService.getProductionDataInjector(aff, filterData);
  }

  loadProductionMonitoringInjector(aff: string, filterData: DataFilters): Observable<ProductionMonitoringInjector> {
    return this.wellProductionService.getProductionMonitoringInjector(aff, filterData);
  }

  loadwellDailyData(aff: string, dailyDataFilters: DailyDataFilters, well:string, typeofWell:string): void {
    this.wellMonitoringService.getDailyData(aff, dailyDataFilters, well, typeofWell).pipe(
      tap((dailyData: ResponseI<DailyData>) => this.store.dispatch(new GetDailyData(dailyData.response)))
    ).subscribe();
  }

  loadDailyData(aff: string, dailyDataFilters: DailyDataFilters): void {

    this.fieldMonitoringService.getDailyData(aff, dailyDataFilters).pipe(
      tap((dailyData: ResponseI<DailyData>) => this.store.dispatch(new GetDailyData(dailyData.response)))
    ).subscribe();
  }

  loadwellProductionAllocated(aff: string, filterData: DataFilters, well:string, typeofWell:string): void {
    this.wellMonitoringService.getProductionAllocated(aff, filterData, well, typeofWell).pipe(
      tap((productionAllocated: ResponseI<ProductionAllocated>) =>
        this.store.dispatch(new GetProductionAllocated(productionAllocated.response))
      )
    ).subscribe();
  }

  loadProductionAllocated(aff: string, filterData: DataFilters): void {
    this.fieldMonitoringService.getProductionAllocated(aff, filterData).pipe(
      tap((productionAllocated: ResponseI<ProductionAllocated>) =>
        this.store.dispatch(new GetProductionAllocated(productionAllocated.response))
      )
    ).subscribe();
  }

  loadFieldOilProduction(aff: string, filterData: DataFilters, welltype?:string): void {
    this.wellMonitoringService.getDataOnTimelapseWell(aff, DataType.OIL_PRODUCTION, filterData, welltype!).pipe(
      tap((fieldOilProduction: ResponseI<FieldGraphData>) =>
        this.store.dispatch(new GetFieldOilProduction(fieldOilProduction.response))
      )
    ).subscribe();
  }

  loadWellGasInjection(aff: string, filterData: DataFilters, welltype?:string): void {
    this.wellMonitoringService.getDataOnTimelapseWell(aff, DataType.GAS_INJECTION, filterData, welltype!).pipe(
      tap((fieldGasInjection: ResponseI<FieldGraphData>) =>
        this.store.dispatch(new GetFieldGasInjection(fieldGasInjection.response))
      )
    ).subscribe();
  }

  loadWellGasProduction(aff: string, filterData: DataFilters, welltype?:string): void {
    this.wellMonitoringService.getDataOnTimelapseWell(aff, DataType.GAS_PRODUCTION, filterData,welltype!).pipe(
      tap((fieldGasProduction: ResponseI<FieldGraphData>) =>
        this.store.dispatch(new GetFieldGasProduction(fieldGasProduction.response))
      )
    ).subscribe();
  }

  loadWellLiquidProduction(aff: string, filterData: DataFilters, welltype?:string): void {
    this.wellMonitoringService.getDataOnTimelapseWell(aff, DataType.LIQUID_PRODUCTION, filterData,welltype!).pipe(
      tap((fieldLiquidProduction: ResponseI<FieldGraphData>) =>
        this.store.dispatch(new GetFieldLiquidProduction(fieldLiquidProduction.response))
      )
    ).subscribe();
  }

  loadWellWaterInjection(aff: string, filterData: DataFilters, welltype?:string): void {
    this.wellMonitoringService.getDataOnTimelapseWell(aff, DataType.WATER_INJECTION, filterData, welltype!).pipe(
      tap((fieldWaterInjection: ResponseI<FieldGraphData>) =>
        this.store.dispatch(new GetFieldWaterInjection(fieldWaterInjection.response))
      )
    ).subscribe();
  }


  loadWellOilProduction(aff: string, filterData: DataFilters, welltype?:string): void {
    this.wellMonitoringService.getDataOnTimelapseWell(aff, DataType.OIL_PRODUCTION, filterData, welltype!).pipe(
      tap((fieldOilProduction: ResponseI<FieldGraphData>) =>
        this.store.dispatch(new GetFieldOilProduction(fieldOilProduction.response))
      )
    ).subscribe();
  }

  loadFieldGasInjection(aff: string, filterData: DataFilters,welltype?:string): void {
    this.fieldMonitoringService.getDataOnTimelapse(aff, DataType.GAS_INJECTION, filterData).pipe(
      tap((fieldGasInjection: ResponseI<FieldGraphData>) =>
        this.store.dispatch(new GetFieldGasInjection(fieldGasInjection.response))
      )
    ).subscribe();
  }

  loadFieldGasProduction(aff: string, filterData: DataFilters, welltype?:string): void {
    this.fieldMonitoringService.getDataOnTimelapse(aff, DataType.GAS_PRODUCTION, filterData).pipe(
      tap((fieldGasProduction: ResponseI<FieldGraphData>) =>
        this.store.dispatch(new GetFieldGasProduction(fieldGasProduction.response))
      )
    ).subscribe();
  }

  loadFieldLiquidProduction(aff: string, filterData: DataFilters, welltype?:string): void {
    this.fieldMonitoringService.getDataOnTimelapse(aff, DataType.LIQUID_PRODUCTION, filterData).pipe(
      tap((fieldLiquidProduction: ResponseI<FieldGraphData>) =>
        this.store.dispatch(new GetFieldLiquidProduction(fieldLiquidProduction.response))
      )
    ).subscribe();
  }

  loadFieldWaterInjection(aff: string, filterData: DataFilters, welltype?:string): void {
    this.fieldMonitoringService.getDataOnTimelapse(aff, DataType.WATER_INJECTION, filterData).pipe(
      tap((fieldWaterInjection: ResponseI<FieldGraphData>) =>
        this.store.dispatch(new GetFieldWaterInjection(fieldWaterInjection.response))
      )
    ).subscribe();
  }

  loadUserName(): void {
    this.authService.currentUser.pipe(
      tap((user: AccountInfo | null) => {
        if (user) {
          this.moniterService.logAuthenticteUserContext(user.username, user?.localAccountId);
        }
        if (user?.name) { this.store.dispatch(new GetUserName(user.name)); }
      })
    ).subscribe();
  }

  loadWellsDailyData(aff: string, wellType: string, filterData: DataFilters, well:string): void {
    this.wellMonitoringService.getWellstypeDailyData(aff, wellType, filterData, well).pipe(
      tap((response: ResponseI<WellDailyData[]>) =>
        this.store.dispatch(wellType === 'producer' ?
          new GetProducerWellsDailyData(response.response) :
          new GetInjectorWellsDailyData(response.response))
      )).subscribe();
  }

  loadFieldWellsDailyData(aff: string, wellType: WellType, filterData: DataFilters): void {
    this.fieldMonitoringService.getWellsDailyData(aff, wellType, filterData).pipe(
      tap((response: ResponseI<WellDailyData[]>) =>
        this.store.dispatch(wellType === WellType.PRODUCER ?
          new GetProducerWellsDailyData(response.response) :
          new GetInjectorWellsDailyData(response.response))
      )).subscribe();
  }

  loadWellsOptimesData(aff: string, wellType: string, filterData: DataFilters,well:string): void {
    this.wellMonitoringService.getWellOptimesforWell(aff, wellType, filterData, well).pipe(
      tap((response: ResponseI<WellOptimesData[]>) =>
        this.store.dispatch(wellType === 'producer' ?
          new GetProducerWellsOptimesData(response.response) :
          new GetInjectorWellsOptimesData(response.response))
      )).subscribe();
}

  loadFieldWellsOptimesData(aff: string, wellType: WellType, filterData: DataFilters): void {
    this.fieldMonitoringService.getWellsOptimes(aff, wellType, filterData).pipe(
      tap((response: ResponseI<WellOptimesData[]>) =>
        this.store.dispatch(wellType === WellType.PRODUCER ?
          new GetProducerWellsOptimesData(response.response) :
          new GetInjectorWellsOptimesData(response.response))
      )).subscribe();
}

  loadFieldRangeDates(aff: string, filterData: DataFilters): void {

    this.fieldMonitoringService.getFieldRangeDates(aff, filterData).pipe(
      tap((response: ResponseI<FieldRangeDates>) => {
        this.store.dispatch(new GetFieldRangeDates(response.response));
        this.store.dispatch(new GetCatalogRangeDates(response.response));
        this.store.dispatch(new GetQcRangeDates(response.response));
        this.store.dispatch(new GetInferenceRangeDates(response.response));
        this.store.dispatch(new GetFieldRangeDates1(response.response));
        this.store.dispatch(new GetFieldRangeDates2(response.response));
        this.store.dispatch(new GetFieldRangeDates3(response.response));
        this.store.dispatch(new GetAllocationRangeDates(response.response));
        
      })
    ).subscribe();
  }
  loadCatalogRangeDates(aff: string, filterData: DataFilters): void {

    this.fieldMonitoringService.getFieldRangeDates(aff, filterData).pipe(
      tap((response: ResponseI<FieldRangeDates>) => {
        this.store.dispatch(new GetFieldRangeDates(response.response));
        this.store.dispatch(new GetCatalogRangeDates(response.response));
      })
    ).subscribe();
  }

  loadWellRangeDates(aff: string, filterData: DataFilters, well:string, typeofWell:string): void {
    this.wellMonitoringService.getwellRangeDates(aff, filterData, well, typeofWell).pipe(
      tap((response: ResponseI<FieldRangeDates>) => {
        this.store.dispatch(new GetFieldRangeDates(response.response));
        this.store.dispatch(new GetCatalogRangeDates(response.response));
        this.store.dispatch(new GetQcRangeDates(response.response));
        this.store.dispatch(new GetInferenceRangeDates(response.response));
        this.store.dispatch(new GetFieldRangeDates1(response.response));
        this.store.dispatch(new GetFieldRangeDates2(response.response));
        this.store.dispatch(new GetFieldRangeDates3(response.response));
       
      })
    ).subscribe();
  }

  loadFieldRangeDatesInjector(aff: string, filterData: DataFilters, well_type: string): void {

    this.buildModelService.getFieldRangeDates(aff, filterData, well_type).pipe(
      tap((response: ResponseI<FieldRangeDates>) => {
        this.store.dispatch(new GetFieldRangeDates(response.response));
        this.store.dispatch(new GetQcRangeDates(response.response));
        this.store.dispatch(new GetInferenceRangeDates(response.response));
        this.store.dispatch(new GetFieldRangeDates1(response.response));
        this.store.dispatch(new GetFieldRangeDates2(response.response));
        this.store.dispatch(new GetFieldRangeDates3(response.response));
       
      })
    ).subscribe();
  }
  setFieldRangeDates(dateRange: FieldRangeDates): void {

    this.store.dispatch(new GetFieldRangeDates(dateRange));
  }

  setCatalogeRangeDates(dateRange: FieldRangeDates): void {
    this.store.dispatch(new GetCatalogRangeDates(dateRange));
  }

  setFieldRangeDates1(dateRange: FieldRangeDates): void {

    this.store.dispatch(new GetFieldRangeDates1(dateRange));

  }

  setFieldRangeDates2(dateRange: FieldRangeDates): void 
  {
    this.store.dispatch(new GetFieldRangeDates2(dateRange));
  }

  setFieldRangeDates3(dateRange: FieldRangeDates): void 
  {
    this.store.dispatch(new GetFieldRangeDates3(dateRange));
  }


  loadMLResult(aff: string, selected_well: SelectedWellTypeInterface, mlRunParams: MLRunParams, flagData?: string): void {
    this.store.dispatch(new GetMLRunParams(mlRunParams));

    this.buildModelService.getMLResult(aff, selected_well, mlRunParams, flagData).pipe(
      tap((response: ResponseI<MachineLearningData>) =>
        this.store.dispatch(new GetMachineLearningData(response.response))
      )
    ).subscribe();
  }
  loadMLResultFromSaveMl(response: MachineLearningData, mlRunParams: MLRunParams): void {
    this.store.dispatch(new GetMLRunParams(mlRunParams));
    this.store.dispatch(new GetMachineLearningData(response));
  }

  setSelectedWell(selectedWell: SelectedWellTypeInterface): void {
    this.store.dispatch(new GetSelectedWell(selectedWell));
  }

  loadDataForMLGraph(aff: string, attribute: string,
    filterData: DataFiltersInference,
    attributeType: AttributeType):
    Observable<ResponseI<AttributeData>> {
    return this.buildModelService.getAttributeData(aff, attribute, filterData, attributeType).pipe(
      tap((response: ResponseI<AttributeData>) =>
        this.store.dispatch(new GetAttributeData(response.response))
      )
    );
  }

  loadDataForMLGraphInjector(aff: string, attribute: string,
    filterData: DataFiltersInference,
   attributeTypeInjector: AttributeTypeInjector):
    Observable<ResponseI<AttributeData>> {
    return this.buildModelService.getAttributeDataInjector(aff, attribute, filterData, attributeTypeInjector).pipe(
      tap((response: ResponseI<AttributeData>) =>
        this.store.dispatch(new GetAttributeData(response.response))
      )
    );
  }
  loadWellTestStatus(aff: string, filterData: DataFilters, flagData?: string): void {

    this.buildModelService.getWellTestStatus(aff, filterData, flagData).pipe(
      tap((response: ResponseI<string[]>) =>
        this.store.dispatch(new GetWellTestStatus(response.response))
      )
    ).subscribe();
  }

  loadWellQcTableData(aff: string, filterData: DataFilters): void {
    this.buildModelService.getWellQcTableData(aff, filterData).pipe(
      tap((response: ResponseI<WellQcTableData[]>) =>
        this.store.dispatch(new GetWellQcTableData(response.response))
      )
    ).subscribe();
  }

  loadWellQcTableDataInjector(aff: string, filterData: DataFilters, well_type: string): void {

    this.buildModelService.getWellQcTableDataInjector(aff, filterData, well_type).pipe(
      tap((response: ResponseI<WellQcTableData[]>) =>
        this.store.dispatch(new GetWellQcTableData(response.response))
      )
    ).subscribe();
  }


  setQcRangeDates(dateRange: FieldRangeDates): void {
    this.store.dispatch(new GetQcRangeDates(dateRange));
  }

  setAllocationRangeDates(dateRange: FieldRangeDates): void {
    this.store.dispatch(new GetAllocationRangeDates(dateRange));
  }

  setCatalogRangeDates(dateRange: FieldRangeDates): void {
     this.store.dispatch(new GetCatalogRangeDates(dateRange));
  }

  setInferenceRangeDates(dateRange: FieldRangeDates): void {
    this.store.dispatch(new GetInferenceRangeDates(dateRange));
  }

  loadSaveModelData(aff: string, savModelParams: SaveModelParams, flagData?: boolean): void {

    this.buildModelService.getSaveModel(aff, savModelParams, flagData).pipe(
      tap((response: ResponseI<string>) => {
        this.store.dispatch(new GetSaveModelData(response.response))
      })
    ).subscribe();
  }

  loadSavedModelTableData(aff: string, field: string, asset: string, well?: string, user?: string, flagData?: boolean) {

    this.buildModelService.getSaveModelTableData(aff, field, asset, well, user, flagData).pipe(
      tap((response: ResponseI<SaveModelTableData[]>) => {

        let responseSaveModelTableData = response.response;
          this.store.dispatch(new GetSaveModelTableData(responseSaveModelTableData))
      })
    ).subscribe();
  }

  loadModelCatalogueTableData(aff: string, asset: string, field: string, well?: string, user?: string) {

    this.buildModelService.getModelCatalogueTableData(aff, asset, field, well, user).pipe(
      tap((response: ResponseI<ModelCatalogueData[]>) => {
        let responseModelCatatlogue = response.response;
        this.store.dispatch(new GetModelCatalogueTableData(responseModelCatatlogue))
      })
    ).subscribe();
  }

  loadModelCatalogueTableData1(aff: string, filterData: DataFilters, well?: string, user?: string) {

    this.buildModelService.getModelCatalogueTableData1(aff, filterData, well, user).pipe(
      tap((response: ResponseI<ModelCatalogueData[]>) => {
        let responseModelCatatlogue = response.response;
          this.store.dispatch(new GetModelCatalogueTableData(responseModelCatatlogue))
      })
    ).subscribe();
  }

  setRunMlParams(runMLPars: MLRunParams) {
    this.store.dispatch(new GetMLRunParams(runMLPars));
  }

  setMLParamsOnclickSavedItem(mlPrams: MLRunParams |undefined) {
    this.selectedRunMLParams.next(mlPrams);
  }
  setselectedAffiliate(affiliate: string) {
    this.selectedAffiliate.next(affiliate);
  }

  loadTrainingVisualzeData(aff: string, asset: string, field: string, well: string, target: string, well_type:string, target_source: string, wellStatus: string, colunmSourceValue:string, max_date?: Date, min_date?: Date,
    max_date1?:Date,min_date1?:Date, max_date2?:Date,min_date2?:Date,max_date3?:Date,min_date3?:Date,
    inference_min_date?: Date, inference_max_date?: Date, flagData?: string) {

    this.buildModelService.getTrainingVisualizeData(aff, asset, field, well, target, well_type, target_source, wellStatus, colunmSourceValue, max_date, min_date,
      max_date1,min_date1, max_date2,min_date2, max_date3,min_date3, 
      inference_min_date, inference_max_date, flagData).pipe(
      tap((response: ResponseI<TrainingDataVisualizationInterface>) => {
        this.store.dispatch(new GetTrainingDataVisualization(response.response));
      })
    ).subscribe();
  }



  loadFeaturesVisualzeData(aff: string, asset: string, field: string, well: string, well_type: string, selectedInputSource:string, selectedFeatureSource:string, selectedUserAdded:any[],tomreTableName?:string, max_date?: Date, min_date?: Date,
    max_date1?: Date, min_date1?: Date ,max_date2?: Date, min_date2?: Date,max_date3?: Date, min_date3?: Date,
    inference_min_date?: Date, inference_max_date?: Date) {

    this.buildModelService.getFeaturesVisualizeData(aff, asset, field, well, well_type, selectedInputSource, selectedFeatureSource, selectedUserAdded,tomreTableName, max_date, min_date,
      max_date1,min_date1,  max_date2,min_date2,  max_date3,min_date3, 
      inference_min_date, inference_max_date).pipe(
      tap((response: ResponseI<FeatureDataVisualizationInterface>) => {
        this.store.dispatch(new GetFeatureDataVisualization(response.response));
      })
    ).subscribe();
  }

  setClearMlSettings(clearMLSettings: boolean) {
    this.clearMLSettings.next(clearMLSettings);
  }
  getTMoreColumnInfo(aff: string, target: string, target_source: string,well_type:string, colunmSourceValue:string): Observable<ResponseI<TMoreColumnsInfo>> {
    return this.buildModelService.getTMoreColumnInfo(aff, target, target_source,well_type,colunmSourceValue);
  }
  loadColunmSource(aff: string, target: string, target_source: string,well_type:string): Observable<ResponseI<any>> {

    return this.buildModelService.loadColunmSource(aff,target, target_source,well_type);
  }

  loadTmoreColunm(aff: string): Observable<ResponseI<any>> {

    return this.buildModelService.loadTmoreColunm(aff);
  }
  loadFeatureList(aff: string,tableName:string, field:string, asset: string, max_date: any, min_date:any, well: string): Observable<ResponseI<any>> {

    return this.buildModelService.loadFeatureList(aff,tableName, field, asset, max_date, min_date, well);
  }

  GetDownloadExcelDataCataglog(aff: string, mdoelData:any): Observable<ResponseI<any>> {
    return this.buildModelService.DownloadExcelDataCataglog(aff, mdoelData);

  }
  updateSaveModel(aff: string, model_name: string, model_description: string, run_id: string): Observable<ResponseI<any>> {
    return this.buildModelService.updateSaveModel(aff, model_name, model_description, run_id);
  }
  deleteSaveModel(aff: string, run_id: string,asset:string,well_name:string,model_name:string,target:string): Observable<ResponseI<any>> {
    return this.buildModelService.deleteSaveModel(aff, run_id,asset,well_name,model_name,target);
  }

  getModelStatus(aff: string, asset: string, well_name: string, target: string,run_id:string): Observable<StageResponse> {
    return this.buildModelService.getModelStatus(aff, asset, well_name, target,run_id);
  }
  getModelStageToProduction(aff: string, asset: string, well_name: string, target: string,run_id:string,model_name:string): Observable<ResponseI<any>> {
    return this.buildModelService.stageToProduction(aff, asset, well_name, target,run_id,model_name);
  }
  getModelStageToArchive(aff: string, asset: string, well_name: string, target: string,run_id:string,model_name:string): Observable<ResponseI<any>> {
    return this.buildModelService.stageToArchive(aff, asset, well_name, target,run_id,model_name);
  }
  callBackAllocationApi(aff: string, reqObj:any): Observable<any> {
    return this.backAllocationService.callBackAllocation(aff,reqObj);
  }

  loadFieldRangeDatesForAllocation(aff: string, filterData: DataFilters): void {
    this.backAllocationService.getFieldRangeDatesForAllocation(aff, filterData).pipe(
      tap((response: ResponseI<FieldRangeDates>) => {
       this.store.dispatch(new GetAllocationRangeDates(response.response));
      })
    ).subscribe();
  }

  callVFMMOdelsList(aff: string, reqObj:any): Observable<ResponseI<VFMModel[]>> {
    return this.backAllocationService.getVFMModelsList(aff,reqObj);
  }

  saveBackAllocation(aff: string, reqObj:any): Observable<any> {
    return this.backAllocationService.saveBackAllocation(aff,reqObj);
  }
  getSavedAllocationsData(aff: string, user?:string):void {
    this.backAllocationService.getSavedBackAllocation(aff, user).pipe(
      tap((response: ResponseI<SaveAllocationData[]>) => {
      let res:any=response;
       this.store.dispatch(new GetAllocatioCatalogueTableData(res));
      })
    ).subscribe();
  }

  saveMergeModel(aff: string, reqObj:any): Observable<any> {
    return this.mergeModelService.saveMergeModel(aff,reqObj);
  }

  getSavedMergeModel(aff: string, user: string) {
    return this.mergeModelService.getSavedMergeModel(aff, user).pipe(
      tap((response: ResponseI<SaveMergeModel[]>) => {
        let getMergeModel = response.response;
        this.store.dispatch(new GetMergeModelData(getMergeModel))
      })
    ).subscribe();
  }

  getSavedMergeModelData(aff: string, user: string): void {
    this.mergeModelService.getSavedMergeModel(aff, user).pipe(
      tap((response: ResponseI<string>) => {
        this.store.dispatch(new GetMergeModelData(response.response))
      })
    ).subscribe();
  }

  editMergeModel(aff: string, reqObj: any ): Observable<ResponseI<any>> {
    return this.mergeModelService.editMergeModel(aff, reqObj);
  }

  deleteMergeModel(aff: string, model_name:string): Observable<ResponseI<any>> {
    return this.mergeModelService.deleteMeregeModel(aff, model_name);
  }
}

