export const PS_PRODUCTION_SETTINGS:string[]=['PS_Production_data','VFM_Models','PS_Production_Monitoring'];
export const PS_PRODUCTION_ATTRIBUTE:string[]=['Qoil_c_e','MFM_Oil'];
export const PS_PRODUCTION_MONITORING_ATTRIBUTE:string[]=['Qoil','MPFM_Qoil'];
export const PS_PRODUCTION_ALLOCATION_SETTINGS:string[]=['PS_daily_production_allocation'];
export const PRODUCTION_ALLOCATION_SETTINGS_ATTRIBUTE:string[]=['Qoil_c_d'];

export const PS_PRODUCTION_SETTINGS_QWAT:string[]=['PS_Production_data','VFM_Models','PS_Production_Monitoring']
export const PS_PRODUCTION_ATTRIBUTE_QWAT:string[]=['Qwat_c_e','MFM_Water']
export const PS_PRODUCTION_MONITORING_ATTRIBUTE_QWAT:string[]=['Qwat','MPFM_Qwat']
export const PS_PRODUCTION_ALLOCATION_SETTINGS_QWAT:string[]=['PS_daily_production_allocation']
export const PRODUCTION_ALLOCATION_SETTINGS_ATTRIBUTE_QWAT:string[]=['Qwat_c_d']

export const PS_PRODUCTION_SETTINGS_QGAS:string[]=['PS_Production_data','VFM_Models','PS_Production_Monitoring']
export const PS_PRODUCTION_ATTRIBUTE_QGAS:string[]=['Qgas_c_e','MFM_Gas']
export const PS_PRODUCTION_MONITORING_ATTRIBUTE_QGAS:string[]=['Qgas','MPFM_Qgas']
export const PS_PRODUCTION_ALLOCATION_SETTINGS_QGAS:string[]=['PS_daily_production_allocation']
export const PRODUCTION_ALLOCATION_SETTINGS_ATTRIBUTE_QGAS:string[]=['Qgas_c_d']

export const PS_PRODUCTION_ATTRIBUTE_QLIQ:string[]=['Qliq','MFM_Liq']
export const PS_PRODUCTION_MONITORING_ATTRIBUTE_QLIQ:string[]=['Qliq','MPFM_Qliq']
export const PRODUCTION_ALLOCATION_SETTINGS_ATTRIBUTE_QLIQ:string[]=['Qoil_c_d']

export const PS_PRODUCTION_ATTRIBUTE_GOR:string[]=['GOR','MFM_GOR']
export const PS_PRODUCTION_MONITORING_ATTRIBUTE_GOR:string[]=['GOR','MPFM_GOR']
export const PRODUCTION_ALLOCATION_SETTINGS_ATTRIBUTE_GOR:string[]=['Qgas_c_d']

export const PS_PRODUCTION_ATTRIBUTE_WCT:string[]=['WCT','MFM_WCT']
export const PS_PRODUCTION_MONITORING_ATTRIBUTE_WCT:string[]=['WCT','MPFM_WCT']
export const PRODUCTION_ALLOCATION_SETTINGS_ATTRIBUTE_WCT:string[]=['Qwat_c_d']


