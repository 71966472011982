import { VFMModel } from './../../../../../types/VFMModel.interface';
import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { FieldRangeDates } from 'src/modules/types';
import {
  PRODUCTION_ALLOCATION_SETTINGS_ATTRIBUTE,
  PRODUCTION_ALLOCATION_SETTINGS_ATTRIBUTE_QGAS,
  PRODUCTION_ALLOCATION_SETTINGS_ATTRIBUTE_QWAT,
  PS_PRODUCTION_ALLOCATION_SETTINGS,
  PS_PRODUCTION_ALLOCATION_SETTINGS_QGAS,
  PS_PRODUCTION_ALLOCATION_SETTINGS_QWAT,
  PS_PRODUCTION_ATTRIBUTE,
  PS_PRODUCTION_ATTRIBUTE_QGAS,
  PS_PRODUCTION_ATTRIBUTE_QWAT,
  PS_PRODUCTION_MONITORING_ATTRIBUTE,
  PS_PRODUCTION_MONITORING_ATTRIBUTE_QGAS,
  PS_PRODUCTION_MONITORING_ATTRIBUTE_QWAT,
  PS_PRODUCTION_SETTINGS,
  PS_PRODUCTION_SETTINGS_QGAS,
  PRODUCTION_ALLOCATION_SETTINGS_ATTRIBUTE_GOR,
  PS_PRODUCTION_MONITORING_ATTRIBUTE_GOR,
  PS_PRODUCTION_ATTRIBUTE_GOR,
  PRODUCTION_ALLOCATION_SETTINGS_ATTRIBUTE_QLIQ,
  PS_PRODUCTION_MONITORING_ATTRIBUTE_QLIQ,
  PS_PRODUCTION_ATTRIBUTE_QLIQ,
  PS_PRODUCTION_SETTINGS_QWAT,
  PS_PRODUCTION_MONITORING_ATTRIBUTE_WCT,
  PRODUCTION_ALLOCATION_SETTINGS_ATTRIBUTE_WCT,
  PS_PRODUCTION_ATTRIBUTE_WCT,
} from './allocation-settings-const';

@Component({
  selector: 'wag-allocation-settinggs',
  template: `
    <form [formGroup]="allocationFormGroup">
      <ng-container
        formArrayName="wells_allocation"
        *ngFor="let group of getWellControl(allocationFormGroup); let i = index"
      >
        <div class="card-container" [formGroupName]="i">
          <div class="wel-name">
            <div>
              {{ group.value.well_name }}
            </div>
            <div class="traget-container">
              <div class="pd-8">
                <ul
                  [ngClass]="
                    group.value.enbleQWG ? 'in-active-list' : 'active-list'
                  "
                >
                  <li *ngFor="let tgt1 of target1">
                    {{ tgt1 }}
                  </li>
                </ul>
              </div>
              <mat-slide-toggle
                color="warn"
                [disabled]="isDisable"

                (change)="changeTarget($event, i)"
                formControlName="enbleQWG"
              ></mat-slide-toggle>

              <div class="pd-8">
                <ul
                  [ngClass]="
                    group.value.enbleQWG ? 'active-list' : 'in-active-list'
                  "
                >
                  <li *ngFor="let tgt2 of target2">
                    {{ tgt2 }}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div>
            <ng-container
              formArrayName="target"
              *ngFor="let tgt of getTargetControl(group); let j = index"
            >
              <div [formGroupName]="j">
                <span>
                  <label class="m-rl-2"> {{ tgt.value.tgt_s1 }}</label>

                  <mat-form-field appearance="outline" floatLabel="always">
                    <mat-select
                      required
                      disableOptionCentering
                      panelClass="my-panel-class"
                      formControlName="tbl_s1"
                      (selectionChange)="onModelTypeChane(j)"
                    >
                      <mat-option
                        *ngFor="let ps_setting of PS_PRODUCTION_SETTINGS"
                        [value]="ps_setting"
                      >
                        {{ ps_setting }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>

                  <ng-container *ngIf="tgt.value.tgt_s1 === 'Qoil'">
                    <ng-container
                      *ngIf="tgt.value.tbl_s1 === 'PS_Production_data'"
                    >
                      <mat-form-field appearance="outline" floatLabel="always">
                        <mat-select
                          required
                          disableOptionCentering
                          panelClass="my-panel-class"
                          formControlName="col_s1"
                        >
                          <mat-option
                            *ngFor="let attribute of PS_PRODUCTION_ATTRIBUTE"
                            [value]="attribute"
                          >
                            {{ attribute }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="tgt.value.tgt_s1 === 'Qliq'">
                    <ng-container
                      *ngIf="tgt.value.tbl_s1 === 'PS_Production_data'"
                    >
                      <mat-form-field appearance="outline" floatLabel="always">
                        <mat-select
                          required
                          disableOptionCentering
                          panelClass="my-panel-class"
                          formControlName="col_s1"
                        >
                          <mat-option
                            *ngFor="
                              let attribute of PS_PRODUCTION_ATTRIBUTE_QLIQ
                            "
                            [value]="attribute"
                          >
                            {{ attribute }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="tgt.value.tgt_s1 === 'Qgas'">
                    <ng-container
                      *ngIf="tgt.value.tbl_s1 === 'PS_Production_data'"
                    >
                      <mat-form-field appearance="outline" floatLabel="always">
                        <mat-select
                          required
                          disableOptionCentering
                          panelClass="my-panel-class"
                          formControlName="col_s1"
                        >
                          <mat-option
                            *ngFor="
                              let attribute of PS_PRODUCTION_ATTRIBUTE_QGAS
                            "
                            [value]="attribute"
                          >
                            {{ attribute }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="tgt.value.tgt_s1 === 'GOR'">
                    <ng-container
                      *ngIf="tgt.value.tbl_s1 === 'PS_Production_data'"
                    >
                      <mat-form-field appearance="outline" floatLabel="always">
                        <mat-select
                          required
                          disableOptionCentering
                          panelClass="my-panel-class"
                          formControlName="col_s1"
                        >
                          <mat-option
                            *ngFor="
                              let attribute of PS_PRODUCTION_ATTRIBUTE_GOR
                            "
                            [value]="attribute"
                          >
                            {{ attribute }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="tgt.value.tgt_s1 === 'Qwat'">
                    <ng-container
                      *ngIf="tgt.value.tbl_s1 === 'PS_Production_data'"
                    >
                      <mat-form-field appearance="outline" floatLabel="always">
                        <mat-select
                          required
                          disableOptionCentering
                          panelClass="my-panel-class"
                          formControlName="col_s1"
                        >
                          <mat-option
                            *ngFor="
                              let attribute of PS_PRODUCTION_ATTRIBUTE_QWAT
                            "
                            [value]="attribute"
                          >
                            {{ attribute }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="tgt.value.tgt_s1 === 'WCT'">
                    <ng-container
                      *ngIf="tgt.value.tbl_s1 === 'PS_Production_data'"
                    >
                      <mat-form-field appearance="outline" floatLabel="always">
                        <mat-select
                          required
                          disableOptionCentering
                          panelClass="my-panel-class"
                          formControlName="col_s1"
                        >
                          <mat-option
                            *ngFor="
                              let attribute of PS_PRODUCTION_ATTRIBUTE_WCT
                            "
                            [value]="attribute"
                          >
                            {{ attribute }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="tgt.value.tgt_s1 === 'Qoil'">
                    <ng-container
                      *ngIf="tgt.value.tbl_s1 === 'PS_Production_Monitoring'"
                    >
                      <mat-form-field appearance="outline" floatLabel="always">
                        <mat-select
                          required
                          disableOptionCentering
                          panelClass="my-panel-class"
                          formControlName="col_s1"
                        >
                          <mat-option
                            *ngFor="
                              let attribute of PS_PRODUCTION_MONITORING_ATTRIBUTE
                            "
                            [value]="attribute"
                          >
                            {{ attribute }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="tgt.value.tgt_s1 === 'Qliq'">
                    <ng-container
                      *ngIf="tgt.value.tbl_s1 === 'PS_Production_Monitoring'"
                    >
                      <mat-form-field appearance="outline" floatLabel="always">
                        <mat-select
                          required
                          disableOptionCentering
                          panelClass="my-panel-class"
                          formControlName="col_s1"
                        >
                          <mat-option
                            *ngFor="
                              let attribute of PS_PRODUCTION_MONITORING_ATTRIBUTE_QLIQ
                            "
                            [value]="attribute"
                          >
                            {{ attribute }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="tgt.value.tgt_s1 === 'Qgas'">
                    <ng-container
                      *ngIf="tgt.value.tbl_s1 === 'PS_Production_Monitoring'"
                    >
                      <mat-form-field appearance="outline" floatLabel="always">
                        <mat-select
                          required
                          disableOptionCentering
                          panelClass="my-panel-class"
                          formControlName="col_s1"
                        >
                          <mat-option
                            *ngFor="
                              let attribute of PS_PRODUCTION_MONITORING_ATTRIBUTE_QGAS
                            "
                            [value]="attribute"
                          >
                            {{ attribute }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="tgt.value.tgt_s1 === 'GOR'">
                    <ng-container
                      *ngIf="tgt.value.tbl_s1 === 'PS_Production_Monitoring'"
                    >
                      <mat-form-field appearance="outline" floatLabel="always">
                        <mat-select
                          required
                          disableOptionCentering
                          panelClass="my-panel-class"
                          formControlName="col_s1"
                        >
                          <mat-option
                            *ngFor="
                              let attribute of PS_PRODUCTION_MONITORING_ATTRIBUTE_GOR
                            "
                            [value]="attribute"
                          >
                            {{ attribute }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="tgt.value.tgt_s1 === 'Qwat'">
                    <ng-container
                      *ngIf="tgt.value.tbl_s1 === 'PS_Production_Monitoring'"
                    >
                      <mat-form-field appearance="outline" floatLabel="always">
                        <mat-select
                          required
                          disableOptionCentering
                          panelClass="my-panel-class"
                          formControlName="col_s1"
                        >
                          <mat-option
                            *ngFor="
                              let attribute of PS_PRODUCTION_MONITORING_ATTRIBUTE_QWAT
                            "
                            [value]="attribute"
                          >
                            {{ attribute }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="tgt.value.tgt_s1 === 'WCT'">
                    <ng-container
                      *ngIf="tgt.value.tbl_s1 === 'PS_Production_Monitoring'"
                    >
                      <mat-form-field appearance="outline" floatLabel="always">
                        <mat-select
                          required
                          disableOptionCentering
                          panelClass="my-panel-class"
                          formControlName="col_s1"
                        >
                          <mat-option
                            *ngFor="
                              let attribute of PS_PRODUCTION_MONITORING_ATTRIBUTE_WCT
                            "
                            [value]="attribute"
                          >
                            {{ attribute }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="tgt.value.tbl_s1 === 'VFM_Models'">
                    <mat-form-field appearance="outline" floatLabel="always">
                      <mat-select
                        [compareWith]="compareObjects"
                        formControlName="col_s1"
                        required
                        disableOptionCentering
                        panelClass="my-panel-class"
                      >
                        <ng-container
                          *ngFor="
                            let well of getVFMModelList(
                              group.value.well_name,
                              tgt.value.tgt_s1
                            );
                            trackBy: trackByWell
                          "
                        >
                          <mat-option
                            [value]="well"
                            matTooltip="{{ well.save_model_time + ', ' + well.description + ', ' +   well.username }}"
                            [matTooltipPosition]="'right'"
                            >{{ well.model_name }}</mat-option
                          >
                        </ng-container>
                      </mat-select>
                    </mat-form-field>
                  </ng-container>
                </span>
                <mat-icon> arrow_forward </mat-icon>

                <span>
                  <label class="m-rl-2"> {{ tgt.value.tgt_s2 }}</label>
                  <mat-form-field appearance="outline" floatLabel="always">
                    <mat-select
                      required
                      disableOptionCentering
                      panelClass="my-panel-class"
                      formControlName="tbl_s2"
                    >
                      <mat-option
                        *ngFor="
                          let allocation_settinggs of PS_PRODUCTION_ALLOCATION_SETTINGS
                        "
                        [value]="allocation_settinggs"
                        >{{ allocation_settinggs }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>

                  <ng-container *ngIf="tgt.value.tgt_s1 === 'Qoil'">
                    <mat-form-field appearance="outline" floatLabel="always">
                      <mat-select
                        required
                        disableOptionCentering
                        panelClass="my-panel-class"
                        formControlName="col_s2"
                      >
                        <mat-option
                          *ngFor="
                            let allocation_settinggs_attr of PRODUCTION_ALLOCATION_SETTINGS_ATTRIBUTE
                          "
                          [value]="allocation_settinggs_attr"
                        >
                          {{ allocation_settinggs_attr }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-container>

                  <ng-container *ngIf="tgt.value.tgt_s1 === 'Qliq'">
                    <mat-form-field appearance="outline" floatLabel="always">
                      <mat-select
                        required
                        disableOptionCentering
                        panelClass="my-panel-class"
                        formControlName="col_s2"
                      >
                        <mat-option
                          *ngFor="
                            let allocation_settinggs_attr of PRODUCTION_ALLOCATION_SETTINGS_ATTRIBUTE_QLIQ
                          "
                          [value]="allocation_settinggs_attr"
                        >
                          {{ allocation_settinggs_attr }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-container>

                  <ng-container *ngIf="tgt.value.tgt_s1 === 'Qgas'">
                    <mat-form-field appearance="outline" floatLabel="always">
                      <mat-select
                        required
                        disableOptionCentering
                        panelClass="my-panel-class"
                        formControlName="col_s2"
                      >
                        <mat-option
                          *ngFor="
                            let allocation_settinggs_attr of PRODUCTION_ALLOCATION_SETTINGS_ATTRIBUTE_QGAS
                          "
                          [value]="allocation_settinggs_attr"
                        >
                          {{ allocation_settinggs_attr }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-container>

                  <ng-container *ngIf="tgt.value.tgt_s1 === 'GOR'">
                    <mat-form-field appearance="outline" floatLabel="always">
                      <mat-select
                        required
                        disableOptionCentering
                        panelClass="my-panel-class"
                        formControlName="col_s2"
                      >
                        <mat-option
                          *ngFor="
                            let allocation_settinggs_attr of PRODUCTION_ALLOCATION_SETTINGS_ATTRIBUTE_GOR
                          "
                          [value]="allocation_settinggs_attr"
                        >
                          {{ allocation_settinggs_attr }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-container>

                  <ng-container *ngIf="tgt.value.tgt_s1 === 'Qwat'">
                    <mat-form-field appearance="outline" floatLabel="always">
                      <mat-select
                        required
                        disableOptionCentering
                        panelClass="my-panel-class"
                        formControlName="col_s2"
                      >
                        <mat-option
                          *ngFor="
                            let allocation_settinggs_attr of PRODUCTION_ALLOCATION_SETTINGS_ATTRIBUTE_QWAT
                          "
                          [value]="allocation_settinggs_attr"
                        >
                          {{ allocation_settinggs_attr }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-container>

                  <ng-container *ngIf="tgt.value.tgt_s1 === 'WCT'">
                    <mat-form-field appearance="outline" floatLabel="always">
                      <mat-select
                        required
                        disableOptionCentering
                        panelClass="my-panel-class"
                        formControlName="col_s2"
                      >
                        <mat-option
                          *ngFor="
                            let allocation_settinggs_attr of PRODUCTION_ALLOCATION_SETTINGS_ATTRIBUTE_WCT
                          "
                          [value]="allocation_settinggs_attr"
                        >
                          {{ allocation_settinggs_attr }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-container>
                </span>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <div class="full-width">
        <wag-date-filters
          [dateRange]="minMaxDateRange"
          [minMaxRange]="minMaxRange"
          (dateRangeChange)="allocationDateRangeChange.emit($event)"
        >
        </wag-date-filters>
      </div>
      <div class="compute">
        <span class="mr-lr">
          <button
            class="primary-btn"
            color="primary"
            (click)="onClickComputeAndExport()"
          >
            Compute
          </button>
        </span>
        <span class="mr-lr">
          <button
            class="primary-btn"
            [disabled]="exportEnabled"
            color="primary"
            (click)="export.emit()"
          >
            Export
          </button>
        </span>
        <span class="mr-lr">
          <button
            class="primary-btn"
            [disabled]="exportEnabled"
            color="primary"
            (click)="save.emit()"
          >
            Save
          </button>
        </span>
      </div>

    </form>
  `,
  styleUrls: ['./allocation-settinggs.component.scss'],
})
export class AllocationSettinggsComponent implements OnChanges {
  PS_PRODUCTION_SETTINGS: string[] = PS_PRODUCTION_SETTINGS;
  PS_PRODUCTION_ATTRIBUTE: string[] = PS_PRODUCTION_ATTRIBUTE;
  PS_PRODUCTION_MONITORING_ATTRIBUTE: string[] =
    PS_PRODUCTION_MONITORING_ATTRIBUTE;
  PS_PRODUCTION_ALLOCATION_SETTINGS: string[] =
    PS_PRODUCTION_ALLOCATION_SETTINGS;
  PRODUCTION_ALLOCATION_SETTINGS_ATTRIBUTE: string[] =
    PRODUCTION_ALLOCATION_SETTINGS_ATTRIBUTE;
  //Qwat
  PS_PRODUCTION_SETTINGS_QWAT: string[] = PS_PRODUCTION_SETTINGS_QWAT;
  PS_PRODUCTION_ATTRIBUTE_QWAT: string[] = PS_PRODUCTION_ATTRIBUTE_QWAT;
  PS_PRODUCTION_MONITORING_ATTRIBUTE_QWAT: string[] =
    PS_PRODUCTION_MONITORING_ATTRIBUTE_QWAT;
  PS_PRODUCTION_ALLOCATION_SETTINGS_QWAT: string[] =
    PS_PRODUCTION_ALLOCATION_SETTINGS_QWAT;
  PRODUCTION_ALLOCATION_SETTINGS_ATTRIBUTE_QWAT: string[] =
    PRODUCTION_ALLOCATION_SETTINGS_ATTRIBUTE_QWAT;

  PS_PRODUCTION_SETTINGS_QGAS: string[] = PS_PRODUCTION_SETTINGS_QGAS;
  PS_PRODUCTION_ATTRIBUTE_QGAS: string[] = PS_PRODUCTION_ATTRIBUTE_QGAS;
  PS_PRODUCTION_MONITORING_ATTRIBUTE_QGAS: string[] =
    PS_PRODUCTION_MONITORING_ATTRIBUTE_QGAS;
  PS_PRODUCTION_ALLOCATION_SETTINGS_QGAS: string[] =
    PS_PRODUCTION_ALLOCATION_SETTINGS_QGAS;
  PRODUCTION_ALLOCATION_SETTINGS_ATTRIBUTE_QGAS: string[] =
    PRODUCTION_ALLOCATION_SETTINGS_ATTRIBUTE_QGAS;

  PS_PRODUCTION_ATTRIBUTE_QLIQ: string[] = PS_PRODUCTION_ATTRIBUTE_QLIQ;
  PS_PRODUCTION_MONITORING_ATTRIBUTE_QLIQ: string[] =
    PS_PRODUCTION_MONITORING_ATTRIBUTE_QLIQ;
  PRODUCTION_ALLOCATION_SETTINGS_ATTRIBUTE_QLIQ: string[] =
    PRODUCTION_ALLOCATION_SETTINGS_ATTRIBUTE_QLIQ;

  PS_PRODUCTION_ATTRIBUTE_GOR: string[] = PS_PRODUCTION_ATTRIBUTE_GOR;
  PS_PRODUCTION_MONITORING_ATTRIBUTE_GOR: string[] =
    PS_PRODUCTION_MONITORING_ATTRIBUTE_GOR;
  PRODUCTION_ALLOCATION_SETTINGS_ATTRIBUTE_GOR: string[] =
    PRODUCTION_ALLOCATION_SETTINGS_ATTRIBUTE_GOR;

  PS_PRODUCTION_ATTRIBUTE_WCT: string[] = PS_PRODUCTION_ATTRIBUTE_WCT;
  PS_PRODUCTION_MONITORING_ATTRIBUTE_WCT: string[] =
    PS_PRODUCTION_MONITORING_ATTRIBUTE_WCT;
  PRODUCTION_ALLOCATION_SETTINGS_ATTRIBUTE_WCT: string[] =
    PRODUCTION_ALLOCATION_SETTINGS_ATTRIBUTE_WCT;

  allocationFormGroup!: FormGroup;

  @Input() allocatedWells: any[] = [];
  @Input() minMaxRange!: FieldRangeDates | null;
  @Input() minMaxDateRange!: FieldRangeDates | null;
  @Input() filterData!: any;
  @Input() VFMModelsList: VFMModel[] = [];
  @Input() target1: string[] = [];
  @Input() target2: string[] = [];
  @Output() allocationDateRangeChange: EventEmitter<FieldRangeDates> =
    new EventEmitter<FieldRangeDates>();
  @Output() compute: EventEmitter<any> = new EventEmitter<any>();
  @Output() export: EventEmitter<any> = new EventEmitter<any>();
  @Output() save: EventEmitter<any> = new EventEmitter<any>();

  exportEnabled: boolean = true;

  allocatedWellsSettings: any[] = [];
  vfmModelList: VFMModel[] = [];
  isChanges: boolean = false;
  isDisable: boolean = true;

  constructor(private formBuilder: FormBuilder) {}

  initiateForm() {
    this.allocationFormGroup = this.formBuilder.group({
      wells_allocation: this.formBuilder.array([]),
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.allocatedWells != undefined && this.isChanges === false) {
      if (changes.allocatedWells.currentValue) {
        this.allocatedWellsSettings = [];
        this.allocatedWellsSettings = changes.allocatedWells.currentValue;
        this.initiateForm();
        this.initAllocationSettings();
      }
      else{

      }
    }
    if (changes.VFMModelsList != undefined) {
      if (changes.VFMModelsList.currentValue) {
        this.vfmModelList = [];
        this.vfmModelList = changes.VFMModelsList.currentValue;
      }
    }
    this.isChanges = false;

    if (this.checkIncluded(this.target1, ['Qoil', 'Qwat'])) {
      this.isDisable = false;
    } else if (this.checkIncluded(this.target1, ['Qoil', 'Qwat', 'Qgas'])) {
      this.isDisable = false;
    } else {
      this.isDisable = true;
    }
  }

  initAllocationSettings() {
    let control = this.allocationFormGroup.controls[
      'wells_allocation'
    ] as FormArray;
    this.allocatedWellsSettings.forEach((data, index) => {
      control.push(this.initWell(data));
      data.target.forEach((tgt: any) => {
        (control.controls[index].get('target') as FormArray).push(
          this.addTarget(tgt)
        );
      });
    });
  }

  initWell(data: any) {
    return this.formBuilder.group({
      well_name: data.well_name,
      enbleQWG:[data.enbleQWG],
      target: this.formBuilder.array([]),
    });
  }

  addTarget(target: any) {
    return this.formBuilder.group({
      tgt_s1: target.tgt_s1,
      tbl_s1: target.tbl_s1,
      col_s1: target.col_s1,
      tgt_s2: target.tgt_s2,
      tbl_s2: target.tbl_s2,
      col_s2: target.col_s2,
    });
  }
  getWellArray(): FormArray {
    return this.allocationFormGroup.get('wells_allocation') as FormArray;
  }
  getTargetArray(index: number): FormArray {
    return this.getWellArray().at(index).get('target') as FormArray;
  }

  onModelTypeChane(index: any) {
    //do nothing
  }

  changeTarget(event: any, index: number) {
    const ctrl = this.getTargetArray(index);
    let ctrlValues: any = ctrl.value;
    if (event.checked) {
      ctrlValues.map((target: any) => {
        if (target.tgt_s1 === 'Qoil') {
          target.tgt_s1 = 'Qliq';
          target.col_s1 = 'Qliq';
        }
        if (target.tgt_s1 === 'Qgas') {
          target.tgt_s1 = 'GOR';
          target.col_s1 = 'GOR';
        }
        if (target.tgt_s1 === 'Qwat') {
          target.tgt_s1 = 'WCT';
          target.col_s1 = 'WCT';
        }
      });
    } else {
      ctrlValues.map((target: any) => {
        if (target.tgt_s1 === 'Qliq') {
          target.tgt_s1 = 'Qoil';
          target.col_s1 = 'Qoil_c_e';
        }
        if (target.tgt_s1 === 'GOR') {
          target.tgt_s1 = 'Qgas';
          target.col_s1 = 'Qgas_c_e';
        }
        if (target.tgt_s1 === 'WCT') {
          target.tgt_s1 = 'Qwat';
          target.col_s1 = 'Qwat_c_e';
        }
      });
    }
    ctrl.setValue(ctrlValues);
  }

  getWellControl(form: any) {
    return form.controls.wells_allocation.controls;
  }
  getTargetControl(form: any) {
    return form.controls.target.controls;
  }

  onClickComputeAndExport() {
    this.compute.emit(this.allocationFormGroup.value);
    this.isChanges = true;
    this.exportEnabled = false;
  }

  getVFMModelList(well: string, target: string): any[] {
    let filterData: any = this.vfmModelList
      .filter((data) => {
        return data.well_id === well && data.target_value === target;
      })
      .map((data) => {
          return {
            model_name: data.model_name,
            run_id: data.run_id,
            username: data.username,
            save_model_time: data.save_model_time,
            description: data.description,
            target_source_json: data.target_source_json
          }
          
      });
    return filterData;
  }

  trackByWell(index: number, VFMModel: VFMModel) {
    return VFMModel ? VFMModel.run_id : undefined;
  }

  checkIncluded(availableItema: any, neededItem: any) {
    if (availableItema.length !== neededItem.length) {
      return false;
    }
    let match = true;
    for (let i = 0; i < neededItem.length; i++) {
      if (!availableItema.includes(neededItem[i])) {
        match = false;
        break;
      }
    }
    return match;
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1.model_name === o2.model_name && o1.run_id === o2.run_id;
  }

}
