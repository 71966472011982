import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Layout } from 'plotly.js';
import { MLConfidenceData, PlotData } from 'src/modules/types';

@Component({
  selector: 'wag-model-performance',
  template: `
    <section class="metrics-section">
      <div class="metrics-table">
        <h3>Performance metric <img src="./../../../../assets/info.svg" class="info-icon" [mdePopoverTriggerFor]="PerformanceDataPopOver"
        mdePopoverTriggerOn="hover" mdePopoverPositionY="above" mdePopoverPositionX="after"></h3>
        <wag-metrics-table [metricsData]="mlConfidenceData?.metrics" [target]="target"></wag-metrics-table>

        <mde-popover #PerformanceDataPopOver="mdePopover" [mdePopoverOverlapTrigger]="false">
    <mat-card class="card-width" >
      <mat-card-content>
        <ul>
          <li> <b>MAE :</b> Mean Average Error. The result is in the unit of the predicted target. </li>
</ul>
<ul>
<li> <b>RMSE :</b> Root Mean Squared Error. This metric can be seen as a normalized <br>distance between the predicted value and the observed one. It is more <br> sensitive to outliers, and is in the unit of the predicted value.</li>
</ul>

<ul>
<li> <b>R2 :</b> This metric measures to what extent the input variables explain the <br> variance of the predicted value. It is between 0 and 1 (the more the better).</li>
</ul>

<ul>
<li>  <b>MAPE :</b> Mean Absolute Percentage Error. It is a relative version of the MAE.<br> It is expressed as percentage (the less the better). </li>
</ul>
</mat-card-content>
  </mat-card>
</mde-popover>
      </div>

      <mat-icon>arrow_forward</mat-icon>
      
      <div class="metrics-graph">
        <h3>Metrics Visualization</h3>

        <div class="switch-btn">
          <span [class.active]="displayedMetricsGraph === 'dispersion'">Error dispersion</span>
          <mat-slide-toggle color="warn" 
                            (change)="onSwitchPlot($event)" 
                            [class.active]="displayedMetricsGraph === 'boxplot'">Model performance</mat-slide-toggle>
        </div>

        <wag-well-graph [data]="mlMetricsGraph"
                        [layout]="mlMetricsLayout"></wag-well-graph>
      </div>
    </section>

    <section class="learning-section">
    <ng-container>
      <h3>Learning curves  <img src="./../../../../assets/info.svg" class="info-icon" [mdePopoverTriggerFor]="learningDataPopOver"
        mdePopoverTriggerOn="hover" mdePopoverPositionY="above" mdePopoverPositionX="after"></h3>
      <wag-well-graph [data]="mlLearningCurvesGraph"
                      [layout]="mlLearningCurvesLayout"></wag-well-graph>

  <mde-popover #learningDataPopOver="mdePopover" [mdePopoverOverlapTrigger]="false">
    <mat-card class="card-width" >
      <mat-card-content>
          <li> <b>Definition :</b> Line plot of learning (y-axis) over experience (x-axis) </li>
        <br>
      <li><b>Composed of two lines :</b>
        <ul>
          <li><b>Train Learning Curve :</b> Learning curve calculated from the training dataset <br>that gives an idea of how well the model is learning.</li>
          <li><b>Test Learning Curve :</b> Learning curve calculated from a hold-out testing <br>dataset that gives an idea of how well the model is generalizing.</li>
        </ul>
      </li>

      <li><b>An example of a good fit learning curve :</b>
        <ul>
          <li>The plot of training loss decreases to a point of stability.</li>
          <li>The plot of validation loss decreases to a point of stability and has a small<br> gap with the training loss.</li>
        </ul>
      </li>
   </mat-card-content>
  </mat-card>
</mde-popover>
</ng-container>
    </section>
  `,
  styleUrls: ['./model-performance.component.scss']
})
export class ModelPerformanceComponent {
  @Input() mlConfidenceData!: MLConfidenceData;
  @Input() mlMetricsGraph!: PlotData[];
  @Input() mlMetricsLayout!: Partial<Layout>;
  @Input() displayedMetricsGraph!: string;
  @Output() switchMetricsGraph: EventEmitter<string> = new EventEmitter<string>();
  @Input() mlLearningCurvesGraph!: PlotData[];
  @Input() mlLearningCurvesLayout!: Partial<Layout>;
  @Input() target!: string;

  onSwitchPlot(event: MatSlideToggleChange): void {
    this.switchMetricsGraph.emit(event.checked ? 'boxplot' : 'dispersion');
  }
}
