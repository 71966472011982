import {Action, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {GetFieldOilProduction} from './actions';
import {FieldGraphData} from 'src/modules/types';

@State<FieldGraphData>({
  name: 'fieldOilProduction',
  defaults: {} as FieldGraphData
})
@Injectable()
export class FieldOilProductionState {
  @Action(GetFieldOilProduction)
  getFieldOilProduction(context: StateContext<FieldGraphData>, {payload}: GetFieldOilProduction): void {
    context.setState(payload);
  }
}
