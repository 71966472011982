import {Action, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {GetFieldRangeDates1} from './actions';
import {FieldRangeDates} from 'src/modules/types';

@State<FieldRangeDates>({
  name: 'fieldRangeDates1',
  defaults: undefined
})
@Injectable()
export class FieldRangeDatesState1 {
  @Action(GetFieldRangeDates1)
  getFieldRangeDates(context: StateContext<FieldRangeDates>, {payload}: GetFieldRangeDates1): void {
    context.setState(payload);
  }
}
