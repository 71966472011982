import { PlotDataCore } from 'src/modules/types';
export const ML_CORELATION_PLOT_DATA: PlotDataCore[] = [
  { name: "Corelation Coefficient", type: "heatmap"}
];


export const ML_CORELATION_LAYOUT: any = {
  legend: {
    orientation: 'h',
    xanchor: 'center',
    x: 0.5,
    y: -0.3
  },
  hovermode: 'x',
  annotations: [],
  yaxis: {
    autorange:"reversed",
    mask:"mask"
 }
  
};
