import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SaveModelTableData, ValueSelect } from 'src/modules/types';



@Component({
  selector: 'wag-save-model-tables',
  template: `

  <mat-expansion-panel>
  <mat-expansion-panel-header>
  <h2>Model Catalogue</h2>

  </mat-expansion-panel-header>
  <h3><mat-checkbox color="warn" [formControl]="chkMyModels" >Show only my models</mat-checkbox></h3>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons ></mat-paginator>
  <table *ngIf="saveModelTableData" mat-table [dataSource]="dataSourceTable">
    <ng-container matColumnDef="model_name">
      <th mat-header-cell *matHeaderCellDef> Model Name </th>
      <td mat-cell *matCellDef="let element" (click)="onClickItem(element)"> {{element.model_name}} </td>
    </ng-container>

    <ng-container matColumnDef="model_description">
      <th mat-header-cell *matHeaderCellDef> Description </th>
      <td mat-cell *matCellDef="let element"> {{element.model_description}} </td>
    </ng-container>

    <ng-container matColumnDef="save_model_time">
      <th mat-header-cell *matHeaderCellDef> Date time </th>
      <td mat-cell *matCellDef="let element"> {{element.save_model_time}} </td>
    </ng-container>

    <ng-container matColumnDef="user_id">
      <th mat-header-cell *matHeaderCellDef> User </th>
      <td mat-cell *matCellDef="let element"> {{element.user_id}} </td>
    </ng-container>

    <ng-container matColumnDef="model_status">
      <th mat-header-cell *matHeaderCellDef> Model Status </th>
      <td mat-cell *matCellDef="let element">

      <span *ngIf="element.model_status==='PRODUCTION'" style="font-weight:500;">{{element.model_status}}</span>
      <span *ngIf="element.model_status!=='PRODUCTION'">{{element.model_status}}</span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

</mat-expansion-panel>

  `,
  styleUrls: ['./save-model-tables.component.scss']
})
export class SaveModelTablesComponent implements OnInit, OnChanges {
  @Input() saveModelTableData!: SaveModelTableData[] | null;
  @Output() savedModelItemClick: EventEmitter<SaveModelTableData> = new EventEmitter<SaveModelTableData>();
  @Output() clickOnMyModelsOnly: EventEmitter<ValueSelect> = new EventEmitter<ValueSelect>();

  dataSourceTable!: MatTableDataSource<SaveModelTableData>
  private paginator!: MatPaginator;
  chkMyModels!: FormControl;
  displayedColumns: string[] = ['model_name', 'model_description', 'save_model_time', 'user_id','model_status'];

  constructor() {
    //  pass
  }
  ngOnChanges() {

    this.dataSourceTable = new MatTableDataSource(this.saveModelTableData!);
    this.setDataSourceAttributes();
  }

  ngOnInit(): void {

    this.dataSourceTable = new MatTableDataSource(this.saveModelTableData!);
    this.chkMyModels = new FormControl(true);
    this.chkMyModels.valueChanges.subscribe(values => {
      this.clickOnMyModelsOnly.emit({ control: 'chkMyModels', value: values })
    })

  }
  @ViewChild(MatPaginator, { static: false }) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSourceTable.paginator = this.paginator;
  }

  onClickItem(element: SaveModelTableData) {

    this.savedModelItemClick.emit(element);
  }

}
