import {Action, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {GetCatalogRangeDates} from './actions';
import {FieldRangeDates} from 'src/modules/types';

@State<FieldRangeDates>({
  name: 'catalogRangeDates',
  defaults: undefined
})
@Injectable()
export class CatalogRangeDatesState {
  @Action(GetCatalogRangeDates)
  getCatalogRangeDates(context: StateContext<FieldRangeDates>, {payload}: GetCatalogRangeDates): void {
    context.setState(payload);
  }
}
