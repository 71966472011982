export enum MlTargetSourceLabel {
  'PS Production Test Data'='well_test',
  'PS Production Data'='production_data',
  'PS Production Monitoring'='production_monitoring', 
  'PS Daily Production Allocation'='production_allocation',
}
export enum MlTargetSourceLabelInjector {
  'Injection FallOff Test' = 'falloff_test',
  'PS Injection Data' = 'injection_data',
  'PS Injection Monitoring' = 'injection_monitoring',
  'PS Daily Injection Allocation' = 'injection_allocation',
}
