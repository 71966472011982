import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { GetSaveModelData } from './actions';


@State<string>({
  name: 'saveModelData',
  defaults: {} as string
})
@Injectable()
export class SaveModelDataState {
  @Action(GetSaveModelData)
  getSaveModelParams(context: StateContext<string>, {payload}: GetSaveModelData): void {
    context.setState(payload);
  }
}
