export const PROD_DATA_ATTR:any = [
  'Qliq_c_e-(bbl/d)',
  'Qoil_c_e-(bbl/d)',
  'Qgas_c_e-(kSm3/d)',
  'GOR_e-(Sm3/Sm3)',
  'WCT_e-(%)',
  'GLR_e-(Sm3/Sm3)',
  'Qgl_c_e-(kSm3/d)',
  'BHP-(bar)',
  'BHT-(°C)',
  'WHP-(bar)',
  'WHT-(°C)',
  'Optime-(hr)',
  'Choke Opening-(%)',
  'Choke size-(inch)',
  'Topside P-(bar)',
  'Topside T-(°C)',
  'Qwat_c_e-(bbl/d)'
];


export const POTENTIAL_ATTR = [
  'Qliq-(bbl/d)',
  'Qoil-(bbl/d)',
  'Qgas-(kSm3/d)',
  'GOR-(Sm3/Sm3)',
  'WCT-(%)',
  'GLR-(Sm3/Sm3)',
  'Qwat-(bbl/d)'
];

export const PROD_TEST_ATTR = [
  'Qliq_test-(bbl/d)',
  'Qoil_test-(bbl/d)',
  'Qgas_test-(kSm3/d)',
  'GOR_test-(Sm3/Sm3)',
  'WCT_test-(%)',
  'GLR_test-(Sm3/Sm3)',
  'BHP_test-(bar)',
  'BHT_test-(°C)',
  'WHP_test-(bar)',
  'WHT_test-(bar)',
  'Choke Opening_test-(%)',
  'Choke size_test-(inch)',
  'Qwat_test-(bbl/d)'
];

export const MONITORING_ATTR = [
  'Qliq_mon-(bbl/d)',
  'Qoil_mon-(bbl/d)',
  'Qgas_mon-(kSm3/d)',
  'GOR_mon-(Sm3/Sm3)',
  'WCT_mon-(%)',
  'Qgl_mon-(kSm3/d)',
  'BHP_mon-(bar)',
  'BHT_mon-(°C)',
  'WHP_mon-(bar)',
  'WHT_mon-(°C)',
  'Optime_mon-(hr)',
  'Choke Opening_mon-(%)',
  'Qwat_mon-(bbl/d)'
];

export const PROD_ALLOCATION_ATTR = [
  'Qliq_alloc-(bbl/d)',
  'Qoil_alloc-(bbl/d)',
  'Qgas_alloc-(kSm3/d)',
  'GOR_alloc-(Sm3/Sm3)',
  'WCT_alloc-(%)',
  'GLR_alloc-(Sm3/Sm3)',
  'Qwat_alloc-(bbl/d)'
];
