import {PlotData} from "src/modules/types";

export const PRODUCTION_ALLOCATED_PLOT_DATA: PlotData[] = [
  {id: 'Qliq_d', name: "Qliq Allocated (BBL/d)", type: "scatter", mode: "lines", marker: {color: "purple"}, yaxis: "y1"},
  {id: 'Qoil_c_d', name: "Qoil Allocated (BBL/d)", type: "scatter", mode: "lines", marker: {color: "green"}, yaxis: "y1"},
  {id: "GOR_d", name: "GOR Allocated (v/v)", type: "scatter", mode: "lines", marker: {color: "orange"}, yaxis: "y2"},
  {id: "WCT_d", name: "Water Cut Allocated (%)", type: "scatter", mode: "lines", marker: {color: "blue"}, yaxis: "y3"},
  {id: 'Qgas_c_d', name: "Qgas Allocated (kSm3/d)", type: "scatter", mode: "lines", marker: {color: "red"}, yaxis: "y4"}
 
];

export const FIELD_PRODUCTION_LAYOUT = {
  xaxis: {
    domain: [0.07,0.925],
    range: [0, Date.now()],
    type: "date"
  },

  yaxis: {
    title: "Qoil, Qliq (bbl/d)",
    side: "left",
    position: 0.00,
    rangemode: "nonnegative",
    titlefont: {
      color: "green"
    },
    tickfont: {
      color: "green"
    },
   
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: true,
  },

  yaxis2: {
    title: "GOR (v/v)",
    side: "right",
    position: 0.95,
    rangemode: "nonnegative",
    titlefont: {
      color: "orange"
    },
    tickfont: {
      color: "orange"
    },
    overlaying: 'y',
    showgrid: false,
    zeroline: false,
    showline: false,
    visible: true,
  },
  yaxis3: {
    title: "WCT",
    side: "right",
    position: 1.00,
    rangemode: "nonnegative",
    titlefont: {
      color: "blue"
    },
    tickfont: {
      color: "blue"
    },
    overlaying: 'y',
    showgrid: false,
    zeroline: true,
    showline: false,
    visible: true,
  },
  yaxis4: {
    title: "Qgas (kSm3/d)",
    side: "left",
    position: 0.05,
    rangemode: "nonnegative",
    titlefont: {
      color: "red"
    },
    tickfont: {
      color: "red"
    },
    overlaying: 'y',
    showgrid: false,
    zeroline: true,
    showline: false,
    visible: true,
  },
  legend: {
    orientation: "h",
    side: "top",
    xanchor: "center",
    x: 0.5
  },
  showlegend: true,
  hovermode: "x"
};
