import {Injectable} from '@angular/core';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LoaderService} from '../../services';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  requests: HttpRequest<any>[] = [];

  constructor(private loaderService: LoaderService,
    private _snackBar: MatSnackBar) { }

  removeRequest(req: HttpRequest<any>) {
    const reqIndex = this.requests.indexOf(req);
    if (reqIndex >= 0) {
      this.requests.splice(reqIndex, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.requests.push(req);

    this.loaderService.isLoading.next(true);
    return new Observable(observer => {
      const subscription = next.handle(req).subscribe(
        event => {
          if (event instanceof HttpResponse) {
            this.removeRequest(req);
            observer.next(event);
          }
        },

        err => {
          this.removeRequest(req);
          observer.error(err);
          this._snackBar.open(err.error.error+'!', '', {
            duration: 10000,
            panelClass: 'error-snackbar',
          });
        },

        () => {
          this.removeRequest(req);
          observer.complete();
        }
      );

      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }
}
