import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { FeatureDataVisualizationInterface } from 'src/modules/types';
import { GetFeatureDataVisualization } from './actions';




@State<FeatureDataVisualizationInterface>({
  name: 'Feature_data_visualization',
  defaults: {} as FeatureDataVisualizationInterface
})
@Injectable()
export class FeatureDataVisualizationState {
  @Action(GetFeatureDataVisualization)
  getSaveModelParams(context: StateContext<FeatureDataVisualizationInterface>, {payload}: GetFeatureDataVisualization): void {
    context.setState(payload);
  }
}
