import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Affiliate } from 'src/modules/types';
import { AFFILIATELIST } from '../../home-constant';

@Component({
  selector: 'wag-affiliate-selection',
  template: `
  <span>
  <h2>Select Affiliate:</h2>
  
  <form [formGroup]="affiliateForm" class="well-filters">
  <mat-form-field appearance="outline" floatLabel="always">
    <mat-label>Affiliate</mat-label>
    <mat-select formControlName="affiliateList"
      required disableOptionCentering panelClass="my-panel-class">
      <mat-option *ngFor="let affiliate of AFFILIATELIST" [value]='affiliate.value' >{{ affiliate.displayName }}</mat-option>
    </mat-select>
   
  </mat-form-field>
  <button class="primary-btn" (click)="onclickSetAffiliate()" >Set</button>
</form>
  
  </span>
  `,
  styleUrls: ['./affiliate-selection.component.scss']
})
export class AffiliateSelectionComponent implements OnInit {
  @Input() selectedAffilate!: string;
  AFFILIATELIST: Affiliate[] = [];
  @Output() affiliateSubmit = new EventEmitter();
  affiliateForm: FormGroup = this.formBuilder.group({
    'affiliateList': []
  });

  constructor(private formBuilder: FormBuilder) {
    let data: any = sessionStorage.getItem('rolls');
  
    if (data !== null) {
     
      const roll: any[] = JSON.parse(data);
      if (roll.includes('Admin')) {
        this.AFFILIATELIST = AFFILIATELIST;
        this.affiliateForm.controls['affiliateList'].setValue(this.AFFILIATELIST[0].value);
        this.affiliateSubmit.emit(this.affiliateForm.controls['affiliateList'].value);
      } else {
        let temp: any[] = [];

        temp = roll.map(r => {
          let a = r.split('.');
          let b: string = a[1];
          return b.toLowerCase();
        })
        
        this.AFFILIATELIST = AFFILIATELIST.filter(item => temp.includes(item.value));
        this.affiliateForm.controls['affiliateList'].setValue(this.AFFILIATELIST[0].value);
      }

    }
    
    this.affiliateSubmit.emit(this.affiliateForm.controls['affiliateList'].value);

  }
  ngOnInit(): void {



    this.affiliateForm.controls['affiliateList'].setValue(this.selectedAffilate);
  }


  onclickSetAffiliate() {
    this.affiliateSubmit.emit(this.affiliateForm.controls['affiliateList'].value);
  }
}
