import {Action, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {MLRunParams} from 'src/modules/types';
import {GetMLRunParams} from './actions';


@State<MLRunParams>({
  name: 'mlRunParams',
  defaults: {} as MLRunParams
})
@Injectable()
export class MLRunParamsState {
  @Action(GetMLRunParams)
  getMLRunParams(context: StateContext<MLRunParams>, {payload}: GetMLRunParams): void {
    context.setState(payload);
  }
}
