import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'displayNA'
})
export class DisplayNAPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value === null) return 'N/A';
    else return value;
  }

}
