import {Component, Input, Output, EventEmitter} from '@angular/core';
import {CardData} from '../../containers';
import {CardTitle} from './card.types';

@Component({
  selector: 'wag-card',
  template: `
    <div *ngIf="name !== 'flaring'" class="container" [class.selected]="isSelected">
      <div class="header">
        <div class="name">{{CardTitle[name]}}</div>
        <mat-checkbox color="warn" [checked]="isSelected" (change)="toggleGraph.emit(name)"></mat-checkbox>
      </div>

      <div class="data">
        <div class="left">
          <div class="data-item">
            <span>Estimated</span>
            <span class="item-value">{{data?.estimated | number:'1.0-0' | displayNA}}</span>
          </div>
          <div class="data-item">
            <span>Potential</span>
            <span class="item-value">{{data?.potential | number:'1.0-0' | displayNA}}</span>
          </div>
          <div class="data-item">
            <span>Modeled</span>
            <span class="item-value">{{data?.modeled | number:'1.0-0' | displayNA}}</span>
          </div>
        </div>

        <div class="right">
          <div class="data-item">
            <span class="right-item-label">Allocated</span>
            <span class="item-value">{{data?.allocated | number:'1.0-0' | displayNA}}</span>
          </div>
        </div>
      </div>

      <div class="footer">
        <div class="toggle-button-status">{{isSelected ? 'close graph' : 'open graph'}}</div>
        <button mat-icon-button (click)="toggleGraph.emit(name)">
          <mat-icon>{{isSelected ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
        </button>
      </div>
    </div>

    <div *ngIf="name === 'flaring'" class="container flaring">
      <div class="header">{{CardTitle['flaring']}}</div>

      <div class="data">
        <span class="label">Allocated</span>
        <span class="value">{{data?.allocated | number | displayNA}}</span>
      </div>
    </div>
  `,
  styleUrls: ['./card.component.scss']
})
export class CardComponent {
  @Input() name!: string;
  @Input() data!: CardData | undefined;
  @Input() isSelected: boolean = false;
  @Output() toggleGraph = new EventEmitter<string>();

  CardTitle: any = CardTitle;
}
