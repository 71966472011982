import {Action, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {GetAttributeData} from './actions';
import {AttributeData} from 'src/modules/types';

@State<AttributeData>({
  name: 'attributeData',
  defaults: {} as AttributeData
})
@Injectable()
export class AttributeDataState {
  @Action(GetAttributeData)
  getAttributeData(context: StateContext<AttributeData>, {payload}: GetAttributeData): void {
    context.setState(payload);
  }
}
