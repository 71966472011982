import {Component, Input} from '@angular/core';
import {WellQcTableData} from 'src/modules/types';

@Component({
  selector: 'wag-well-qc-table',
  template: `
   <div class="section-container"> 
    <mat-accordion multi="true">
     <mat-expansion-panel>
          <mat-expansion-panel-header>
          <h3>Percentage of data by attributes</h3>
          </mat-expansion-panel-header>
         <ng-container  *ngIf="!wellTypeInjector">
         <table *ngIf="wellQcTableData" mat-table [dataSource]="wellQcTableData">
         <ng-container matColumnDef="attribute">
           <th mat-header-cell *matHeaderCellDef> Attributes </th>
           <td mat-cell *matCellDef="let element" [ngClass]="{'colorRed':element.both < 50,'colorOrange':element.both >=50 && element.both<80, 'colorGreen':element.both >=80 }"> {{element.attribute}} </td>
         </ng-container>
         
         <ng-container matColumnDef="pdms">
           <th mat-header-cell *matHeaderCellDef> PDMS - Production Data </th>
           <td mat-cell *matCellDef="let element" [ngClass]="{'colorRed':element.pdms < 50,'colorOrange':element.pdms >=50 && element.pdms<80, 'colorGreen':element.pdms >=80 }"> {{element.pdms}} </td>
         </ng-container>
         
         <ng-container matColumnDef="pi">
           <th mat-header-cell *matHeaderCellDef> PI - Production Monitoring </th>
           <td mat-cell *matCellDef="let element" [ngClass]="{'colorRed':element.pi < 50,'colorOrange':element.pi >=50 && element.pi<80, 'colorGreen':element.pi >=80 }"> {{element.pi}} </td>
         </ng-container>
         
         <ng-container matColumnDef="both">
           <th mat-header-cell *matHeaderCellDef> Both </th>
           <td mat-cell *matCellDef="let element" [ngClass]="{'colorRed':element.both < 50,'colorOrange':element.both >=50 && element.both<80, 'colorGreen':element.both >=80 }"> {{element.both}} </td>
         </ng-container>
     
         <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
         <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
       </table>
         </ng-container>
         <ng-container>
         <ng-container  *ngIf="wellTypeInjector">
         <table *ngIf="wellQcTableData" mat-table [dataSource]="wellQcTableData">
         <ng-container matColumnDef="attribute">
           <th mat-header-cell *matHeaderCellDef> Attributes </th>
           <td mat-cell *matCellDef="let element" [ngClass]="{'colorRed':element.both < 50,'colorOrange':element.both >=50 && element.both<80, 'colorGreen':element.both >=80 }"> {{element.attribute}} </td>
         </ng-container>
         
         <ng-container matColumnDef="pdms">
           <th mat-header-cell *matHeaderCellDef>PDMS - Injection Data </th>
           <td mat-cell *matCellDef="let element" [ngClass]="{'colorRed':element.pdms < 50,'colorOrange':element.pdms >=50 && element.pdms<80, 'colorGreen':element.pdms >=80 }"> {{element.pdms}} </td>
         </ng-container>
         
         <ng-container matColumnDef="pi">
           <th mat-header-cell *matHeaderCellDef>PI - Injection Monitoring </th>
           <td mat-cell *matCellDef="let element" [ngClass]="{'colorRed':element.pi < 50,'colorOrange':element.pi >=50 && element.pi<80, 'colorGreen':element.pi >=80 }"> {{element.pi}} </td>
         </ng-container>
         
         <ng-container matColumnDef="both">
           <th mat-header-cell *matHeaderCellDef> Both </th>
           <td mat-cell *matCellDef="let element" [ngClass]="{'colorRed':element.both < 50,'colorOrange':element.both >=50 && element.both<80, 'colorGreen':element.both >=80 }"> {{element.both}} </td>
         </ng-container>
     
         <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
         <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
       </table>
         </ng-container>
         </ng-container>
        </mat-expansion-panel>
      </mat-accordion>
      </div>
  `,
  styleUrls: ['./well-qc-table.component.scss']
})
export class WellQcTableComponent {

  @Input() wellQcTableData!: WellQcTableData[] | null;
  @Input() wellTypeInjector!:boolean;
  displayedColumns: string[] = ['attribute', 'pdms', 'pi', 'both'];

}
