import { Component, Input } from '@angular/core';
import { MLConfidenceMetrics } from 'src/modules/types';

@Component({
  selector: 'wag-metrics-table',
  template: `
    <table class="performance-table">
      <thead>
        <tr>
          <th></th>
          <ng-container *ngIf="(target==='Qoil' || target==='Qliq' || target==='Qwat' || target==='Qwinj')">
          <th>R2</th>
          <th>MAE (bbl/d)</th>
          <th>MAPE (%)</th>
          <th>RMSE (bbl/d)</th>
          </ng-container>
          <ng-container *ngIf="(target==='Qgas' || target==='Qginj')">
          <th>R2 </th>
          <th>MAE ( kSm3/d)</th>
          <th>MAPE (%)</th>
          <th>RMSE ( kSm3/d)</th>
          </ng-container>
          <ng-container *ngIf="(target==='GOR')">
          <th>R2 </th>
          <th>MAE (Sm3/Sm3)</th>
          <th>MAPE (%)</th>
          <th>RMSE (Sm3/Sm3)</th>
          </ng-container>
          <ng-container *ngIf="(target==='WCT')">
          <th>R2</th>
          <th>MAE (%)</th>
          <th>MAPE (%)</th>
          <th>RMSE (%)</th>
          </ng-container>
        </tr>
      </thead>
  
      <tbody>
        <tr>
          <td>Training</td>
         <ng-container *ngIf="(target==='Qoil' || target==='Qliq' || target==='Qwat' || target==='Qwinj' );else training">
         <td>{{ metricsData?.r2_train | number: '1.0-2' }}</td>
         <td>{{ convertUint(metricsData?.mae_train)| number: '1.0-2' }}</td>
         <td>{{ metricsData?.mape_train | number: '1.0-0' }} %</td>
         <td>{{ convertUint(metricsData?.rmse_train)| number: '1.0-2' }}</td>
         </ng-container>
         <ng-template #training>
         <td>{{ metricsData?.r2_train | number: '1.0-2' }}</td>
         <td>{{ metricsData?.mae_train | number: '1.0-2' }}</td>
         <ng-container *ngIf="(target==='WCT' );else testingWCT">
         <td>{{ convertUintWCT(metricsData?.mae_test) | number: '1.0-0' }} %</td>
         </ng-container>
         <ng-template #testingWCT>
         <td>{{ metricsData?.mape_test | number: '1.0-0' }} %</td>
         </ng-template>
         <td>{{ metricsData?.rmse_train | number: '1.0-2' }}</td>
         </ng-template>
        </tr>
        <tr>
          <td>Testing</td>
         <ng-container *ngIf="(target==='Qoil' || target==='Qliq' || target==='Qwat' || target==='Qwinj');else testing">
         <td>{{ metricsData?.r2_test | number: '1.0-2' }}</td>
         <td>{{ convertUint(metricsData?.mae_test) | number: '1.0-2' }}</td>
         <td>{{ metricsData?.mape_test | number: '1.0-0' }} %</td>
         <td>{{ convertUint(metricsData?.rmse_test) | number: '1.0-2' }}</td>
         </ng-container>
         <ng-template #testing>
         <td>{{ metricsData?.r2_test | number: '1.0-2' }}</td>
         <td>{{ metricsData?.mae_test | number: '1.0-2' }}</td>
         <ng-container *ngIf="(target==='WCT' );else testingWCT">
         <td>{{ convertUintWCT(metricsData?.mae_test) | number: '1.0-0' }} %</td>
         </ng-container>
         <ng-template #testingWCT>
         <td>{{ metricsData?.mape_test | number: '1.0-0' }} %</td>
         </ng-template>
         <td>{{ metricsData?.rmse_test | number: '1.0-2' }}</td>
         </ng-template>
        </tr>
      </tbody>
    </table>
  `,
  styleUrls: ['./metrics-table.component.scss']
})
export class MetricsTableComponent {
  @Input() metricsData!: MLConfidenceMetrics | undefined;
  @Input() target!: string;


  convertUint(unit: any): string {
    if (unit != undefined) {
      let unitConversion = unit * 6.29;
      return unitConversion + '';
    }else{
      return 0+'';
    }
     
  }
  convertUintWCT(unit: any): string {
    if (unit != undefined) {
      let unitConversion = unit * 100;
      return unitConversion + '';
    }else{
      return 0+'';
    }
     
  }
}
